import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Row,
} from 'design-react-kit/dist/design-react-kit';
import { Table } from 'semantic-ui-react';

const Skeleton = () => {
  return (
    <>
      {[0, 1, 2].map(i => (
        <>
          <Table.Row className="skeleton-template" style={{ minHeight: '15px' }}>
            <Table.Cell>
              <Card noWrapper teaser className="p-0">
                <CardBody className="p-0">
                  <CardText className="p-0" style={{ minHeight: '15px' }}></CardText>
                </CardBody>
              </Card>
            </Table.Cell>
            <Table.Cell>
              <Card noWrapper teaser className="p-0">
                <CardBody className="p-0">
                  <CardText className="p-0" style={{ minHeight: '15px' }}></CardText>
                </CardBody>
              </Card>
            </Table.Cell>
            <Table.Cell>
              <Card noWrapper teaser className="p-0">
                <CardBody className="p-0">
                  <CardText className="p-0" style={{ minHeight: '15px' }}></CardText>
                </CardBody>
              </Card>
            </Table.Cell>
          </Table.Row>
        </>
      ))}
    </>
  );
};

export default Skeleton;
