import { defineMessages } from 'react-intl';

const messages = defineMessages({
  defaultFieldset: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  dataMap: {
    id: 'Data map',
    defaultMessage: 'Data map',
  },
  resourcesAsLayer: {
    id: 'Resources as layer',
    defaultMessage: 'Resources as layer',
  },
  resources: {
    id: 'Resources',
    defaultMessage: 'Resources',
  },
  resourcesDescription: {
    id: 'Add resources to show on the map',
    defaultMessage: 'Add resources to show on the map',
  },
  resource: {
    id: 'Resource',
    defaultMessage: 'Resource',
  },
  resourceUrl: {
    id: 'Resource url',
    defaultMessage: 'Resource url',
  },
  resourceUrlDescription: {
    id: 'Use DYNPARAM for the dynamic parameter value.',
    defaultMessage: 'Use DYNPARAM for the dynamic parameter value.',
  },
  resourceTitle: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  gisField: {
    id: 'GIS field',
    defaultMessage: 'GIS field',
  },
  marker: {
    id: 'Marker',
    defaultMessage: 'Marker',
  },
  default: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  fromProperty: {
    id: 'From property',
    defaultMessage: 'From property',
  },
  imageProperty: {
    id: 'Image from property',
    defaultMessage: 'Image from property',
  },
  markerIcon: {
    id: 'Marker icon',
    defaultMessage: 'Marker icon',
  },
  value: {
    id: 'Value',
    defaultMessage: 'Value',
  },
  icon: {
    id: 'Icon',
    defaultMessage: 'Icon',
  },
  color: {
    id: 'Color',
    defaultMessage: 'Color',
  },
  valueMapping: {
    id: 'Value mapping',
    defaultMessage: 'Value mapping',
  },
  valueMappingDescription: {
    id:
      'Insert an icon for each field value, if a value is not mapped a default value will be inserted',
    defaultMessage:
      'Insert an icon for each field value, if a value is not mapped a default value will be inserted',
  },
  defaultIcon: {
    id: 'Default icon',
    defaultMessage: 'Default icon',
  },
  defaultColor: {
    id: 'Default color',
    defaultMessage: 'Default color',
  },
  iconsSize: {
    id: 'Icon size',
    defaultMessage: 'Icon size',
  },
  sizeSmall: {
    id: 'Size small',
    defaultMessage: 'Small',
  },
  sizeMedium: {
    id: 'Size medium',
    defaultMessage: 'Medium',
  },
  sizeNormal: {
    id: 'Size normal',
    defaultMessage: 'Normal',
  },
  sizeBig: {
    id: 'Size big',
    defaultMessage: 'Big',
  },
  propertyField: {
    id: 'Property field',
    defaultMessage: 'Property field',
  },
  label: {
    id: 'Label',
    defaultMessage: 'Label',
  },
  tooltip: {
    id: 'Tooltip',
    defaultMessage: 'Tooltip',
  },
  popupTitle: {
    id: 'Popup title',
    defaultMessage: 'Popup title',
  },
  popupText: {
    id: 'Popup text',
    defaultMessage: 'Popup text',
  },
  zoom: {
    id: 'Zoom',
    defaultMessage: 'Zoom',
  },
  zoomDescription: {
    id: 'Click on the map to set the current zoom',
    defaultMessage: 'Click on the map to set the current zoom',
  },
  centerLat: {
    id: 'Center latitude',
    defaultMessage: 'Center latitude',
  },
  centerLatDescription: {
    id: 'Click on the map to set the current latitude',
    defaultMessage: 'Click on the map to set the current latitude',
  },
  centerLong: {
    id: 'Center longitude',
    defaultMessage: 'Center longitude',
  },
  centerLongDescription: {
    id: 'Click on the map to set the current longitude',
    defaultMessage: 'Click on the map to set the current longitude',
  },
  labelDescription: {
    id: 'Use the placeholder $\'{record.myfield}\' to insert the heat of "myfield" into the string',
    defaultMessage:
      'Use the placeholder $\'{record.myfield}\' to insert the heat of "myfield" into the string',
  },
  grouped: {
    id: 'Enable grouping points',
    defaultMessage: 'Enable grouping points',
  },
  groupedDescription: {
    id: 'Check if you want group nearby points',
    defaultMessage: 'Check if you want group nearby points',
  },
  resourcesKml: {
    id: 'KML resources',
    defaultMessage: 'KML resources',
  },
  resourcesKmlDescription: {
    id: 'Add KML resources to show on the map',
    defaultMessage: 'Add KML resources to show on the map',
  },
  alwaysVisible: {
    id: 'KML always visible',
    defaultMessage: 'Always visible',
  },
  alwaysVisibleDescription: {
    id: 'KML always visible description',
    defaultMessage: 'Check if this resource must be always visible',
  },
  mapHeight: {
    id: 'Map height',
    defaultMessage: 'Map height',
  },
  range: {
    id: 'Range',
    defaultMessage: 'Range',
  },
  minValue: {
    id: 'Min value',
    defaultMessage: 'Min value',
  },
  maxValue: {
    id: 'Max value',
    defaultMessage: 'Max value',
  },
  valueRangeMapping: {
    id: 'Value range mapping',
    defaultMessage: 'Value range mapping',
  },
  valueRangeMappingDescription: {
    id:
      'Insert an icon for each field range value, if a value is not mapped a default value will be inserted. Min and max are both included.',
    defaultMessage:
      'Insert an icon for each field range value, if a value is not mapped a default value will be inserted. Min and max are both included.',
  },
  dataUrl: {
    id: 'Data URL',
    defaultMessage: 'Data URL',
  },
  dataUrlDescription: {
    id: 'Insert the URL for data if the main url is used only for geographical data.',
    defaultMessage: 'Insert the URL for data if the main url is used only for geographical data.',
  },
  mappingAttrGeo: {
    id: 'Mapping attribute 1',
    defaultMessage: 'Mapping attribute 1',
  },
  mappingAttrData: {
    id: 'Mapping attribute 2',
    defaultMessage: 'Mapping attribute 2',
  },
  resTitleJavascript: {
    id: 'Javascript for dynamic title',
    defaultMessage: 'Javascript for dynamic title',
  },
  urlparamvalues: {
    id: 'URL parameter values',
    defaultMessage: 'URL parameter values',
  },
  urlparamvaluesDescription: {
    id: 'URL parameter values description',
    defaultMessage: `A custom javascript wich returns an array of objects (label: '', value: '', disabled: '' )`,
  },
  defaultUrlParamValue: {
    id: 'Default value for URL Parameter',
    defaultMessage: 'Default value for URL Parameter',
  },
});

const MapSchema = ({ intl }) => ({
  title: intl.formatMessage(messages.dataMap),
  fieldsets: [
    {
      id: 'default',
      title: intl.formatMessage(messages.defaultFieldset),
      fields: [
        'resources_as_layers',
        'resources',
        'resources_kml',
        'zoom',
        'center_lat',
        'center_long',
        'map_height',
      ],
    },
  ],
  properties: {
    resources_as_layers: {
      title: intl.formatMessage(messages.resourcesAsLayer),
      type: 'boolean',
    },
    resources: {
      title: intl.formatMessage(messages.resources),
      description: intl.formatMessage(messages.resourcesDescription),
      widget: 'object_list_inline',
      schema: ResourceSchema({ intl }),
    },
    resources_kml: {
      title: intl.formatMessage(messages.resourcesKml),
      description: intl.formatMessage(messages.resourcesKmlDescription),
      widget: 'object_list_inline',
      schema: ResourceKmlSchema({ intl }),
    },
    zoom: {
      title: intl.formatMessage(messages.zoom),
      description: intl.formatMessage(messages.zoomDescription),
      defaultValue: 2,
      minimum: 0,
      type: 'integer',
    },
    center_lat: {
      title: intl.formatMessage(messages.centerLat),
      description: intl.formatMessage(messages.centerLatDescription),
      defaultValue: 0,
      type: 'number',
    },
    center_long: {
      title: intl.formatMessage(messages.centerLong),
      description: intl.formatMessage(messages.centerLongDescription),
      defaultValue: 0,
      type: 'number',
    },
    map_height: {
      title: intl.formatMessage(messages.mapHeight),
      defaultValue: 600,
      type: 'number',
    },
  },
  required: [],
});

const ResourceSchema = ({ intl }) => ({
  title: intl.formatMessage(messages.resource),
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: [
        'url',
        'title',
        'gis_field',
        'data_url',
        'mapping_attr_geo',
        'mapping_attr_data',
        'url_parameter_values',
        'url_parameter_default',
      ],
    },
    {
      id: 'marker_style',
      title: intl.formatMessage(messages.marker),
      fields: [
        'grouped',
        'marker_icon',
        'default_icon',
        'default_color',
        'icon_size',
        'marker_icon_field',
        'marker_icon_field_values',
        'marker_icon_field_ranges',
        'label',
        'tooltip',
        'popup_title',
        'popup_text',
      ],
    },
  ],
  properties: {
    url: {
      title: intl.formatMessage(messages.resourceUrl),
      description: intl.formatMessage(messages.resourceUrlDescription),
      type: 'string',
      widget: 'url',
    },
    title: {
      title: intl.formatMessage(messages.resourceTitle),
    },
    gis_field: {
      title: intl.formatMessage(messages.gisField),
      choices: [],
    },
    data_url: {
      title: intl.formatMessage(messages.dataUrl),
      description: intl.formatMessage(messages.dataUrlDescription),
      type: 'string',
      widget: 'url',
    },
    mapping_attr_geo: {
      title: intl.formatMessage(messages.mappingAttrGeo),
      choices: [],
    },
    mapping_attr_data: {
      title: intl.formatMessage(messages.mappingAttrData),
      choices: [],
    },
    url_parameter_values: {
      title: intl.formatMessage(messages.urlparamvalues),
      description: intl.formatMessage(messages.urlparamvaluesDescription),
      widget: 'textarea',
    },
    url_parameter_default: {
      title: intl.formatMessage(messages.defaultUrlParamValue),
    },
    grouped: {
      title: intl.formatMessage(messages.grouped),
      description: intl.formatMessage(messages.groupedDescription),
      type: 'boolean',
    },
    marker_icon: {
      title: intl.formatMessage(messages.markerIcon),
      choices: [
        ['default', intl.formatMessage(messages.default)],
        ['property', intl.formatMessage(messages.fromProperty)],
        ['image', intl.formatMessage(messages.imageProperty)],
      ],
      default: 'default',
    },
    default_icon: {
      title: intl.formatMessage(messages.defaultIcon),
      widget: 'icon',
    },
    default_color: {
      title: intl.formatMessage(messages.defaultColor),
      widget: 'color',
    },
    icon_size: {
      title: intl.formatMessage(messages.iconsSize),
      choices: [
        ['0.5', intl.formatMessage(messages.sizeSmall)],
        ['0.75', intl.formatMessage(messages.sizeMedium)],
        ['1', intl.formatMessage(messages.sizeNormal)],
        ['1.25', intl.formatMessage(messages.sizeBig)],
      ],
      default: '1',
    },
    marker_icon_field: {
      title: intl.formatMessage(messages.propertyField),
      choices: [],
    },
    marker_icon_field_values: {
      title: intl.formatMessage(messages.valueMapping),
      description: intl.formatMessage(messages.valueMappingDescription),
      widget: 'object_list_inline',
      schema: MarkerIconValueSchem({ intl }),
    },
    marker_icon_field_ranges: {
      title: intl.formatMessage(messages.valueRangeMapping),
      description: intl.formatMessage(messages.valueRangeMappingDescription),
      widget: 'object_list_inline',
      schema: MarkerIconRangeSchema({ intl }),
    },
    label: {
      title: intl.formatMessage(messages.label),
      description: intl.formatMessage(messages.labelDescription),
    },
    tooltip: {
      title: intl.formatMessage(messages.tooltip),
    },
    popup_title: {
      title: intl.formatMessage(messages.popupTitle),
    },
    popup_text: {
      title: intl.formatMessage(messages.popupText),
      widget: 'textarea',
    },
  },
  required: ['url', 'title', 'gis_field', 'marker_icon'],
});

const ResourceKmlSchema = ({ intl }) => ({
  title: intl.formatMessage(messages.resource),
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['url', 'title', 'popover', 'titleJs', 'alwaysVisible'],
    },
  ],
  properties: {
    url: {
      title: intl.formatMessage(messages.resourceUrl),
      type: 'string',
      widget: 'url',
    },
    title: {
      title: intl.formatMessage(messages.resourceTitle),
    },
    popover: {
      title: intl.formatMessage(messages.popupText),
      widget: 'textarea',
    },
    alwaysVisible: {
      title: intl.formatMessage(messages.alwaysVisible),
      description: intl.formatMessage(messages.alwaysVisibleDescription),
      type: 'boolean',
    },
    titleJs: {
      title: intl.formatMessage(messages.resTitleJavascript),
      widget: 'textarea',
    },
  },
  required: ['url', 'title'],
});

const MarkerIconValueSchem = ({ intl }) => ({
  title: intl.formatMessage(messages.value),
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['value', 'icon', 'color'],
    },
  ],
  properties: {
    value: {
      title: intl.formatMessage(messages.value),
    },
    icon: {
      title: intl.formatMessage(messages.icon),
      widget: 'icon',
    },
    color: {
      title: intl.formatMessage(messages.color),
      widget: 'color',
    },
  },
  required: ['value', 'icon', 'color'],
});

const MarkerIconRangeSchema = ({ intl }) => ({
  title: intl.formatMessage(messages.range),
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['min_value', 'max_value', 'icon', 'color'],
    },
  ],
  properties: {
    min_value: {
      title: intl.formatMessage(messages.minValue),
    },
    max_value: {
      title: intl.formatMessage(messages.maxValue),
    },
    icon: {
      title: intl.formatMessage(messages.icon),
      widget: 'icon',
    },
    color: {
      title: intl.formatMessage(messages.color),
      widget: 'color',
    },
  },
  required: ['min_value', 'max_value', 'icon', 'color'],
});

export default MapSchema;
