/**
 * Highlight news reducer.
 * @module reducers/highlightNews/highlightNews
 */

import {
  GET_HIGHLIGHT_NEWS,
  RESET_HIGHLIGHT_NEWS,
  UPDATE_HIGHLIGHT_NEWS,
} from '@arpav/constants/ActionTypes';
import { flattenToAppURL } from '@plone/volto/helpers';

const initialState = {
  data: null,
  subrequests: {},
  get: {
    loaded: false,
    loading: false,
    error: null,
  },
  update: {
    loaded: false,
    loading: false,
    error: null,
  },
};

/**
 * Get request key
 * @function getRequestKey
 * @param {string} actionType Action type.
 * @returns {string} Request key.
 */
function getRequestKey(actionType) {
  return actionType.split('_')[0].toLowerCase();
}

/**
 * Highlight news reducer.
 * @function schema
 * @param {Object} highlightNews Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function highlightNews(state = initialState, action = {}) {
  switch (action.type) {
    /** PENDING */
    case `${GET_HIGHLIGHT_NEWS}_PENDING`:
    case `${UPDATE_HIGHLIGHT_NEWS}_PENDING`:
      return action.subrequest
        ? {
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                ...(state.subrequests[action.subrequest] || {
                  data: null,
                }),
                [getRequestKey(action.type)]: {
                  loading: true,
                  loaded: false,
                  error: null,
                },
              },
            },
          }
        : {
            ...state,
            [getRequestKey(action.type)]: {
              loading: true,
              loaded: false,
              error: null,
            },
          };

    /** SUCCESS */
    case `${GET_HIGHLIGHT_NEWS}_SUCCESS`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                data: {
                  ...action.result,
                  items: action?.result?.items?.map(item => ({
                    ...item,
                    url: flattenToAppURL(item['@id']),
                  })),
                },
                [getRequestKey(action.type)]: {
                  loading: false,
                  loaded: true,
                  error: null,
                },
              },
            },
          }
        : {
            ...state,
            data: {
              ...action.result,
              items: action?.result?.items?.map(item => ({
                ...item,
                url: flattenToAppURL(item['@id']),
              })),
            },
            [getRequestKey(action.type)]: {
              loading: false,
              loaded: true,
              error: null,
            },
          };
    case `${UPDATE_HIGHLIGHT_NEWS}_SUCCESS`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };

    /** FAIL */
    case `${GET_HIGHLIGHT_NEWS}_FAIL`:
    case `${UPDATE_HIGHLIGHT_NEWS}_FAIL`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                data: null,
                [getRequestKey(action.type)]: {
                  loading: false,
                  loaded: true,
                  error: action.error,
                },
              },
            },
          }
        : {
            ...state,
            data: null,
            [getRequestKey(action.type)]: {
              loading: false,
              loaded: true,
              error: action.error,
            },
          };
    case RESET_HIGHLIGHT_NEWS:
      return initialState;
    default:
      return state;
  }
}
