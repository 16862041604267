/**
 * Root reducer.
 * @module reducers/root
 */

import announcementsList from '@arpav/reducers/announcements/list';
import arpavApiContent from '@arpav/reducers/arpavApiContent/arpavApiContent';
import config from '@plone/volto/registry';
import defaultReducers from '@plone/volto/reducers';
import defaultView from '@arpav/reducers/defaultView/defaultView';
import environmentalThemeRelItems from '@arpav/reducers/environmentalTheme/environmentalThemeRelItems';
import eventBooking from '@arpav/reducers/eventBooking/eventBooking';
import highlightNews from '@arpav/reducers/highlightNews/highlightNews';
import homeMeteoData from '@arpav/reducers/home/meteoData';
import mostVisited from '@arpav/reducers/mostVisited/mostVisited';
import newsItemsList from '@arpav/reducers/newsItemsList/newsItemsList';
import { originalQueryReducer } from '@arpav/reducers/originalQueryReducer';
import provinceRelItems from './province/provinceRelItems';
import rawContent from '@arpav/reducers/rawContent/rawContent';
import { searchFiltersReducer } from '@arpav/reducers/searchFiltersReducer';
import { searchResultsReducer } from '@arpav/reducers/searchResultsReducer';
/**
 * Root reducer.
 * @function
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
const reducers = {
  ...defaultReducers,
  ...config.addonReducers,
  announcementsList: announcementsList,
  arpavApiContent: arpavApiContent,
  defaultView: defaultView,
  environmentalThemeRelItems: environmentalThemeRelItems,
  eventBooking: eventBooking,
  highlightNews: highlightNews,
  homeMeteoData: homeMeteoData,
  mostVisited: mostVisited,
  newsItemsList: newsItemsList,
  originalQuery: originalQueryReducer,
  provinceRelItems: provinceRelItems,
  rawContent: rawContent,
  searchFilters: searchFiltersReducer,
  searchResults: searchResultsReducer,
};

export default reducers;
