import {
  addCarouselCardTemplateOptions,
  addDefaultOptions,
  addEventsTemplate,
  addNewsTemplateOptions,
  addPartnerTemplateOptions,
  addSimpleCardTemplateOptions,
} from '@arpav/config/Blocks/ListingOptions';

import CardCarouselTemplate from '@arpav/components/ArpavTheme/Blocks/Listing/CardCarouselTemplate';
import EventsTemplate from '@arpav/components/ArpavTheme/Blocks/Listing/EventsTemplate';
import EventsTemplateSkeleton from '@arpav/components/ArpavTheme/Blocks/Listing/TemplatesSkeletons/EventsTemplateSkeleton';
import GridGalleryTemplate from '@arpav/components/ArpavTheme/Blocks/Listing/GridGalleryTemplate';
import GridGalleryTemplateSkeleton from '@arpav/components/ArpavTheme/Blocks/Listing/TemplatesSkeletons/GridGalleryTemplateSkeleton';
import NewsTemplate from '@arpav/components/ArpavTheme/Blocks/Listing/NewsTemplate';
import NewsTemplateSkeleton from '@arpav/components/ArpavTheme/Blocks/Listing/TemplatesSkeletons/NewsTemplateSkeleton';
import PartnerTemplate from '@arpav/components/ArpavTheme/Blocks/Listing/PartnerTemplate';
import SimpleCardTemplate from '@arpav/components/ArpavTheme/Blocks/Listing/SimpleCard/SimpleCardTemplate';

const arpavListingVariations = [
  {
    id: 'simpleCard',
    isDefault: true,
    title: 'Card semplice',
    template: SimpleCardTemplate,
    schemaEnhancer: ({ schema, formData, intl }) => {
      addSimpleCardTemplateOptions(schema, formData, intl);
      return schema;
    },
  },
  {
    id: 'eventsCard',
    isDefault: true,
    title: 'Eventi',
    template: EventsTemplate,
    skeleton: EventsTemplateSkeleton,
    fullobjects: true,
    schemaEnhancer: ({ schema, formData, intl }) => {
      addEventsTemplate(schema, formData, intl);
      return schema;
    },
  },
  {
    id: 'newsCard',
    isDefault: true,
    title: 'Notizie',
    template: NewsTemplate,
    skeleton: NewsTemplateSkeleton,
    fullobjects: true,
    schemaEnhancer: ({ schema, formData, intl }) => {
      addNewsTemplateOptions(schema, formData, intl);
      return schema;
    },
  },
  {
    id: 'carouselCard',
    title: 'Card a carosello',
    template: CardCarouselTemplate,
    schemaEnhancer: ({ schema, formData, intl }) => {
      addCarouselCardTemplateOptions(schema, formData, intl);
      return schema;
    },
  },
  {
    id: 'partner',
    title: 'Partner',
    template: PartnerTemplate,
    schemaEnhancer: ({ schema, formData, intl }) => {
      addPartnerTemplateOptions(schema, formData, intl);
      return schema;
    },
  },
  {
    id: 'imageGallery',
    title: 'Image gallery',
    isDefault: false,
    template: GridGalleryTemplate,
    skeleton: GridGalleryTemplateSkeleton,
    schemaEnhancer: ({ schema, formData, intl }) => {
      addDefaultOptions(schema, formData, intl);
      return schema;
    },
  },
];

export const getArpavListingVariations = () => {
  return arpavListingVariations;
};
export const removeListingVariation = (config, id) => {
  let indexOfVariation = -1;
  indexOfVariation = config.blocks?.blocksConfig?.listing?.variations?.findIndex(x => x.id === id);
  if (indexOfVariation >= 0) {
    config.blocks.blocksConfig.listing.variations.splice(indexOfVariation, 1);
  }
};
