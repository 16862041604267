/**
 * EITheme view component.
 * @module components/theme/ArpavView/Indicators
 */

import { Col, Container, Row } from 'design-react-kit/dist/design-react-kit';

import { FormattedMessage } from 'react-intl';
import IndicatorListBody from '@arpav/components/ArpavTheme/View/Indicators/IndicatorListBody';
import Legend from '@arpav/components/ArpavTheme/View/Indicators/Legend';
import { Metadata } from '@arpav/components/ArpavTheme/View';
import { SimpleCard } from '@arpav/components/ArpavTheme';

/**
 * EITheme view component class.
 * @function EITheme
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const EITheme = ({ content }) => (
  <div id="page-ei-theme">
    <Container>
      <Row className="mb-4">
        <Col className="text-center template-header">
          <h1 className="mb-4">{content.title}</h1>
        </Col>
      </Row>

      <Row>
        {content.items?.map(item => (
          <Col xs={12} lg={6} key={item['@id']}>
            <IndicatorListBody item={item} />
          </Col>
        ))}
      </Row>
      {content.other_items?.length ? (
        <>
          <Row>
            <Col>
              <h2 className="mt-5 mb-4">
                <FormattedMessage id="Other contents" defaultMessage="Other contents" />
              </h2>
            </Col>
          </Row>
          <div className="simple-card-default card-wrapper card-teaser-wrapper card-teaser-wrapper-equal card-teaser-block-3 mb-3">
            {content.other_items.map(item => (
              <SimpleCard content={item} key={item['@id']} />
            ))}
          </div>
        </>
      ) : null}
      <Legend />
      <div className="mb-5">
        <Metadata content={content} />
      </div>
    </Container>
  </div>
);

export default EITheme;
