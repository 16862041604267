import { assign, getEvents, getOptions } from '@eeacms/volto-openlayers-map/helpers';

import { Component } from 'react';
import { executePlaceholder } from '@arpav/helpers';
import { icon as fIcon } from '@fortawesome/fontawesome-svg-core';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { isEqual } from 'lodash';
import { openlayers } from '@eeacms/volto-openlayers-map';
import { withMapContext } from '@eeacms/volto-openlayers-map/hocs';

/**
 * Get icon svg code
 *
 * @param {Object} icon Fontawesome icon definition
 * @param {String} icon.iconName Icon name
 * @param {String} icon.prefix Fontawesome icon prefix (fas, far, fab)
 * @param {String} color String for style color
 * @returns {String} Icon svg code for inline image src
 */
const getIconHtml = (icon, color) => {
  const iconDefinition = fIcon(
    { iconName: icon.iconName, prefix: icon.prefix },
    color && {
      styles: {
        color: color,
      },
    }
  );
  if (iconDefinition) {
    const iconNode = iconDefinition.node[0];
    iconNode.setAttribute('width', 36);
    iconNode.setAttribute('height', 36);
    return iconNode.outerHTML;
  }
  return null;
};

class ResourceLayer extends Component {
  layer = undefined;

  options = {
    className: undefined,
    declutter: undefined,
    extent: undefined,
    map: undefined,
    maxResolution: undefined,
    maxZoom: undefined,
    minResolution: undefined,
    minZoom: undefined,
    opacity: undefined,
    renderBuffer: undefined,
    renderOrder: undefined,
    source: undefined,
    style: undefined,
    updateWhileAnimating: undefined,
    updateWhileInteracting: undefined,
    visible: undefined,
    zIndex: undefined,
    title: undefined,
  };

  events = {
    'change:extent': undefined,
    'change:maxResolution': undefined,
    'change:maxZoom': undefined,
    'change:minResolution': undefined,
    'change:minZoom': undefined,
    'change:opacity': undefined,
    'change:source': undefined,
    'change:visible': undefined,
    'change:zIndex': undefined,
    change: undefined,
    error: undefined,
    postrender: undefined,
    prerender: undefined,
    propertychange: undefined,
  };

  constructor(props) {
    super(props);
    this.options = getOptions(assign(this.options, this.props));
    this.addLayer = this.addLayer.bind(this);
  }

  updateOptions() {
    const { resource, data_resource, merge_data_resource } = this.props;

    const { extent, format, geom, source, style } = openlayers;
    const { Circle, Fill, Stroke, Style, Text, Icon } = style;

    const defaultColor = resource.default_color
      ? `rgba(${resource.default_color.r},${resource.default_color.g},${resource.default_color.b},${resource.default_color.a})`
      : 'rgb(255,0,0)';
    const defaultStyles = {
      LineString: new Style({
        stroke: new Stroke({
          color: defaultColor,
          width: 2,
        }),
      }),
      MultiLineString: new Style({
        stroke: new Stroke({
          color: defaultColor,
          width: 1,
        }),
      }),
      MultiPolygon: new Style({
        stroke: new Stroke({
          color: defaultColor,
          width: 1,
        }),
        fill: new Fill({
          color: resource.default_color
            ? `rgba(${resource.default_color.r},${resource.default_color.g},${
                resource.default_color.b
              },${Math.max(resource.default_color.a - 0.2, 0.1)})`
            : 'rgba(255,0,0,0.1)',
        }),
      }),
      Polygon: new Style({
        stroke: new Stroke({
          color: defaultColor,
          lineDash: [4],
          width: 3,
        }),
        fill: new Fill({
          color: resource.default_color
            ? `rgba(${resource.default_color.r},${resource.default_color.g},${
                resource.default_color.b
              },${Math.max(resource.default_color.a - 0.2, 0.1)})`
            : 'rgba(255,0,0,0.1)',
        }),
      }),
      GeometryCollection: new Style({
        stroke: new Stroke({
          color: defaultColor,
          width: 2,
        }),
        fill: new Fill({
          color: defaultColor,
        }),
        image: new Circle({
          radius: 10,
          fill: null,
          stroke: new Stroke({
            color: defaultColor,
          }),
        }),
      }),
      Circle: new Style({
        stroke: new Stroke({
          color: defaultColor,
          width: 1,
        }),
        fill: new Fill({
          color: resource.default_color
            ? `rgba(${resource.default_color.r},${resource.default_color.g},${
                resource.default_color.b
              },${Math.max(resource.default_color.a - 0.2, 0.1)})`
            : 'rgba(255,0,0,0.1)',
        }),
      }),
    };

    const styles = {};
    const styleCache = {};
    let geojsonObject = {
      type: 'FeatureCollection',
      features: [],
    };
    if (data_resource?.data && resource.gis_field) {
      let items = [];
      // check if is configured an url for data
      if (resource.data_url) {
        // check if mapping attributes are configured
        if (resource.mapping_attr_geo && resource.mapping_attr_data) {
          // search for record in geo data
          merge_data_resource?.data?.map(item => {
            const geo_item = data_resource.data.find(
              gitem => gitem[resource.mapping_attr_geo] === item[resource.mapping_attr_data]
            );
            if (geo_item) {
              // add merged result in items list
              items.push({ ...item, ...geo_item });
            }
          });
        } else {
          console.warn('Bad configuration! Missing one of or both mapping attributes');
        }
      } else {
        // use data resource as map data
        items = data_resource.data;
      }
      styles[resource['@id']] = defaultStyles;
      if (
        resource?.marker_icon_field &&
        (resource?.marker_icon_field_values?.length || resource.marker_icon_field_ranges?.length)
      ) {
        styles[resource['@id']][resource.marker_icon_field] = {};
      }
      styleCache[resource['@id']] = {};
      if (items) {
        geojsonObject.features = items.map(item => {
          const popup_title = executePlaceholder({ ...item }, resource.popup_title);
          const popup_text = executePlaceholder({ ...item }, resource.popup_text);
          let geometry = item[resource.gis_field];
          if (typeof geometry === 'string') {
            geometry = JSON.parse(geometry);
          }
          return {
            type: 'Feature',
            geometry: geometry,
            properties: {
              id: resource['@id'],
              data: item,
              label: executePlaceholder({ ...item }, resource.label),
              tooltip: executePlaceholder({ ...item }, resource.tooltip),
              isPopup: !!(popup_title || popup_text),
              popup_title: popup_title,
              popup_text: popup_text,
            },
          };
        });
      }

      const default_icon = resource.default_icon;
      const icon_scale = resource.icon_size || 1;
      let iconData = getIconHtml(
        {
          iconName: faMapMarkerAlt.iconName,
          prefix: faMapMarkerAlt.prefix,
        },
        defaultColor
      );
      if (default_icon) {
        const customIcon = getIconHtml(default_icon, defaultColor);
        iconData = customIcon || iconData;
      }
      const image = new Icon({
        anchor: [0.5, 1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: icon_scale,
        src: `data:image/svg+xml,${iconData}`,
      });
      styles[resource['@id']].Point = new Style({
        image: image,
      });
      styles[resource['@id']].MultiPoint = new Style({
        image: image,
      });

      this.options.source = resource.grouped
        ? new source.Cluster({
            distance: 30,
            minDistance: 30,
            source: new source.Vector({
              features: new format.GeoJSON({
                featureProjection: 'EPSG:3857',
              }).readFeatures(geojsonObject),
            }),
            geometryFunction: feature => {
              const geometry = feature.getGeometry();
              const type = geometry.getType();
              if (type == 'Point') {
                return geometry;
              } else if (type === 'LineString') {
                return new geom.Point(geometry.getCoordinateAt(0.5));
              } else if (type === 'Polygon') {
                return geometry.getInteriorPoint();
              } else {
                return new geom.Point(extent.getCenter(feature.getGeometry().getExtent()));
              }
            },
          })
        : new source.Vector({
            features: new format.GeoJSON({
              featureProjection: 'EPSG:3857',
            }).readFeatures(geojsonObject),
          });
      this.options.style = feature => {
        const size = resource.grouped ? feature.get('features').length : 1;
        let style = styles[resource['@id']][feature.getGeometry().getType()];
        if (size > 1) {
          style = styleCache[resource['@id']][size];
          if (!style) {
            style = styles[resource['@id']][feature.getGeometry().getType()].clone();
            style.setText(
              new Text({
                offsetY: -10,
                text: size.toString(),
                scale: 1.5,
                fill: new Fill({
                  color: '#000',
                }),
                stroke: new Stroke({
                  color: '#fff',
                  width: 2,
                }),
              })
            );
            styleCache[resource['@id']][size] = style;
          }
        } else {
          const featureProperties = (resource.grouped
            ? feature.get('features')[0]
            : feature
          ).getProperties();
          if (
            resource.marker_icon === 'property' &&
            resource.marker_icon_field &&
            (resource.marker_icon_field_values?.length || resource.marker_icon_field_ranges?.length)
          ) {
            const featureData = featureProperties.data;

            if (featureData && featureData[resource.marker_icon_field]) {
              // get style for current value, if already calculated
              let vstyle =
                styles[resource['@id']][resource.marker_icon_field][
                  featureData[resource.marker_icon_field]
                ];

              // otherwhise calculate the style for this value
              if (!vstyle) {
                let vmarker = [],
                  rmarker = [];

                // check if the value is in the icon_field_values
                if (resource.marker_icon_field_values) {
                  vmarker = resource.marker_icon_field_values.filter(
                    value => value.value == featureData[resource.marker_icon_field]
                  );
                }

                // check if the value is in the icon_field_ranges
                if (resource.marker_icon_field_ranges) {
                  rmarker = resource.marker_icon_field_ranges.filter(value => {
                    return (
                      Number(value.min_value) < featureData[resource.marker_icon_field] &&
                      Number(value.max_value) > featureData[resource.marker_icon_field]
                    );
                  });
                }

                // if found generate the style
                const marker = vmarker.length ? vmarker : rmarker;
                if (marker?.length) {
                  if (marker[0].icon && marker[0].color) {
                    const iconData = getIconHtml(
                      {
                        iconName: faMapMarkerAlt.iconName,
                        prefix: faMapMarkerAlt.prefix,
                      },
                      defaultColor
                    );

                    const customIcon = getIconHtml(
                      { iconName: marker[0].icon.iconName, prefix: marker[0].icon.prefix },
                      `rgba(${marker[0].color.r},${marker[0].color.g},${marker[0].color.b},${marker[0].color.a})`
                    );

                    vstyle = new Style({
                      image: new Icon({
                        anchor: [0.5, 1],
                        anchorXUnits: 'fraction',
                        anchorYUnits: 'fraction',
                        scale: icon_scale,
                        src: `data:image/svg+xml,${customIcon || iconData}`,
                      }),
                    });
                  }
                }
                // save the generated style (or default) for this value
                styles[resource['@id']][resource.marker_icon_field][
                  featureData[resource.marker_icon_field]
                ] = vstyle || style;
              }
              style = vstyle || style;
            }
          } else if (resource.marker_icon === 'image') {
            const featureData = featureProperties.data;

            if (featureData && featureData[resource.marker_icon_field]) {
              style = new Style({
                image: new Icon({
                  anchor: [0.5, 1],
                  anchorXUnits: 'fraction',
                  anchorYUnits: 'fraction',
                  scale: icon_scale,
                  src: featureData[resource.marker_icon_field],
                }),
              });
            }
          }
          if (featureProperties.label) {
            style.setText(
              new Text({
                offsetY: 8,
                text: featureProperties.label,
                scale: 1.5,
                fill: new Fill({
                  color: '#000',
                }),
                stroke: new Stroke({
                  color: '#fff',
                  width: 2,
                }),
              })
            );
          }
        }
        return style;
      };
    }
  }

  addLayer() {
    const { layer } = openlayers;
    const {
      id,
      mapRendered,
      resource,
      resourceLayers,
      setResourceLayers,
      title,
      popover,
    } = this.props;
    let events = getEvents(this.events, this.props);

    this.layer = new layer.Vector(this.options);
    resourceLayers[id] = {
      title: title,
      layer: this.layer,
      icon: resource?.default_icon,
      color: resource?.default_color,
      popover: popover,
    };
    setResourceLayers({ ...resourceLayers });
    for (let event in events) {
      this.layer.on(event, events[event]);
    }
    if (!mapRendered) {
      this.props.addLayer(this.layer);
    }
  }

  componentDidMount() {
    if (this.props.data_resource && this.props.merge_data_resource && this.props.resource) {
      this.updateOptions();
    }
    this.addLayer();
  }

  componentDidUpdate(prevProps) {
    const { data_resource, resource, merge_data_resource } = this.props;
    if (
      !isEqual(prevProps.data_resource, data_resource) ||
      !isEqual(prevProps.merge_data_resource, merge_data_resource) ||
      !isEqual(prevProps.resource, resource)
    ) {
      this.updateOptions();
      if (this.layer) {
        this.layer.setStyle(this.options.style);
        this.layer.setSource(this.options.source);
      }
    } else {
      const prevOptions = getOptions(assign(this.options, prevProps));
      const options = getOptions(assign(this.options, this.props));
      if (!isEqual(prevOptions, options)) {
        Object.keys(options).forEach(o => {
          if (o !== 'source' && o !== 'style' && prevOptions[o] !== o) {
            this.layer.set(o, options[o]);
          }
        });
        this.options = getOptions(assign(this.options, this.props));
        this.layer.changed();
      }
    }
  }

  componentWillUnmount() {
    if (__SERVER__ || !this.layer) return;
    this.layer.dispose();
  }

  render() {
    return null;
  }
}

export default withMapContext(ResourceLayer);
