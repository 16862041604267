/**
 * IndicatorListBody view component.
 * @module components/theme/ArpavView/Indicators
 */

import {
  Card,
  CardBody,
  CardReadMore,
  CardText,
  CardTitle,
} from 'design-react-kit/dist/design-react-kit';
import { FormattedMessage, injectIntl } from 'react-intl';
import { StatusIcon, TrendIcon } from '@arpav/components/ArpavTheme/View/Indicators/Legend';

import { FormattedDate } from '@plone/volto/components';

/**
 * IndicatorListBody view component class.
 * @function IndicatorListBody
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const IndicatorListBody = ({ intl, item }) => {
  const evaluation = item?.items?.[0] || {};
  return (
    <Card spacing className="card-bg card-img rounded shadow no-after">
      <CardBody className="pb-5">
        <CardTitle tag="h3">{item.title}</CardTitle>
        <div className="category-top">
          <span className="category">
            <FormattedMessage id="Last update date" defaultMessage="Last update date" />:{' '}
          </span>
          {evaluation.evaluation_last_update && (
            <FormattedDate date={evaluation.evaluation_last_update} />
          )}
        </div>
        <div className="category-top">
          <span className="category">
            <FormattedMessage id="Time coverage" defaultMessage="Time coverage" />:{' '}
          </span>
          {evaluation.evaluation_coverage_years?.length && (
            <>
              <FormattedMessage id="from" defaultMessage="from" />{' '}
              {evaluation.evaluation_coverage_years[0]}{' '}
              <FormattedMessage id="to" defaultMessage="to" />{' '}
              {evaluation.evaluation_coverage_years.slice(-1)}
            </>
          )}
        </div>
        <div className="category-top">
          <span className="category">
            <FormattedMessage id="Unit of measure" defaultMessage="Unit of measure" />:{' '}
          </span>
          <span className="category-top-unit-measure">{evaluation.evaluation_unit_measure}</span>
        </div>
        <div className="category-top">
          <span className="category">
            <FormattedMessage id="Current state" defaultMessage="Current state" />:{' '}
          </span>
          <StatusIcon value={evaluation.evaluation_status} />
        </div>
        <div className="category-top">
          <span className="category">
            <FormattedMessage id="Trend" defaultMessage="Trend" />:{' '}
          </span>
          <TrendIcon value={evaluation.evaluation_trend} />
        </div>
        <CardText>{item.description}</CardText>
        <CardReadMore
          href={item.url}
          title={item.title}
          text={intl.formatMessage({
            id: 'Read More…',
          })}
          iconName="it-arrow-right"
        ></CardReadMore>
      </CardBody>
    </Card>
  );
};

export default injectIntl(IndicatorListBody);
