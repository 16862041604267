import { Card, CardBody, CardText, CardTitle } from 'design-react-kit/dist/design-react-kit';
import { ListingCategory, ListingImage } from '@arpav/components/ArpavTheme';

import { CardCategory } from '@arpav/components/ArpavTheme';
import { UniversalLink } from '@plone/volto/components';

export const CardPersona = ({
  item,
  className,
  showImage,
  listingText,
  icon,
  type,
  isEditMode,
}) => {
  const image = ListingImage({
    item,
    maxSize: 300,
    useOriginal: false,
  });
  return (
    <Card className={`card-persona card-teaser-image card-flex no-after ${className ?? ''}`}>
      <div className="card-image-wrapper">
        <CardBody>
          {(icon || type) && (
            <CardCategory iconName={icon}>
              <ListingCategory category={type} item={item} />
            </CardCategory>
          )}
          <CardTitle tag="h3">
            <UniversalLink item={!isEditMode ? item : null} href={isEditMode ? '#' : ''}>
              {item.title || item.id}
            </UniversalLink>
          </CardTitle>
          {listingText && <CardText>{listingText}</CardText>}
        </CardBody>
        {showImage && <div className="card-image card-image-rounded">{image}</div>}
      </div>
    </Card>
  );
};

export default CardPersona;
