import { Card, CardBody, CardCategory, CardTitle } from 'design-react-kit/dist/design-react-kit';

import { FileSize } from '@arpav/components/ArpavTheme';
import { flattenToAppURL } from '@plone/volto/helpers';

const AnnouncementAttachments = ({ title, files }) => {
  return (
    <div className="mt-4">
      <h4>{title}</h4>
      <div className="card-wrapper card-teaser-wrapper">
        {files.map(item => (
          <Card teaser noWrapper className="shadow rounded border" key={item['@id']}>
            <CardBody>
              <CardCategory iconName="it-clip"></CardCategory>
              <CardTitle tag="h5">
                {item.file?.download && (
                  <a href={flattenToAppURL(item.file.download)}>{item.title}</a>
                )}
                <small className="text-muted">
                  {' ('}
                  <FileSize size={item.file.size} />
                  {')'}
                </small>
              </CardTitle>
            </CardBody>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default AnnouncementAttachments;
