const RichTextView = ({ data }) => {
  return (
    <>
      {data.richtext && (
        <div
          dangerouslySetInnerHTML={{
            __html: data.richtext.data,
          }}
        />
      )}
    </>
  );
};

export default RichTextView;
