import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from 'design-react-kit/dist/design-react-kit';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Metadata,
  PageHeader,
  SideMenu,
  SkipToMainContent,
} from '@arpav/components/ArpavTheme/View';
import { flattenToAppURL, getParentUrl } from '@plone/volto/helpers';

import { CardCategory } from '@arpav/components/ArpavTheme';
import ContextNavigation from '@plone/volto/components/theme/Navigation/ContextNavigation';
import OrganizationInfo from '@arpav/components/ArpavTheme/View/Organization/OrganizationInfo';
import { UniversalLink } from '@plone/volto/components';

const Organization = ({ content }) => (
  <Container id="page-organization">
    <SkipToMainContent />
    <PageHeader content={content} showdates={false} />
    <Row className="border-top row-column-border row-column-menu-left">
      <Col
        tag="section"
        lg={8}
        id="main-content-section"
        className="it-page-sections-container pr-lg-0"
      >
        <div className="mt-3 mt-lg-5 mr-lg-5 ml-lg-3">
          {content.activity && (
            <div
              dangerouslySetInnerHTML={{
                __html: content.activity.data,
              }}
            />
          )}
        </div>
      </Col>
      <Col tag="aside" lg={4}>
        <SideMenu
          content={content}
          sections={[
            {
              component: OrganizationInfo,
              props: {
                content,
              },
            },
            {
              component: ContextNavigation,
              props: {
                content,
                params: {
                  includeTop: true,
                  root_path: content.organization_root?.['@id']
                    ? getParentUrl(flattenToAppURL(content.organization_root['@id']))
                    : undefined,
                },
              },
            },
          ]}
        />
      </Col>
    </Row>
    {content.items?.length ? (
      <div className="simple-card-default mt-5">
        <h2>
          <FormattedMessage id="Is divided into" defaultMessage="Is divided into" />
        </h2>
        <div className="card-wrapper card-teaser-wrapper card-teaser-wrapper-equal card-teaser-block-3 mb-3">
          {content.items.map(item => (
            <Card noWrapper teaser className="align-items-top rounded shadow" key={item['@id']}>
              <CardBody>
                <CardCategory iconName="it-pa">
                  <span className="text font-weight-bold">{item.organization_type}</span>
                </CardCategory>
                <CardTitle tag="h3">
                  <UniversalLink item={item} title={item.title}>
                    {item.title}
                  </UniversalLink>
                </CardTitle>
                <CardText>{item.description}</CardText>
              </CardBody>
            </Card>
          ))}
        </div>
      </div>
    ) : null}
    <div className="mb-5">
      <Metadata content={content} />
    </div>
  </Container>
);

export default injectIntl(Organization);
