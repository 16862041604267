import { ChartSchema, SOURCE_TYPES } from '@arpav/components/ArpavTheme/Blocks/DataChart/schema';

import DataChartView from '@arpav/components/ArpavTheme/Blocks/DataChart/DataChartView';
import { InlineForm } from '@plone/volto/components';
import { SidebarPortal } from '@plone/volto/components';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { isEqual } from 'lodash';
import { memo } from 'react';
import withFileData from '@arpav/components/ArpavTheme/Blocks/DataChart/withFileData';

const tweakSchema = (schema, data, data_result) => {
  if (data?.source_type === SOURCE_TYPES.csv.id) {
    schema.properties.api_url.isDisabled = true;
    schema.properties.file_path.isDisabled = false;
  } else if (data?.source_type === SOURCE_TYPES.api.id) {
    schema.properties.api_url.isDisabled = false;
    schema.properties.file_path.isDisabled = true;
  } else {
    schema.properties.api_url.isDisabled = false;
    schema.properties.file_path.isDisabled = false;
  }

  const fields = (data_result?.meta?.fields || []).sort().map(f => [f, f]);
  schema.properties.x_field.choices = fields;
  schema.properties.filter_field.choices = fields;
  const datasetField = schema.properties.dataset;
  datasetField.schemaExtender = schema => {
    schema.properties.y_field.choices = fields;
    schema.properties.color_field.choices = fields;
    return schema;
  };
  return schema;
};

const DataChartEdit = memo(
  props => {
    const { block, data, data_result, onChangeBlock, selected } = props;
    const schema = tweakSchema(ChartSchema(props), data, data_result);

    return (
      <div className={`${data['@type']}-edit`}>
        <SidebarPortal selected={selected}>
          <InlineForm
            schema={schema}
            title={schema.title}
            onChangeField={(id, value) => {
              if (id === 'source_type') {
                onChangeBlock(block, {
                  [id]: value,
                  '@type': data['@type'],
                  file_path: [],
                  api_url: null,
                });
              } else {
                onChangeBlock(block, {
                  ...data,
                  [id]: value,
                });
              }
            }}
            formData={data}
          />
        </SidebarPortal>
        <DataChartView isEditMode={true} {...props} />
      </div>
    );
  },
  function areEquals(prevProps, nextProps) {
    return !(
      nextProps.selected !== prevProps.selected ||
      !isEqual(prevProps.data, nextProps.data) ||
      !isEqual(prevProps.data_result, nextProps.data_result)
    );
  }
);

export default compose(
  injectIntl,
  withFileData(({ block, data: { api_url, file_path, source_type } }) => ({
    api_url,
    block,
    file_path,
    isEditMode: true,
    source_type,
  }))
)(DataChartEdit);
