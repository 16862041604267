import {
  Card,
  CardBody,
  CardReadMore,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from 'design-react-kit/dist/design-react-kit';
import { PreviewImage, UniversalLink } from '@plone/volto/components';
import { defineMessages, injectIntl } from 'react-intl';

import { ListingLinkMore } from '@arpav/components/ArpavTheme';
import PropTypes from 'prop-types';
import React from 'react';
import { getItemClass } from '@arpav/helpers';

const messages = defineMessages({
  readMore: {
    id: 'Read More…',
    defaultMessage: 'Read More…',
  },
});

const SummaryTemplate = ({ intl, items, linkTitle, linkHref }) => (
  <Container>
    <Row className="mt-3 mt-lg-5">
      {items.map(item => (
        <Col xs={12} key={item.url}>
          <Card
            spacing
            className={`card-bg card-img rounded shadow no-after ${getItemClass(item)}`}
          >
            <Row>
              <Col md={4}>
                <div className="img-responsive-wrapper left">
                  <div className="img-responsive">
                    <figure className="img-wrapper">
                      <PreviewImage
                        item={item}
                        alt={item.image_caption ? item.image_caption : item.title}
                        size="large"
                        className="ui image floated right clear"
                      />
                    </figure>
                  </div>
                </div>
              </Col>
              <Col md={8}>
                <CardBody className="pb-5">
                  <CardTitle tag="h2">{item.title}</CardTitle>
                  {item.description && <CardText>{item.description}</CardText>}
                  <CardReadMore
                    iconName="it-arrow-right"
                    tag={UniversalLink}
                    item={item}
                    text={intl.formatMessage(messages.readMore)}
                  ></CardReadMore>
                </CardBody>
              </Col>
            </Row>
          </Card>
        </Col>
      ))}
    </Row>
    <ListingLinkMore title={linkTitle} href={linkHref} className="my-4" />
  </Container>
);

SummaryTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default injectIntl(SummaryTemplate);
