/**
 * Summary view component.
 * @module components/theme/View/SummaryView
 */

import {
  Card,
  CardBody,
  CardReadMore,
  CardText,
  CardTitle,
  Col,
  Row,
} from 'design-react-kit/dist/design-react-kit';
import { PreviewImage, UniversalLink } from '@plone/volto/components';
import { createRef, useEffect, useState } from 'react';
import { defineMessages, injectIntl } from 'react-intl';

import { Container } from 'semantic-ui-react';
import { PageHeader } from '@arpav/components/ArpavTheme/View';
import { Pagination } from '@arpav/components/ArpavTheme';
import PropTypes from 'prop-types';
import config from '@plone/volto/registry';
import { getBaseUrl } from '@plone/volto/helpers';
import { getContent } from '@plone/volto/actions';
import { getItemClass } from '@arpav/helpers';
import { useDispatch } from 'react-redux';

const messages = defineMessages({
  readMore: {
    id: 'Read More…',
    defaultMessage: 'Read More…',
  },
});

/**
 * Summary view component class.
 * @function SummaryView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const SummaryView = ({ content, intl, location }) => {
  const { pathname } = location;
  const listingRef = createRef();
  const b_size = config.settings.defaultPageSize;
  const totalPages = Math.ceil(content.items_total / b_size);
  const [currentPage, setCurrentPage] = useState(0); // default value to 0 for prevent load content first time
  const [initialPath] = useState(getBaseUrl(pathname));
  const dispatch = useDispatch();

  useEffect(() => {
    // prevent load content first time
    if (dispatch && initialPath && currentPage && currentPage > 0) {
      dispatch(getContent(initialPath, null, null, currentPage));
    }
  }, [dispatch, initialPath, currentPage]);

  return (
    <Container className="view-wrapper summary-view">
      <PageHeader content={content} />
      <section id="content-core" ref={listingRef}>
        <Row className="mt-3 mt-lg-5">
          {content.items.map(item => (
            <Col xs={12} key={item.url}>
              <Card
                spacing
                className={`card-bg card-img rounded shadow no-after ${getItemClass(item)}`}
              >
                <Row>
                  <Col md={4}>
                    <div className="img-responsive-wrapper left">
                      <div className="img-responsive">
                        <figure className="img-wrapper">
                          <PreviewImage
                            item={item}
                            alt={item.image_caption ? item.image_caption : item.title}
                            size="large"
                            className="ui image floated right clear"
                          />
                        </figure>
                      </div>
                    </div>
                  </Col>
                  <Col md={8}>
                    <CardBody className="pb-5">
                      <CardTitle tag="h2">{item.title}</CardTitle>
                      {item.description && <CardText>{item.description}</CardText>}
                      <CardReadMore
                        iconName="it-arrow-right"
                        tag={UniversalLink}
                        item={item}
                        text={intl.formatMessage(messages.readMore)}
                      ></CardReadMore>
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
        {totalPages > 1 && (
          <div className="pagination-wrapper">
            <Pagination
              activePage={currentPage || 1}
              totalPages={totalPages}
              onPageChange={(e, { activePage: { children } }) => {
                listingRef.current.scrollIntoView({ behavior: 'smooth' });
                setCurrentPage(children);
              }}
            />
          </div>
        )}
      </section>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
SummaryView.propTypes = {
  /**
   * Content of the object
   */
  content: PropTypes.shape({
    /**
     * Title of the object
     */
    title: PropTypes.string,
    /**
     * Description of the object
     */
    description: PropTypes.string,
    /**
     * Child items of the object
     */
    items: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * Title of the item
         */
        title: PropTypes.string,
        /**
         * Description of the item
         */
        description: PropTypes.string,
        /**
         * Url of the item
         */
        url: PropTypes.string,
        /**
         * Image of the item
         */
        image: PropTypes.object,
        /**
         * Image caption of the item
         */
        image_caption: PropTypes.string,
        /**
         * Type of the item
         */
        '@type': PropTypes.string,
      })
    ),
  }).isRequired,
};

export default injectIntl(SummaryView);
