import '@arpav/components/ArpavTheme/Blocks/DataTable/datatable-edit.less';

import { SOURCE_TYPES, TableSchema } from '@arpav/components/ArpavTheme/Blocks/DataTable/schema';

import DataTableView from '@arpav/components/ArpavTheme/Blocks/DataTable/DataTableView';
import { InlineForm } from '@plone/volto/components';
import { SidebarPortal } from '@plone/volto/components';
import { compose } from 'redux';
import config from '@plone/volto/registry';
import { injectIntl } from 'react-intl';
import withFileData from '@arpav/components/ArpavTheme/Blocks/DataTable/withFileData';

const tweakSchema = (schema, data, data_result, intl) => {
  if (data?.source_type === SOURCE_TYPES.csv.id) {
    schema.properties.api_url.isDisabled = true;
    schema.properties.file_path.isDisabled = false;
  } else if (data?.source_type === SOURCE_TYPES.api.id) {
    schema.properties.api_url.isDisabled = false;
    schema.properties.file_path.isDisabled = true;
  } else {
    schema.properties.api_url.isDisabled = false;
    schema.properties.file_path.isDisabled = false;
  }
  const columnsField = schema.properties.columns;
  const ColumnsSchema = columnsField.schema;

  const columns = (data_result?.meta?.fields || []).sort().map(n => [n, n]);
  ColumnsSchema.properties.column.choices = columns;
  schema.properties.default_sort_column.choices = data?.columns?.lenght
    ? data?.columns?.map(col => [col.column, col.title])
    : columns;

  const { cellRenderers } = config.blocks.blocksConfig.dataTable;
  const renderers = Object.keys(cellRenderers).map(k => [
    k,
    intl.formatMessage({ id: cellRenderers[k].title, title: cellRenderers[k].title }),
  ]);
  ColumnsSchema.properties.renderer.choices = renderers;
  ColumnsSchema.properties.filterRequired.isDisabled;

  columnsField.schemaExtender = (schema, colum_data) => {
    if (data?.pagination) {
      schema.properties.filtered.isDisabled = true;
      colum_data.filtered = false;
      colum_data.filterRequired = false;
    }
    schema.properties.filterRequired.isDisabled = !colum_data?.filtered;
    const extension = colum_data.renderer
      ? cellRenderers[colum_data.renderer].schemaExtender
      : null;
    return extension ? extension(schema, colum_data) : schema;
  };

  return schema;
};

const DataTableEdit = props => {
  const { selected, onChangeBlock, block, data, data_result, intl } = props;

  let schema = tweakSchema(TableSchema(props), data, data_result, intl);

  return (
    <div className={`${data['@type']}-edit`}>
      <SidebarPortal selected={selected}>
        <InlineForm
          schema={schema}
          title={schema.title}
          onChangeField={(id, value) => {
            if (id === 'source_type') {
              onChangeBlock(block, {
                ...data,
                [id]: value,
                file_path: null,
                api_url: null,
                sortable: false,
                default_sort_direction: undefined,
                default_sort_column: undefined,
                columns: undefined,
              });
            } else {
              onChangeBlock(block, {
                ...data,
                [id]: value,
              });
            }
          }}
          formData={data}
        />
      </SidebarPortal>
      <DataTableView isEditMode={true} {...props} />
    </div>
  );
};

export default compose(
  injectIntl,
  withFileData(
    ({
      data: {
        source_type,
        file_path,
        api_url,
        default_sort_direction,
        default_sort_column,
        pagination,
        page_size,
      },
      block,
    }) => ({
      source_type,
      file_path,
      api_url,
      default_sort_direction,
      default_sort_column,
      pagination,
      page_size,
      block,
      isEditMode: true,
    })
  )
)(DataTableEdit);
