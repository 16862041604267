/**
 * Highlight news reducer.
 * @module reducers/newsItemsList/newsItemsList
 */
import { GET_NEWSITEMS_LIST } from '@arpav/constants/ActionTypes';

const initialState = {
  data: null,
  subrequests: {},
  get: {
    loaded: false,
    loading: false,
    error: null,
  },
};

/**
 * Get request key
 * @function getRequestKey
 * @param {string} actionType Action type.
 * @returns {string} Request key.
 */
function getRequestKey(actionType) {
  return actionType.split('_')[0].toLowerCase();
}

/**
 * Highlight news reducer.
 * @function schema
 * @param {Object} highlightNews Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function highlightNews(state = initialState, action = {}) {
  switch (action.type) {
    /** PENDING */
    case `${GET_NEWSITEMS_LIST}_PENDING`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: true,
          loaded: false,
          error: null,
        },
      };

    /** SUCCESS */
    case `${GET_NEWSITEMS_LIST}_SUCCESS`:
      return {
        ...state,
        data: action.result,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    /** FAIL */
    case `${GET_NEWSITEMS_LIST}_FAIL`:
      return {
        ...state,
        data: null,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: action.error,
        },
      };
    case GET_NEWSITEMS_LIST:
      return initialState;
    default:
      return state;
  }
}
