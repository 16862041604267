/**
 * View toc block.
 * @module components/manage/Blocks/ToC/View
 */
import { LinkList, NavItem, Navbar } from 'design-react-kit/dist/design-react-kit';
import React, { useState } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { getBlocksFieldname, getBlocksLayoutFieldname } from '@plone/volto/helpers';

import AnchorLink from 'react-anchor-link-smooth-scroll';
import PropTypes from 'prop-types';
import config from '@plone/volto/registry';
import cx from 'classnames';
import { withBlockExtensions } from '@plone/volto/helpers';

const messages = defineMessages({
  text: {
    id: 'Table of Contents',
    defaultMessage: 'Table of Contents',
  },
});

/**
 * View toc block class.
 * @class View
 * @extends Component
 */
const View = props => {
  const { properties, data } = props;
  const { variation } = props;
  const blocksFieldname = getBlocksFieldname(properties);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(properties);
  const levels = React.useMemo(
    () =>
      data.levels?.length > 0 ? data.levels.map(l => parseInt(l.slice(1))) : [1, 2, 3, 4, 5, 6],
    [data]
  );
  const tocEntries = React.useMemo(() => {
    let rootLevel = Infinity;
    let entries = [];
    let prevEntry = {};
    let tocEntries = {};
    let tocEntriesLayout = [];

    properties[blocksLayoutFieldname].items.forEach(id => {
      const block = properties[blocksFieldname][id];
      if (typeof block === 'undefined') {
        return null;
      }
      if (!config.blocks.blocksConfig[block['@type']]?.tocEntry) return null;
      const entry = config.blocks.blocksConfig[block['@type']]?.tocEntry(block, data);
      if (entry) {
        const level = entry[0];
        const title = entry[1];
        const items = [];
        if (!level || !levels.includes(level)) return;
        tocEntriesLayout.push(id);
        tocEntries[id] = { level, title: title || block.plaintext, items, id };
        if (level < rootLevel) {
          rootLevel = level;
        }
      }
    });

    tocEntriesLayout.forEach(id => {
      const entry = tocEntries[id];
      if (entry.level === rootLevel) {
        entries.push(entry);
        prevEntry = entry;
        return;
      }
      if (!prevEntry.id) return;
      if (entry.level > prevEntry.level) {
        entry.parentId = prevEntry.id;
        prevEntry.items.push(entry);
        prevEntry = entry;
      } else if (entry.level < prevEntry.level) {
        let parent = tocEntries[prevEntry.parentId];
        while (entry.level <= parent.level) {
          parent = tocEntries[parent.parentId];
        }
        entry.parentId = parent.id;
        parent.items.push(entry);
        prevEntry = entry;
      } else {
        entry.parentId = prevEntry.parentId;
        tocEntries[prevEntry.parentId].items.push(entry);
        prevEntry = entry;
      }
    });

    return entries;
  }, [data, levels, properties, blocksFieldname, blocksLayoutFieldname]);

  // const Renderer = variation?.view;
  const [isCurrent, setIsCurrent] = useState();

  return (
    <div
      className={cx(
        'sticky-wrapper navbar-wrapper page-side-menu block stable-of-contents',
        variation?.id
      )}
    >
      <Navbar expand="lg" className="it-navscroll-wrapper navbar-expand-lg">
        <div className="navbar-collapsable expanded" id="navbarNavB" style={{ display: 'block' }}>
          <div className="overlay" style={{ display: 'block' }}></div>
          <div className="menu-wrapper">
            <LinkList
              wrapperClassName="menu-link-list"
              header={<h3>{data.title || props.intl.formatMessage(messages.text)}</h3>}
            >
              {tocEntries.map(item => (
                <NavItem key={item.id} className={item.level}>
                  <AnchorLink
                    href={`#${item.id}`}
                    className={`nav-link ${isCurrent === item.id && 'active'}`}
                    onClick={() => setIsCurrent(item.id)}
                  >
                    {item.title}
                  </AnchorLink>
                </NavItem>
              ))}
            </LinkList>
          </div>
        </div>
      </Navbar>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default injectIntl(withBlockExtensions(View));
