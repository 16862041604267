import CustomJavascriptSchema from './schema';
import { FormattedMessage } from 'react-intl';
import { InlineForm } from '@plone/volto/components';
import React from 'react';
import { SidebarPortal } from '@plone/volto/components';

const CustomJavascriptEdit = props => {
  const { block, data, selected, onChangeBlock, intl } = props;
  const schema = CustomJavascriptSchema({ intl });
  return (
    <div className="public-ui">
      <SidebarPortal selected={selected}>
        <InlineForm
          schema={schema}
          title={schema.title}
          formData={data}
          onChangeField={(id, value) => {
            onChangeBlock(block, {
              ...data,
              [id]: value,
            });
          }}
        />
      </SidebarPortal>
      <em>
        <FormattedMessage id="Custom Javascript" defaultMessage="Custom Javascript" />
        :&nbsp;
        <FormattedMessage id="No preview available" defaultMessage="No preview available" />
      </em>
    </div>
  );
};

export default CustomJavascriptEdit;
