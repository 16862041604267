import PropTypes from 'prop-types';

export const ListingCategory = ({ category }) => {
  return category ? <>{category}</> : null;
};

ListingCategory.propTypes = {
  category: PropTypes.string,
  item: PropTypes.object,
};

export default ListingCategory;
