import { Component, createRef } from 'react';
import { assign, getOptions } from '@eeacms/volto-openlayers-map/helpers';

import { Portal } from 'react-portal';
import _uniqueId from 'lodash/uniqueId';
import cs from 'classnames';
import { openlayers } from '@eeacms/volto-openlayers-map';
import { withMapContext } from '@eeacms/volto-openlayers-map/hocs';

class Control extends Component {
  control = undefined;

  options = {
    element: undefined,
    render: undefined,
    target: undefined,
  };

  constructor(props) {
    super(props);
    this.options = getOptions(assign(this.options, this.props));
    this.addControl = this.addControl.bind(this);
    this.element = createRef();
    this.state = {
      id: null,
    };
  }

  addControl() {
    const { map, mapRendered } = this.props;

    const id = this.props.id || _uniqueId('ol-overlay-');

    const element = document.createElement('div');
    element.setAttribute('id', id);
    element.setAttribute(
      'class',
      cs(!this.props.className ? 'ol-control' : '', this.props.className)
    );
    element.style.top = this.props.top;
    element.style.left = this.props.left;
    element.style.bottom = this.props.bottom;
    element.style.right = this.props.right;

    let options = {
      ...getOptions(assign(this.options, this.props)),
      element,
    };

    this.control = new openlayers.control.Control(options);

    if (!mapRendered) {
      this.props.addControl(this.control);
    } else {
      map.addControl(this.control);
    }

    this.setState({ id });
  }

  componentDidMount() {
    this.addControl();
  }

  componentDidUpdate(prevProps) {
    const { bottom, className, left, mapRendered, right, top } = this.props;
    if (__SERVER__ || !mapRendered) return;
    const element = this.control.element;
    if (element) {
      if (prevProps.className !== className) {
        element.setAttribute('class', cs(!className ? 'ol-control' : '', className));
      }
      if (prevProps.top !== top) {
        element.style.top = top;
      }
      if (prevProps.left !== left) {
        element.style.left = left;
      }
      if (prevProps.bottom !== bottom) {
        element.style.bottom = bottom;
      }
      if (prevProps.right !== right) {
        element.style.right = right;
      }
    }
  }

  componentWillUnmount() {
    const { map, mapRendered } = this.props;
    if (__SERVER__ || !mapRendered) return;
    map.removeControl(this.control);
  }

  render() {
    return this.state.id && this.props.children ? (
      <Portal node={document.querySelector(`#${this.state.id}`)}>{this.props.children}</Portal>
    ) : (
      ''
    );
  }
}

export default withMapContext(Control);
