/**
 * FooterSmall component.
 * @module components/theme/Footer/FooterSmall
 */

import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { getItemsByPath, getSubFooter } from 'volto-subfooter';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from 'design-react-kit/dist/design-react-kit';
import { UniversalLink } from '@plone/volto/components';
import cx from 'classnames';
import { displayBanner } from 'volto-gdpr-privacy';
import { flattenToAppURL } from '@plone/volto/helpers';
import { getSiteProperty } from '@arpav/helpers';
import { toPublicURL } from '@plone/volto/helpers';
import { useLocation } from 'react-router-dom';

const messages = defineMessages({
  goToPage: {
    id: 'Vai alla pagina',
    defaultMessage: 'Vai alla pagina',
  },
  cookieSettings: {
    id: 'Impostazioni cookie footer',
    defaultMessage: 'Impostazioni cookie',
  },
  privacy: {
    id: 'Privacy',
    defaultMessage: 'Privacy',
  },
  notelegali: {
    id: 'Note legali',
    defaultMessage: 'Note legali',
  },
  accessibilita: {
    id: 'Accessibilità',
    defaultMessage: 'Accessibilità',
  },
  credits: {
    id: 'Credits',
    defaultMessage: 'Credits',
  },
});

/**
 * FooterSmall component class.
 * @class FooterSmall
 * @extends Component
 */
const FooterSmall = () => {
  const intl = useIntl();
  const pathname = useLocation().pathname;
  const dispatch = useDispatch();
  const [links, setLinks] = useState([]);

  const subFooter = useSelector(state => state.subFooter?.result);
  const subFooterItems = getItemsByPath(subFooter, pathname)?.filter(item => item.visible);

  useEffect(() => {
    dispatch(getSubFooter());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let _links = getSiteProperty('smallFooterLinks', intl.locale) ?? [];
    setLinks(_links);
  }, [intl.locale]);

  return (
    <div className="it-footer-small-prints clearfix">
      <Container tag="div">
        <h3 className="sr-only">{intl.formatMessage(messages.goToPage)}</h3>
        <ul className="it-footer-small-prints-list   mb-0 d-flex flex-column flex-md-row">
          {subFooterItems?.length > 0 &&
            subFooterItems.map((item, index) => {
              let url = item.href || flattenToAppURL(item.linkUrl?.[0]?.['@id']) || '/';
              return (
                <li
                  className={cx('list-inline-item', {
                    'in-evidence': item.inEvidence,
                  })}
                  key={url + index}
                >
                  <UniversalLink href={url} title={item.title}>
                    {item.title}
                  </UniversalLink>
                </li>
              );
            })}
          {links?.length > 0 &&
            links.map(link => (
              <li className="list-inline-item" key={link.url}>
                <UniversalLink href={link.url} title={link.title}>
                  {link.title}
                </UniversalLink>
              </li>
            ))}
          <li className="list-inline-item">
            <button
              className="footer-gdpr-privacy-show-banner"
              onClick={e => {
                e.preventDefault();
                dispatch(displayBanner(true, true));
              }}
              title={intl.formatMessage(messages.cookieSettings)}
            >
              {intl.formatMessage(messages.cookieSettings)}
            </button>
          </li>
          <li className="list-inline-item">
            <UniversalLink
              href={toPublicURL('/privacy')}
              title={intl.formatMessage(messages.privacy)}
            >
              {intl.formatMessage(messages.privacy)}
            </UniversalLink>
          </li>
          <li className="list-inline-item">
            <UniversalLink
              href={toPublicURL('/note-legali')}
              title={intl.formatMessage(messages.notelegali)}
            >
              {intl.formatMessage(messages.notelegali)}
            </UniversalLink>
          </li>
          <li className="list-inline-item">
            <UniversalLink
              href={toPublicURL('/accessibilita')}
              title={intl.formatMessage(messages.accessibilita)}
            >
              {intl.formatMessage(messages.accessibilita)}
            </UniversalLink>
          </li>
          <li className="list-inline-item">
            <UniversalLink
              href={toPublicURL('/credits')}
              title={intl.formatMessage(messages.credits)}
            >
              {intl.formatMessage(messages.credits)}
            </UniversalLink>
          </li>
          <li className="list-inline-item">
            <span>Copyright © ARPA Veneto - P.IVA 03382700288</span>
          </li>
        </ul>
      </Container>
    </div>
  );
};

export default FooterSmall;
