import {
  Card,
  CardBody,
  CardCategory,
  CardReadMore,
  CardText,
  CardTitle,
  Chip,
  ChipLabel,
} from 'design-react-kit/dist/design-react-kit';
import { defineMessages, useIntl } from 'react-intl';
import { getItemClass, renderDateTime } from '@arpav/helpers';

import { ListingText } from '@arpav/components/ArpavTheme';
import { UniversalLink } from '@plone/volto/components';

const messages = defineMessages({
  active: {
    id: 'active_announcements',
    defaultMessage: 'Active',
  },
  expDate: {
    id: 'Expiration',
    defaultMessages: 'Expiration',
  },
  expired: {
    id: 'Expired',
    defaultMessage: 'Expired',
  },
  readMore: {
    id: 'Read More…',
    defaultMessage: 'Read More…',
  },
  completed: {
    id: 'completed_announcement',
    defaultMessage: 'Completed',
  },
});

const AnnouncementListItem = ({ item, iscompetition }) => {
  const intl = useIntl();
  let ann_date;
  let is_expired = false;
  const is_completed = iscompetition && item.announcement_completed;

  // prepare expiration date label
  if (item.competition_end) {
    ann_date = (
      <>
        <span>{intl.formatMessage(messages.expDate)}: </span>
        {renderDateTime(item.competition_end)}
      </>
    );
    is_expired = new Date(item.competition_end) < new Date();
  }

  // prepare status chip
  const chipconfig = {
    className: null,
    label: null,
  };
  if (is_completed) {
    chipconfig.className = 'expired';
    chipconfig.label = intl.formatMessage(messages.completed);
  } else if (!is_expired) {
    chipconfig.className = 'announcement';
    chipconfig.label = intl.formatMessage(messages.active);
  } else if (is_expired) {
    chipconfig.className = 'expired';
    chipconfig.label = intl.formatMessage(messages.expired);
  }

  return (
    <Card className={`align-items-top rounded shadow mb-4 ${getItemClass(item)}`} noWrapper teaser>
      <CardBody className="pb-5">
        <CardCategory date={ann_date}>
          <span className="text font-weight-bold">{item.announcement_type}</span>
        </CardCategory>
        <CardTitle tag="h3">
          <UniversalLink item={item}>{item.title}</UniversalLink>
        </CardTitle>
        <CardText>
          <ListingText item={item} />
          <div>
            <Chip disabled={false} large={false} simple tag="span" className={chipconfig.className}>
              <ChipLabel tag="span">{chipconfig.label}</ChipLabel>
            </Chip>
          </div>
        </CardText>

        <CardReadMore
          iconName="it-arrow-right"
          tag={UniversalLink}
          item={item}
          text={intl.formatMessage(messages.readMore)}
        />
      </CardBody>
    </Card>
  );
};

const AnnouncementListItemSkeleton = () => {
  return (
    <Card className={`align-items-top rounded shadow mb-4`} noWrapper teaser>
      <CardBody className="pb-5">
        <CardCategory date="">
          <span className="text font-weight-bold"></span>
        </CardCategory>
        <CardTitle tag="h3">
          <UniversalLink href="#"></UniversalLink>
        </CardTitle>
        <CardText></CardText>

        <CardReadMore iconName="it-arrow-right" tag={UniversalLink} href="" text="" />
      </CardBody>
    </Card>
  );
};

export { AnnouncementListItem, AnnouncementListItemSkeleton };
