/*
CUSTOMIZATIONS:
-added skeleton
- added additionalFilters
- 'background class' and 'block class'
*/

import { FormattedMessage, injectIntl } from 'react-intl';
import { Pagination, Skeleton } from '@arpav/components/ArpavTheme';

import config from '@plone/volto/registry';
import { isEqual } from 'lodash';
import { memo } from 'react';
import withQuerystringResults from '@plone/volto/components/manage/Blocks/Listing/withQuerystringResults';
import cx from 'classnames';

const ListingBody = memo(
  withQuerystringResults(
    props => {
      const {
        data = {},
        isEditMode,
        listingItems,
        totalPages,
        itemsTotal,
        onPaginationChange,
        variation,
        currentPage,
        isFolderContentsListing,
        hasLoaded,
        hasQuery,
        addFilters,
        firstLoading,
        properties,
        loadingQuery,
        listingRef,
        additionalFilters,
      } = props;

      let ListingBodyTemplate;
      let templateConfig;
      // Legacy support if template is present
      const variations = config.blocks?.blocksConfig['listing']?.variations || [];
      const defaultVariation = variations.filter(item => item.isDefault)?.[0];

      if (data.template && !data.variation) {
        let legacyTemplateConfig = variations.find(item => item.id === data.template);

        if (!legacyTemplateConfig) {
          legacyTemplateConfig = defaultVariation;
        }
        templateConfig = legacyTemplateConfig;
        ListingBodyTemplate = legacyTemplateConfig.template;
      } else {
        templateConfig = variation ?? defaultVariation;
        ListingBodyTemplate = templateConfig?.template;
      }

      const SkeletonTemplate = templateConfig.skeleton || Skeleton;

      const getBackgroundClass = () => {
        const block = properties.blocks[data.block];
        if (!block?.show_block_bg) return '';

        let bg_class = '';
        if (data?.header_bg) {
          bg_class = ` section-background-header${
            data.bg_color ? ` bg-header-${data.bg_color}` : ''
          }`;
        } else {
          bg_class = data.bg_color ? ` bg-${data.bg_color}` : '';
        }

        if (block.template === 'gridGalleryTemplate') {
          return `section section-muted section-inset-shadow py-5${bg_class}`;
        } else {
          return `section py-5${bg_class}`;
        }
      };

      const getBlockClasses = () => {
        let bg_color = getBackgroundClass();
        let items_color = data.items_color ? `items-color-${data.items_color}` : '';

        return `${bg_color} ${items_color}`;
      };

      return (
        <div className="public-ui">
          {loadingQuery && (
            <div
              className={cx(getBlockClasses(), { 'full-width': data.set_full_width })}
              ref={listingRef}
            >
              <SkeletonTemplate {...data} />
            </div>
          )}
          {!loadingQuery && (listingItems.length > 0 || additionalFilters?.length > 0) ? (
            <div
              className={cx(getBlockClasses(), { 'full-width': data.set_full_width })}
              ref={listingRef}
            >
              <ListingBodyTemplate
                items={listingItems}
                isEditMode={isEditMode}
                {...data}
                addFilters={addFilters}
                additionalFilters={additionalFilters}
                items_total={itemsTotal}
                loading={loadingQuery}
                firstLoading={firstLoading}
              />
              {totalPages > 1 && (
                <div className="pagination-wrapper">
                  <Pagination
                    activePage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPaginationChange}
                  />
                </div>
              )}
            </div>
          ) : isEditMode ? (
            <div className="listing message">
              {isFolderContentsListing && (
                <FormattedMessage
                  id="No items found in this container."
                  defaultMessage="No items found in this container."
                />
              )}
              {hasLoaded && hasQuery && (
                <FormattedMessage id="No results found." defaultMessage="No results found." />
              )}
            </div>
          ) : null}
        </div>
      );
    },
    function areEqual(prevProps, nextProps) {
      return isEqual(prevProps.data, nextProps.data);
    }
  )
);

export default injectIntl(ListingBody);
