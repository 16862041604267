import { Image } from '@arpav/components/ArpavTheme';
import PropTypes from 'prop-types';

/**
 * WideImage view component class.
 * @function WideImage
 * @params {object} content: Content object.
 * @returns {string} Markup of the component.
 */
const WideImage = ({ image, title, caption }) => {
  return (
    <div className="wide-image mx-0 mb-3">
      <figure className="figure">
        {image && (
          <Image
            // src={
            //   image['content-type'] === 'image/svg+xml'
            //     ? flattenToAppURL(image.download)
            //     : flattenToAppURL(image.scales.large.download)
            // }
            image={image}
            alt={caption || title}
            title={caption || title}
            critical
            loading="eager"
            key={image?.download}
          />
        )}
        {caption && <figcaption className="figure-caption text-center pt-3">{caption}</figcaption>}
      </figure>
    </div>
  );
};
export default WideImage;

WideImage.propTypes = {
  params: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.shape({
      download: PropTypes.string,
    }),
    caption: PropTypes.string,
  }),
};
