import { defineMessages } from 'react-intl';

const messages = defineMessages({
  customJavascript: {
    id: 'Custom Javascript',
    defaultMessage: 'Custom Javascript',
  },
  defaultFieldset: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  jsCode: {
    id: 'Javascript code',
    defaultMessage: 'Javascript code',
  },
  jsCodeDesc: {
    id: 'Use "block" variable as dom container.',
    defaultMessage: 'Use "block" variable as dom container.',
  },
});

// define block schema
const CustomJavascriptSchema = ({ intl }) => ({
  title: intl.formatMessage(messages.customJavascript),
  fieldsets: [
    {
      id: 'default',
      title: intl.formatMessage(messages.defaultFieldset),
      fields: ['jscode'],
    },
  ],
  properties: {
    jscode: {
      title: intl.formatMessage(messages.jsCode),
      description: intl.formatMessage(messages.jsCodeDesc),
      widget: 'textarea',
    },
  },
  required: ['jscode'],
});

export default CustomJavascriptSchema;
