import {
  Card,
  CardBody,
  CardReadMore,
  CardText,
  CardTitle,
} from 'design-react-kit/dist/design-react-kit';
import { FormattedDate, PreviewImage } from '@plone/volto/components';

import DefaultImageSVG from '@arpav/components/ArpavTheme/Blocks/Listing/default-image.svg';
import { Link } from 'react-router-dom';
import { UniversalLink } from '@plone/volto/components';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  pressRelease: {
    id: 'Press release',
    defaultMessage: 'Press release',
  },
  readMore: {
    id: 'Read More…',
    defaultMessage: 'Read More…',
  },
});

const NewsItemCard = ({ intl, item, isEditMode }) => {
  const currentYear = `${new Date().getFullYear()}`;
  return (
    <Card spacing className="card-bg card-img rounded shadow no-after">
      <div className="img-responsive-wrapper">
        <div className="img-responsive">
          <figure className="img-wrapper">
            <PreviewImage
              item={item}
              size="large"
              alt={item.image_caption ? item.image_caption : item.title}
              className="ui image"
            />
          </figure>
          <div className="card-calendar d-flex flex-column justify-content-center">
            <FormattedDate
              date={item.date}
              format={{
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              }}
            >
              {parts => {
                return parts
                  .filter(
                    p => p.type !== 'literal' && (p.type !== 'year' || p.value !== currentYear)
                  )
                  .map((p, i) => (
                    <span className={`card-${p.type == 'day' ? 'date' : 'day'}`} key={i}>
                      {p.value}
                    </span>
                  ));
              }}
            </FormattedDate>
          </div>
          <div className={`card-${item.is_pressrelease ? 'pressrelease' : 'news'}-category`}>
            {item.is_pressrelease
              ? intl.formatMessage(messages.pressRelease)
              : intl.formatMessage({ id: 'News Item' })}
          </div>
        </div>
      </div>
      <CardBody className="pb-5">
        <CardTitle tag="h3">{item.title}</CardTitle>
        <CardText>{item.text}</CardText>
        <CardReadMore
          iconName="it-arrow-right"
          tag={UniversalLink}
          item={!isEditMode ? item : null}
          href={isEditMode ? '#' : null}
          text={intl.formatMessage(messages.readMore)}
        ></CardReadMore>
      </CardBody>
    </Card>
  );
};

const NewsItemCardSkeleton = () => {
  return (
    <Card spacing className="card-bg card-img rounded shadow">
      <div className="img-responsive-wrapper">
        <div className="img-responsive">
          <figure className="img-wrapper">
            <img src={DefaultImageSVG} className="img-placeholder" />
          </figure>
          <div className="card-calendar d-flex flex-column justify-content-center"></div>
          <div className="card-news-category"></div>
        </div>
      </div>
      <CardBody>
        <CardTitle tag="h3"></CardTitle>
        <CardText> </CardText>
        <CardReadMore tag={Link} iconName="it-arrow-right" text=" " to="#" />
      </CardBody>
    </Card>
  );
};

export { NewsItemCard, NewsItemCardSkeleton };
