import { getArpavApiContent, getRawContent } from '@arpav/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';

import { SOURCE_TYPES } from '@arpav/components/ArpavTheme/Blocks/DataTable/schema';
import csv from 'papaparse';
import { isCMSInternalURL } from '@arpav/helpers';
import { orderBy } from 'lodash';

function populateData(
  file_data,
  page_size,
  isEditMode,
  currentPage,
  direction,
  column,
  pagination
) {
  let result = {};
  if (file_data?.data) {
    result = {
      meta: {
        ...file_data?.meta,
      },
    };
    if (result?.meta) {
      result.meta.page_size = parseInt(page_size) || 30;
      result.meta.total = file_data.data.length;
    }
    result.data = (() => {
      let sorted_data = column
        ? orderBy(file_data.data || [], [column], [direction === 'descending' ? 'desc' : 'asc'])
        : file_data.data;
      let start = result.meta.page_size * (currentPage - 1);
      start = start > sorted_data.length ? sorted_data.length : start;
      return isEditMode
        ? sorted_data.slice(0, 10)
        : pagination
        ? sorted_data.slice(start, start + result.meta.page_size)
        : sorted_data;
    })();
  }
  return result;
}

/**
 * Filter data locally.
 *
 * @param {Object[]} data
 * @param {Object} filters
 *
 * @returns {Object} Returns a copy of received data with filtered data
 */
const filterData = (data, filters) => {
  let filtered = [...data.data];
  Object.keys(filters).forEach(k => {
    filtered = filtered.filter(i => i[k] == filters[k]);
  });
  return {
    ...data,
    data: filtered,
  };
};

/**
 * Get table columns.
 *
 * @param {Object[]} columns
 * @param {Object} metadata
 *
 * @returns {Object[]}
 */
const defineColumns = (columns, metadata) => {
  return columns?.length > 0
    ? columns
    : metadata?.fields?.map(n => ({
        column: n,
      }));
};

/**
 * Define filtered columns
 *
 * @param {Object[]} columns
 * @param {Object[]} data
 *
 * @returns {String[], String[]}
 */
const defineFilteredColumns = columns => {
  let requiredFilters = [];
  const filteredColumns = columns
    ?.filter(col => {
      if (col.filtered) {
        if (col.filterRequired) {
          requiredFilters.push(col.column);
        }
        return true;
      }
      return false;
    })
    .sort(
      (a, b) =>
        a.filterRequired && !b.filterRequired ? -1 : b.filterRequired && !a.filterRequired ? 1 : 0 // sort by filterRequired
    );
  return [filteredColumns, requiredFilters];
};

/**
 * Return unique values for filtered columns
 *
 * @param {Object[]} filters
 * @param {Object[]} data
 *
 * @returns {Object[]}
 */
const extractFilterOptions = (columns, data) => {
  const options = {};
  columns.forEach(c => {
    options[c.column] = [];
    [...new Set(data.map(item => item[c.column]))].forEach(d => {
      options[c.column].push({
        label: d,
        value: d,
      });
    });
  });
  return options;
};

/**
 * Prepare CSV file URL
 * @param {String} path
 * @returns {String}
 */
const prepareCSVUrl = path => {
  let file_path = path;
  if (path && isCMSInternalURL(path)) {
    file_path += '/@@download/file';
  }
  return file_path;
};

const withFileData = getData => WrappedComponent => {
  return props => {
    const {
      source_type,
      file_path,
      api_url,
      default_sort_direction,
      default_sort_column,
      pagination,
      page_size,
      block,
      isEditMode,
    } = getData(props);
    const isCsv = source_type === SOURCE_TYPES.csv.id;
    const isApi = source_type === SOURCE_TYPES.api.id;

    const [currentPage, setCurrentPage] = useState(1);
    const [direction, setDirection] = useState(
      default_sort_direction === 'descending' ? 'descending' : 'ascending'
    );
    const [filter, setFilter] = useState({});
    useEffect(() => {
      setDirection(default_sort_direction);
    }, [default_sort_direction]);
    const [column, setColumn] = useState(default_sort_column);
    useEffect(() => {
      setColumn(default_sort_column);
    }, [default_sort_column]);
    const dispatch = useDispatch();

    const path = prepareCSVUrl(file_path);

    const rawRequest = useSelector(state => state.rawContent?.subrequests?.[path]);
    const apiRequest = useSelector(state => state.arpavApiContent?.[`${block}-${api_url || ''}`]);

    const [filteredData, setFilteredData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [filteredColumns, setFilteredColumns] = useState([]);
    const [requiredFilters, setRequiredFilters] = useState([]);
    const [showTable, setShowTable] = useState(false);
    const [filtersOptions, setFiltersOptions] = useState({});

    useEffect(() => {
      if (isCsv && path && dispatch && !rawRequest?.loading) dispatch(getRawContent(path));
    }, [file_path]);

    useEffect(() => {
      if (isApi && dispatch && api_url && currentPage) {
        let params = {};
        if (currentPage) {
          params.p = currentPage;
        }
        if (page_size) {
          params.page_size = page_size;
        }
        if (column) {
          params.sort = direction === 'descending' ? `-${column}` : column;
        }
        if (filter) {
          params = {
            ...params,
            ...filter,
          };
        }
        dispatch(getArpavApiContent(api_url, params, `${block}-${api_url}`));
      }
    }, [dispatch, api_url, currentPage, page_size, direction, column]);

    const file_content = rawRequest?.data;
    const file_data = useMemo(() => {
      let res = {};
      if (file_content) {
        res = csv.parse(file_content, { header: true });
      }
      return populateData(
        res?.data && filter && Object.keys(filter)?.length
          ? {
              ...res,
              data: res.data.filter(item => {
                const every = Object.keys(filter).every(col => {
                  return item[col] === filter[col];
                });
                return every;
              }),
            }
          : res,
        page_size,
        isEditMode,
        currentPage,
        direction,
        column,
        pagination
      );
    }, [file_content, page_size, isEditMode, currentPage, direction, column, pagination, filter]);

    const api_content = isEditMode ? apiRequest?.data?.slice(0, 10) : apiRequest?.data;
    const data_result = useMemo(() => {
      if (api_content)
        return {
          data: api_content,
          errors: [],
          meta: {
            aborted: false,
            fields: Object.keys(api_content[0]),
            truncated: false,
            ...apiRequest?.meta,
          },
        };
    }, [api_content]);

    // locally filtered data
    useEffect(() => {
      if (data_result) {
        setFilteredData(filterData(data_result, filter));
      }
    }, [filter, data_result?.data?.length]);

    // calculante columns
    useEffect(() => {
      const meta = isCsv ? file_data?.meta : data_result?.meta;
      setColumns(defineColumns(props?.data?.columns, meta));
    }, [data_result?.meta, file_data?.meta]);

    // calculate columns to use in filters
    useEffect(() => {
      if (!props?.data?.pagination) {
        const [cols, req] = defineFilteredColumns(columns);
        setFilteredColumns(cols);
        setRequiredFilters(req);
      }
    }, [columns]);

    // calculate show table variable
    useEffect(() => {
      let st = true;
      if (requiredFilters?.length) {
        requiredFilters.forEach(f => {
          st = filter[f] ? st : false;
        });
      }
      setShowTable(st);
    }, [requiredFilters, filter]);

    useEffect(() => {
      const data = isCsv ? file_data?.data : data_result?.data;
      if (filteredColumns?.length && data?.length) {
        setFiltersOptions(extractFilterOptions(filteredColumns, data));
      }
    }, [filteredColumns, data_result?.data?.length, file_data?.data?.length]);

    return (
      <WrappedComponent
        data_result={{ ...(isCsv ? file_data || {} : isApi ? filteredData || {} : {}) }}
        error={rawRequest?.error || apiRequest?.error}
        loaded={rawRequest?.loaded || apiRequest?.loaded}
        loading={rawRequest?.loading || apiRequest?.loading}
        onPaginationChange={(e, { activePage }) => setCurrentPage(activePage)}
        currentPage={currentPage}
        onSort={clickedColumn => {
          setColumn(clickedColumn);
          setDirection(direction === 'ascending' ? 'descending' : 'ascending');
        }}
        sortColumn={column}
        sortDirection={direction}
        filter={filter}
        columns={columns}
        filteredColumns={filteredColumns}
        showTable={showTable}
        filtersOptions={filtersOptions}
        onFilter={value => {
          setFilter({
            ...value,
          });
        }}
        {...props}
      />
    );
  };
};

export default withFileData;
