/**
 * Raw content actions.
 * @module actions/rawContent/rawContent
 */

import { GET_RAW_CONTENT } from '@arpav/constants/ActionTypes';

/**
 * Get raw content function.
 * @function getRawContent
 * @param {string} url Content url.
 * @returns {Object} Get schema action.
 */
export function getRawContent(url) {
  return {
    type: GET_RAW_CONTENT,
    request: {
      op: 'get',
      path: url,
    },
    url,
  };
}
