/**
 * ARPAV API content actions.
 * @module actions/arpavApiContent/arpavApiContent
 */

import { GET_ARPAV_API_CONTENT } from '@arpav/constants/ActionTypes';

/**
 * Get ARPAV API content function.
 * @function getArpavApiContent
 * @param {string} url Content url.
 * @param {Object} params Content url.
 * @returns {Object} Get schema action.
 */
export function getArpavApiContent(url, params, id) {
  let qs = Object.keys(params)
    .sort()
    .map(key => `${key}=${params[key]}`)
    .join('&');
  return {
    type: GET_ARPAV_API_CONTENT,
    request: {
      op: 'get',
      path: `${url}${qs ? `${url.indexOf('?') !== -1 ? '&' : '?'}${qs}` : ''}`,
    },
    url,
    id,
  };
}
