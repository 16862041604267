import { MostVisitedPages } from '@arpav/components/ArpavTheme';

const View = ({ id, path }) => {
  return (
    <div className="block lastPublications">
      <div className="simple-card-default full-width pb-lg-0 h-auto">
        <MostVisitedPages id={id} path={path} />
      </div>
    </div>
  );
};

export default View;
