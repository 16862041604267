/**
 * Raw coontent reducer.
 * @module reducers/rawContent/rawContent
 */

import { GET_RAW_CONTENT } from '@arpav/constants/ActionTypes';

const initialState = {
  data: null,
  subrequests: {},
};

/**
 * Raw coontent reducer.
 * @function schema
 * @param {Object} rawContent Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function rawContent(state = initialState, action = {}) {
  let { result, url } = action;

  switch (action.type) {
    case `${GET_RAW_CONTENT}_PENDING`:
      return {
        subrequests: {
          ...state.subrequests,
          [url]: {
            ...(state.subrequests[url] || {
              data: null,
            }),
            loading: true,
            loaded: false,
            error: undefined,
          },
        },
      };
    case `${GET_RAW_CONTENT}_SUCCESS`:
      return {
        ...state,
        subrequests: {
          ...state.subrequests,
          [url]: {
            data: result,
            loading: false,
            loaded: true,
            error: undefined,
          },
        },
      };
    case `${GET_RAW_CONTENT}_FAIL`:
      return {
        ...state,
        subrequests: {
          ...state.subrequests,
          [url]: {
            data: null,
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };
    default:
      return state;
  }
}
