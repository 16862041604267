import { assign, getEvents, getOptions } from '@eeacms/volto-openlayers-map/helpers';

import React from 'react';
import { isEqual } from 'lodash';
import { openlayers } from '@eeacms/volto-openlayers-map';
import { withMapContext } from '@eeacms/volto-openlayers-map/hocs';

const { layer } = openlayers;

const KML_URL = '/risorse/data-aria/Forecast/CAMx_4_O3max_24.kml';

class Ozono extends React.Component {
  layer = undefined;

  options = {
    className: undefined,
    declutter: undefined,
    extent: undefined,
    map: undefined,
    maxResolution: undefined,
    maxZoom: undefined,
    minResolution: undefined,
    minZoom: undefined,
    opacity: undefined,
    renderBuffer: undefined,
    renderOrder: undefined,
    source: undefined,
    style: undefined,
    updateWhileAnimating: undefined,
    updateWhileInteracting: undefined,
    visible: undefined,
    zIndex: undefined,
  };

  events = {
    'change:extent': undefined,
    'change:maxResolution': undefined,
    'change:maxZoom': undefined,
    'change:minResolution': undefined,
    'change:minZoom': undefined,
    'change:opacity': undefined,
    'change:source': undefined,
    'change:visible': undefined,
    'change:zIndex': undefined,
    change: undefined,
    error: undefined,
    postrender: undefined,
    prerender: undefined,
    propertychange: undefined,
  };

  constructor(props) {
    super(props);
    this.options = getOptions(assign(this.options, this.props));
    this.addLayer = this.addLayer.bind(this);
  }

  addLayer() {
    const { mapRendered } = this.props;
    let events = getEvents(this.events, this.props);
    this.layer = new layer.Vector(this.options);
    for (let event in events) {
      this.layer.on(event, events[event]);
    }
    if (!mapRendered) {
      this.props.addLayer(this.layer);
    }
  }

  componentDidMount() {
    // set KML url
    this.options.source = new openlayers.source.Vector({
      url: KML_URL,
      format: new openlayers.format.KML(),
    });

    this.addLayer();
  }

  componentDidUpdate(prevProps) {
    const prevOptions = getOptions(assign(this.options, prevProps));
    const options = getOptions(assign(this.options, this.props));
    if (!isEqual(prevOptions, options)) {
      Object.keys(options).forEach(o => {
        if (o !== 'source' && o !== 'style' && prevOptions[o] !== o) {
          this.layer.set(o, options[o]);
        }
      });
      this.options = getOptions(assign(this.options, this.props));
      this.layer.changed();
    }
  }

  componentWillUnmount() {
    if (__SERVER__ || !this.layer) return;
    this.layer.dispose();
  }

  render() {
    return null;
  }
}

export default withMapContext(Ozono);
