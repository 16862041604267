import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useState } from 'react';

import { getArpavApiContent } from '@arpav/actions';
import useDeepCompareEffect from 'use-deep-compare-effect';

const withResourcesData = getData => WrappedComponent => {
  return props => {
    const { resources, block, path } = getData(props);
    const dispatch = useDispatch();

    const [requestParameter, setRequestParameter] = useState({
      currentlayer: null,
      parameter: null,
    });

    // get api url
    const api_url_list = (resources || [])
      ?.filter(resource => resource.url)
      .map(resource => ({
        id: resource['@id'],
        url: resource.url,
        data_url: resource.data_url,
        has_param: !!resource.url_parameter_values,
        request_param: resource.url_parameter_default || '',
      }));

    // data results
    const request_list = useSelector(state => {
      let result = {};
      Object.keys(state.arpavApiContent || [])
        .filter(key => key.startsWith(`${path}-${block}-`))
        .forEach(key => {
          result[key.replace(`${path}-${block}-`, '')] = state.arpavApiContent[key];
        });
      return result;
    });

    useDeepCompareEffect(() => {
      const loading = Object.keys(request_list).filter(k => request_list[k].loading);
      const notLoaded = Object.keys(request_list).filter(k => !request_list[k].loaded);
      if (dispatch && api_url_list?.length && !loading.length && !notLoaded.length) {
        api_url_list.forEach(({ url, id, data_url, has_param, request_param }) => {
          // load main url data
          if (url && !data_result?.[id]) {
            if (has_param && !data_url) {
              url = url.replace(/DYNPARAM/g, request_param);
            }
            dispatch(getArpavApiContent(url, {}, `${path}-${block}-${id}`));
          }
          // load data url data
          if (data_url && !data_result?.[id]) {
            if (has_param) {
              data_url = data_url.replace(/DYNPARAM/g, request_param);
            }
            dispatch(getArpavApiContent(data_url, {}, `${path}-${block}-${id}-data`));
          }
        });
      }
    }, [dispatch, resources, block, path]);

    useDeepCompareEffect(() => {
      const api = api_url_list.find(a => a.id === requestParameter.currentlayer);
      if (api) {
        const url = (api.data_url || api.url).replace(/DYNPARAM/g, requestParameter.parameter);
        const contentparam = `${path}-${block}-${api.id}` + (api.data_url ? '-data' : '');
        dispatch(getArpavApiContent(url, {}, contentparam));
      }
    }, [requestParameter]);

    const data_result = useMemo(() => {
      if (request_list) {
        let result = {};
        Object.keys(request_list).forEach(k => {
          if (!request_list[k].loading) {
            const data = request_list[k]?.data;
            result[k] = {
              ...request_list[k],
              data: data,
              meta: {
                ...request_list[k]?.meta,
                fields: data?.length ? Object.keys(data[0]) : [],
              },
            };
          }
        });
        return result;
      }
    }, [request_list]);

    return (
      <WrappedComponent
        {...props}
        data_result={data_result}
        updateRequestParameter={setRequestParameter}
      />
    );
  };
};

export default withResourcesData;
