import { defineMessages, injectIntl } from 'react-intl';

import { Icon } from '@plone/volto/components';
import { Link } from 'react-router-dom';
import { Plug } from '@plone/volto/components/manage/Pluggable';
import calendarSVG from '@plone/volto/icons/calendar.svg';
import { compose } from 'redux';
import { connect } from 'react-redux';
import downloadSVG from '@plone/volto/icons/download.svg';
import { find } from 'lodash';
import { getBaseUrl } from '@plone/volto/helpers';

const messages = defineMessages({
  downloadEventBooking: {
    id: 'Download event booking',
    defaultMessage: 'Download event booking',
  },
  manageEventBooking: {
    id: 'Manage event booking',
    defaultMessage: 'Manage event booking',
  },
});

export const EventBookingToolbar = ({ intl, content, objectActions, pathname, token }) => {
  const path = getBaseUrl(pathname);

  const editPermission = find(objectActions, { id: 'edit' });

  return token && editPermission && content?.['@type'] === 'Event' ? (
    <Plug pluggable="toolbar-more-manage-content" id="manageeventbooking">
      <li>
        <Link
          id="toolbar-manage-event-booking-form"
          aria-label={intl.formatMessage(messages.manageEventBooking)}
          className="manageeventbooking"
          to={`${path}/manageeventsubscription`}
        >
          <div>
            <span className="pastanaga-menu-label">
              {intl.formatMessage(messages.manageEventBooking)}
            </span>
            <span className="pastanaga-menu-value" />
          </div>
          <Icon
            name={calendarSVG}
            size="30px"
            title={intl.formatMessage(messages.manageEventBooking)}
          />
        </Link>
        <Link
          id="toolbar-manage-event-booking-form"
          aria-label={intl.formatMessage(messages.downloadEventBooking)}
          className="downloadeventbooking"
          target="_blank"
          to={`${path}/event-booking-csv`}
        >
          <div>
            <span className="pastanaga-menu-label">
              {intl.formatMessage(messages.downloadEventBooking)}
            </span>
            <span className="pastanaga-menu-value" />
          </div>
          <Icon
            name={downloadSVG}
            size="30px"
            title={intl.formatMessage(messages.downloadEventBooking)}
          />
        </Link>
      </li>
    </Plug>
  ) : null;
};

export default compose(
  injectIntl,
  connect((state, props) => ({
    content: state.content.data,
    objectActions: state.actions.actions.object,
    pathname: props.location.pathname,
    token: state.userSession.token,
  }))
)(EventBookingToolbar);
