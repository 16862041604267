import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Row,
} from 'design-react-kit/dist/design-react-kit';

const Skeleton = () => {
  return (
    <div className="skeleton-template">
      <Row>
        {[0, 1, 2].map(i => (
          <Col key={i} xs="12">
            <Card className="align-items-top rounded shadow mb-3" noWrapper teaser>
              <CardBody className="pb-5">
                <CardTitle tag="h3"> </CardTitle>
                <CardText> </CardText>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Skeleton;
