/**
 * Default view actions.
 * @module actions/defaultView/defaultView
 */

import { GET_DEFAULT_VIEW } from '@arpav/constants/ActionTypes';

/**
 * Get default view function.
 * @function getDefaultView
 * @param {string} url Content url.
 * @returns {Object} Get schema action.
 */
export function getDefaultView(url) {
  url = typeof url !== 'undefined' ? url : '';
  return {
    type: GET_DEFAULT_VIEW,
    request: {
      op: 'get',
      path: `${url}/@default_view`,
    },
  };
}
