import { Card, CardBody, CardTitle } from 'design-react-kit/dist/design-react-kit';
import { defineMessages, useIntl } from 'react-intl';

import { Icon } from '@arpav/components/ArpavTheme';
import PropTypes from 'prop-types';
import { RichTextArticle } from '@arpav/components/ArpavTheme/View';

const messages = defineMessages({
  luoghi: {
    id: 'luogo',
    defaultMessage: 'Luogo',
  },
});

const EventoLuoghi = ({ content }) => {
  const intl = useIntl();

  return content?.location ? (
    <RichTextArticle
      tag_id="luoghi"
      className="page-side-menu mt-5"
      title={intl.formatMessage(messages.luoghi)}
    >
      <Card className="rounded shadow mt-3 location-item" teaser noWrapper>
        <Icon icon="it-pin" />
        <CardBody>
          <CardTitle tag="h5">{content.location}</CardTitle>
        </CardBody>
      </Card>
    </RichTextArticle>
  ) : null;
};

EventoLuoghi.propTypes = {
  content: PropTypes.object.isRequired,
};

export default EventoLuoghi;
