import DataMapView from '@arpav/components/ArpavTheme/Blocks/DataMap/DataMapView';
import { InlineForm } from '@plone/volto/components';
import MapSchema from '@arpav/components/ArpavTheme/Blocks/DataMap/schema';
import { SidebarPortal } from '@plone/volto/components';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { isEqual } from 'lodash';
import { memo } from 'react';
import withResourcesData from '@arpav/components/ArpavTheme/Blocks/DataMap/withResourcesData';

const tweakSchema = (schema, data_result) => {
  const resourcesField = schema.properties.resources;

  resourcesField.schemaExtender = (schema, reference_data) => {
    const main_fields = (data_result?.[reference_data?.['@id']]?.meta?.fields || [])
      .sort()
      .map(n => [n, n]);
    const data_fields = (data_result?.[`${reference_data?.['@id']}-data`]?.meta?.fields || [])
      .sort()
      .map(n => [n, n]);

    schema.properties.marker_icon_field.choices = [].concat(main_fields, data_fields);
    schema.properties.gis_field.choices = main_fields;
    schema.properties.mapping_attr_geo.choices = main_fields;
    schema.properties.mapping_attr_data.choices = data_fields;
    return schema;
  };
  return schema;
};

const DataMapEdit = memo(
  props => {
    const { selected, onChangeBlock, block, data, data_result } = props;
    const schema = tweakSchema(MapSchema(props), data_result);

    return (
      <div className={`${data['@type']}-edit`}>
        <SidebarPortal selected={selected}>
          <InlineForm
            schema={schema}
            title={schema.title}
            onChangeField={(id, value) => {
              onChangeBlock(block, {
                ...data,
                [id]: value,
              });
            }}
            formData={data}
          />
        </SidebarPortal>
        <DataMapView isEditMode={true} {...props} />
      </div>
    );
  },
  function areEquals(prevProps, nextProps) {
    return !(nextProps.selected !== prevProps.selected || !isEqual(prevProps.data, nextProps.data));
  }
);

export default compose(
  injectIntl,
  withResourcesData(({ data: { resources }, block, path, pathname }) => ({
    block,
    resources,
    path: path ?? pathname,
    isEditMode: true,
  }))
)(DataMapEdit);
