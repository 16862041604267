import { getSiteProperty } from '@arpav/helpers';
import { useIntl } from 'react-intl';

/*
 * Customization with image
 *
 * If you have a jpg/png, do like this:
 *
 * <figure className="icon">
 *  <img src={logo} alt="" />
 * </figure>
 *
 * Note the icon class.
 */

const LogoFooter = () => {
  const intl = useIntl();
  return (
    <figure className="logo">
      <img
        src="/logo_arpav_white.png"
        alt={getSiteProperty('siteTitle', intl.locale)}
        title={getSiteProperty('siteTitle', intl.locale)}
      />
    </figure>
  );
};

export default LogoFooter;
