import { RenderBlocks, RichText } from '@arpav/components/ArpavTheme/View';

import PropTypes from 'prop-types';
import { hasBlocksData } from '@plone/volto/helpers';

/**
 * TextOrBlocks view component class.
 * @function TextOrBlocks
 * @params {object} content: Content object.
 * @returns {string} Markup of the component.
 */
const TextOrBlocks = ({ content, exclude = ['title', 'description'] }) => (
  // Render text or blocks in view, skip title and description blocks by default
  <>
    {hasBlocksData(content) ? (
      <RenderBlocks content={content} exclude={exclude} />
    ) : (
      content.text?.data && <RichText serif={false} content={content.text.data} />
    )}
  </>
);

export default TextOrBlocks;

TextOrBlocks.propTypes = {
  content: PropTypes.any,
};
