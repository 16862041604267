import { Card, CardBody, CardTitle } from 'design-react-kit/dist/design-react-kit';
import { RRule, rrulestr } from 'rrule';
import { defineMessages, useIntl } from 'react-intl';

import PropTypes from 'prop-types';
import moment from 'moment/min/moment-with-locales';
import { rrulei18n } from '@plone/volto/components/manage/Widgets/RecurrenceWidget/Utils';
import { viewDate } from '@arpav/helpers';

const messages = defineMessages({
  start: {
    id: 'start',
    defaultMessage: 'Inizio evento',
  },
  end: {
    id: 'end',
    defaultMessage: 'Fine evento',
  },
  additional_dates: {
    id: 'Date aggiuntive',
    defaultMessage: 'Date aggiuntive',
  },
  excluded_dates: {
    id: "L'evento non si terrà nelle seguenti date",
    defaultMessage: "L'evento non si terrà nelle seguenti date",
  },
});

/**
 * Dates view component class.
 * @function Dates
 * @params {object} Dates: object.
 * @returns {string} Markup of the component.
 */
const Dates = ({ content }) => {
  const intl = useIntl();
  let recurrence = content.recurrence;
  let rruleSet = null;
  let recurrenceText = null;
  let start = new Date(content.start);
  let end = new Date(content.end);

  if (recurrence) {
    const RRULE_LANGUAGE = rrulei18n(intl, moment);
    recurrence = recurrence.replace(/DTSTART:[^\n]+\n/, '');
    const dtstart = RRule.optionsToString({
      dtstart: new Date(content.start),
    });
    recurrence = dtstart + '\n' + recurrence;
    rruleSet = rrulestr(recurrence, {
      unfold: true,
      forceset: true,
    });
    recurrenceText = rruleSet.rrules()[0]?.toText(
      t => {
        return RRULE_LANGUAGE.strings[t];
      },
      RRULE_LANGUAGE,
      RRULE_LANGUAGE.dateFormatter
    );
  }

  if (rruleSet) {
    const startOfDay = new Date();
    startOfDay.setUTCHours(0, 0, 0, 0);
    const currentocc = rruleSet.after(startOfDay);
    if (currentocc) {
      start = currentocc;
      end = new Date(start.getTime() + (end.getTime() - new Date(content.start).getTime()));
    }
  }
  const isSameDay = start.toDateString() === end.toDateString();
  start = viewDate(intl.locale, start);
  end = viewDate(intl.locale, end);

  return content ? (
    <>
      <div className="point-list-wrapper my-4 mb-5">
        <div className="point-list">
          <div className="point-list-aside point-list-warning">
            <div className="point-date text-monospace">{start.format('DD')}</div>
            <div className="point-month text-monospace">{start.format('MMMM')}</div>
          </div>
          <div className="point-list-content">
            <Card className="card card-teaser rounded shadow" noWrapper={true} tag="div">
              <CardBody tag="div" className={'card-body'}>
                <CardTitle tag="h5">
                  {!content.whole_day && `${start.format('HH:mm')} - `}
                  {intl.formatMessage(messages.start)}
                </CardTitle>
                {isSameDay && (
                  <CardTitle tag="h5">
                    {!content.whole_day && `${end.format('HH:mm')} - `}
                    {intl.formatMessage(messages.end)}
                  </CardTitle>
                )}
              </CardBody>
            </Card>
          </div>
        </div>
        {!isSameDay && (
          <div className="point-list">
            <div className="point-list-aside point-list-warning">
              <div className="point-date text-monospace">{end.format('DD')}</div>
              <div className="point-month text-monospace">{end.format('MMMM')}</div>
            </div>
            <div className="point-list-content">
              <Card className="card card-teaser rounded shadow" noWrapper={true} tag="div">
                <CardBody tag="div" className={'card-body'}>
                  <CardTitle tag="h5">
                    {!content.whole_day && `${end.format('HH:mm')} - `}
                    {intl.formatMessage(messages.end)}
                  </CardTitle>
                </CardBody>
              </Card>
            </div>
          </div>
        )}
      </div>
      {recurrenceText && (
        <div className="mt-4 mb-5 text-serif">
          <strong>{recurrenceText}</strong>
        </div>
      )}
      {rruleSet?.rdates().length > 0 && (
        <div className="mt-4">
          <h5>{intl.formatMessage(messages.additional_dates)}</h5>
          {rruleSet.rdates().map((additionalDate, index) => (
            <div key={`rdate-${index}`} className="text-serif">
              {viewDate(intl.locale, additionalDate, 'dddd DD MMMM YYYY')}
            </div>
          ))}
        </div>
      )}
      {rruleSet?.exdates().length > 0 && (
        <div className="mt-4">
          <h5>{intl.formatMessage(messages.excluded_dates)}</h5>
          {rruleSet.exdates().map((exDate, index) => (
            <div key={`exdate-${index}`} className="text-serif">
              {viewDate(intl.locale, exDate, 'dddd DD MMMM YYYY')}
            </div>
          ))}
        </div>
      )}
    </>
  ) : null;
};

export default Dates;

Dates.propTypes = {
  content: PropTypes.object.isRequired,
};
