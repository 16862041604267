import { Modal, ModalBody, ModalHeader } from 'design-react-kit/dist/design-react-kit';

import { useCallback } from 'react';

/**
 * Execute scripts
 *
 * @param {Element} elm
 */
const executeJavascript = elm => {
  Array.from(elm.querySelectorAll('script')).forEach(script => {
    try {
      eval(script.innerHTML);
    } catch (err) {
      console.error(err);
    }
  });
};

const ModalPopup = ({ content, isOpen, onClose, title }) => {
  const contentRef = useCallback(
    ref => {
      if (isOpen && ref && content) {
        executeJavascript(ref);
      }
    },
    [isOpen, content]
  );
  return (
    <Modal
      isOpen={isOpen}
      wrapClassName="public-ui"
      size="xl"
      toggle={onClose}
      backdrop="static"
      centered={true}
      scrollable={true}
    >
      <ModalHeader toggle={onClose}>{title ? title : ''}</ModalHeader>
      <ModalBody>
        <div ref={contentRef} dangerouslySetInnerHTML={{ __html: content }} />
      </ModalBody>
    </Modal>
  );
};

export default ModalPopup;
