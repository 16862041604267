/**
 * Default view reducer.
 * @module reducers/defaultView/defaultView
 */

import { GET_DEFAULT_VIEW } from '@arpav/constants/ActionTypes';
import { flattenToAppURL } from '@plone/volto/helpers';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  schema: null,
  post: {
    loaded: false,
    loading: false,
    error: null,
  },
  update: {
    loaded: false,
    loading: false,
    error: null,
  },
  put: {
    loaded: false,
    loading: false,
    error: null,
  },
};

/**
 * Default view reducer.
 * @function schema
 * @param {Object} defaultView Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function defaultView(state = initialState, action = {}) {
  switch (action.type) {
    /** PENDING */
    case `${GET_DEFAULT_VIEW}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };

    /** SUCCESS */
    case `${GET_DEFAULT_VIEW}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        defaultView: {
          ...action.result,
          items: action?.result?.items?.map(item => ({
            ...item,
            url: flattenToAppURL(item['@id']),
          })),
        },
      };

    /** FAIL */
    case `${GET_DEFAULT_VIEW}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        defaultView: null,
      };
    default:
      return state;
  }
}
