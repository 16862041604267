import {
  Card,
  CardBody,
  CardReadMore,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from 'design-react-kit/dist/design-react-kit';

import { Link } from 'react-router-dom';

const EventsTemplateSkeleton = ({ title }) => {
  return (
    <Container>
      <div className="skeleton-template">
        {title && (
          <Row>
            <Col>
              <h2 className="mb-4">{title}</h2>
            </Col>
          </Row>
        )}
        <Row>
          {[0, 1, 2, 3, 4, 5].map(i => (
            <Col xs={12} lg={4} key={i}>
              {/*start card*/}
              <Card spacing className="card-bg card-img rounded shadow">
                <div className="img-responsive-wrapper">
                  <div className="img-responsive">
                    <figure className="img-wrapper"></figure>
                    <div className="card-calendar d-flex flex-column justify-content-center"></div>
                    <div className="card-news-category"> </div>
                  </div>
                </div>
                <CardBody>
                  <CardTitle tag="h3"> </CardTitle>
                  <CardText> </CardText>
                  <CardReadMore tag={Link} iconName="it-arrow-right" text=" " to="#" />
                </CardBody>
              </Card>
              {/*end card*/}
            </Col>
          ))}
        </Row>
      </div>
    </Container>
  );
};

export default EventsTemplateSkeleton;
