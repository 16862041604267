import { defineMessages } from 'react-intl';

const messages = defineMessages({
  homeMap: {
    id: 'Home map',
    defaultMessage: 'Home map',
  },
  meteoShow: {
    id: 'View meteo layer',
    defaultMessage: 'View meteo layer',
  },
  meteoBtnLabel: {
    id: 'Meteo button label',
    defaultMessage: 'Meteo button label',
  },
  meteoUrl: {
    id: 'Meteo button URL',
    defaultMessage: 'Meteo button URL',
  },
  pm10Show: {
    id: 'View PM10 layer',
    defaultMessage: 'View PM10 layer',
  },
  pm10BtnLabel: {
    id: 'PM10 button label',
    defaultMessage: 'PM10 button label',
  },
  pm10Url: {
    id: 'PM10 button URL',
    defaultMessage: 'PM10 button URL',
  },
  ozonoShow: {
    id: 'View ozono layer',
    defaultMessage: 'View ozono layer',
  },
  ozonoBtnLabel: {
    id: 'Ozono button label',
    defaultMessage: 'Ozono button label',
  },
  ozonoUrl: {
    id: 'Ozono button URL',
    defaultMessage: 'Ozono button URL',
  },
  polliniShow: {
    id: 'View pollini layer',
    defaultMessage: 'View pollini layer',
  },
  polliniBtnLabel: {
    id: 'Pollini button label',
    defaultMessage: 'Pollini button label',
  },
  polliniUrl: {
    id: 'Pollini button URL',
    defaultMessage: 'Pollini button URL',
  },
  nevevalangheShow: {
    id: 'View neve/valanghe layer',
    defaultMessage: 'View neve/valanghe layer',
  },
  nevevalangheBtnLabel: {
    id: 'Neve/valanghe button label',
    defaultMessage: 'Neve/valanghe button label',
  },
  nevevalangheUrl: {
    id: 'Neve/valanghe button URL',
    defaultMessage: 'Neve/valanghe button URL',
  },
  nevevalangheKml: {
    id: 'Neve/valanghe KML',
    defaultMessage: 'Neve/valanghe KML',
  },
  balneazioneShow: {
    id: 'View balneazione layer',
    defaultMessage: 'View balneazione layer',
  },
  balneazioneBtnLabel: {
    id: 'Balneazione button label',
    defaultMessage: 'Balneazione button label',
  },
  balneazioneUrl: {
    id: 'Balneazione button URL',
    defaultMessage: 'Balneazione button URL',
  },
  livelliIdroShow: {
    id: 'View livelliIdro layer',
    defaultMessage: 'View livelliIdro layer',
  },
  livelliIdroBtnLabel: {
    id: 'LivelliIdro button label',
    defaultMessage: 'LivelliIdro button label',
  },
  livelliIdroUrl: {
    id: 'LivelliIdro button URL',
    defaultMessage: 'LivelliIdro button URL',
  },
  radarShow: {
    id: 'View radar layer',
    defaultMessage: 'View radar layer',
  },
  radarBtnLabel: {
    id: 'Radar button label',
    defaultMessage: 'Radar button label',
  },
  radarUrl: {
    id: 'Radar button URL',
    defaultMessage: 'Radar button URL',
  },
  kmlUrl: {
    id: 'KML URL',
    defaultMessage: 'KML URL',
  },
});

const HomeMapSchema = ({ intl }) => ({
  title: intl.formatMessage(messages.homeMap),
  fieldsets: [
    {
      id: 'default',
      title: intl.formatMessage({ id: 'Default' }),
      fields: [
        'meteoShow',
        'meteoBtnLabel',
        'meteoUrl',
        'pm10Show',
        'pm10BtnLabel',
        'pm10Url',
        'ozonoShow',
        'ozonoBtnLabel',
        'ozonoUrl',
        'polliniShow',
        'polliniBtnLabel',
        'polliniUrl',
        'nevevalangheShow',
        'nevevalangheBtnLabel',
        'nevevalangheUrl',
        'nevevalangheKml',
        'balneazioneShow',
        'balneazioneBtnLabel',
        'balneazioneUrl',
        'livelliIdroShow',
        'livelliIdroBtnLabel',
        'livelliIdroUrl',
        'radarShow',
        'radarBtnLabel',
        'radarUrl',
        'kmlUrl',
      ],
    },
  ],
  properties: {
    meteoShow: {
      title: intl.formatMessage(messages.meteoShow),
      type: 'boolean',
    },
    meteoBtnLabel: {
      title: intl.formatMessage(messages.meteoBtnLabel),
    },
    meteoUrl: {
      title: intl.formatMessage(messages.meteoUrl),
      widget: 'object_browser',
      mode: 'link',
      selectedItemAttrs: ['Title', 'Description'],
      allowExternals: true,
    },
    pm10Show: {
      title: intl.formatMessage(messages.pm10Show),
      type: 'boolean',
    },
    pm10BtnLabel: {
      title: intl.formatMessage(messages.pm10BtnLabel),
    },
    pm10Url: {
      title: intl.formatMessage(messages.pm10Url),
      widget: 'object_browser',
      mode: 'link',
      selectedItemAttrs: ['Title', 'Description'],
      allowExternals: true,
    },
    ozonoShow: {
      title: intl.formatMessage(messages.ozonoShow),
      type: 'boolean',
    },
    ozonoBtnLabel: {
      title: intl.formatMessage(messages.ozonoBtnLabel),
    },
    ozonoUrl: {
      title: intl.formatMessage(messages.ozonoUrl),
      widget: 'object_browser',
      mode: 'link',
      selectedItemAttrs: ['Title', 'Description'],
      allowExternals: true,
    },
    polliniShow: {
      title: intl.formatMessage(messages.polliniShow),
      type: 'boolean',
    },
    polliniBtnLabel: {
      title: intl.formatMessage(messages.polliniBtnLabel),
    },
    polliniUrl: {
      title: intl.formatMessage(messages.polliniUrl),
      widget: 'object_browser',
      mode: 'link',
      selectedItemAttrs: ['Title', 'Description'],
      allowExternals: true,
    },
    nevevalangheShow: {
      title: intl.formatMessage(messages.nevevalangheShow),
      type: 'boolean',
    },
    nevevalangheBtnLabel: {
      title: intl.formatMessage(messages.nevevalangheBtnLabel),
    },
    nevevalangheUrl: {
      title: intl.formatMessage(messages.nevevalangheUrl),
      widget: 'object_browser',
      mode: 'link',
      selectedItemAttrs: ['Title', 'Description'],
      allowExternals: true,
    },
    nevevalangheKml: {
      title: intl.formatMessage(messages.nevevalangheKml),
      widget: 'object_browser',
      mode: 'link',
      selectedItemAttrs: ['Title', 'Description'],
      allowExternals: false,
    },
    balneazioneShow: {
      title: intl.formatMessage(messages.balneazioneShow),
      type: 'boolean',
    },
    balneazioneBtnLabel: {
      title: intl.formatMessage(messages.balneazioneBtnLabel),
    },
    balneazioneUrl: {
      title: intl.formatMessage(messages.balneazioneUrl),
      widget: 'object_browser',
      mode: 'link',
      selectedItemAttrs: ['Title', 'Description'],
      allowExternals: true,
    },
    livelliIdroShow: {
      title: intl.formatMessage(messages.livelliIdroShow),
      type: 'boolean',
    },
    livelliIdroBtnLabel: {
      title: intl.formatMessage(messages.livelliIdroBtnLabel),
    },
    livelliIdroUrl: {
      title: intl.formatMessage(messages.livelliIdroUrl),
      widget: 'object_browser',
      mode: 'link',
      selectedItemAttrs: ['Title', 'Description'],
      allowExternals: true,
    },
    radarShow: {
      title: intl.formatMessage(messages.radarShow),
      type: 'boolean',
    },
    radarBtnLabel: {
      title: intl.formatMessage(messages.radarBtnLabel),
    },
    radarUrl: {
      title: intl.formatMessage(messages.radarUrl),
      widget: 'object_browser',
      mode: 'link',
      selectedItemAttrs: ['Title', 'Description'],
      allowExternals: true,
    },
    kmlUrl: {
      title: intl.formatMessage(messages.kmlUrl),
      widget: 'object_browser',
      mode: 'link',
      selectedItemAttrs: ['Title', 'Description'],
      allowExternals: false,
    },
  },
  required: ['title'],
});

export default HomeMapSchema;
