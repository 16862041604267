import { Card, CardBody, CardTitle } from 'design-react-kit/dist/design-react-kit';
import { defineMessages, injectIntl } from 'react-intl';

import { Icon } from '@arpav/components/ArpavTheme';
import PropTypes from 'prop-types';
import { flattenToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  attachment: {
    id: 'attachment',
    defaultMessage: 'Allegato',
  },
});

const Attachment = ({ intl, title, description, download_url }) => (
  <Card className="card card-teaser shadow p-4 mt-3 rounded attachment" noWrapper={true} tag="div">
    <Icon
      icon="it-clip"
      alt={intl.formatMessage(messages.attachment)}
      title={intl.formatMessage(messages.attachment)}
    />
    <CardBody tag="div">
      <CardTitle tag="h5">
        <a href={flattenToAppURL(download_url)}>{title}</a>
      </CardTitle>
      {description && <p>{description}</p>}
    </CardBody>
  </Card>
);

Attachment.propTypes = {
  title: PropTypes.string,
  download_url: PropTypes.string,
};

export default injectIntl(Attachment);
