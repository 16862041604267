/**
 * Return random int in range
 *
 * @param {number} min
 * @param {number} max
 * @returns {number} Random integer
 */
export const getRandomInteger = (min, max) => {
  return Math.floor(Math.random() * (max - min)) + min;
};
