import { getSiteProperty } from '@arpav/helpers';
import { useIntl } from 'react-intl';

/*
 * Customization with image
 *
 * If you have a jpg/png, do like this:
 *
 * <figure className="icon">
 *  <img src={logo} alt="" />
 * </figure>
 *
 * Note the icon class.
 */

const Logo = ({ white }) => {
  const intl = useIntl();

  return (
    <figure className="logo">
      <img
        src={white === true ? '/logo_arpav_white.png' : '/logo_arpav.png'}
        alt={getSiteProperty('siteTitle', intl.locale)}
        title={getSiteProperty('siteTitle', intl.locale)}
      />
    </figure>
  );
};

export default Logo;
