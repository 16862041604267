/**
 * Highlight news reducer.
 * @module reducers/highlightNews/highlightNews
 */

import {
  CREATE_EVENT_BOOKING,
  DELETE_EVENT_BOOKING,
  GET_EVENT_BOOKING,
  RESET_EVENT_BOOKING,
  UPDATE_EVENT_BOOKING,
} from '@arpav/constants/ActionTypes';

const initialState = {
  data: null,
  create: {
    loaded: false,
    loading: false,
    error: null,
  },
  delete: {
    loaded: false,
    loading: false,
    error: null,
  },
  get: {
    loaded: false,
    loading: false,
    error: null,
  },
  update: {
    loaded: false,
    loading: false,
    error: null,
  },
};

/**
 * Get request key
 * @function getRequestKey
 * @param {string} actionType Action type.
 * @returns {string} Request key.
 */
function getRequestKey(actionType) {
  return actionType.split('_')[0].toLowerCase();
}

/**
 * Event booking news reducer.
 * @function schema
 * @param {Object} eventBooking Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function eventBooking(state = initialState, action = {}) {
  switch (action.type) {
    /** PENDING */
    case `${CREATE_EVENT_BOOKING}_PENDING`:
    case `${DELETE_EVENT_BOOKING}_PENDING`:
    case `${GET_EVENT_BOOKING}_PENDING`:
    case `${UPDATE_EVENT_BOOKING}_PENDING`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: true,
          loaded: false,
          error: null,
        },
      };

    /** SUCCESS */
    case `${CREATE_EVENT_BOOKING}_SUCCESS`:
    case `${GET_EVENT_BOOKING}_SUCCESS`:
      return {
        ...state,
        data: action.result,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${DELETE_EVENT_BOOKING}_SUCCESS`:
    case `${UPDATE_EVENT_BOOKING}_SUCCESS`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };

    /** FAIL */
    case `${GET_EVENT_BOOKING}_FAIL`:
      return {
        ...state,
        data: null,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: action.error,
        },
      };
    case `${CREATE_EVENT_BOOKING}_FAIL`:
    case `${DELETE_EVENT_BOOKING}_FAIL`:
    case `${UPDATE_EVENT_BOOKING}_FAIL`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    case RESET_EVENT_BOOKING:
      return initialState;
    default:
      return state;
  }
}
