import { LinkList, NavItem, Navbar } from 'design-react-kit/dist/design-react-kit';

import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { compose } from 'redux';
import { flattenToAppURL } from '@plone/volto/helpers';
import { withContentNavigation } from '@plone/volto/components/theme/Navigation/withContentNavigation';
import { withRouter } from 'react-router';

function renderNode(node, level) {
  return (
    <NavItem key={node['@id']}>
      <RouterLink
        to={flattenToAppURL(node.href)}
        className={`nav-link ${node.is_current && 'active'}`}
      >
        <span>{node.title}</span>
      </RouterLink>
      {(node.items?.length && (
        <LinkList>{node.items.map(node => renderNode(node, level))}</LinkList>
      )) ||
        ''}
    </NavItem>
  );
}

/**
 * A navigation slot implementation, similar to the classic Plone navigation
 * portlet. It uses the same API, so the options are similar to
 * INavigationPortlet
 */
export function ContextNavigationComponent(props) {
  const { params = {} } = props;
  const { navigation = {} } = props;
  const { items = [] } = navigation;

  return items.length ? (
    <div className="sticky-wrapper navbar-wrapper page-side-menu context-navigation">
      <Navbar expand="lg" className="it-navscroll-wrapper navbar-expand-lg">
        <div className="navbar-collapsable expanded" id="navbarNavB" style={{ display: 'block' }}>
          <div className="menu-wrapper">
            <LinkList
              wrapperClassName="menu-link-list"
              header={
                <h3>
                  {params.includeTop ? (
                    <RouterLink to={flattenToAppURL(items[0].href)}>{items[0].title}</RouterLink>
                  ) : (
                    <RouterLink to={flattenToAppURL(navigation.url || '')}>
                      {navigation.title}
                    </RouterLink>
                  )}
                </h3>
              }
            >
              {items.slice(1).map(node => renderNode(node, 0))}
            </LinkList>
          </div>
        </div>
      </Navbar>
    </div>
  ) : (
    ''
  );
}

ContextNavigationComponent.propTypes = {
  /**
   * Navigation tree returned from @contextnavigation restapi endpoint
   */
  navigation: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      })
    ),
    has_custom_name: PropTypes.bool,
    title: PropTypes.string,
  }),
};

export default compose(withRouter, withContentNavigation)(ContextNavigationComponent);
