import { Accordion, Button, Container, Dimmer, Loader, Segment } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { Helmet, asyncConnect, getBaseUrl } from '@plone/volto/helpers';
import { Icon, NotFound, Toolbar } from '@plone/volto/components';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getEventBooking, resetEventBooking } from '@arpav/actions';
import { useEffect, useState } from 'react';

import EventSubscriptionTable from '@arpav/components/ArpavTheme/EventSubscription/EventSubscriptionTable';
import { Portal } from 'react-portal';
import { Unauthorized } from '@arpav/components/ArpavTheme';
import backSVG from '@plone/volto/icons/back.svg';
import { compose } from 'redux';
import downSVG from '@plone/volto/icons/down-key.svg';
import { find } from 'lodash';
import { listActions } from '@plone/volto/actions';
import moment from 'moment';
import qs from 'query-string';
import upSVG from '@plone/volto/icons/up-key.svg';
import { withRouter } from 'react-router-dom';

const messages = defineMessages({
  deleteConfirm: {
    id: 'Do you really want to delete the following reservation?',
    default: 'Do you really want to delete the following reservation?',
  },
  eventBooking: {
    id: 'Event booking',
    defaultMessage: 'Event booking',
  },
  message: {
    id: 'Message',
    defaultMessage: 'Message',
  },
  messageDescription: {
    id: 'Message that will be sent to the user',
    defaultMessage: 'Message that will be sent to the user',
  },
});

const EventSubscriptionManage = ({
  content,
  history,
  intl,
  objectActions,
  pathname,
  returnUrl,
  token,
}) => {
  const loading = useSelector(
    state =>
      state.eventBooking.create?.loading ||
      state.eventBooking.get?.loading ||
      state.eventBooking.update?.loading
  );
  const shifts = useSelector(state => state.eventBooking.data?.shifts);

  const dispatch = useDispatch();

  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };

  /**
   * Cancel handler
   * @method onCancel
   * @returns {undefined}
   */
  const onCancel = () => {
    history.push(returnUrl || getBaseUrl(pathname));
  };

  useEffect(() => {
    if (pathname) {
      dispatch(getEventBooking(getBaseUrl(pathname)));
    }
    return () => dispatch(resetEventBooking());
  }, [dispatch, pathname]);

  const editPermission = find(objectActions, { id: 'edit' });

  return token && editPermission ? (
    content?.['@type'] === 'Event' ? (
      <Container id="page-manage-event-booking" className="manage-event-booking">
        <Dimmer.Dimmable as="div" blurring dimmed={loading}>
          <Dimmer active={loading}>
            <Loader indeterminate size="massive">
              <FormattedMessage id="Loading" defaultMessage="Loading." />
            </Loader>
          </Dimmer>

          <Helmet title={intl.formatMessage(messages.eventBooking)} />
          <Container>
            <article id="content">
              <section id="content-core">
                <Segment.Group raised>
                  <Accordion fluid styled className="form">
                    {!loading &&
                      shifts?.map((shift, index) => (
                        <div key={index}>
                          <Accordion.Title
                            active={activeIndex === index}
                            index={index}
                            onClick={handleClick}
                            key={index}
                          >
                            <FormattedMessage id="Shift" defaultMessage="Shift" />:{' '}
                            {moment(new Date(shift.date)).format('D/MM/YYYY')} {shift.start}-
                            {shift.end}
                            {activeIndex === index ? (
                              <Icon name={upSVG} size="20px" />
                            ) : (
                              <Icon name={downSVG} size="20px" />
                            )}
                          </Accordion.Title>
                          <Accordion.Content active={activeIndex === index}>
                            <EventSubscriptionTable
                              items={shift.participants}
                              history={history}
                              pathname={pathname}
                            />
                          </Accordion.Content>
                        </div>
                      ))}
                  </Accordion>
                </Segment.Group>
              </section>
            </article>
          </Container>
          {__CLIENT__ && (
            <Portal node={document.getElementById('toolbar')}>
              <Toolbar
                pathname={pathname}
                hideDefaultViewButtons
                inner={
                  <>
                    <Button
                      aria-label={intl.formatMessage({
                        id: 'Cancel',
                      })}
                      onClick={onCancel}
                    >
                      <Icon
                        name={backSVG}
                        className="circled"
                        size="30px"
                        title={intl.formatMessage({
                          id: 'Cancel',
                        })}
                      />
                    </Button>
                  </>
                }
              />
            </Portal>
          )}
        </Dimmer.Dimmable>
      </Container>
    ) : (
      <NotFound />
    )
  ) : (
    <Container>
      <Unauthorized />
    </Container>
  );
};

export default compose(
  injectIntl,
  asyncConnect([
    {
      key: 'actions',
      promise: async ({ location, store: { dispatch } }) => {
        await dispatch(listActions(getBaseUrl(location.pathname)));
      },
    },
  ]),
  withRouter,
  connect((state, props) => ({
    content: state.content.data,
    objectActions: state.actions.actions.object,
    pathname: props.location.pathname,
    returnUrl: qs.parse(props.location.search).return_url,
    token: state.userSession.token,
  }))
)(EventSubscriptionManage);
