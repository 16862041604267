import { GET_PROVINCE_RELITEMS } from '@arpav/constants/ActionTypes';

/**
 * Get bookings function.
 * @function getEventBooking
 * @param {string} url Context url.
 * @returns {Object} Get schema action.
 */
export function getProvinceRelatedItems(url) {
  url = typeof url !== 'undefined' ? url : '';

  return {
    type: `${GET_PROVINCE_RELITEMS}`,
    request: {
      op: 'get',
      path: `${url}/@relateditems`,
    },
  };
}
