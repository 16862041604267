import { SimpleCardTemplateAppearance_COMPACT } from '@arpav/config/Blocks/ListingOptions';

import { Container } from 'design-react-kit/dist/design-react-kit';
import PropTypes from 'prop-types';
import SimpleCardTemplateCompact from '@arpav/components/ArpavTheme/Blocks/Listing/SimpleCard/SimpleCardTemplateCompact';
import SimpleCardTemplateDefault from '@arpav/components/ArpavTheme/Blocks/Listing/SimpleCard/SimpleCardTemplateDefault';

const SimpleCardTemplate = data => {
  let content = null;
  switch (data.appearance) {
    case SimpleCardTemplateAppearance_COMPACT:
      content = <SimpleCardTemplateCompact {...data} />;
      break;
    default:
      content = <SimpleCardTemplateDefault {...data} />;
  }

  return <Container>{content}</Container>;
};

SimpleCardTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
  linkTitle: PropTypes.any,
  linkHref: PropTypes.any,
};

export default SimpleCardTemplate;
