/**
 * SideMenu view component class.
 * @function SideMenu
 * @params {object} content: Content object.
 * @returns {string} Markup of the component.
 */
const SideMenu = ({ content, sections }) => {
  return (
    <>
      {sections?.length > 0 &&
        sections.map((section, i) => (
          <section.component content={content} {...section.props} key={'section' + i} />
        ))}
    </>
  );
};
export default SideMenu;
