import { Col, Row } from 'design-react-kit/dist/design-react-kit';

import { getRandomInteger } from '@arpav/helpers';

const Skeleton = () => {
  return (
    <div className="skeleton-template" style={{ height: '350px' }}>
      <Row className="align-items-end">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(item => (
          <Col key={item} xs={1} style={{ height: `${100 / getRandomInteger(1, 6)}%` }}>
            <div className="img-skeleton"></div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Skeleton;
