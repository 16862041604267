import { assign, getEvents, getOptions } from '@eeacms/volto-openlayers-map/helpers';

import React from 'react';
import { isEqual } from 'lodash';
import { openlayers } from '@eeacms/volto-openlayers-map';
import { withMapContext } from '@eeacms/volto-openlayers-map/hocs';

const { layer } = openlayers;

const iconsStyles = {};

class Meteo extends React.Component {
  layer = undefined;

  options = {
    className: undefined,
    declutter: undefined,
    extent: undefined,
    map: undefined,
    maxResolution: undefined,
    maxZoom: undefined,
    minResolution: undefined,
    minZoom: undefined,
    opacity: undefined,
    renderBuffer: undefined,
    renderOrder: undefined,
    source: undefined,
    style: undefined,
    updateWhileAnimating: undefined,
    updateWhileInteracting: undefined,
    visible: undefined,
    zIndex: 1,

    // custom properties
    meteoTempIconsSetter: undefined,
    meteoExtraIconsSetter: undefined,
  };

  events = {
    'change:extent': undefined,
    'change:maxResolution': undefined,
    'change:maxZoom': undefined,
    'change:minResolution': undefined,
    'change:minZoom': undefined,
    'change:opacity': undefined,
    'change:source': undefined,
    'change:visible': undefined,
    'change:zIndex': undefined,
    change: undefined,
    error: undefined,
    postrender: undefined,
    prerender: undefined,
    propertychange: undefined,
  };

  constructor(props) {
    super(props);
    this.options = getOptions(assign(this.options, this.props));
    this.addLayer = this.addLayer.bind(this);
  }
  addLayer() {
    const { mapRendered } = this.props;
    let events = getEvents(this.events, this.props);
    this.layer = new layer.Vector(this.options);
    for (let event in events) {
      this.layer.on(event, events[event]);
    }
    if (!mapRendered) {
      this.props.addLayer(this.layer);
    }
  }

  componentDidMount() {
    this.options.source = new openlayers.source.Vector({
      projection: 'EPSG:27700',
      format: new openlayers.format.GeoJSON(),
    });
    this.options.style = this.styleFunction;

    this.addLayer();
    this.addFeatures();
  }

  componentDidUpdate(prevProps) {
    const prevOptions = getOptions(assign(this.options, prevProps));
    const options = getOptions(assign(this.options, this.props));
    if (!isEqual(prevOptions, options)) {
      Object.keys(options).forEach(o => {
        if (o !== 'source' && o !== 'style' && prevOptions[o] !== o) {
          this.layer.set(o, options[o]);
        }
      });
      this.options = getOptions(assign(this.options, this.props));
      this.layer.changed();
    }
  }

  componentWillUnmount() {
    if (__SERVER__ || !this.layer) return;
    this.layer.dispose();
  }

  /**
   * Add features
   */
  addFeatures() {
    const me = this;
    const { isMorning, dateFilter } = this.getDateInfo();
    const bollettino = 'MV';
    const geojsonObject = {
      type: 'FeatureCollection',
      crs: {
        type: 'name',
        properties: {
          name: 'EPSG:3857',
        },
      },
      features: [],
    };
    try {
      fetch(
        `https://api.arpa.veneto.it/REST/v1/bollettini_meteo_simboli?data=${dateFilter}&bollettino=${bollettino}`
      )
        .then(res => res.json())
        .then(result => {
          const tempIcons = [];
          const extraIcons = [];
          // get features
          result.data?.map(i => {
            if (
              i.simbolo &&
              (!i.intervallo ||
                (isMorning && i.intervallo === 'notte/mattina') ||
                (!isMorning && i.intervallo === 'pom/sera'))
            ) {
              if (i.zona.startsWith('EXTRA_')) {
                extraIcons.push(i);
              } else if (i.zona.startsWith('TEMP_')) {
                tempIcons.push(i);
              } else {
                geojsonObject.features.push({
                  type: 'Feature',
                  geometry: JSON.parse(i.geometry),
                  properties: {
                    id: i.zonaid,
                    data: { ...i },
                  },
                });
                iconsStyles[i.zonaid] = this.createIconStyle(i);
              }
            }
          });
          // read features
          const features = new openlayers.format.GeoJSON({
            featureProjection: 'EPSG:3857',
          }).readFeatures(geojsonObject);
          // add features
          me.layer?.getSource()?.addFeatures(features);
          me.props.meteoTempIconsSetter(tempIcons);
          me.props.meteoExtraIconsSetter(extraIcons);
        });
    } catch (e) {
      // Do nothing
    }
  }

  /**
   * Returns date info
   * @returns {Object}
   */
  getDateInfo() {
    const now = new Date();
    return {
      isMorning: now.getHours() < 12,
      dateFilter:
        now.getFullYear() +
        '-' +
        ('0' + (now.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + now.getDate()).slice(-2),
    };
  }

  /**
   * Get style for feature
   *
   * @param {openlayers.ol.Feature} feature
   * @param {Numeric} resolution
   * @returns {openlayers.style.Style}
   */
  styleFunction(feature, resolution) {
    const style = iconsStyles[feature.values_.id];
    const scale = feature.values_.data.zona.startsWith('TEMP_') ? 4 : 6;
    style.getImage().setScale(1 / Math.pow(resolution, 1 / scale));
    return style;
  }

  /**
   *
   * @param {Object} data
   * @returns {openlayers.style.Style}
   */
  createIconStyle(data) {
    const iconStyle = new openlayers.style.Style({
      image: new openlayers.style.Icon({
        anchor: [0.5, 1],
        scale: 0.2,
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        src: `/risorse/data-bollettini/meteo/icone/meteo/${data.simbolo}`,
      }),
    });
    return iconStyle;
  }

  render() {
    return null;
  }
}

export default withMapContext(Meteo);
