/**
 * EIIndicator view component.
 * @module components/theme/ArpavView/Indicators
 */

import { Container, LinkList } from 'design-react-kit/dist/design-react-kit';
import { createRef, useEffect, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { Icon } from '@arpav/components/ArpavTheme';
import { Pagination } from '@arpav/components/ArpavTheme';
import { UniversalLink } from '@plone/volto/components';
import config from '@plone/volto/registry';
import { getBaseUrl } from '@plone/volto/helpers';
import { getContent } from '@plone/volto/actions';
import qs from 'query-string';
import { useDispatch } from 'react-redux';

/**
 * EIIndicator view component class.
 * @function EIIndicator
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const EIIndicator = ({ content, history, location, token }) => {
  const { pathname } = location;
  const listingRef = createRef();
  const b_size = config.settings.defaultPageSize;
  const b_start = qs.parse(history.location.search).b_start;
  const totalPages = Math.ceil(content.items_total / b_size);
  const [currentPage, setCurrentPage] = useState(b_start ? Math.floor(b_start / b_size) + 1 : 0); // default value to 0 for prevent load content first time
  const [initialPath] = useState(getBaseUrl(pathname));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!token && content?.items?.length > 0) {
      history.replace(content.items[0].url);
    }
  }, []);

  useEffect(() => {
    // prevent load content first time
    if (dispatch && initialPath && currentPage && currentPage > 0) {
      dispatch(getContent(initialPath, null, null, currentPage));
    }
  }, [dispatch, initialPath, currentPage]);
  return token ? (
    <Container id="page-ei-indicator">
      <h1 className="documentFirstHeading">{content.title}</h1>
      {content.description && <p className="documentDescription">{content.description}</p>}
      <p ref={listingRef}>
        <FormattedMessage id="Evaluations" defaultMessage="Evaluations" />:
      </p>
      <LinkList className="left-align">
        {content.items?.map((item, index, items) => (
          <li key={item['@id']}>
            <UniversalLink item={item} title={item['@type']} className="left-icon list-item">
              <Icon className="left" icon="it-chevron-right" aria-hidden />
              {item.title || item['@id']}
            </UniversalLink>
            {index + 1 < items.length ? <span className="list-item divider" /> : null}
          </li>
        ))}
      </LinkList>
      {totalPages > 1 && (
        <div className="pagination-wrapper">
          <Pagination
            activePage={currentPage || 1}
            totalPages={totalPages}
            onPageChange={(e, { activePage: { children } }) => {
              listingRef.current.scrollIntoView({ behavior: 'smooth' });
              setCurrentPage(children);
              history.push({
                search: qs.stringify({
                  b_start: b_size * (children - 1),
                }),
              });
            }}
          />
        </div>
      )}
    </Container>
  ) : null;
};

export default EIIndicator;
