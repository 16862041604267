import { Container, Row } from 'design-react-kit/dist/design-react-kit';
import { defineMessages, useIntl } from 'react-intl';
import { AnnouncementsSummarySkeleton as Skeleton } from '@arpav/components/ArpavTheme';
import { getBaseUrl } from '@plone/volto/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { createRef, useEffect, useState } from 'react';
import { UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

import { getQueryStringResults } from '@plone/volto/actions';

import { Pagination } from '@arpav/components/ArpavTheme';
import config from '@plone/volto/registry';

import { viewDate } from '@arpav/helpers';
import { Table } from 'semantic-ui-react';

const messages = defineMessages({
  Object: {
    id: 'Object',
    defaultMessage: 'Oggetto',
  },
  File: {
    id: 'File',
    defaultMessage: 'File',
  },
  PubblicationDate: {
    id: 'Pubblication date',
    defaultMessage: 'Data pubblicazione',
  },
});

const Body = ({ id, path, data }) => {
  const intl = useIntl();
  const listingRef = createRef();

  const [initialPath] = useState(getBaseUrl(path));

  const announcements_data_loading = useSelector(
    state => state.querystringsearch.subrequests[id]?.loading
  );
  const announcements_data = useSelector(state => state.querystringsearch.subrequests[id]?.items);

  const AnnouncementsCount = useSelector(state => state.querystringsearch.subrequests[id]?.total);

  // pagination variables
  const b_size = config.settings.defaultPageSize;
  let totalPages = Math.ceil(AnnouncementsCount / b_size) || 1;

  let [b_start, setBStart] = useState(0);
  let currentPage = b_start ? Math.floor(b_start / b_size) + 1 : 0;

  const dispatch = useDispatch();

  /**
   * on page change event
   * @param {Event} e Event
   * @param {Number} children
   */
  const onPageChange = (e, { activePage: { children } }) => {
    listingRef.current.scrollIntoView({ behavior: 'smooth' });
    setBStart((children - 1) * b_size);
    currentPage = children;
    b_start = b_size * (children - 1);
  };

  useEffect(() => {
    dispatch(
      getQueryStringResults(
        initialPath,
        {
          metadata_fields: '_all',
          fullobjects: 1,
          b_start: b_start,
          b_size: b_size,
          query: [
            {
              i: 'portal_type',
              o: 'plone.app.querystring.operation.selection.any',
              v: ['Announcement File'],
            },
            {
              i: 'created',
              o: 'plone.app.querystring.operation.date.largerThan',
              v: ['2023/07/01'],
            },
            {
              i: 'announcementfile_type',
              o: 'plone.app.querystring.operation.selection.any',
              v: data.type,
            },
          ],
          sort_on: 'created',
          sort_order: true,
        },
        id
      )
    );
  }, [dispatch, initialPath, b_start]);

  return (
    <Container>
      <div ref={listingRef}>
        <Row>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell> {intl.formatMessage(messages.Object)}</Table.HeaderCell>
                <Table.HeaderCell> {intl.formatMessage(messages.File)}</Table.HeaderCell>
                <Table.HeaderCell>{intl.formatMessage(messages.PubblicationDate)}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body noWrapper teaser>
              {announcements_data_loading ? (
                <Skeleton />
              ) : (
                announcements_data?.length > 0 &&
                announcements_data.map((item, index) => (
                  <>
                    <Table.Row>
                      <Table.Cell>
                        <UniversalLink href={flattenToAppURL(item.parent['@id'])}>
                          {item.parent.title}
                        </UniversalLink>
                      </Table.Cell>
                      <Table.Cell>
                        <UniversalLink href={flattenToAppURL(item.file.download)} download>
                          {item.title}
                        </UniversalLink>
                      </Table.Cell>
                      <Table.Cell>{viewDate(intl.locale, item.created, 'DD-MM-Y')}</Table.Cell>
                    </Table.Row>
                  </>
                ))
              )}
            </Table.Body>
          </Table>
        </Row>
      </div>
      {totalPages > 1 && (
        <div className="pagination-wrapper">
          <Pagination
            activePage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </Container>
  );
};

export default Body;
