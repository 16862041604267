import * as Icons from '@fortawesome/free-solid-svg-icons';
import * as IconsBrands from '@fortawesome/free-brands-svg-icons';
import * as IconsRegular from '@fortawesome/free-regular-svg-icons';

import {
  config as faConfig,
  dom as faDom,
  library as faLibrary,
} from '@fortawesome/fontawesome-svg-core';

import { ArpavApi } from '@arpav/components';
import EventBookingToolbar from '@arpav/components/ArpavTheme/EventSubscription/Toolbar';
import HighlightNewsToolbar from '@arpav/components/ArpavTheme/manage/HighlightNews/Toolbar';
import bandoSVG from '@arpav/icons/bando.svg';
import bookSVG from '@plone/volto/icons/book.svg';
import contentSVG from '@plone/volto/icons/content.svg';
import enterpriseSVG from '@plone/volto/icons/enterprise.svg';
// CTs icons
import faArchiveSVG from '@arpav/icons/archive.svg';
import faBookSVG from '@arpav/icons/book.svg';
import faBoxOpenSVG from '@arpav/icons/box-open.svg';
import faBuildingSVG from '@arpav/icons/building.svg';
import faCalendarAltSVG from '@arpav/icons/calendar-alt.svg';
import faCogSVG from '@arpav/icons/cog.svg';
import faFileAltSVG from '@arpav/icons/file-alt.svg';
import faFileDownloadSVG from '@arpav/icons/file-download.svg';
import faFileInvoiceSVG from '@arpav/icons/file-invoice.svg';
import faFileSVG from '@arpav/icons/file.svg';
import faFolderOpenSVG from '@arpav/icons/folder-open.svg';
import faImageSVG from '@arpav/icons/image.svg';
import faLinkSVG from '@arpav/icons/link.svg';
import faMapMarkedAltSVG from '@arpav/icons/map-marked-alt.svg';
import faNewspaperSVG from '@arpav/icons/newspaper.svg';
import faQuestionSVG from '@arpav/icons/question-solid.svg';
import faSitemapSVG from '@arpav/icons/sitemap.svg';
import faUserSVG from '@arpav/icons/user.svg';
import gdprPrivacyPanelConfig from '@arpav/config/volto-gdpr-privacy-defaultPanelConfig.js';
import getArpavBlocks from '@arpav/config/Blocks/blocks';
import getArpavViews from '@arpav/config/Views/views';
import getArpavWidgets from '@arpav/config/Widgets/widgets';
import { loadables } from '@arpav/config/Loadables/loadables';
import menuAltSVG from '@plone/volto/icons/menu-alt.svg';
import menuSVG from '@plone/volto/icons/menu.svg';
import navSVG from '@plone/volto/icons/nav.svg';
import shareSVG from '@plone/volto/icons/share.svg';
import spreadsheetSVG from '@plone/volto/icons/spreadsheet.svg';
import starSVG from '@plone/volto/icons/star.svg';
import tagSVG from '@plone/volto/icons/tag.svg';

const iconList = Object.keys(Icons.fas).map(icon => Icons[icon]);
const iconListRegular = Object.keys(IconsRegular.far).map(icon => IconsRegular[icon]);

const iconListBrands = Object.keys(IconsBrands.fab).map(icon => IconsBrands[icon]);

//fontawessome config
faConfig.autoAddCss = false;
faLibrary.add(...iconList, ...iconListRegular, ...iconListBrands);

export default function applyConfig(config) {
  // Add here your project's configuration here by modifying `config` accordingly
  // devProxtyToApiPath or use RAZZLE_DEV_PROXY_API_PATH=http://localhost:901/www_arpa_veneto_it yarn start
  config.settings = {
    ...config.settings,
    devProxyToApiPath: 'http://192.168.30.191:8080/www_arpa_veneto_it',
    isMultilingual: false,
    supportedLanguages: ['it'],
    defaultLanguage: 'it',
    verticalFormTabs: true,
    showTags: false,
    showSelfRegistration: false,
    defaultPageSize: 24,
    hasWorkingCopySupport: true,
    arpavThemeViewsConfig: {
      imagePosition: 'afterHeader', // possible values: afterHeader, documentBody
      // Venue: {
      //   sections: [
      //     //sections order for Venue content-type view. See components/ArpavTheme/View/VenueView/VenueView.jsx for default VenueViewSectionsOrder
      //   ],
      // },
    },
    matomoSiteId: 'kN0lmWg3WO',
    matomoUrlBase: 'https://ingestion.webanalytics.italia.it/',
    nonContentRoutes: [
      ...config.settings.nonContentRoutes,
      /\/eventsubscription\/.*$/,
      '/manageeventsubscription',
      '/highlightnews',
    ],
    siteProperties: {
      siteTitle: 'Agenzia Regionale per la Prevenzione e Protezione Ambientale del Veneto', //può essere una stringa, o un oggetto nel caso di multilingua: {'it':'Nome del Comune', 'en':'Site name'}. Se multilingua il default è comunque la stringa.
      siteSubtitle: "Uno dei tanti Comuni d'Italia", //può essere una stringa, o un oggetto nel caso di multilingua: {'it':'Uno dei tanti Comuni d'Italia', 'en':'Uno dei tanti Comuni d'Italia'}. Se multilingua il default è comunque la stringa.
      parentSiteTitle: "Sistema Nazionale per la Protezione dell'Ambiente", //può essere una stringa, o un oggetto nel caso di multilingua: {'it':'Nome della Regione', 'en':'Region name'}.Se multilingua il default è comunque la stringa.
      parentSiteURL: 'https://www.snpambiente.it', //può essere una stringa, o un oggetto nel caso di multilingua: {'it':'https://www.governo.it', 'en':'https://www.governo.it/en'}. Se multilingua il default è comunque la stringa.
      subsiteParentSiteTitle: 'Nome del sito padre del sottosito', //può essere una stringa, o un oggetto nel caso di multilingua: {'it':'Nome del sito padre', 'en':'Parent site name'}. Se multilingua il default è comunque la stringa.
      amministrazioneTrasparenteUrl: '/amministrazione-trasparente',
      //arLoginUrl: 'https://io-comune.agamar.redturtle.it/login',
      // arLogoutUrl: 'https://io-comune.agamar.redturtle.it/logout',
      //spidLogin: true, //se true, nella pagina di errore Unauthorized, mostra il pulsante per il login a Spid.
      headerslimTertiaryMenu: {
        it: [
          //{ title: 'Contatti', url: '/it/contatti' },
          //{ title: 'Novità', url: '/it/novita' },
        ],
        en: [
          //{ title: 'Contacts', url: '/en/contacts' },
          //{ title: 'News', url: '/en/news' },
        ],
      },
      smallFooterLinks: {
        default: [
          // { title: 'Media policy', url: '/media-policy' },
          // { title: 'Note legali', url: '/note-legali' },
          // { title: 'Privacy policy', url: '/privacy-policy' },
          // { title: 'Mappa del sito', url: '/sitemap' },
          // { title: 'Credits', url: 'https://www.redturtle.it/' },
        ],
        it: [
          // { title: 'Media policy', url: '/it/media-policy' },
          // { title: 'Note legali', url: '/it/note-legali' },
          // { title: 'Privacy policy', url: '/it/privacy-policy' },
          // { title: 'Mappa del sito', url: '/sitemap' },
          // { title: 'Credits', url: 'https://www.redturtle.it/' },
        ],
        en: [
          // { title: 'Media policy', url: '/en/media-policy' },
          // { title: 'Legal notes', url: '/en/legal-notes' },
          // { title: 'Privacy policy', url: '/en/privacy-policy' },
          // { title: 'Sitemap', url: '/sitemap' },
          // { title: 'Credits', url: 'https://www.redturtle.it/' },
        ],
      },
      enableCustomerSatisfaction: true,
      splitMegamenuColumns: true, // se impostato a false, non spezza le colonne con intestazioni nel megamenu
    },
    defaultExcludedFromSearch: {
      portalTypes: ['Image', 'File'],
    },
    contentIcons: {
      ...config.settings.contentIcons,
      Document: faFileInvoiceSVG,
      Folder: faFolderOpenSVG,
      'News Item': faNewspaperSVG,
      Event: faCalendarAltSVG,
      Image: faImageSVG,
      File: faFileSVG,
      Link: faLinkSVG,
      'Plone Site': faFolderOpenSVG,
      LRF: faFolderOpenSVG,

      Argomento: faBoxOpenSVG,
      Bando: bandoSVG,
      CartellaModulistica: faArchiveSVG,
      Documento: faFileAltSVG,
      Venue: faMapMarkedAltSVG,
      Persona: faUserSVG,
      Servizio: faCogSVG,
      Subsite: faSitemapSVG,
      UnitaOrganizzativa: faBuildingSVG,
      Modulo: faFileDownloadSVG,
      Faq: faQuestionSVG,
      Announcement: bandoSVG,
      'Announcement File': faFileSVG,
      Organization: faBuildingSVG,
      Publication: faBookSVG,
      // 'EI Area': faFolderOpenSVG,
      // 'EI Evaluation'
      // 'EI Indicator'
      // 'EI Theme': faFolderOpenSVG,
      // 'Environmental Theme': faEnviraSVG,
      // Province
    },
    controlPanelsIcons: {
      ...config.settings.controlPanelsIcons,
      'arpav-announcements-controlpanel': bookSVG,
      'arpav-controlpanel': contentSVG,
      'arpav-metadata-controlpanel': tagSVG,
      'arpav-organizations-controlpanel': enterpriseSVG,
      'bandi-settings': bookSVG,
      'highlightnews-controlpanel': starSVG,
      'dropdown-menu-settings': menuSVG,
      'secondary-menu-settings': menuAltSVG,
      'social-settings': shareSVG,
      'subsites-settings': navSVG,
      'tecnoteca-awstats-controlpanel': spreadsheetSVG,
    },
    imageScales: {
      listing: 16,
      icon: 32,
      tile: 64,
      thumb: 128,
      mini: 200,
      midi: 300,
      preview: 400,
      teaser: 600,
      large: 800,
      larger: 1000,
      great: 1200,
      huge: 1600,
    },
    'volto-gdpr-privacy': {
      ...config.settings['volto-gdpr-privacy'],
      defaultPanelConfig: gdprPrivacyPanelConfig,
    },
    'volto-editablefooter': {
      options: {
        socials: true,
      },
    },
    videoAllowExternalsDefault: true,
    showTrasparenzaFields: false,
    appExtras: [
      ...config.settings.appExtras,
      {
        match: '',
        component: HighlightNewsToolbar,
      },
      {
        match: '',
        component: EventBookingToolbar,
      },
      {
        match: '',
        component: () => <style type="text/css">{faDom.css()}</style>, // load fontawesom dom css
      },
      {
        match: '',
        component: ArpavApi,
      },
    ],
    loadables: {
      ...config.settings.loadables,
      ...loadables,
    },
  };

  if (__SERVER__ !== 'undefined' && __SERVER__) {
    const serverConfig =
      typeof __SERVER__ !== 'undefined' && __SERVER__
        ? // eslint-disable-next-line @typescript-eslint/no-var-requires
          require('@arpav/config/server').default
        : {};
    config.settings.expressMiddleware = [
      ...config.settings.expressMiddleware, // BBB
      ...serverConfig.expressMiddleware,
    ];
  }

  /******************************************************************************
   * VIEWS
   ******************************************************************************/
  config.views = {
    ...config.views,
    ...getArpavViews(config),
  };

  /******************************************************************************
   * WIDGETS
   ******************************************************************************/
  config.widgets = {
    ...config.widgets,
    ...getArpavWidgets(config),
  };

  /******************************************************************************
   * BLOCKS
   ******************************************************************************/
  config.blocks = {
    ...config.blocks,
    ...getArpavBlocks(config),
  };

  return config;
}
