import { defineMessages, useIntl } from 'react-intl';

import { cloneDeep } from 'lodash';

const messages = defineMessages({
  width: {
    id: 'Width',
    defaultMessage: 'Width',
  },
  height: {
    id: 'Height',
    defaultMessage: 'Height',
  },
  widthDescription: {
    id: 'Set image width in px',
    defaultMessage: 'Set image width in px',
  },
  heightDescription: {
    id: 'Set image height in px',
    defaultMessage: 'Set image height in px',
  },
});

const Image = ({ column, value }) => {
  const title = column.title || column.column;
  return (
    <img
      src={value}
      alt={title}
      title={title}
      role="img"
      loading="lazy"
      width={column.width}
      height={column.height}
    />
  );
};

Image.schemaExtender = (schema, data) => {
  if (!data.renderer === 'image') return schema;

  const intl = useIntl();
  schema = cloneDeep(schema);

  schema.properties.width = {
    type: 'integer',
    title: intl.formatMessage(messages.width),
    description: intl.formatMessage(messages.widthDescription),
  };

  schema.properties.height = {
    type: 'integer',
    title: intl.formatMessage(messages.height),
    description: intl.formatMessage(messages.heightDescription),
  };

  schema.fieldsets[0].fields.push('width');
  schema.fieldsets[0].fields.push('height');

  return schema;
};

export default Image;
