import { GET_ENVIRONMENTAL_THEME_RELITEMS } from '@arpav/constants/ActionTypes';

const initialState = {
  data: {},
  loaded: false,
  loading: false,
  error: null,
};

/**
 * Environmentale theme related items reducer.
 * @function schema
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function environmentalThemeRelItems(state = initialState, action = {}) {
  if (action.type.startsWith(GET_ENVIRONMENTAL_THEME_RELITEMS)) {
    const [itemid, action_status] = action.type
      .replace(`${GET_ENVIRONMENTAL_THEME_RELITEMS}_`, '')
      .split('_');
    switch (action_status) {
      /** PENDING */
      case 'PENDING':
        return {
          ...state,
          loading: true,
          loaded: false,
          error: null,
        };

      /** SUCCESS */
      case 'SUCCESS':
        return {
          ...state,
          data: {
            ...state.data,
            [itemid]: action.result,
          },
          loading: false,
          loaded: true,
          error: null,
        };
      /** FAIL */
      case 'FAIL':
        return {
          ...state,
          data: {
            ...state.data,
            [itemid]: null,
          },
          loading: false,
          loaded: true,
          error: action.error,
        };
      case '':
        return initialState;
      default:
        return state;
    }
  }
  return state;
}
