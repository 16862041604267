/**
 * ARPAV API content reducer.
 * @module reducers/arpavApiContent/arpavApiContent
 */

import { GET_HOME_METEO_DATA } from '@arpav/constants/ActionTypes';

const initialState = {
  data: {},
  loaded: false,
  loading: false,
  error: null,
};

/**
 * ARPAV API content reducer.
 * @function schema
 * @param {Object} arpavApiContent Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function homeMeteoData(state = initialState, action = {}) {
  let { result, url, id } = action;

  switch (action.type) {
    case `${GET_HOME_METEO_DATA}_PENDING`:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };
    case `${GET_HOME_METEO_DATA}_SUCCESS`:
      return {
        ...state,
        data: action.result,
        loading: false,
        loaded: true,
        error: null,
      };
    case `${GET_HOME_METEO_DATA}_FAIL`:
      return {
        ...state,
        data: [],
        loading: false,
        loaded: true,
        error: action.error,
      };
    default:
      break;
  }
  return state;
}
