import { Bar, Bubble, Doughnut, Line, Pie, PolarArea, Radar, Scatter } from 'react-chartjs-2';
import { FormattedMessage, injectIntl } from 'react-intl';
import { executePlaceholder, getRandomInteger } from '@arpav/helpers';
import { useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ChartJS from 'chart.js/auto'; // import chartjs with all registered
import Skeleton from '@arpav/components/ArpavTheme/Blocks/DataChart/Skeleton';
import { compose } from 'redux';
import { uniqBy } from 'lodash';
import withFileData from '@arpav/components/ArpavTheme/Blocks/DataChart/withFileData';

const DataChartView = ({ data, data_result, error, loaded, loading }) => {
  const [maxWidth, setMaxWidth] = useState();

  useEffect(() => {
    setMaxWidth(data.width);
  }, [data.width]);

  const options = {
    responsive: true,
    layout: {
      padding: {
        top: 15,
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: !!data.title,
        text: data.title,
      },
      tooltip: {
        callbacks: {
          title: context_list => {
            const context = context_list[0];
            const item = context.raw.item;
            return `${
              item
                ? data.x_placeholder
                  ? executePlaceholder({ ...item }, data.x_placeholder)
                  : item[data.x_field]
                : context.label
            }`;
          },
          label: context => {
            return `${context.dataset.label}: ${context.formattedValue}`;
          },
        },
      },
    },
    parsing: {
      xAxisKey: 'x',
      yAxisKey: 'y',
      key: 'y',
    },
    scales: {
      x: {
        title: {
          display: !!data.x_title,
          text: data.x_title,
        },
      },
      y: {
        title: {
          display: !!data.y_title,
          text: data.y_title,
        },
      },
    },
  };

  const hasLabel = ['doughnut', 'pie', 'polararea', 'radar'].indexOf(data.chart_type) !== -1;

  let filteredData = [];
  if (hasLabel) {
    const filterValues = (data?.dataset || []).map(dataset => dataset.filter_value);
    filteredData = data.filter_field
      ? (data_result?.data || []).filter(
          item => filterValues.indexOf(item[data.filter_field]) !== -1
        )
      : data_result?.data || [];
  }

  const charData = {
    labels: hasLabel
      ? uniqBy(filteredData, data.x_field).map(item =>
          data.x_placeholder
            ? executePlaceholder({ ...item }, data.x_placeholder)
            : item[data.x_field]
        )
      : undefined,
    datasets: (data?.dataset || []).map(dataset => {
      const getDatasetColor = () =>
        dataset.color
          ? `rgba(${dataset.color.r}, ${dataset.color.g}, ${dataset.color.b}, ${dataset.color.a})`
          : `rgba(${getRandomInteger(0, 256)}, ${getRandomInteger(0, 256)}, ${getRandomInteger(
              0,
              256
            )}, 1)`;
      const filterValues = data.filter_field
        ? (data_result?.data || []).filter(item => item[data.filter_field] == dataset.filter_value)
        : data_result?.data || [];

      const color = dataset.color_field
        ? filterValues.map(item =>
            item[dataset.color_field] &&
            /^#([A-Fa-f0-9]{8}|[A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i.test(item[dataset.color_field])
              ? item[dataset.color_field]
              : getDatasetColor()
          )
        : getDatasetColor();

      return {
        label: dataset.title,
        data: filterValues.map(item =>
          data.chart_type === 'radar' || data.chart_type === 'polararea'
            ? item[dataset.y_field]
            : {
                x:
                  data.use_as_value && data.x_placeholder
                    ? executePlaceholder({ ...item }, data.x_placeholder)
                    : item[data.x_field],
                y: item[dataset.y_field],
                item: item,
              }
        ),
        borderColor: data.chart_type === 'line' ? color : undefined,
        backgroundColor: color,
        hoverOffset: 40,
      };
    }),
  };

  let ChartType = Bar;
  switch (data.chart_type) {
    case 'bar':
      ChartType = Bar;
      break;
    case 'bubble':
      ChartType = Bubble;
      break;
    case 'doughnut':
      ChartType = Doughnut;
      break;
    case 'line':
      ChartType = Line;
      break;
    case 'pie':
      ChartType = Pie;
      break;
    case 'polararea':
      ChartType = PolarArea;
      break;
    case 'radar':
      ChartType = Radar;
      break;
    case 'scatter':
      ChartType = Scatter;
      break;
    default:
      ChartType = Bar;
      break;
  }

  return (
    <div className="block dataChart">
      <div className="public-ui">
        {loading ? (
          <Skeleton />
        ) : loaded ? (
          <div
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              maxWidth: maxWidth,
            }}
          >
            <ChartType data={charData} options={options} />
          </div>
        ) : error ? (
          <div>
            <FormattedMessage id="Error on loading data" defaultMessage="Error on loading data" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default compose(
  injectIntl,
  withFileData(({ block, data: { api_url, file_path, source_type } }) => ({
    api_url,
    block,
    file_path,
    isEditMode: false,
    source_type,
  }))
)(DataChartView);
