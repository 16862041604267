import { Col, Container, Row } from 'design-react-kit/dist/design-react-kit';
import { PreviewImage, UniversalLink } from '@plone/volto/components';

import { ListingLinkMore } from '@arpav/components/ArpavTheme';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useIntl } from 'react-intl';

const PartnerTemplate = ({
  items,
  isEditMode,
  title,
  linkTitle,
  linkHref,
  show_block_bg,
  description,
}) => {
  const intl = useIntl();

  return (
    <Container>
      {title && (
        <Row className="mb-4">
          <Col className="text-center">
            <div className="template-header">
              <h2 className={cx('mb-4', { 'mt-5': !show_block_bg })}>{title}</h2>
              {description ? <p>{description}</p> : null}
            </div>
          </Col>
        </Row>
      )}
      <Row className="align-items-center justify-content-center">
        {items?.map((item, index) => (
          // Partner row is divided in 10 cols
          <Col xs={2} lg={1} className="mb-3" key={index}>
            <UniversalLink
              item={!isEditMode ? item : null}
              href={isEditMode ? '#' : null}
              title={item.title || item.id}
            >
              <PreviewImage
                item={item}
                size="large"
                alt={item.image_caption ? item.image_caption : item.title}
                className="ui image"
              />
            </UniversalLink>
          </Col>
        ))}
      </Row>
      <ListingLinkMore title={linkTitle} href={linkHref} className="my-4" />
    </Container>
  );
};

PartnerTemplate.propTypes = {
  description: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
  linkTitle: PropTypes.any,
  linkHref: PropTypes.any,
  show_block_bg: PropTypes.bool,
  title: PropTypes.string,
};

export default PartnerTemplate;
