/**
 * EventoView view component.
 * @module components/theme/View/EventoView
 */

import { Col, Container, Row } from 'design-react-kit/dist/design-react-kit';
import {
  ContentImage,
  ContentTypeViewSections,
  EventoContatti,
  EventoCosE,
  EventoDateOrari,
  EventoIscrizione,
  EventoLuoghi,
  EventoPlaceholderAfterContent,
  EventoPlaceholderAfterRelatedItems,
  EventoUlterioriInformazioni,
  Metadata,
  PageHeader,
  RelatedItemInEvidence,
  RelatedItems,
  SideMenu,
  SkipToMainContent,
} from '@arpav/components/ArpavTheme/View';

import PropTypes from 'prop-types';

export const EventoViewSectionsOrder = [
  {
    /* HEADER IMAGE */
    component: ContentImage,
    props: { position: 'documentBody' },
  },
  { /* COS'è */ component: EventoCosE },
];

/**
 * EventoView view component class.
 * @function EventoView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const EventoView = ({ content, location }) => {
  return (
    <>
      <Container className="my-4 newsitem-view">
        <div className="px-4 px-lg-0">
          <SkipToMainContent />
          <PageHeader
            content={content}
            readingtime={null}
            showreadingtime={true}
            showdates={true}
            showtassonomiaargomenti={true}
          />

          <Row className="border-top row-column-border row-column-menu-left">
            <Col
              tag="section"
              lg={8}
              id="main-content-section"
              className="it-page-sections-container pr-lg-0"
            >
              <div className="mt-3 mt-lg-5 mr-lg-5 ml-lg-3">
                {/* HEADER IMAGE */}
                <ContentImage content={content} position="afterHeader" />
                {/* SEZIONI */}
                <ContentTypeViewSections
                  content={content}
                  location={location}
                  defaultSections={EventoViewSectionsOrder}
                />
              </div>
            </Col>
            <Col tag="aside" lg={4}>
              <SideMenu
                content={content}
                sections={[
                  { component: EventoDateOrari },
                  { component: EventoIscrizione },
                  { component: EventoLuoghi },
                  { component: EventoContatti },
                ]}
              />
            </Col>
          </Row>
          <div className="mb-5 ml-lg-3">
            <EventoUlterioriInformazioni content={content} />
          </div>
        </div>
      </Container>
    </>
  );
};
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
EventoView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    effective: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,

    patrocinato_da: PropTypes.string,
    image: PropTypes.object,
    image_caption: PropTypes.string,
    orari: PropTypes.shape({
      data: PropTypes.string,
    }),
    prezzo: PropTypes.shape({
      data: PropTypes.string,
    }),
    organizzato_da_esterno: PropTypes.shape({
      data: PropTypes.string,
    }),

    descrizione_destinatari: PropTypes.shape({
      data: PropTypes.string,
    }),

    ulteriori_informazioni: PropTypes.shape({
      data: PropTypes.string,
    }),
    sponsor: PropTypes.shape({
      data: PropTypes.string,
    }),
    items: PropTypes.array,
    strutture_politiche: PropTypes.array,
    supportato_da: PropTypes.array,
    organizzato_da_interno: PropTypes.array,
    persone_amministrazione: PropTypes.array,
    modified: PropTypes.string,
    luoghi_evento: PropTypes.array,
    related_news: PropTypes.array,
    tassonomia_argomenti: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        token: PropTypes.string,
      })
    ),
  }).isRequired,
};

export default EventoView;
