import { Col, Icon, LinkList, LinkListItem, Row } from 'design-react-kit/dist/design-react-kit';
import {
  Metadata,
  PageHeader,
  SideMenu,
  SkipToMainContent,
  TextOrBlocks,
} from '@arpav/components/ArpavTheme/View';
import { createRef, useEffect, useState } from 'react';

import { Container } from 'semantic-ui-react';
import ContextNavigation from '@plone/volto/components/theme/Navigation/ContextNavigation';
import { Pagination } from '@arpav/components/ArpavTheme';
import config from '@plone/volto/registry';
import { flattenToAppURL } from '@plone/volto/helpers';
import { getBaseUrl } from '@plone/volto/helpers';
import { getContent } from '@plone/volto/actions';
import qs from 'query-string';
import { useDispatch } from 'react-redux';

const TransparentAdministration = ({ content, location, path, id, history }) => {
  const { pathname } = location;
  const listingRef = createRef();
  const b_size = config.settings.defaultPageSize;
  const b_start = qs.parse(history.location.search).b_start;
  const totalPages = Math.ceil(content.items_total / b_size);
  const [currentPage, setCurrentPage] = useState(b_start ? Math.floor(b_start / b_size) + 1 : 0); // default value to 0 for prevent load content first time
  const [initialPath] = useState(getBaseUrl(pathname));
  const dispatch = useDispatch();

  useEffect(() => {
    // prevent load content first time
    if (dispatch && initialPath && currentPage && currentPage > 0) {
      dispatch(getContent(initialPath, null, null, currentPage));
    }
  }, [dispatch, initialPath, currentPage]);
  return (
    <Container id="page-document">
      <SkipToMainContent />
      <PageHeader content={content} showdates={false} />
      <Row className="border-top row-column-border row-column-menu-left">
        <Col
          tag="section"
          lg={8}
          id="main-content-section"
          className="it-page-sections-container pr-lg-0"
        >
          {content.blocks_layout?.items?.length > 0 ? (
            <div className="mt-3 mt-lg-5 mr-lg-5 ml-lg-3">
              <TextOrBlocks content={content} exclude={['title', 'description', 'toc']} />
            </div>
          ) : null}
          {content.items.length > 0 ? (
            <LinkList multiline>
              <div>
                {content.items.map(item => (
                  <LinkListItem
                    key={item['@id']}
                    className="right-icon"
                    href={flattenToAppURL(item['@id'])}
                  >
                    <span>{item.title}</span>
                    <Icon color="primary" icon="it-chevron-right" aria-hidden />
                    <p>{item.description}</p>
                  </LinkListItem>
                ))}
              </div>
            </LinkList>
          ) : null}
          {totalPages > 1 && (
            <div className="pagination-wrapper">
              <Pagination
                activePage={currentPage || 1}
                totalPages={totalPages}
                onPageChange={(e, { activePage: { children } }) => {
                  listingRef.current.scrollIntoView({ behavior: 'smooth' });
                  setCurrentPage(children);
                  history.push({
                    search: qs.stringify({
                      b_start: b_size * (children - 1),
                    }),
                  });
                }}
              />
            </div>
          )}
        </Col>
        <Col tag="aside" lg={4}>
          {
            <SideMenu
              content={content}
              sections={[
                {
                  component: ContextNavigation,
                  props: {
                    content,
                    params: {
                      includeTop: true,
                      root_path: flattenToAppURL('arpav'),
                      bottomLevel: 2,
                    },
                  },
                },
              ]}
            />
          }
        </Col>
      </Row>
      <div className="mb-5">
        <Metadata content={content} />
      </div>
    </Container>
  );
};
export default TransparentAdministration;
