/**
 * Layouts.
 * @module constants/Workflows
 */

import { last, split } from 'lodash';

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  pending: {
    id: 'Pending',
    defaultMessage: 'Pending',
  },
  private: {
    id: 'Private',
    defaultMessage: 'Private',
  },
  public: {
    id: 'Public',
    defaultMessage: 'Public',
  },
  review: {
    id: 'Review',
    defaultMessage: 'Review',
  },
  reject: {
    id: 'Send back',
    defaultMessage: 'Send back',
  },
  retract: {
    id: 'Retract',
    defaultMessage: 'Retract',
  },
});

export default function getWorkflowMapping(url, current, intl) {
  const mapping = {
    published: {
      value: 'public',
      label: intl.formatMessage(messages.public),
      color: '#007bc1',
      url,
    },
    publish: {
      value: 'public',
      label: intl.formatMessage(messages.public),
      color: '#007bc1',
      url,
    },
    private: {
      value: 'private',
      label: intl.formatMessage(messages.private),
      color: '#ed4033',
      url,
    },
    pending: {
      value: 'pending',
      label: intl.formatMessage(messages.pending),
      color: '#f6a808',
      url,
    },
    send_back: {
      value: 'private',
      label: intl.formatMessage(messages.reject),
      color: '#ed4033',
      url,
    },
    retract: {
      value: 'private',
      label: intl.formatMessage(messages.retract),
      color: '#ed4033',
      url,
    },
    reject: {
      value: 'private',
      label: intl.formatMessage(messages.reject),
      color: '#ed4033',
      url,
    },
    submit: { value: 'review', label: intl.formatMessage(messages.review), color: '#f4e037', url },
  };

  if (url) {
    const key = last(split(url, '/'));
    if (key in mapping) {
      return mapping[key];
    }

    return { value: key, label: key, color: '#000', url };
  }

  if (current in mapping) {
    return mapping[current];
  }

  return { value: current, label: current, color: '#000', url };
}
