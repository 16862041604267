/*
Queto file contiene le estensioni per le funzioni presenti in common.js, in modo da poter definire comportamenti diversi nel proprio progetto.
Sovrascrivere questo file nel progetto figlio ed implementare le proprie estensioni.*/

const getItemIcon_extend = item => {
  let icon = null;
  return icon;
};

export default getItemIcon_extend;
