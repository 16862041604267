import { addDefaultAdditionalOptions, addSchemaField } from '@arpav/config/Blocks/ListingOptions';

import config from '@plone/volto/registry';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  show_block_bg: {
    id: 'Mostra lo sfondo del blocco',
    defaultMessage: 'Mostra lo sfondo del blocco',
  },
  header_bg: {
    id: "Mostra lo sfondo solo nell'header",
    defaultMessage: "Mostra lo sfondo solo nell'header",
  },
  items_color: {
    id: 'listing_items_color',
    defaultMessage: "Colore dell'elemento",
  },
  bg_color: {
    id: 'block_bg_color',
    defaultMessage: 'Colore di sfondo',
  },
  set_full_width: {
    id: 'Imposta larghezza massima del blocco',
    defaultMessage: 'Imposta larghezza massima del blocco',
  },
});

/** DEFAULT **/

const addDefaultOptions = (schema, formData, intl, position = 0) => {
  let listing_items_colors = config.blocks.blocksConfig.listing?.listing_items_colors || [];
  let listing_bg_colors = config.blocks.blocksConfig.listing?.listing_bg_colors || [];

  let pos = position;
  addSchemaField(schema, 'title', intl.formatMessage({ id: 'Title' }), null, null, pos);
  pos++;
  addSchemaField(
    schema,
    'description',
    intl.formatMessage({ id: 'Description' }),
    null,
    {
      widget: 'textarea',
    },
    pos
  );
  pos++;

  if (listing_items_colors.length > 0) {
    addSchemaField(
      schema,
      'items_color',
      intl.formatMessage(messages.items_color),
      null,
      { widget: 'color_list', intl: intl, colors: listing_items_colors },
      pos
    );
    pos++;
  }

  addSchemaField(
    schema,
    'show_block_bg',
    intl.formatMessage(messages.show_block_bg),
    null,
    { type: 'boolean' },
    pos
  );
  pos++;

  addSchemaField(
    schema,
    'header_bg',
    intl.formatMessage(messages.header_bg),
    null,
    { type: 'boolean' },
    pos
  );
  pos++;

  if (listing_bg_colors.length > 0) {
    addSchemaField(
      schema,
      'bg_color',
      intl.formatMessage(messages.bg_color),
      null,
      { widget: 'color_list', intl: intl, colors: listing_bg_colors },
      pos
    );
    pos++;
  }

  addSchemaField(
    schema,
    'set_full_width',
    intl.formatMessage(messages.set_full_width),
    null,
    { type: 'boolean' },
    pos
  );
  pos++;

  pos = addDefaultAdditionalOptions(schema, formData, intl, pos);

  return pos;
};

export default addDefaultOptions;
