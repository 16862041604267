import config from '@plone/volto/registry';

const MeteoExtraControl = ({ icons, visible }) => {
  const { settings } = config;
  const imagesBaseUrl = settings.publicURL + '/risorse/data-bollettini/meteo/icone/meteo/';

  const m = icons.find(icon => icon.zona === 'EXTRA_MONTAGNA');
  const p = icons.find(icon => icon.zona === 'EXTRA_PIANURA');
  return (
    <>
      {visible &&
        [m, p].map(i => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexFlow: 'column wrap',
              height: '50%',
              maxWidth: '120px',
              zIndex: '-1',
            }}
          >
            {i && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexFlow: 'column wrap',
                  height: '100%',
                  width: '100%',
                  backgroundColor: 'rgba(210, 210, 210, 0.5)',
                  fontSize: '14px',
                  zIndex: '-1',
                }}
              >
                <span> {i.zona.replace('EXTRA_', ' ')} </span>
                <img
                  src={imagesBaseUrl + i.simbolo}
                  style={{
                    width: '60px',
                    padding: '10px',
                  }}
                />
                <span
                  style={{
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    fontSize: '12px',
                  }}
                >
                  {i.testo}
                </span>
              </div>
            )}
          </div>
        ))}
    </>
  );
};

export default MeteoExtraControl;
