import { Chip, ChipLabel } from 'design-react-kit/dist/design-react-kit';
import { defineMessages, injectIntl } from 'react-intl';

import PropTypes from 'prop-types';
import moment from 'moment/min/moment-with-locales';
import { Icon } from '@arpav/components/ArpavTheme';

/**
 * PageHeaderOrganization view component class.
 * @function PageHeaderOrganization
 * @params {object} content: Content object.
 * @returns {string} Markup of the component.
 */

const PageHeaderOrganization = ({ content }) =>
  content['@type'] === 'Organization' && content.type ? (
    <p className="mt-5">
      <Icon icon="it-pa" />
      <span className="ml-2 font-weight-bold">{content.type?.title}</span>
    </p>
  ) : null;

PageHeaderOrganization.propTypes = {
  params: PropTypes.shape({
    content: PropTypes.object,
  }),
};

export default injectIntl(PageHeaderOrganization);
