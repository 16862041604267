/**
 * IconTab component.
 * @module components/manage/Widgets/IconWidget/IconTab
 */

import { useEffect, useState } from 'react';

import { Button } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from '@plone/volto/components';
import PropTypes from 'prop-types';
import paginationLeftSVG from '@plone/volto/icons/left-key.svg';
import paginationRightSVG from '@plone/volto/icons/right-key.svg';

const IconTab = ({ icons, onIconChange }) => {
  const iconKeys = Object.keys(icons);
  const page_size = 20;
  const [activeIcons, setActiveIcons] = useState(iconKeys.slice(0, page_size));
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(Object.keys(icons).length / page_size);
  const prevBatch = currentPage > 1;
  const nextBatch = currentPage < totalPages;

  useEffect(() => {
    const start = page_size * (currentPage - 1);
    setActiveIcons(iconKeys.slice(start, start + page_size));
  }, [currentPage]);
  return (
    <>
      <div className="iconscontainer">
        {activeIcons.map(k => (
          <Button
            key={k}
            icon
            size="mini"
            className="iconbutton"
            onClick={e => onIconChange(icons[k], e)}
          >
            <FontAwesomeIcon icon={icons[k]} />
          </Button>
        ))}
      </div>
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <Button
          icon
          size="mini"
          disabled={!prevBatch}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          <Icon name={paginationLeftSVG} size="18px" />
        </Button>
        <Button
          icon
          size="mini"
          disabled={!nextBatch}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          <Icon name={paginationRightSVG} size="18px" />
        </Button>
      </div>
    </>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
IconTab.propTypes = {
  icons: PropTypes.object.isRequired,
  onIconChange: PropTypes.func.isRequired,
};

export default IconTab;
