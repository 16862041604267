/**
 * Tags component.
 * @module components/ArpavTheme/Tags/Tags
 */

import { Chip, ChipLabel, Col, Row } from 'design-react-kit/dist/design-react-kit';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Container } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Tags component class.
 * @function Tags
 * @param {array} tags Array of tags.
 * @returns {string} Markup of the component.
 */
const Tags = ({ tags }) =>
  tags && tags.length > 0 ? (
    <Container className="tags">
      <Row className="row-column-border row-column-menu-left">
        <Col>
          <p className="text-serif mb-0 mt-4">
            <FormattedMessage id="Categorized below" defaultMessage="Categorized below" />
          </p>
          {tags.map(tag => (
            <Link className="ui label" to={`/search?Subject=${tag}`} key={tag}>
              <Chip color="primary" disabled={false} large={false} simple tag="span">
                <ChipLabel tag="span">{tag}</ChipLabel>
              </Chip>
            </Link>
          ))}
        </Col>
      </Row>
    </Container>
  ) : (
    <span />
  );

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Tags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
};

/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
Tags.defaultProps = {
  tags: null,
};

export default injectIntl(Tags);
