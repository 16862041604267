/**
 * EIArea view component.
 * @module components/theme/ArpavView/Indicators
 */

import { BodyClass, getBaseUrl } from '@plone/volto/helpers';
import { Breadcrumbs, Pagination } from '@arpav/components/ArpavTheme';
import { Metadata, RichText, richTextHasContent } from '@arpav/components/ArpavTheme/View';
import { createRef, useEffect, useState } from 'react';

import { Container } from 'design-react-kit/dist/design-react-kit';
import Legend from '@arpav/components/ArpavTheme/View/Indicators/Legend';
import { Portal } from 'react-portal';
import ThemeListBody from '@arpav/components/ArpavTheme/View/Indicators/ThemeListBody';
import config from '@plone/volto/registry';
import cx from 'classnames';
import { getContent } from '@plone/volto/actions';
import { injectIntl } from 'react-intl';
import qs from 'query-string';
import { useDispatch } from 'react-redux';

/**
 * EIArea view component class.
 * @function EIArea
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const EIArea = ({ content, history, location }) => {
  const { pathname } = location;
  const listingRef = createRef();
  const b_size = config.settings.defaultPageSize;
  const b_start = qs.parse(history.location.search).b_start;
  const totalPages = Math.ceil(content.items_total / b_size);
  const [currentPage, setCurrentPage] = useState(b_start ? Math.floor(b_start / b_size) + 1 : 0); // default value to 0 for prevent load content first time
  const [initialPath] = useState(getBaseUrl(pathname));
  const dispatch = useDispatch();

  useEffect(() => {
    // prevent load content first time
    if (dispatch && initialPath && currentPage && currentPage > 0) {
      dispatch(getContent(initialPath, null, null, currentPage));
    }
  }, [dispatch, initialPath, currentPage]);

  const rightHeaderHasContent = richTextHasContent(content.text);

  return (
    <div id="page-ei-area">
      <Container>
        <div className="ArgomentoTitleWrapper rounded shadow mt-5 mb-5">
          <div
            className={cx('title-description-wrapper', {
              'col-lg-6': rightHeaderHasContent,
              'col-lg-12': !rightHeaderHasContent,
            })}
          >
            <Breadcrumbs pathname={location.pathname} />

            <h1 className="mb-3">{content?.title}</h1>
            <p className="description">{content?.description}</p>
          </div>
          {rightHeaderHasContent && (
            <div className="col-lg-4 offset-lg-2">
              <RichText serif={false} content={content.text} />
            </div>
          )}
          <>
            <Portal node={__CLIENT__ && document.getElementById('portal-header-image')}>
              <div className="section section-background-header p-0 has-texture"></div>
            </Portal>
            <BodyClass className="has-image" />
          </>
        </div>

        {content.items.map((item, index) => (
          <ThemeListBody
            key={item['@id']}
            id={item['@id']}
            pathname={item.url}
            even={(index + 1) % 2 === 0}
          />
        ))}
        <Legend />
        {totalPages > 1 && (
          <div className="pagination-wrapper">
            <Pagination
              activePage={currentPage || 1}
              totalPages={totalPages}
              onPageChange={(e, { activePage: { children } }) => {
                listingRef.current.scrollIntoView({ behavior: 'smooth' });
                setCurrentPage(children);
                history.push({
                  search: qs.stringify({
                    b_start: b_size * (children - 1),
                  }),
                });
              }}
            />
          </div>
        )}
        <div className="mb-5">
          <Metadata content={content} />
        </div>
      </Container>
    </div>
  );
};

export default injectIntl(EIArea);
