import { Col, Container, Row } from 'design-react-kit/dist/design-react-kit';
import {
  Metadata,
  PageHeader,
  ProvinceRelItems,
  SideMenu,
  SkipToMainContent,
  TextOrBlocks,
} from '@arpav/components/ArpavTheme/View';
import { expandToBackendURL, flattenToAppURL, getParentUrl } from '@plone/volto/helpers';
import { useDispatch, useSelector } from 'react-redux';

import ContextNavigation from '@plone/volto/components/theme/Navigation/ContextNavigation';
import { getProvinceRelatedItems } from '@arpav/actions';
import { useEffect } from 'react';

const Province = ({ content, token, history, location }) => {
  const loading = useSelector(state => state.provinceRelItems.loading);
  const documentstypes = useSelector(state => state.provinceRelItems.data?.types);

  const dispatch = useDispatch();

  // related items requests
  useEffect(() => {
    dispatch(getProvinceRelatedItems(expandToBackendURL(content['@id'])));
  }, [dispatch]);

  return (
    <Container id="page-province">
      <SkipToMainContent />
      <PageHeader content={content} showdates={false} />

      <Row className="border-top row-column-border row-column-menu-left">
        <Col
          tag="section"
          lg={8}
          id="main-content-section"
          className="it-page-sections-container pr-lg-0"
        >
          <div className="mt-3 mt-lg-5 mr-lg-5 ml-lg-3">
            <TextOrBlocks content={content} exclude={['title', 'description', 'toc']} />
          </div>
        </Col>
        <Col tag="aside" lg={4}>
          {
            <SideMenu
              content={content}
              sections={[
                {
                  component: ContextNavigation,
                  props: {
                    content,
                    params: {
                      includeTop: true,
                      root_path: getParentUrl(flattenToAppURL(content['@id'])),
                    },
                  },
                },
              ]}
            />
          }
        </Col>
      </Row>

      {!loading
        ? documentstypes?.map((item, index) => (
            <ProvinceRelItems
              key={item['@id']}
              label={item.label}
              relitems={item.items}
              totalcount={item.items_count}
              itemindex={index}
            />
          ))
        : [0, 1].map((item, index) => (
            <div className="skeleton-template" key={index}>
              <ProvinceRelItems skeleton={true} itemindex={index} />
            </div>
          ))}

      <div className="mb-5  ml-lg-3">
        <Metadata content={content} />
      </div>
    </Container>
  );
};

export default Province;
