/**
 * ColorWidget component.
 * @module components/manage/Widgets/ColorWidget
 */

import { FormFieldWrapper, Icon } from '@plone/volto/components';

import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import clearSVG from '@plone/volto/icons/clear.svg';
import { useState } from 'react';

const hexToRgb = hex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

const ColorWidget = props => {
  const { id, value, required, isDisabled, onChange } = props;

  const onColorChange = color => {
    onChange(id, color.rgb);
  };

  const onResetColor = () => {
    onChange(id, null);
  };

  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [clientY, setClientY] = useState(0);

  const hexValue = value ? (typeof value === 'string' ? { ...hexToRgb(value), a: 1 } : value) : {};

  const styles = {
    color: {
      width: '36px',
      height: '14px',
      borderRadius: '2px',
      background: `rgba(${hexValue.r}, ${hexValue.g}, ${hexValue.b}, ${hexValue.a})`,
    },
    swatch: {
      padding: '5px',
      background: '#fff',
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      cursor: !isDisabled ? 'pointer' : 'default',
      display: 'inline-block',
      marginTop: '18px',
      marginRight: '10px',
      width: '46px',
    },
    popover: {
      position: 'absolute',
      zIndex: '2',
      bottom: clientY > 310 ? '50px' : undefined,
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
  };

  const handleClick = e => {
    setClientY(e.clientY);
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  return (
    <FormFieldWrapper {...props}>
      <div className="color-widget-wrapper">
        <div style={styles.swatch} onClick={handleClick}>
          <div style={styles.color} />
        </div>
        {!isDisabled && (
          <>
            {!required && hexValue && Object.keys(hexValue).length ? (
              <button onClick={() => onResetColor()} className="item ui noborder button">
                <Icon name={clearSVG} size="24px" className="close" />
              </button>
            ) : null}
            {displayColorPicker ? (
              <div style={styles.popover}>
                <div style={styles.cover} onClick={handleClose} />
                <SketchPicker color={hexValue} onChangeComplete={onColorChange} />
              </div>
            ) : null}
          </>
        )}
      </div>
    </FormFieldWrapper>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ColorWidget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  required: PropTypes.bool,
  isDisabled: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
};

/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
ColorWidget.defaultProps = {
  description: null,
  required: false,
  isDisabled: false,
  error: [],
  value: null,
};

export default ColorWidget;
