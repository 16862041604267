import { GET_ANNOUNCEMENTS } from '@arpav/constants/ActionTypes';

/**
 * Get bookings function.
 * @function getEventBooking
 * @param {string} url Content url.
 * @param {string} uid Reservation uid.
 * @returns {Object} Get schema action.
 */
export function getAnnouncements(url, type, data = {}) {
  url = typeof url !== 'undefined' ? url : '';

  if (type) {
    data.ann_type = type;
  }

  const query = Object.keys(data)
    .map(function (key) {
      return key + '=' + data[key];
    })
    .join('&');

  return {
    type: GET_ANNOUNCEMENTS,
    request: {
      op: 'get',
      path: `${url}/@announcementslist${query ? `?${query}` : ''}`,
    },
  };
}
