import {
  CardBody,
  CardText,
  CardTitle,
  Card as ICard,
  LinkList,
  LinkListItem,
} from 'design-react-kit/dist/design-react-kit';
import React, { useMemo } from 'react';
import { getImage, getText } from '@eeacms/volto-cards-block/helpers';

import { Icon } from '@arpav/components/ArpavTheme';
import { Image } from 'semantic-ui-react';
import cx from 'classnames';
import { flattenToAppURL } from '@plone/volto/helpers';

const Card = ({ data, id, item }) => {
  const image = useMemo(() => getImage(item.image), [item.image]);
  const title = useMemo(() => getText(item.title), [item.title]);
  const description = useMemo(() => getText(item.description), [item.description]);

  return (
    <ICard
      fluid={data.fluid ?? true}
      className={cx(
        'card-img no-after',
        {
          rounded: data.rounded,
          'card-bg': (item.theme || data.theme) === 'primary',
        },
        item.theme || data.theme
      )}
    >
      {image && (
        <div className="img-responsive-wrapper">
          <div className="img-responsive">
            <figure className="img-wrapper">
              <Image wrapped ui={false} src={image} alt={item.title} />
            </figure>
          </div>
        </div>
      )}
      <CardBody className="px-1">
        {title && <CardTitle tag="h3">{title}</CardTitle>}
        {item.info?.map((info, index) => {
          const text = getText(info.text);
          return text ? <span key={`info-${id}-${index}`}>{text}</span> : '';
        })}
        {description && <CardText>{description}</CardText>}
        {item.links && (
          <LinkList multiline className="mb-0">
            {item.links?.map((link, index) => {
              const title = getText(link.title) || `Link ${index + 1}`;
              return (
                link.url && (
                  <LinkListItem href={flattenToAppURL(link.url)} className="card-icons-list">
                    <strong>{title}</strong>
                    <small>
                      <Icon color="primary" icon="it-arrow-right" aria-hidden />
                    </small>
                  </LinkListItem>
                )
              );
            })}
          </LinkList>
        )}
      </CardBody>
    </ICard>
  );
};

export default Card;
