import { FormGroup, Input, Label } from 'design-react-kit/dist/design-react-kit';
import { Layer, Layers } from '@eeacms/volto-openlayers-map/Layers';
import { executePlaceholder, isCMSInternalURL } from '@arpav/helpers';
import { useEffect, useRef, useState } from 'react';

import Control from '@arpav/components/ArpavTheme/Blocks/DataMap/Control';
import { Controls } from '@eeacms/volto-openlayers-map/Controls';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Interactions } from '@eeacms/volto-openlayers-map/Interactions';
import LayerControls from './LayerControls';
import { MAP_DEFAULT_EXTENT } from 'constants/Theme';
import { Map } from '@eeacms/volto-openlayers-map/Map';
import ModalPopup from '@arpav/components/ArpavTheme/Blocks/DataMap/ModalPopup';
import { Overlays } from '@eeacms/volto-openlayers-map/Overlays';
import ResourceLayer from '@arpav/components/ArpavTheme/Blocks/DataMap/ResourceLayer';
import { compose } from 'redux';
import cx from 'classnames';
import { injectIntl } from 'react-intl';
import { openlayers } from '@eeacms/volto-openlayers-map';
import withResourcesData from '@arpav/components/ArpavTheme/Blocks/DataMap/withResourcesData';

/**
 * Get Active Layer info
 *
 * @param {Boolean[]} visibles
 * @param {*} layers
 * @returns {Obejct}
 */
const getActiveLayerInfo = (visibles, layers) => {
  return layers.find((element, index) => {
    return visibles[index];
  });
};

const DataMapViewComponent = ({
  data,
  data_result,
  isEditMode,
  block,
  onChangeBlock,
  selected,
  path,
  updateRequestParameter,
}) => {
  const { extent, format, proj, source } = openlayers;

  const mapRef = useRef();
  const overlayRef = useRef();
  const tooltipRef = useRef();
  const tooltipTextRef = useRef();

  const [popupTitle, setPopupTitle] = useState();
  const [popupContent, setPopupContent] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [activeLayer, setActiveLayer] = useState();

  // used for render layers controls
  const [resourceLayers, setResourceLayers] = useState(() => {
    const result = {};
    (data?.resources_kml || [0]).forEach(resource => (result[resource['@id']] = {}));
    (data?.resources || []).forEach(resource => (result[resource['@id']] = {}));
    return result;
  });
  // used for show or hide layer
  const [layersVisibility, setLayersVisibility] = useState(
    data.resources_as_layers
      ? [...(data?.resources_kml || []), ...(data?.resources || [])].map(() => true)
      : [...(data?.resources_kml || []), ...(data?.resources || [])].map((r, i) => i === 0)
  );
  const all_layers = [...(data?.resources_kml || []), ...(data?.resources || [])];

  let [layersTitles, setLayersTitles] = useState({});

  useEffect(() => {
    if (mapRef.current?.map) {
      const mapView = mapRef.current.map.getView();
      if (mapView && data && (data.center_long || data.center_lat || data.zoom)) {
        const mapZoom = mapView.getZoom();
        const mapCenter = mapView.getCenter();
        const center = proj.transform(
          data.center_long && data.center_lat ? [data.center_long, data.center_lat] : [0, 0],
          'EPSG:4326',
          'EPSG:3857'
        );
        let zommAnimation = {};
        let centerAnimation = {};
        if (data.zoom != mapZoom) {
          zommAnimation.zoom = data.zoom;
        }
        if (center[0] != mapCenter[0] || center[1] != mapCenter[1]) {
          centerAnimation.center = center;
        }
        mapView.animate(centerAnimation, zommAnimation);
      }
    }
  }, [data?.zoom, data?.center_long, data?.center_lat, path]);

  useEffect(() => {
    setResourceLayers(() => {
      const result = {};
      (data?.resources_kml || []).forEach(resource => {
        // get layer config
        const res = resourceLayers[resource['@id']] || {};
        // evaluate title
        if (res?.title) {
          res.title = executePlaceholder({}, res.title);
        }
        // hinerits always visible property
        res.alwaysVisible = resource.alwaysVisible;
        // set resource
        result[resource['@id']] = res;
        // update layer titles
        setLayersTitles({
          ...layersTitles,
          [resource['@id']]: res.titles,
        });
      });
      (data?.resources || []).forEach(resource => {
        // get layer config
        const res = resourceLayers[resource['@id']] || {};
        // evaluate title
        if (res?.title) {
          res.title = executePlaceholder({}, res.title);
        }
        // set resource
        result[resource['@id']] = res;
        // update layer titles
        setLayersTitles({
          ...layersTitles,
          [resource['@id']]: res.titles,
        });
      });
      return result;
    });
    let layervisibility = true;
    const vislay = data.resources_as_layers
      ? [...(data?.resources_kml || []), ...(data?.resources || [])].map(() => true)
      : [...(data?.resources_kml || []), ...(data?.resources || [])].map((r, i) => {
          const v = layervisibility || !!r.alwaysVisible;
          layervisibility =
            !r.alwaysVisible && layervisibility ? !layervisibility : layervisibility;
          return v;
        });
    setLayersVisibility(vislay);
  }, [data?.resources, data?.resources_kml, data?.resources_as_layers, path]);

  useEffect(() => {
    (data?.resources_kml || []).forEach(resource => {
      const res = resourceLayers[resource['@id']] || {};
      if (resource.titleJs && !isEditMode) {
        var x = new XMLHttpRequest();
        x.open('GET', resource.url, true);
        x.onreadystatechange = function () {
          if (x.readyState == 4 && x.status == 200) {
            const doc = x.responseXML;
            const jsfn = `executeTitleJs  = function(doc) {${resource.titleJs}}`;
            let executeTitleJs;

            try {
              eval(jsfn);
            } catch (e) {
              console.error('Error evaluating js');
            }

            if (executeTitleJs) {
              res.title = executeTitleJs(doc);
              // update layer titles
              setLayersTitles({
                ...layersTitles,
                [resource['@id']]: res.titles,
              });
            }
          }
        };
        x.send(null);
      }
    });
  }, [data?.resources_kml]);

  useEffect(() => {
    if ((!isEditMode || selected) && mapRef.current) {
      mapRef.current.map.on('click', onClick);
    }
  }, [isEditMode, selected]);

  useEffect(() => {
    setActiveLayer(getActiveLayerInfo(layersVisibility, all_layers));
  }, [layersVisibility]);

  const onClick = event => {
    if (!isEditMode) {
      let features = [];

      // get popover for kml maps
      const visibleLayer = all_layers[layersVisibility.indexOf(true)];
      const popover = visibleLayer?.popover;

      event.map.forEachFeatureAtPixel(
        event.pixel,
        feature => {
          const originalFeatures = feature.get('features');
          const size = originalFeatures ? originalFeatures.length : 1;
          const featureProperties = (
            originalFeatures ? originalFeatures[0] : feature
          ).getProperties();
          if (size > 1) {
            const featuresExtent = new extent.createEmpty();
            originalFeatures.forEach(f => {
              extent.extend(featuresExtent, f.getGeometry().getExtent());
            });
            // add 2000 for show marker
            event.map.getView().fit(extent.buffer(featuresExtent, 2000), event.map.getSize());
          } else if (featureProperties.isPopup) {
            event.map.getView().animate({ center: event.coordinate });
            features.push(feature);
            // before set title and content, and after show modal
            // to prevent the javascript execution of the previous modal
            setPopupTitle(featureProperties.popup_title);
            setPopupContent(featureProperties.popup_text);
            setModalIsOpen(true);
          } else if (popover) {
            setPopupContent(executePlaceholder(featureProperties, popover));
            setModalIsOpen(true);
            features.push(featureProperties);
          }
        },
        {
          hitTolerance: 4,
        }
      );
      if (!features || features.length === 0) {
        setModalIsOpen(false);
      }
    } else {
      const coordinate = proj.transform(event.coordinate, 'EPSG:3857', 'EPSG:4326');
      onChangeBlock(block, {
        ...data,
        center_long: coordinate[0],
        center_lat: coordinate[1],
        zoom: event.map.getView().getZoom(),
      });
    }
  };

  const onPointerMove = event => {
    var hit = event.map.forEachFeatureAtPixel(
      event.pixel,
      feature => {
        const originalFeatures = feature.get('features');
        const featureProperties = (
          originalFeatures ? originalFeatures[0] : feature
        ).getProperties();
        const size = originalFeatures ? originalFeatures.length : 1;
        if (!isEditMode) {
          if (size === 1) {
            const tooltip = featureProperties.tooltip;
            if (tooltip) {
              overlayRef.current.overlay.setPosition(event.coordinate);
              tooltipTextRef.current.innerHTML = tooltip;
              tooltipRef.current.hidden = false;
            }
          }
        }
        return featureProperties.isPopup || size > 1;
      },
      {
        hitTolerance: 4,
      }
    );
    if (hit) {
      event.map.getTargetElement().style.cursor = 'pointer';
    } else if (isEditMode) {
      event.map.getTargetElement().style.cursor = 'crosshair';
    } else {
      event.map.getTargetElement().style.cursor = '';
      tooltipRef.current.hidden = true;
    }
  };

  const containerStyle = {
    height: (data?.map_height ? data.map_height : '500') + 'px',
  };

  return (
    <div className="block dataMap" style={containerStyle}>
      <Map
        view={{
          center:
            data?.center_long && data?.center_lat
              ? proj.transform([data.center_long, data.center_lat], 'EPSG:4326', 'EPSG:3857')
              : proj.transform([0, 0], 'EPSG:4326', 'EPSG:3857'),
          zoom: data?.zoom || 2,
          maxZoom: 20,
          extent: MAP_DEFAULT_EXTENT,
        }}
        ref={mapRef}
        onPointermove={onPointerMove}
      >
        <Layers>
          <Layer.Tile
            source={
              new source.OSM({
                // url:
                //   'https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}',
                url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
              })
            }
          />
          {(data?.resources_kml || []).map((resource, i) => {
            return (
              <ResourceLayer
                key={resource['@id']}
                id={resource['@id']}
                resourceLayers={resourceLayers}
                setResourceLayers={setResourceLayers}
                source={
                  new source.Vector({
                    url: isCMSInternalURL(resource.url)
                      ? `${resource.url}/@@download/file`
                      : resource.url,
                    format: new format.KML(),
                  })
                }
                popover={resource.popover}
                title={resource.title}
                visible={isEditMode ? true : resource.alwaysVisible ? true : layersVisibility[i]}
              />
            );
          })}
          {(data?.resources || []).map((resource, i) => {
            const id = resource['@id'];
            const index = (data?.resources_kml || []).length + i;
            return (
              <ResourceLayer
                key={id}
                id={id}
                resourceLayers={resourceLayers}
                setResourceLayers={setResourceLayers}
                resource={{ ...resource }}
                data_resource={{ ...data_result?.[id] }}
                merge_data_resource={{ ...data_result?.[`${id}-data`] }}
                title={resource.title}
                visible={
                  isEditMode ? true : resource.alwaysVisible ? true : layersVisibility[index]
                }
              />
            );
          })}
        </Layers>
        <Controls attribution={false}>
          {data.resources_as_layers ? (
            <Control className="ol-control" top="0.5rem" right="0.5rem" left={null}>
              {Object.keys(resourceLayers).map((k, i) => {
                const layer = resourceLayers[k];
                if (!layer.alwaysVisible) {
                  const color = layer.color
                    ? `rgb(${layer.color.r},${layer.color.g},${layer.color.b})`
                    : null;
                  return (
                    <FormGroup check tag="div" key={`layer-${i}`}>
                      <Input
                        id={`layer-${i}`}
                        type="checkbox"
                        disabled={isEditMode}
                        checked={layersVisibility[i]}
                        onChange={() => {
                          layersVisibility[i] = !layersVisibility[i];
                          setLayersVisibility([...layersVisibility]);
                          layer.layer?.setVisible(!layer.layer.getVisible());
                          layer.layer?.changed();
                        }}
                        aria-controls="search-results-region"
                      />
                      <Label
                        check
                        className="font-weight-normal"
                        for={`layer-${i}`}
                        tag="label"
                        widths={['xs', 'sm', 'md', 'lg', 'xl']}
                        style={
                          color
                            ? {
                                color: color,
                              }
                            : {}
                        }
                      >
                        {layersTitles[layer['@id']]}
                      </Label>
                    </FormGroup>
                  );
                }
              })}
            </Control>
          ) : (
            <Control
              className="layer-button-control bg-transparent text-center"
              top="0.5rem"
              left="0"
              right="0"
            >
              {Object.keys(resourceLayers).map((k, i) => {
                const layer = resourceLayers[k];
                if (!layer.alwaysVisible) {
                  const isWhite =
                    layer.color?.r == 255 && layer.color?.g == 255 && layer.color?.b == 255;
                  const color = layer.color
                    ? `rgb(${layer.color.r},${layer.color.g},${layer.color.b})`
                    : null;
                  return (
                    <button
                      key={`layer-${i}`}
                      className={cx('chip chip-simple chip-lg mr-2 mb-2', {
                        selected: layersVisibility[i],
                      })}
                      type="button"
                      disabled={isEditMode}
                      onClick={() => {
                        setLayersVisibility(layersVisibility.map((l, y) => y === i));
                      }}
                      style={
                        color
                          ? {
                              ...(layersVisibility[i] &&
                                !isWhite && {
                                  background: color,
                                }),
                              borderColor: color,
                            }
                          : {}
                      }
                    >
                      {layer.icon ? (
                        <FontAwesomeIcon
                          icon={layer.icon}
                          className="mr-2"
                          style={
                            color
                              ? {
                                  ...(!layersVisibility[i] &&
                                    !isWhite && {
                                      color: color,
                                    }),
                                }
                              : {}
                          }
                        />
                      ) : null}
                      <span
                        className="chip-label"
                        style={
                          color
                            ? {
                                ...(!layersVisibility[i] &&
                                  !isWhite && {
                                    color: `rgb(${color.r},${color.g},${color.b})`,
                                  }),
                              }
                            : {}
                        }
                      >
                        {layer.title}
                      </span>
                    </button>
                  );
                }
              })}
            </Control>
          )}
          <LayerControls
            className="layer-button-secondary-control bg-transparent text-center"
            bottom="0.5rem"
            left="0"
            right="0"
            isEditMode={isEditMode}
            controlsFn={activeLayer?.url_parameter_values}
            defaultValue={activeLayer?.url_parameter_default}
            activeLayerId={activeLayer?.['@id']}
            updateRequestParameter={updateRequestParameter}
            layerData={{
              ...data_result?.[activeLayer?.['@id'] + (activeLayer?.data_url ? '-data' : '')],
            }}
          ></LayerControls>
        </Controls>
        <Overlays ref={overlayRef} stopEvent={false} positioning="bottom-center">
          <div
            className="tooltip fade show bs-tooltip-top"
            role="tooltip"
            x-placement="top"
            style={{ position: 'relative' }}
            ref={tooltipRef}
          >
            <div className="tooltip-inner" ref={tooltipTextRef} />
          </div>
        </Overlays>
        <Interactions />
      </Map>
      <ModalPopup
        title={popupTitle}
        content={popupContent}
        onClose={() => setModalIsOpen(!modalIsOpen)}
        isOpen={modalIsOpen}
      />
    </div>
  );
};

export const DataMapView = compose(
  injectIntl,
  withResourcesData(({ data: { resources }, block, path, pathname }) => ({
    block,
    resources,
    path: path ?? pathname,
    isEditMode: false,
  }))
)(DataMapViewComponent);

// wait init of __CLIENT__ for use openlayers libraries
const Preloader = props => (__CLIENT__ ? <DataMapView {...props} /> : <div className=""></div>);

export default Preloader;
