import config from '@plone/volto/registry';

export const schemaListing = props => {
  const { intl } = props;

  return {
    title: intl.formatMessage({
      id: 'Listing',
    }),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['querystring1', 'querystring2'],
      },
      ...(config.blocks.blocksConfig.listing.showLinkMore
        ? [
            {
              id: 'linkmore',
              title: intl.formatMessage({
                id: 'Link more',
              }),
              fields: ['linkTitle1', 'linkHref1', 'linkTitle2', 'linkHref2'],
            },
          ]
        : []),
    ],

    properties: {
      querystring1: {
        title: `${intl.formatMessage({
          id: 'Query',
        })} 1`,
        widget: 'querystring',
      },
      linkTitle1: {
        title: `${intl.formatMessage({
          id: 'Link title',
        })} 1`,
      },
      linkHref1: {
        title: `${intl.formatMessage({
          id: 'Link to',
        })} 1`,
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['Title', 'Description'],
        allowExternals: true,
      },
      querystring2: {
        title: `${intl.formatMessage({
          id: 'Query',
        })} 2`,
        widget: 'querystring',
      },
      linkTitle2: {
        title: `${intl.formatMessage({
          id: 'Link title',
        })} 2`,
      },
      linkHref2: {
        title: `${intl.formatMessage({
          id: 'Link to',
        })} 2`,
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['Title', 'Description'],
        allowExternals: true,
      },
    },
    required: [],
  };
};

export default schemaListing;
