import { GET_PROVINCE_RELITEMS } from '@arpav/constants/ActionTypes';

const initialState = {
  data: {},
  loaded: false,
  loading: false,
  error: null,
};

/**
 * Environmentale theme related items reducer.
 * @function schema
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function provinceRelItems(state = initialState, action = {}) {
  if (action.type.startsWith(GET_PROVINCE_RELITEMS)) {
    switch (action.type) {
      /** PENDING */
      case GET_PROVINCE_RELITEMS + '_PENDING':
        return {
          ...state,
          loading: true,
          loaded: false,
          error: null,
        };

      /** SUCCESS */
      case GET_PROVINCE_RELITEMS + '_SUCCESS':
        return {
          ...state,
          data: action.result,
          loading: false,
          loaded: true,
          error: null,
        };
      /** FAIL */
      case GET_PROVINCE_RELITEMS + '_FAIL':
        return {
          ...state,
          loading: false,
          loaded: true,
          error: action.error,
        };
      case '':
        return initialState;
      default:
        return state;
    }
  }
  return state;
}
