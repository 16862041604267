/*
 * Customization with image
 *
 * If you have a jpg/png, do like this:
 *
 * <figure className="icon">
 *  <img src={logo} alt="" />
 * </figure>
 *
 * Note the icon class.
 */

const LogoSecondary = ({ white }) => {
  return (
    <figure className="logo">
      <img
        src={white === true ? '/logo_snpa_white.svg' : '/logo_snpa.svg'}
        alt="Sistema nazionale protezione ambientale"
        title="Sistema nazionale protezione ambientale"
      />
    </figure>
  );
};

export default LogoSecondary;
