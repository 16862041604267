/**
 * SimpleCard view component.
 * @module components/ArpavTheme/View/Commons
 */

import {
  Card,
  CardBody,
  CardReadMore,
  CardText,
  CardTitle,
} from 'design-react-kit/dist/design-react-kit';
import { CardCategory, ListingCategory, ListingText } from '@arpav/components/ArpavTheme';
import { defineMessages, injectIntl } from 'react-intl';
import { getCalendarDate, getEventRecurrenceMore, getItemClass } from '@arpav/helpers';

import PropTypes from 'prop-types';
import { UniversalLink } from '@plone/volto/components';
import cx from 'classnames';
import { getCategory } from '@arpav/components/ArpavTheme/Blocks/Listing/Commons/utils';
import { getContentIcon } from '@plone/volto/helpers';

const messages = defineMessages({
  readMore: {
    id: 'Read More…',
    defaultMessage: 'Read More…',
  },
});

const SimpleCard = ({
  content,
  intl,
  show_icon = true,
  hide_dates = false,
  show_description = true,
  show_detail_link = false,
  isEditMode = false,
  show_type = false,
  show_section = false,
  detail_link_label,
}) => {
  const icon = show_icon ? getContentIcon(content['@type'], content.is_folderish) : null;
  const itemTitle = content.evaluation_parent_title || content.title;
  const date = hide_dates ? null : getCalendarDate(content);
  const eventRecurrenceMore = hide_dates ? null : getEventRecurrenceMore(content, isEditMode);
  const listingText = show_description ? <ListingText item={content} /> : null;
  const category = getCategory(content, show_type, show_section);

  return (
    <Card className={`align-items-top rounded shadow ${getItemClass(content)}`} noWrapper teaser>
      <CardBody
        className={cx('', {
          'pb-5': show_detail_link || eventRecurrenceMore,
        })}
      >
        {(icon || category || date) && (
          <CardCategory icon={icon} date={date}>
            {category && (
              <span className="text font-weight-bold">
                <ListingCategory category={category} item={content} />
              </span>
            )}
          </CardCategory>
        )}
        <CardTitle tag="h3">
          <UniversalLink item={!isEditMode ? content : null} href={isEditMode ? '#' : null}>
            {itemTitle}
          </UniversalLink>
        </CardTitle>
        {listingText && (
          <CardText className={cx('', { 'mb-5': eventRecurrenceMore })}>{listingText}</CardText>
        )}
        {eventRecurrenceMore}
        {show_detail_link && (
          <CardReadMore
            iconName="it-arrow-right"
            tag={UniversalLink}
            item={!isEditMode ? content : null}
            href={isEditMode ? '#' : null}
            text={detail_link_label || intl.formatMessage(messages.readMore)}
          />
        )}
      </CardBody>
    </Card>
  );
};

SimpleCard.propTypes = {
  content: PropTypes.object,
  show_icon: PropTypes.bool,
  hide_dates: PropTypes.bool,
  show_description: PropTypes.bool,
  show_detail_link: PropTypes.bool,
  isEditMode: PropTypes.bool,
  show_type: PropTypes.bool,
  show_section: PropTypes.bool,
  detail_link_label: PropTypes.string,
};

export default injectIntl(SimpleCard);
