/**
 * Event booking actions.
 * @module actions/eventBooking/eventBooking
 */

import {
  CREATE_EVENT_BOOKING,
  DELETE_EVENT_BOOKING,
  GET_EVENT_BOOKING,
  RESET_EVENT_BOOKING,
  UPDATE_EVENT_BOOKING,
} from '@arpav/constants/ActionTypes';

/**
 * Get bookings function.
 * @function getEventBooking
 * @param {string} url Content url.
 * @param {string} uid Reservation uid.
 * @returns {Object} Get schema action.
 */
export function getEventBooking(url, uid) {
  url = typeof url !== 'undefined' ? url : '';
  return {
    type: GET_EVENT_BOOKING,
    request: {
      op: 'get',
      path: `${url}/@event_booking${uid ? `/${uid}` : ''}`,
    },
  };
}

/**
 * Create booking function.
 * @function createEventBooking
 * @param {string} url Content url.
 * @param {Object} data Data to update.
 * @returns {Object} Get schema action.
 */
export function createEventBooking(url, data) {
  url = typeof url !== 'undefined' ? url : '';
  return {
    type: CREATE_EVENT_BOOKING,
    request: {
      op: 'post',
      path: `${url}/@event_booking`,
      data: data,
    },
  };
}

/**
 * Update booking function.
 * @function updateEventBooking
 * @param {string} url Content url.
 * @param {string} uid Reservation uid.
 * @param {Object} data Data to update.
 * @returns {Object} Get schema action.
 */
export function updateEventBooking(url, uid, data) {
  url = typeof url !== 'undefined' ? url : '';
  return {
    type: UPDATE_EVENT_BOOKING,
    request: {
      op: 'patch',
      path: `${url}/@event_booking${uid ? `/${uid}` : ''}`,
      data: data,
    },
  };
}

/**
 * Delete event booking function.
 * @function deleteEventBooking
 * @param {string|Array} urls Content url(s).
 * @param {string} uid Reservation uid.
 * @param {Object} data Data for email notification.
 * @returns {Object} Delete content action.
 */
export function deleteEventBooking(url, uid, data) {
  url = typeof url !== 'undefined' ? url : '';
  return {
    type: DELETE_EVENT_BOOKING,
    mode: 'serial',
    request: {
      op: 'del',
      path: `${url}/@event_booking${uid ? `/${uid}` : ''}`,
      data: data,
    },
  };
}

/**
 * Reset booking function.
 * @function resetEventBooking
 * @returns {Object} Get schema action.
 */
export function resetEventBooking() {
  return {
    type: RESET_EVENT_BOOKING,
  };
}
