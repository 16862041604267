import {
  Card,
  CardBody,
  CardReadMore,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from 'design-react-kit/dist/design-react-kit';
import { FormattedDate, Icon as PloneIcon } from '@plone/volto/components';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import {
  Icon,
  ListingText,
  MostVisitedPagesSkeleton as Skeleton,
} from '@arpav/components/ArpavTheme';
import { getBaseUrl, getContentIcon } from '@plone/volto/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { getMostVisited } from '@arpav/actions';
import { getQueryStringResults } from '@plone/volto/actions';

const messages = defineMessages({
  readMore: {
    id: 'Read More…',
    defaultMessage: 'Read More…',
  },
});

const Body = ({ id, path }) => {
  const intl = useIntl();

  // save the path so it won't trigger dispatch on eager router location change
  const [initialPath] = useState(getBaseUrl(path));

  const most_visited = useSelector(state => state.mostVisited.mostvisited);
  const most_visited_loading = useSelector(state => state.mostVisited.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMostVisited({ limit: 10 }));
  }, [dispatch, initialPath]);

  return (
    <Container>
      <Row>
        <Col lg={12} className="pt-5 py-lg-5">
          <div className="ml-lg-3">
            {most_visited_loading ? (
              <Skeleton />
            ) : (
              <Row>
                {most_visited?.items?.length > 0 &&
                  most_visited.items.map((item, index) => (
                    <Col key={`most-visited-${index}`} xs={12}>
                      <Card className="py-2 pl-0" teaser noWrapper>
                        <div>
                          <span className="category-top categoryicon-top-inline mr-2">
                            <PloneIcon
                              name={getContentIcon(item['@type'], item.is_folderish)}
                              className="icon"
                              title={item['@type']}
                            />
                          </span>
                        </div>
                        <CardBody>
                          <CardTitle tag="p" className="mb-0">
                            <a href={item.url} title={item.title}>
                              <strong>{item.title}</strong>
                            </a>
                          </CardTitle>
                          <CardText>
                            <span>
                              <a href="/" title="Home">
                                <em>Home</em>
                              </a>
                            </span>
                            {item.breadcrumbs?.map((item, i) => {
                              return (
                                <span key={i}>
                                  <Icon
                                    color=""
                                    icon="it-arrow-right"
                                    size="16px"
                                    padding={false}
                                  />
                                  {item.url ? (
                                    <a href={item.url} title={item.title}>
                                      <em>{item.title}</em>
                                    </a>
                                  ) : (
                                    <em>{item.title}</em>
                                  )}
                                </span>
                              );
                            })}
                          </CardText>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
              </Row>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Body;
