import { mapValues, orderBy } from 'lodash';

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  csv: {
    id: 'CSV',
    defaultMessage: 'CSV',
  },
  api: {
    id: 'ARPAV API',
    defaultMessage: 'ARPAV API',
  },
  dataChart: {
    id: 'Data chart',
    defaultMessage: 'Data Chart',
  },
  defaultFieldset: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  sourceType: {
    id: 'Source type',
    defaultMessage: 'Source type',
  },
  dataFile: {
    id: 'Data file',
    defaultMessage: 'Data file',
  },
  apiUrl: {
    id: 'API url',
    defaultMessage: 'API url',
  },
  chartType: {
    id: 'Chart type',
    defaultMessage: 'Chart type',
  },
  xField: {
    id: 'X field',
    defaultMessage: 'X field',
  },
  xPlaceholder: {
    id: 'X placeholder',
    defaultMessage: 'X placeholder',
  },
  xPlaceholderDescription: {
    id: 'Use the placeholder $\'{record.myfield}\' to insert the heat of "myfield" into the string',
    defaultMessage:
      'Use the placeholder $\'{record.myfield}\' to insert the heat of "myfield" into the string',
  },
  xTitle: {
    id: 'X title',
    defaultMessage: 'X title',
  },
  yTitle: {
    id: 'Y title',
    defaultMessage: 'Y title',
  },
  yField: {
    id: 'Y field',
    defaultMessage: 'Y field',
  },
  filterField: {
    id: 'Filter field',
    defaultMessage: 'Filter field',
  },
  filterFieldDescription: {
    id: 'Select the field to use to filter the information in the datasets',
    defaultMessage: 'Select the field to use to filter the information in the datasets',
  },
  datasets: {
    id: 'Datasets',
    defaultMessage: 'Datasets',
  },
  datasetsDescription: {
    id: 'Insert information for dataset',
    defaultMessage: 'Insert information for dataset',
  },
  dataset: {
    id: 'Dataset',
    defaultMessage: 'Dataset',
  },
  datasetTitle: {
    id: 'Dataset title',
    defaultMessage: 'Dataset title',
  },
  filterValue: {
    id: 'Filter value',
    defaultMessage: 'Filter value',
  },
  filterValueDescription: {
    id: 'Enter the value to filter the data in this dataset',
    defaultMessage: 'Enter the value to filter the data in this dataset',
  },
  color: {
    id: 'Color',
    defaultMessage: 'Color',
  },
  bar: {
    id: 'Bar chart',
    defaultMessage: 'Bar chart',
  },
  bubble: {
    id: 'Bubble chart',
    defaultMessage: 'Bubble chart',
  },
  doughnut: {
    id: 'Doughnut chart',
    defaultMessage: 'Doughnut chart',
  },
  line: {
    id: 'Line chart',
    defaultMessage: 'Line chart',
  },
  pie: {
    id: 'Pie chart',
    defaultMessage: 'Pie chart',
  },
  polararea: {
    id: 'Polar area chart',
    defaultMessage: 'Polar area chart',
  },
  radar: {
    id: 'Radar chart',
    defaultMessage: 'Radar chart',
  },
  scatter: {
    id: 'Scatter chart',
    defaultMessage: 'Scatter chart',
  },
  width: {
    id: 'Width',
    defaultMessage: 'Width',
  },
  height: {
    id: 'Height',
    defaultMessage: 'Height',
  },
  useAsValue: {
    id: 'Use x placeholder as x value',
    defaultMessage: 'Use x placeholder as x value',
  },
  useAsValueDescription: {
    id: 'Check if you want use x placeholder as value for x axis',
    defaultMessage: 'Check if you want use x placeholder as value for x axis',
  },
  colorField: {
    id: 'Color field',
    defaultMessage: 'Color field',
  },
  colorFieldDescription: {
    id: 'Select the field from which to take the color',
    defaultMessage: 'Select the field from which to take the color',
  },
});

const SOURCE_TYPES = {
  csv: {
    id: 'csv',
    label: messages.csv,
  },
  api: {
    id: 'api',
    label: messages.api,
  },
};

const ChartSchema = ({ intl }) => ({
  title: intl.formatMessage(messages.dataChart),
  fieldsets: [
    {
      id: 'default',
      title: intl.formatMessage(messages.defaultFieldset),
      fields: [
        'title',
        'source_type',
        'file_path',
        'api_url',
        'chart_type',
        'x_field',
        'x_placeholder',
        'use_as_value',
        'x_title',
        'y_title',
        'filter_field',
        'width',
        'dataset',
      ],
    },
  ],
  properties: {
    title: {
      title: intl.formatMessage({ id: 'title' }),
    },
    source_type: {
      factory: 'Choice',
      type: 'string',
      title: intl.formatMessage(messages.sourceType),
      choices: orderBy(
        mapValues(SOURCE_TYPES, v => [v.id, intl.formatMessage(v.label)]),
        1
      ),
    },
    file_path: {
      title: intl.formatMessage(messages.dataFile),
      widget: 'pick_object',
    },
    api_url: {
      type: 'string',
      title: intl.formatMessage(messages.apiUrl),
      widget: 'url',
    },
    chart_type: {
      title: intl.formatMessage(messages.chartType),
      choices: orderBy(
        [
          ['bar', intl.formatMessage(messages.bar)],
          ['bubble', intl.formatMessage(messages.bubble)],
          ['doughnut', intl.formatMessage(messages.doughnut)],
          ['line', intl.formatMessage(messages.line)],
          ['pie', intl.formatMessage(messages.pie)],
          ['polararea', intl.formatMessage(messages.polararea)],
          ['radar', intl.formatMessage(messages.radar)],
          ['scatter', intl.formatMessage(messages.scatter)],
        ],
        1
      ),
      default: 'bar',
    },
    x_field: {
      title: intl.formatMessage(messages.xField),
      choices: [],
    },
    x_placeholder: {
      title: intl.formatMessage(messages.xPlaceholder),
      description: intl.formatMessage(messages.xPlaceholderDescription),
    },
    use_as_value: {
      type: 'boolean',
      title: intl.formatMessage(messages.useAsValue),
      description: intl.formatMessage(messages.useAsValueDescription),
      default: false,
    },
    x_title: {
      title: intl.formatMessage(messages.xTitle),
    },
    y_title: {
      title: intl.formatMessage(messages.yTitle),
    },
    filter_field: {
      title: intl.formatMessage(messages.filterField),
      description: intl.formatMessage(messages.filterFieldDescription),
      choices: [],
    },
    width: { title: intl.formatMessage(messages.width) },
    dataset: {
      title: intl.formatMessage(messages.datasets),
      description: intl.formatMessage(messages.datasetsDescription),
      widget: 'object_list_inline',
      schema: DatasetSchema({ intl }),
    },
  },
  required: ['source_type', 'chart_type', 'x_field', 'dataset'],
});

const DatasetSchema = ({ intl }) => ({
  title: intl.formatMessage(messages.dataset),
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['title', 'filter_value', 'y_field', 'color_field', 'color'],
    },
  ],
  properties: {
    title: {
      title: intl.formatMessage(messages.datasetTitle),
    },
    filter_value: {
      title: intl.formatMessage(messages.filterValue),
    },
    y_field: {
      title: intl.formatMessage(messages.yField),
      choices: [],
    },
    color_field: {
      title: intl.formatMessage(messages.colorField),
      description: intl.formatMessage(messages.colorFieldDescription),
      choices: [],
    },
    color: {
      title: intl.formatMessage(messages.color),
      widget: 'color',
    },
  },
  required: ['title', 'y_field'],
});

export { SOURCE_TYPES, ChartSchema };
