import { GET_ENVIRONMENTAL_THEME_RELITEMS } from '@arpav/constants/ActionTypes';

/**
 * Get bookings function.
 * @function getEventBooking
 * @param {string} url Content url.
 * @param {string} uid Reservation uid.
 * @returns {Object} Get schema action.
 */
export function getEnvironmentalThemeRelatedItems(url, itemindex) {
  url = typeof url !== 'undefined' ? url : '';

  return {
    type: `${GET_ENVIRONMENTAL_THEME_RELITEMS}_${itemindex}`,
    request: {
      op: 'get',
      path: `${url}/@relateditems`,
    },
  };
}
