/**
 * Login container.
 * @module components/ArpavTheme/EventSubscription/EventSubscriptionForm
 */

import { Form } from 'semantic-ui-react';
import { InlineForm } from '@plone/volto/components';

const EventSubscriptionForm = ({
  loading,
  schema,
  data,
  error,
  errors,
  onChangeField,
  onSubmit,
}) => (
  <div className="cms-ui">
    {!loading && schema?.fieldsets ? (
      <Form method="post" onSubmit={onSubmit} error={error}>
        <InlineForm schema={schema} formData={data} errors={errors} onChangeField={onChangeField} />
      </Form>
    ) : null}
  </div>
);

export default EventSubscriptionForm;
