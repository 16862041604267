import { Table } from 'semantic-ui-react';
import { range } from 'lodash';

/**
 * ElementView block skeleton component class.
 *
 * @function View
 * @param {Object} data Block data object.
 * @param {String} id Block id.
 * @returns {String} Markup of the component.
 */
const Skeleton = ({ columns, pageSize }) => {
  const skeletonColumns = columns?.length ? columns : [0, 1, 2];
  return (
    <div className="skeleton-template">
      <Table celled={true}>
        <Table.Header>
          <Table.Row>
            {skeletonColumns.map((c, i) => (
              <Table.HeaderCell key={i}></Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {(pageSize ? range(pageSize) : [0, 1, 2]).map(i => (
            <Table.Row key={i}>
              {skeletonColumns.map((c, i) => (
                <Table.Cell key={i}></Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default Skeleton;
