import { Col, Row } from 'design-react-kit/dist/design-react-kit';
import { Icon, ListingLinkMore } from '@arpav/components/ArpavTheme';
import { Icon as PloneIcon, UniversalLink } from '@plone/volto/components';

import PropTypes from 'prop-types';
import cx from 'classnames';
import { getContentIcon } from '@plone/volto/helpers';
import { getItemClass } from '@arpav/helpers';

const SimpleCardTemplateCompact = ({
  items,
  isEditMode,
  linkTitle,
  linkHref,
  show_icon,
  show_block_bg,
  title,
  description,
}) => (
  <div className="simple-card-compact-template">
    {title && (
      <Row className="mb-4">
        <Col className="text-center">
          <div className="template-header">
            <h2 className={cx('mb-4', { 'mt-5': !show_block_bg })}>{title}</h2>
            {description ? <p>{description}</p> : null}
          </div>
        </Col>
      </Row>
    )}
    <Row className="justify-content-between align-items-stretch mx-n2">
      {items?.map((item, index) => (
        <Col xs={12} md={4} className="mb-4 px-2" key={index}>
          <UniversalLink
            className={`button-card rounded text-left btn-icon btn btn-white btn-block text-primary ${getItemClass(
              item
            )}`}
            item={!isEditMode ? item : null}
            href={isEditMode ? '#' : null}
            title={item.title || item.id}
          >
            {show_icon ? (
              <div className="mr-3">
                <PloneIcon
                  name={getContentIcon(item['@type'], item.is_folderish)}
                  size="18px"
                  className="icon"
                  title={item['@type']}
                />
              </div>
            ) : null}
            <span className="flex-grow-1">{item.title || item.id}</span>
            <Icon icon="it-arrow-right" padding={false} />
          </UniversalLink>
        </Col>
      ))}
    </Row>

    <ListingLinkMore title={linkTitle} href={linkHref} className="my-4" />
  </div>
);

SimpleCardTemplateCompact.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
  linkTitle: PropTypes.any,
  c: PropTypes.any,
};

export default SimpleCardTemplateCompact;
