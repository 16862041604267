import PropTypes from 'prop-types';
import { AnnouncementsSummaryBody } from 'components/ArpavTheme';

const AnnouncementsSummaryEditView = props => {
  // const { data, path, pathname, block } = props;
  const { id, path, data, history, location } = props;
  return (
    <div className={'block'}>
      <AnnouncementsSummaryBody
        id={id}
        path={path}
        data={data}
        history={history}
        location={location}
      />
    </div>
  );
};

AnnouncementsSummaryEditView.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string,
};

export default AnnouncementsSummaryEditView;
