import { FormattedMessage, injectIntl } from 'react-intl';
import { Plug, Pluggable } from '@plone/volto/components/manage/Pluggable';

import PropTypes from 'prop-types';
import { Workflow } from '@plone/volto/components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getContent } from '@plone/volto/actions';

const States = ({ pathname, content }) => {
  return (
    <div className="menu-more pastanaga-menu" style={{ height: '300px' }}>
      <header>
        <h2>
          {content.title}: <FormattedMessage id="State" defaultMessage="State" />
        </h2>
      </header>
      <div className="pastanaga-menu-list overflow-unset">
        <ul>
          <Pluggable name="toolbar-more-menu-list" />
          <Plug pluggable="toolbar-more-menu-list" id="state">
            {content['@type'] !== 'Plone Site' && (
              // Plone Site does not have workflow
              <li className="state-select">
                <Workflow pathname={pathname} />
              </li>
            )}
          </Plug>
        </ul>
      </div>
    </div>
  );
};

States.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default compose(
  injectIntl,
  connect(
    state => ({
      content: state.content.data,
    }),
    { getContent }
  )
)(States);
