import { Col, Container, Row } from 'design-react-kit/dist/design-react-kit';
import { Metadata, PageHeader, SkipToMainContent } from '@arpav/components/ArpavTheme/View';

import { Button } from 'design-react-kit/dist/design-react-kit';
import { FileSize } from '@arpav/components/ArpavTheme';
import { FormattedMessage } from 'react-intl';
import { Icon } from '@arpav/components/ArpavTheme';
import { Image } from '@arpav/components/ArpavTheme';

const Publication = ({ content, token, history, location }) => {
  return (
    <Container id="page-organization">
      <SkipToMainContent />

      {/** page header */}
      <PageHeader content={content} showdates={false} />

      <Row className="border-top row-column-border row-column-menu-left">
        <Col
          tag="section"
          lg={8}
          id="main-content-section"
          className="it-page-sections-container pr-lg-0"
        >
          <div className="mt-3 mt-lg-5 mr-lg-5 ml-lg-3">
            <dl className="row">
              {content.edited && (
                <>
                  <dt className="col-sm-3">
                    <Icon color="" icon="it-chevron-right" padding={false} />
                    <FormattedMessage id="Publication edited" defaultMessage="Edited" />
                  </dt>
                  <dd className="col-sm-9">{content.edited}</dd>
                </>
              )}
              {content.year && (
                <>
                  <dt className="col-sm-3">
                    <Icon color="" icon="it-chevron-right" padding={false} />
                    <FormattedMessage id="Year" defaultMessage="Year" />
                  </dt>
                  <dd className="col-sm-9">{content.year}</dd>
                </>
              )}
              {content.amount != 0 && content.amount && (
                <>
                  <dt className="col-sm-3">
                    <Icon color="" icon="it-chevron-right" padding={false} />
                    <FormattedMessage id="Quantity" defaultMessage="Quantity" />
                  </dt>
                  <dd className="col-sm-9">{content.amount}</dd>
                </>
              )}
              {content.isbn && (
                <>
                  <dt className="col-sm-3">
                    <Icon color="" icon="it-chevron-right" padding={false} />
                    <FormattedMessage id="ISBN" defaultMessage="ISBN" />
                  </dt>
                  <dd className="col-sm-9">{content.isbn}</dd>
                </>
              )}
              {content.type && (
                <>
                  <dt className="col-sm-3">
                    <Icon color="" icon="it-chevron-right" padding={false} />
                    <FormattedMessage id="Type" defaultMessage="Type" />
                  </dt>
                  <dd className="col-sm-9">{content.type}</dd>
                </>
              )}
            </dl>
            {content.file && (
              <div>
                <Button color="primary" icon href={content.file.download}>
                  <Icon color="white" icon="it-download" />
                  &nbsp; {content.file.filename}
                  <small>
                    {' ('}
                    <FileSize size={content.file.size} />
                    {')'}
                  </small>
                </Button>
              </div>
            )}
          </div>
        </Col>
        <Col tag="aside" lg={4}>
          {content.image && (
            <Image
              image={content.image}
              alt={content.image_caption}
              critical
              loading="eager"
              key={content.image?.download}
            />
          )}
        </Col>
      </Row>

      <div className="mb-5  ml-lg-3">
        <Metadata content={content} />
      </div>
    </Container>
  );
};

export default Publication;
