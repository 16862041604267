import { Card, CardBody, CardText, CardTitle } from 'design-react-kit/dist/design-react-kit';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

const messages = defineMessages({
  sendEmailTo: {
    id: 'Send e-mail to {to}',
    defaultMessage: 'Send e-mail to {to}',
  },
  viewCurriculum: {
    id: "View manage's curriculum",
    defaultMessage: "View manage's curriculum",
  },
});

const OrganizationInfo = ({ content, intl }) => (
  <Card noWrapper spacing className="card-bg card-img rounded shadow no-after mb-5">
    <CardBody>
      <CardTitle tag="h2">
        {content.manager_label ? (
          <>{content.manager_label.title}</>
        ) : (
          <FormattedMessage id="Manager" defaultMessage="Manager" />
        )}{' '}
        {content.manager}
      </CardTitle>
      <CardText tag="div">
        {content.manager_email && (
          <p>
            <strong>
              <FormattedMessage id="Email" defaultMessage="Email" />
            </strong>
            :{' '}
            <a
              title={intl.formatMessage(messages.sendEmailTo, { to: content.manager })}
              href={`mailto:${content.manager_email}`}
            >
              {content.manager_email}
            </a>
          </p>
        )}
        {content.address && (
          <p>
            <strong>
              <FormattedMessage id="Address" defaultMessage="Address" />
            </strong>
            : {content.address}
          </p>
        )}
        {content.phone && (
          <p>
            <strong>
              <FormattedMessage id="Telephone" defaultMessage="Telephone" />
            </strong>
            : {content.phone}
          </p>
        )}
        {content.fax && (
          <p>
            <strong>
              <FormattedMessage id="Fax" defaultMessage="Fax" />
            </strong>
            : {content.fax}
          </p>
        )}
        {content.pec && (
          <p>
            <strong>
              <FormattedMessage id="PEC" defaultMessage="PEC" />
            </strong>
            :{' '}
            <a
              title={intl.formatMessage(messages.sendEmailTo, { to: content.title })}
              href={`mailto:${content.pec}`}
            >
              {content.pec}
            </a>
          </p>
        )}
        {content.curriculum_url && (
          <p>
            <a title={intl.formatMessage(messages.viewCurriculum)} href={content.curriculum_url}>
              <FormattedMessage id="Curriculum" defaultMessage="Curriculum" />
            </a>
          </p>
        )}
      </CardText>
    </CardBody>
  </Card>
);
export default injectIntl(OrganizationInfo);
