import { LinkList, NavItem, Navbar } from 'design-react-kit/dist/design-react-kit';
import { defineMessages, injectIntl } from 'react-intl';

import { Link as RouterLink } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  next: {
    id: 'Next',
    defaultMessage: 'Next',
  },
  prev: {
    id: 'Previous',
    defaultMessage: 'Previous',
  },
});

function renderNode(node) {
  return (
    <NavItem key={node['@id']}>
      <RouterLink
        to={flattenToAppURL(node['@id'])}
        className={`nav-link ${node.is_current && 'active'}`}
      >
        <span>{node.title}</span>
      </RouterLink>
    </NavItem>
  );
}

const NextPreviousItems = ({ intl, content, showParent }) => (
  <div className="sticky-wrapper navbar-wrapper page-side-menu">
    <Navbar expand="lg" className="it-navscroll-wrapper navbar-expand-lg">
      <div className="navbar-collapsable expanded" id="navbarNavB" style={{ display: 'block' }}>
        <div className="overlay" style={{ display: 'block' }}></div>
        <div className="menu-wrapper">
          <LinkList
            wrapperClassName="menu-link-list"
            header={
              <h3>
                {showParent && content.parent?.title ? (
                  <RouterLink to={flattenToAppURL(content.parent['@id'] || '')}>
                    {content.parent.title}
                  </RouterLink>
                ) : null}
              </h3>
            }
          >
            {content.previous_item?.title
              ? renderNode({
                  ...content.previous_item,
                  title: `${intl.formatMessage(messages.prev)}: ${content.previous_item.title}`,
                })
              : null}
            {renderNode({
              ...content,
              is_current: true,
            })}
            {content.next_item?.title
              ? renderNode({
                  ...content.next_item,
                  title: `${intl.formatMessage(messages.next)}: ${content.next_item.title}`,
                })
              : null}
          </LinkList>
        </div>
      </div>
    </Navbar>
  </div>
);

export default injectIntl(NextPreviousItems);
