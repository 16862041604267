// Customization: added folder title and bootstrap layout

/**
 * Document view component.
 * @module components/theme/View/ListingView
 */

import { Pagination, SimpleCard } from '@arpav/components/ArpavTheme';
import { createRef, useEffect, useState } from 'react';

import { Container } from 'design-react-kit/dist/design-react-kit';
import { PageHeader } from '@arpav/components/ArpavTheme/View';
import PropTypes from 'prop-types';
import config from '@plone/volto/registry';
import { getBaseUrl } from '@plone/volto/helpers';
import { getContent } from '@plone/volto/actions';
import qs from 'query-string';
import { useDispatch } from 'react-redux';

/**
 * List view component class.
 * @function ListingView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */

const ListingView = ({ content, location, history }) => {
  const { pathname } = location;
  const listingRef = createRef();
  const b_size = config.settings.defaultPageSize;
  const b_start = qs.parse(history.location.search).b_start;
  const totalPages = Math.ceil(content.items_total / b_size);
  const [currentPage, setCurrentPage] = useState(b_start ? Math.floor(b_start / b_size) + 1 : 0); // default value to 0 for prevent load content first time
  const [initialPath] = useState(getBaseUrl(pathname));
  const dispatch = useDispatch();

  useEffect(() => {
    // prevent load content first time
    if (dispatch && initialPath && currentPage && currentPage > 0) {
      dispatch(getContent(initialPath, null, null, currentPage));
    }
  }, [dispatch, initialPath, currentPage]);

  return (
    <Container id="page-folder">
      <PageHeader content={content} />
      <section id="content-core" ref={listingRef}>
        <div className="simple-card-default card-wrapper card-teaser-wrapper card-teaser-wrapper-equal card-teaser-block-3 mb-3">
          {content.items.map(item => (
            <SimpleCard content={item} key={item['@id']} />
          ))}
        </div>
        {totalPages > 1 && (
          <div className="pagination-wrapper">
            <Pagination
              activePage={currentPage || 1}
              totalPages={totalPages}
              onPageChange={(e, { activePage: { children } }) => {
                listingRef.current.scrollIntoView({ behavior: 'smooth' });
                setCurrentPage(children);
                history.push({
                  search: qs.stringify({
                    b_start: b_size * (children - 1),
                  }),
                });
              }}
            />
          </div>
        )}
      </section>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ListingView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string,
        '@type': PropTypes.string,
        description: PropTypes.string,
        review_state: PropTypes.string,
        title: PropTypes.string,
        url: PropTypes.string,
      })
    ),
  }).isRequired,
};

export default ListingView;
