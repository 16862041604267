import DefaultImageSVG from '@arpav/components/ArpavTheme/Blocks/Listing/default-image.svg';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { flattenToAppURL } from '@plone/volto/helpers';

/**
 * Renders a preview image for a catalog brain result item.
 *
 */
function PreviewImage(props) {
  const { item, size = 'preview', alt, ...rest } = props;
  const is_image = item.image_field;
  const src = is_image
    ? flattenToAppURL(`${item['@id']}/@@images/${item.image_field}/${size}`)
    : DefaultImageSVG;

  return (
    <img
      src={src}
      alt={alt ?? item.title}
      {...rest}
      className={cx(rest.className || '', {
        'img-placeholder': !is_image,
      })}
    />
  );
}

PreviewImage.propTypes = {
  size: PropTypes.string,
  item: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    image_field: PropTypes.string,
    title: PropTypes.string.isRequired,
  }),
};

export default PreviewImage;
