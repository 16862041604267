import { addDefaultOptions, templatesOptions } from '@arpav/config/Blocks/ListingOptions';

export const addEventsTemplate = (schema, formData, intl, position = 0) => {
  let pos = position;
  pos = addDefaultOptions(schema, formData, intl, pos);

  pos = templatesOptions(
    schema,
    formData,
    intl,
    [
      'show_icon',
      'hide_dates',
      'show_section',
      'show_type',
      'show_description',
      'show_detail_link',
      'show_path_filters',
    ],
    {
      hide_dates: { default: false },
      show_type: { default: false },
      show_detail_link: { default: false },
      show_path_filters: { default: false },
    },
    pos
  );
  return pos;
};
