import { ListingBlockBody as ListingBody } from '@plone/volto/components';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withBlockExtensions } from '@plone/volto/helpers';
import { Col, Row } from 'design-react-kit/dist/design-react-kit';

const DoubleListingEditView = props => {
  const { data, path, pathname, block } = props;

  return (
    <div className={cx('block listing', data.variation || 'default')}>
      <Row>
        <Col sm={12} md={6}>
          <ListingBody
            {...props}
            path={path ?? pathname}
            block={`${block}-1`}
            data={{
              ...data,
              block: `${block}-1`,
              querystring: data.querystring1,
              linkTitle: data.linkTitle1,
              linkHref: data.linkHref1,
            }}
          />
        </Col>
        <Col sm={12} md={6}>
          <ListingBody
            {...props}
            path={path ?? pathname}
            block={`${block}-2`}
            data={{
              ...data,
              block: `${block}-2`,
              querystring: data.querystring2,
              linkTitle: data.linkTitle2,
              linkHref: data.linkHref2,
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

DoubleListingEditView.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string,
};

export default withBlockExtensions(DoubleListingEditView);
