/**
 * Breadcrumbs components.
 * @module components/theme/Breadcrumbs/Breadcrumbs
 */

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Breadcrumbs as ArpavBreadcrumbs } from '@arpav/components/ArpavTheme';

const Breadcrumbs = ({ pathname }) => {
  let brdc = ArpavBreadcrumbs({ pathname: pathname });

  const content = useSelector(state => state.content?.data);

  const CT_CustomBreadcrumbs = ['Pagina Argomento', 'EI Area']; //don't display breadcrumbs for this content-types. They will be displayed by content-type view if needed.
  if (CT_CustomBreadcrumbs?.indexOf(content?.['@type']) >= 0) {
    brdc = null;
  }
  return brdc ? (
    <div className="public-ui">
      <section id="briciole" className="container">
        {brdc}
      </section>
    </div>
  ) : null;
};

Breadcrumbs.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default Breadcrumbs;
