/**
 * Legend view component.
 * @module components/theme/ArpavView/Indicators
 */

import { Alert, Col, Row } from 'design-react-kit/dist/design-react-kit';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const messages = defineMessages({
  statusG: {
    id: 'Positive',
    defaultMessage: 'Positive',
  },
  statusY: {
    id: 'Intermediate or uncertain',
    defaultMessage: 'Intermediate or uncertain',
  },
  statusR: {
    id: 'Negative',
    defaultMessage: 'Negative',
  },
  trendG: {
    id: 'Improving',
    defaultMessage: 'Improving',
  },
  trendY: {
    id: 'Stable or uncertain',
    defaultMessage: 'Stable or uncertain',
  },
  trendR: {
    id: 'Worsening',
    defaultMessage: 'Worsening',
  },
});

export const StatusIcon = injectIntl(({ intl, value }) => {
  let message, color, icon;
  switch (value) {
    case 'G':
      message = intl.formatMessage(messages.statusG);
      color = '#0ad50a';
      icon = ['fas', 'smile'];
      break;
    case 'Y':
      message = intl.formatMessage(messages.statusY);
      color = '#f0f00a';
      icon = ['fas', 'meh'];
      break;
    case 'R':
      message = intl.formatMessage(messages.statusR);
      color = '#f00';
      icon = ['fas', 'frown'];
      break;
  }
  return message && color && icon ? (
    <FontAwesomeIcon
      icon={icon}
      className="fal icon sm icon-sm"
      style={{ color: color, cursor: 'help' }}
      aria-label={message}
      title={message}
    />
  ) : null;
});

export const TrendIcon = injectIntl(({ intl, value }) => {
  let message, color, icon;
  switch (value) {
    case 'G':
      message = intl.formatMessage(messages.trendG);
      color = '#0ad50a';
      icon = ['fas', 'arrow-up'];
      break;
    case 'Y':
      message = intl.formatMessage(messages.trendY);
      color = '#f0f00a';
      icon = ['fas', 'arrow-right'];
      break;
    case 'R':
      message = intl.formatMessage(messages.trendR);
      color = '#f00';
      icon = ['fas', 'arrow-down'];
      break;
  }

  return message && color && icon ? (
    <span
      className="fa-stack icon icon-sm"
      aria-label={message}
      title={message}
      style={{ cursor: 'help' }}
    >
      <FontAwesomeIcon
        icon={['fas', 'square']}
        className="fal fa-stack-2x icon icon-sm"
        style={{ color: color }}
      />
      <FontAwesomeIcon icon={icon} className="fal fa-stack-1x fa-inverse icon" />
    </span>
  ) : null;
});

/**
 * Legend view component class.
 * @function Legend
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const Legend = ({ showDpsir }) => (
  <div className="my-5">
    <Alert color="info" fade={false} className="rounded">
      <Row>
        {showDpsir ? (
          <Col xs={12} lg={4}>
            <h4 className="alert-heading">
              <FormattedMessage id="DPSIR" defaultMessage="DPSIR" />
            </h4>
            <ul className="list-unstyled ml-3">
              <li>
                D - <FormattedMessage id="Determinant" defaultMessage="Determinant" />
              </li>
              <li>
                P - <FormattedMessage id="Pressure" defaultMessage="Pressure" />
              </li>
              <li>
                S - <FormattedMessage id="Status" defaultMessage="Status" />
              </li>
              <li>
                I - <FormattedMessage id="Impact" defaultMessage="Impact" />
              </li>
              <li>
                R - <FormattedMessage id="Resource" defaultMessage="Resource" />
              </li>
            </ul>
          </Col>
        ) : null}
        <Col xs={12} lg={showDpsir ? 4 : 6}>
          <h4 className="alert-heading">
            <FormattedMessage id="Current state" defaultMessage="Current state" />
          </h4>
          <ul className="fa-ul">
            <li>
              <span className="fa-li">
                <StatusIcon value="G" />
              </span>{' '}
              <FormattedMessage {...messages.statusG} />
            </li>
            <li>
              <span className="fa-li">
                <StatusIcon value="Y" />
              </span>{' '}
              <FormattedMessage {...messages.statusY} />
            </li>
            <li>
              <span className="fa-li">
                <StatusIcon value="R" />
              </span>{' '}
              <FormattedMessage {...messages.statusR} />
            </li>
          </ul>
        </Col>
        <Col xs={12} lg={showDpsir ? 4 : 6}>
          <h4 className="alert-heading">
            <FormattedMessage id="Resource trend" defaultMessage="Resource trend" />
          </h4>
          <ul className="fa-ul">
            <li>
              <span className="fa-li">
                <TrendIcon value="G" />
              </span>{' '}
              <FormattedMessage {...messages.trendG} />
            </li>
            <li>
              <span className="fa-li">
                <TrendIcon value="Y" />
              </span>{' '}
              <FormattedMessage {...messages.trendY} />
            </li>
            <li>
              <span className="fa-li">
                <TrendIcon value="R" />
              </span>{' '}
              <FormattedMessage {...messages.trendR} />
            </li>
          </ul>
        </Col>
      </Row>
    </Alert>
  </div>
);

export default injectIntl(Legend);
