import { Col, Container, Row } from 'design-react-kit/dist/design-react-kit';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Icon } from '@arpav/components/ArpavTheme';
import PropTypes from 'prop-types';
import RealTimeItem from './RealTimeItem';
import Slider from 'react-slick';
import { compose } from 'redux';
import withApiData from './withApiData';

const NextArrow = props => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <Icon icon="chevron-right" size="md" />
    </div>
  );
};

const PrevArrow = props => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <Icon icon="chevron-left" size="md" />
    </div>
  );
};

const settings = {
  dots: false,
  infinite: true,
  autoplay: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: true,
  pauseOnFocus: true,
  pauseOnDotsHover: true,
  swipe: true,
  swipeToSlide: true,
  focusOnSelect: true,
  draggable: true,
  accessibility: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 980,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const HomeRealTimeData = ({ isEditMode, data_result, loading, loaded }) => {
  return (
    <div className="info-slider">
      <div className="it-hero-wrapper it-text-centered align-items-center no-min-height">
        <Container>
          <Row>
            <Col>
              <div className="it-hero-text-wrapper pl-0 pr-0">
                <p className="info-slider-surtitle">
                  <FormattedMessage id="Real time data" defaultMessage="Real time data" />
                </p>
                {loading || isEditMode ? (
                  <div className="skeleton-template">
                    <RealTimeItem />
                  </div>
                ) : (
                  <Slider {...settings} className="mr-3 mr-lg-0">
                    {data_result?.data?.map(i => (
                      <RealTimeItem key={i.codice_stazione} item={i} />
                    ))}
                  </Slider>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
HomeRealTimeData.propTypes = {
  isEditMode: PropTypes.bool,
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  data_result: PropTypes.arrayOf(PropTypes.object),
};

export default compose(
  injectIntl,
  withApiData(({ block, isEditMode }) => ({
    block,
    isEditMode: isEditMode,
  }))
)(HomeRealTimeData);
