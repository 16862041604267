import { Col, Row } from 'design-react-kit/dist/design-react-kit';
import { defineMessages, injectIntl } from 'react-intl';

import { FormattedDate } from '@plone/volto/components';
import PropTypes from 'prop-types';

/**
 * PageHeaderDates view component class.
 * @function PageHeaderDates
 * @params {object} content: Content object.
 * @returns {string} Markup of the component.
 */

const messages = defineMessages({
  expire: {
    id: 'expire',
    defaultMessage: 'Scadenza',
  },
});

const PageHeaderDates = ({ content, intl }) => (
  <>
    {(content.effective || content.expires) && (
      <Col xs={6}>
        {content.effective && content['@type'] !== 'Event' && (
          <Row>
            <Col xs={12}>
              <small>{intl.formatMessage({ id: 'Publication date' })}:</small>
              <p className="font-weight-semibold text-monospace">
                <FormattedDate
                  date={content.effective}
                  format={{
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  }}
                />
              </p>
            </Col>
          </Row>
        )}
        {content.expires && content['@type'] !== 'News Item' && (
          <Row>
            <Col xs={12}>
              <small>{intl.formatMessage(messages.expire)}:</small>
              <p className="font-weight-semibold text-monospace">
                <FormattedDate
                  date={content.expires}
                  format={{
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  }}
                />
              </p>
            </Col>
          </Row>
        )}
      </Col>
    )}
  </>
);
export default injectIntl(PageHeaderDates);

PageHeaderDates.propTypes = {
  params: PropTypes.shape({
    content: PropTypes.object,
  }),
};
