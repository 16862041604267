import { AnnouncementListItem, AnnouncementListItemSkeleton } from './AnnouncementListItem';
import { Button, Col, Row } from 'design-react-kit/dist/design-react-kit';
import { Container, Image } from 'semantic-ui-react';
import { Metadata, PageHeader, SkipToMainContent } from '@arpav/components/ArpavTheme/View';
import { createRef, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Pagination } from '@arpav/components/ArpavTheme';
import config from '@plone/volto/registry';
import { getAnnouncements } from '@arpav/actions';
import { getBaseUrl } from '@plone/volto/helpers';
import qs from 'query-string';

const messages = defineMessages({
  active: {
    id: 'active_announcements',
    defaultMessage: 'Active',
  },
  title_active: {
    id: 'title_active_announcements',
    defaultMessage: 'View active announcements',
  },
  expired: {
    id: 'expired_announcements',
    defaultMessage: 'Expired',
  },
  title_expired: {
    id: 'title_expired_announcements',
    defaultMessage: 'View expired announcements',
  },
  all: {
    id: 'all_announcements',
    defaultMessage: 'All',
  },
  title_all: {
    id: 'title_all_announcements',
    defaultMessage: 'View all announcements',
  },
});
const AnnouncementsList = ({ content, location, history }) => {
  const intl = useIntl();

  const loading = useSelector(state => state.announcementsList.loading);
  const itemsList = useSelector(state => state.announcementsList.data?.items);
  const itemsCount = useSelector(state => state.announcementsList.data?.items_total);

  const [annFilter, setAnnFilter] = useState('active');

  // pagination variables
  const b_size = config.settings.defaultPageSize;
  const totalPages = Math.ceil(itemsCount / b_size);
  const [b_start, setBStart] = useState(qs.parse(history.location.search).b_start || 0);
  let currentPage = b_start ? Math.floor(b_start / b_size) + 1 : 0;
  const listingRef = createRef();
  const dispatch = useDispatch();

  const onFilterChange = f => {
    setAnnFilter(f);
    setBStart(0);
    currentPage = 1;
  };

  /**
   * on page change event
   * @param {Event} e Event
   * @param {Number} children
   */
  const onPageChage = (e, { activePage: { children } }) => {
    listingRef.current.scrollIntoView({ behavior: 'smooth' });
    setBStart((children - 1) * b_size);
    currentPage = children;
    history.push({
      search: qs.stringify({
        b_start: b_size * (children - 1),
      }),
    });
  };

  // news requests
  useEffect(() => {
    dispatch(
      getAnnouncements(getBaseUrl(location.pathname), annFilter, {
        b_size: b_size,
        b_start: b_start,
      })
    );
  }, [dispatch, b_start, annFilter]);

  return (
    <Container id="announcements-list">
      <SkipToMainContent />
      <PageHeader content={content} showdates={false} />
      {content.image && (
        <Image
          className="document-image"
          src={content.image.scales.thumb.download}
          floated="right"
        />
      )}
      <div className="d-flex justify-content-end mb-3" ref={listingRef}>
        <Button
          color="primary"
          size="sm"
          outline={annFilter !== 'active'}
          title={intl.formatMessage(messages.title_active)}
          onClick={e => onFilterChange('active')}
          className="ml-2"
        >
          {intl.formatMessage(messages.active)}
        </Button>{' '}
        <Button
          color="primary"
          outline={annFilter !== 'expired'}
          size="sm"
          title={intl.formatMessage(messages.title_expired)}
          onClick={e => onFilterChange('expired')}
          className="ml-2"
        >
          {intl.formatMessage(messages.expired)}
        </Button>{' '}
        <Button
          size="sm"
          color="primary"
          outline={annFilter !== 'all'}
          title={intl.formatMessage(messages.title_all)}
          onClick={e => onFilterChange('all')}
          className="ml-2"
        >
          {intl.formatMessage(messages.all)}
        </Button>
      </div>
      <Row className="mb-4">
        {!loading
          ? itemsList?.map(item => (
              <Col xs={12} lg={4} key={item['@id']} className="d-flex">
                <AnnouncementListItem item={item} />
              </Col>
            ))
          : Array.from(Array(6).keys()).map((item, index) => (
              <Col xs={12} lg={4} key={index}>
                <div className="skeleton-template">
                  <AnnouncementListItemSkeleton />
                </div>
              </Col>
            ))}
      </Row>
      {totalPages > 1 && (
        <div className="pagination-wrapper">
          <Pagination
            activePage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChage}
          />
        </div>
      )}
      <div className="mb-5">
        <Metadata content={content} />
      </div>
    </Container>
  );
};

export default AnnouncementsList;
