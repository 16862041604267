import getItemIcon_extend from '@arpav/components/ArpavTheme/Icons/common/common_extend';

const getItemIcon = item => {
  const type_icons = {
    Document: 'it-folder',
    Folder: 'it-folder',
    'News Item': 'it-note',
    Event: 'it-calendar',
    Image: 'it-camera',
    File: 'it-clip',
    Link: 'it-link',
    'Plone Site': 'it-folder',
    LRF: 'it-folder',
    Announcement: 'it-pa',
    'Announcement File': 'it-clip',
    Organization: 'it-pa',
    Publication: 'it-note',
  };
  let icon = type_icons[item['@type']] || 'it-pa'; //default-icon

  if (item['@type'] === 'News Item') {
    if (item.design_italia_meta_type === 'Avvisi' || item.design_italia_meta_type === 'Avviso') {
      icon = 'exclamation-triangle';
    }

    if (
      item.design_italia_meta_type === 'Comunicati stampa' ||
      item.design_italia_meta_type === 'Comunicato stampa'
    ) {
      icon = 'it-note';
    }
  }

  let custom_icon = getItemIcon_extend(item);

  return custom_icon || icon;
};

export default getItemIcon;
