import { Chip, ChipLabel } from 'design-react-kit/dist/design-react-kit';
import { defineMessages, useIntl } from 'react-intl';

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { RelatedItems } from '@arpav/components/ArpavTheme/View';
import cx from 'classnames';
import { viewDate } from '@arpav/helpers';

const messages = defineMessages({
  other_info: {
    id: 'other_info',
    defaultMessage: 'Ulteriori informazioni',
  },
  modified: {
    id: 'modified',
    defaultMessage: 'Ultimo aggiornamento',
  },
  rights: {
    id: 'rights',
    defaultMessage: 'Diritti',
  },
  searchFor: {
    id: 'Search for {label}',
    defaultMessage: 'Search for {label}',
  },
  subjects: {
    id: 'subjects',
    defaultMessage: 'Tags',
  },
});

/**
 * Metadata view component class.
 * @function Metadata
 * @params {object} content: Content object.
 * @returns {string} Markup of the component.
 */
const Metadata = ({
  content,
  showTags = true,
  showRelatedItems = true,
  noMargin = false,
  children,
}) => {
  const intl = useIntl();
  return (
    <article id="metadata" className={cx('it-page-section', { 'mt-5': !noMargin })}>
      {children}
      {!content.hide_modification_date ? (
        <>
          <p className="text-serif mb-0 mt-4">{intl.formatMessage(messages.modified)}</p>
          <strong>{viewDate(intl.locale, content.modified, 'DD-MM-Y HH:mm')}</strong>
        </>
      ) : (
        ``
      )}
      {content.rights && (
        <>
          <p className="text-serif mb-0 mt-4">{intl.formatMessage(messages.rights)}</p>
          <strong>{content.rights}</strong>
        </>
      )}
      {showTags && content.subjects?.length > 0 && (
        <>
          <p className="text-serif mt-4">{intl.formatMessage(messages.subjects)}</p>
          {content.subjects?.map((item, i) => (
            <Chip
              color=""
              disabled={false}
              large={false}
              simple
              tag={Link}
              key={item + i}
              className="mr-2"
              title={intl.formatMessage(messages.searchFor, {
                label: item,
              })}
              to={`/search?Subject=${item}`}
            >
              <ChipLabel tag="span">{item}</ChipLabel>
            </Chip>
          ))}
        </>
      )}
      {showRelatedItems && <RelatedItems content={content} />}
    </article>
  );
};
export default Metadata;

Metadata.propTypes = {
  content: PropTypes.object,
  showTags: PropTypes.bool,
  noMargin: PropTypes.bool,
  title: PropTypes.string,
};
