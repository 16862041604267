import loadable from '@loadable/component';

export const loadables = {
  GoogleReCaptcha: loadable(() => import('react-google-recaptcha-v3')),
  tinymce: loadable.lib(() => import('tinymce/tinymce')),
  tinyMceAdvlist: loadable.lib(() => import('tinymce/plugins/advlist')),
  tinyMceAutolink: loadable.lib(() => import('tinymce/plugins/autolink')),
  tinyMceLists: loadable.lib(() => import('tinymce/plugins/lists')),
  tinyMceLink: loadable.lib(() => import('tinymce/plugins/link')),
  tinyMceImage: loadable.lib(() => import('tinymce/plugins/image')),
  tinyMceCharmap: loadable.lib(() => import('tinymce/plugins/charmap')),
  tinyMcePrint: loadable.lib(() => import('tinymce/plugins/print')),
  tinyMcePreview: loadable.lib(() => import('tinymce/plugins/preview')),
  tinyMceAnchor: loadable.lib(() => import('tinymce/plugins/anchor')),
  tinyMceSearchreplace: loadable.lib(() => import('tinymce/plugins/searchreplace')),
  tinyMceVisualblocks: loadable.lib(() => import('tinymce/plugins/visualblocks')),
  tinyMceCode: loadable.lib(() => import('tinymce/plugins/code')),
  tinyMceFullsceen: loadable.lib(() => import('tinymce/plugins/fullscreen')),
  tinyMceInsertdatetime: loadable.lib(() => import('tinymce/plugins/insertdatetime')),
  tinyMceMedia: loadable.lib(() => import('tinymce/plugins/media')),
  tinyMceTable: loadable.lib(() => import('tinymce/plugins/table')),
  tinyMcePaste: loadable.lib(() => import('tinymce/plugins/paste')),
  tinyMceHelp: loadable.lib(() => import('tinymce/plugins/help')),
  tinyMceWordcount: loadable.lib(() => import('tinymce/plugins/wordcount')),
  tinyMceDefaultIcons: loadable.lib(() => import('tinymce/icons/default/icons')),
  tinyMceThemeSilver: loadable.lib(() => import('tinymce/themes/silver')),
};
