import { Alert, Col, Container, Row } from 'design-react-kit/dist/design-react-kit';
import { ListingImage, ListingLinkMore } from '@arpav/components/ArpavTheme';
import { defineMessages, useIntl } from 'react-intl';

import GridGalleryPreview from '@arpav/components/ArpavTheme/Blocks/Listing/GridGalleryPreview';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { flattenToAppURL } from '@plone/volto/helpers';
import { useState } from 'react';

const messages = defineMessages({
  maxItemsExceeded: {
    id: 'grid-gallery-max-items-exceeded',
    defaultMessage:
      'Per questo template il numero di risultati per pagina deve essere 7. Controlla le impostazioni.',
  },
});

const GridGalleryTemplate = ({ items, isEditMode, title, linkTitle, linkHref, block }) => {
  const intl = useIntl();
  const [viewImageIndex, setViewImageIndex] = useState(null);

  return (
    <div className="grid-gallery-template">
      <Container>
        {title && (
          <Row>
            <Col>
              <h2 className="mb-4">{title}</h2>
            </Col>
          </Row>
        )}

        {isEditMode && items.length > 7 && (
          <Alert color="danger">{intl.formatMessage(messages.maxItemsExceeded)}</Alert>
        )}
        <div className="grid-gallery-grid">
          {items.map((item, index) => {
            let image = ListingImage({
              item,
              useOriginal: true,
              className: '',
            });
            const field_image = item.image_scales?.[item.image_field]?.[0];
            const src = field_image?.scales?.large?.download || field_image?.download;

            if (src)
              image = (
                <picture className="volto-image">
                  <img
                    src={flattenToAppURL(`${item.getURL}/${src}`)}
                    alt=""
                    role="presentation"
                    loading="lazy"
                    aria-hidden="true"
                    style={{ width: '100%', objectFit: 'cover' }}
                  />
                </picture>
              );

            return (
              <div
                key={item['@id'] ?? index}
                className={cx('grid-gallery-item', `item-${index % 7}`)}
              >
                <a
                  href={isEditMode ? '#' : null}
                  onClick={e => {
                    e.preventDefault();
                    if (!isEditMode) setViewImageIndex(index);
                  }}
                >
                  {image && image}
                  <h3>{item.title}</h3>
                </a>
              </div>
            );
          })}
        </div>
        <ListingLinkMore title={linkTitle} href={linkHref} className="my-5" />
      </Container>
      <GridGalleryPreview
        id={`image-gallery-${block}`}
        viewIndex={viewImageIndex}
        setViewIndex={setViewImageIndex}
        items={items}
      />
    </div>
  );
};

GridGalleryTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkTitle: PropTypes.any,
  linkHref: PropTypes.any,
  isEditMode: PropTypes.bool,
  title: PropTypes.string,
};

export default GridGalleryTemplate;
