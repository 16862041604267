import { Dates, RichText, RichTextArticle } from '@arpav/components/ArpavTheme/View';
import { defineMessages, useIntl } from 'react-intl';

import PropTypes from 'prop-types';

const messages = defineMessages({
  date_e_orari: {
    id: 'date_e_orari',
    defaultMessage: 'Date e orari',
  },
  orari: {
    id: 'orari',
    defaultMessage: 'Orari',
  },
});

const EventoDateOrari = ({ content }) => {
  const intl = useIntl();

  return (
    <RichTextArticle
      tag_id="date-e-orari"
      className="page-side-menu"
      title={intl.formatMessage(messages.date_e_orari)}
    >
      <Dates content={content} />

      <RichText title={intl.formatMessage(messages.orari)} content={content?.orari} />
    </RichTextArticle>
  );
};

EventoDateOrari.propTypes = {
  content: PropTypes.object.isRequired,
};

export default EventoDateOrari;
