const defaultPanelConfig = {
  last_updated: '2022-03-02T12:07:00+00:00', //it is used to know whether to resubmit the banner to the user if the choices have changed.

  text: {
    //Text that is shown when the banner appears
    it: {
      title: 'Usiamo i cookies',
      description: `Questo Sito utilizza cookie tecnici di sessione per garantire
        il servizio di navigazione del Sito, nonchè cookie tecnici analitici per
        raccogliere informazioni sull'uso del Sito da parte degli utenti.
        La navigazione può comportare la ricezione di cookie tecnici e di profilazione
        di terze parti, per assicurare la migliore esperienza di navigazione e permettere
        di visualizzare alcuni contenuti disponibili su Youtube, direttamente all'interno del Sito.
        L'utente può decidere di abilitare o meno i cookie di profilazione, cliccando sul pulsante
        "Cambia le impostazioni". Per saperne di più l'utente può consultare la nostra
        <a href="/cookie-policy">Cookie Policy.</a>.
      `,
    },
    en: {
      title: 'We use cookies',
      description: '',
    },
    fr: {
      title: 'We use cookies',
      description: '',
    },
  },

  //technical cookies configurations
  technical: {
    //main title and text for the technical cookies column in banner-cookies-settings
    text: {
      it: {
        title: 'Cookie tecnici',
        description: `Sono utilizzati cookie tecnici per garantire il servizio di navigazione del Sito:
          i cookie tecnici "necessari" consentono l'esplorazione sicura ed efficiente del sito;
          i cookie tecnici "analitici" analizzano il traffico da e verso il Sito e raccolgono
          informazioni di navigazione degli utenti.
          A tale fine vengono utilizzati esclusivamente dati aggregati e anonimi, a scopo statistico,
          dai quali non si potrà risalire all'identità.`,
      },
      en: {
        title: 'Technical cookies',
        description: '',
      },
      fr: {
        title: 'Cookies techniques',
        description: '',
      },
    },

    //technical cookies
    choices: [
      // {
      //   config_key: 'GANALYTICS', //reference to config.gdprPrivacyConfig keys
      //   text: {
      //     it: {
      //       title: 'Google Analytics',
      //       description:
      //         "I cookie di Google Analytics sono usati per analizzare la navigazione sul sito al fine di migliorarla e fornire all'utente un'esperienza di navigazione migliore possibile.",
      //     },
      //     en: {
      //       title: 'Google Analytics',
      //       description:
      //         'Google Analytics cookies are used to analyze navigation on the site in order to improve it and provide the user with the best possible browsing experience.',
      //     },
      //     fr: {
      //       title: 'Google Analytics',
      //       description:
      //         "Les cookies de Google Analytics sont utilisés pour analyser la navigation sur le site afin de l'améliorer et offrir à l'utilisateur la meilleure expérience de navigation possible.",
      //     },
      //   },
      // },
    ],
  },

  //profiling cookies configuration
  profiling: {
    text: {
      it: {
        title: 'Cookie di profilazione di terze parti',
        description: `La navigazione di questo Sito può comportare la ricezione di cookie
          di profilazione di terze parti, per permettere di visualizzare
          alcuni contenuti disponibili su Youtube direttamente dal Sito.`,
      },
      en: {
        title: 'Profiling cookies',
        description: '',
      },
      fr: {
        title: 'Cookies de profilage',
        description: '',
      },
    },

    choices: [
      {
        config_key: 'YOUTUBE',
        referenceUrls: ['youtube.com', 'youtube-nocookie.com', 'youtu.be'],
        text: {
          it: {
            title: 'YouTube',
            description: `I cookie di profilazione di Youtube potrebbero creare profili
              personalizzati relativi all'utente, in base ai quali possono essere
              visualizzati messaggi di pubblicità in linea con le preferenze
              dell'utente cui può essere facilitato l'accesso ai servizi di Google.`,
            //text to show in conditional embed if that cookies are not enabled
            conditional_embed_text: 'Per vedere il video, accetta i cookie di Youtube.',
          },
          en: {
            title: 'Youtube',
            description: '',
            //text to show in conditional embed if that cookies are not enabled
            conditional_embed_text: 'To view the video, please accept Youtube cookies.',
          },
          fr: {
            title: 'Youtube',
            description: '',
            //text to show in conditional embed if that cookies are not enabled
            conditional_embed_text: 'Pour voir la vidéo, veuillez accepter les cookies Youtube.',
          },
        },
      },
      // {
      //   config_key: 'GOOGLEMAPS',
      //   referenceUrls: ['google.com/maps'],
      //   text: {
      //     it: {
      //       title: 'Google Maps',
      //       description:
      //         "I cookie di profilazione di Google permettono di fare analisi di accesso alla pagina e sul comportamento dell'utente, e di mostrarti le pubblicità che potrebbero interessarti di più.",
      //       //text to show in conditional embed if that cookies are not enabled
      //       conditional_embed_text: 'Per vedere la mappa, accetta i cookie di Google Maps.',
      //     },
      //     en: {
      //       title: 'Google Maps',
      //       description:
      //         'Google profiling cookies allow you to analyze page access and user behavior, and to show you the advertisements that may interest you the most.',
      //       //text to show in conditional embed if that cookies are not enabled
      //       conditional_embed_text: 'To view map, please accept Google Maps cookies.',
      //     },
      //     fr: {
      //       title: 'Google Maps',
      //       description:
      //         "Les cookies de profilage Google vous permettent d'analyser l'accès aux pages et le comportement des utilisateurs, et de vous montrer les publicités qui pourraient vous intéresser le plus.",
      //       //text to show in conditional embed if that cookies are not enabled
      //       conditional_embed_text:
      //         'Pour afficher la carte, veuillez accepter les cookies de Google Maps.',
      //     },
      //   },
      // },
      // {
      //   config_key: 'FACEBOOKPIXEL',
      //   text: {
      //     it: {
      //       title: 'Facebook Pixel',
      //       description:
      //         "I cookie di Facebook Pixel consentono di misurare, ottimizzare le campagne e le offerte di prodotti raccogliendo dati di profilazione dell'utente quali ad esempio sesso, età, interessi e altro.",
      //     },
      //     en: {
      //       title: 'Facebook Pixel',
      //       description:
      //         'Facebook Pixel cookies allow you to measure and optimize campaigns and product offers by collecting user profiling data such as gender, age, interests and more.',
      //     },
      //   },
      // },
      // {
      //   config_key: 'FACEBOOK',
      //   //url releated to this cookies, used to conditionally embed iframes
      //   referenceUrls: ['facebook.com', 'facebook.it'],
      //   text: {
      //     it: {
      //       title: 'Facebook',
      //       description: 'Cookie di profilazione correlati a Facebook. ',
      //       conditional_embed_text:
      //         'Per vedere i contenuti di Facebook, accetta i cookie relativi a Facebook.',
      //     },
      //     en: {
      //       title: 'Facebook',
      //       description: 'Related Facebook profiling cookies. ',
      //       conditional_embed_text:
      //         'To view Facebook content, please accept Facebook cookies.',
      //     },
      //   },
      // },
      // {
      //   config_key: 'JUICER',
      //   referenceUrls: ['juicer.com'],
      //   text: {
      //     it: {
      //       title: 'Juicer',
      //       description: 'Cookie di profilazione correlati a Juicer.',
      //       conditional_embed_text:
      //         'Per vedere il contenuto da Juicer, accetta i cookie di Juicer.',
      //     },
      //     en: {
      //       title: 'Juicer',
      //       description: 'Related profiling Juicer cookies.',
      //       conditional_embed_text:
      //         'To view the Juicer content, please accept Juicer cookies.',
      //     },
      //   },
      // },
    ],
  },
};

export default defaultPanelConfig;
