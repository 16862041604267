import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Container,
} from 'design-react-kit/dist/design-react-kit';

const Skeleton = () => {
  return (
    <Container>
      <div className="skeleton-template">
        <div className="card-wrapper card-teaser-wrapper card-teaser-wrapper-equal card-teaser-block-2 mb-3">
          {[0, 1, 2, 3].map(i => (
            <Card className="align-items-top rounded shadow" noWrapper teaser key={i}>
              <CardBody className="pb-5">
                <CardTitle tag="h5"> </CardTitle>
                <CardText> </CardText>
              </CardBody>
            </Card>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default Skeleton;
