/**
 * Event subscription.
 * @module components/ArpavTheme/EventSubscription/EventSubscription
 */

import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'design-react-kit/dist/design-react-kit';
import { Confirm, Dimmer, Loader } from 'semantic-ui-react';
import { FormValidation, asyncConnect, getBaseUrl } from '@plone/volto/helpers';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { Icon, NotFound, Toast, UniversalLink } from '@plone/volto/components';
import { PageHeader, SkipToMainContent } from '@arpav/components/ArpavTheme/View';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  createEventBooking,
  deleteEventBooking,
  getEventBooking,
  resetEventBooking,
  updateEventBooking,
} from '@arpav/actions';
import { find, keys } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import EventSubscriptionForm from '@arpav/components/ArpavTheme/EventSubscription/EventSubscriptionForm';
import { GoogleReCaptchaWidget } from '@arpav/components';
import aheadSVG from '@plone/volto/icons/ahead.svg';
import backSVG from '@plone/volto/icons/back.svg';
import clearSVG from '@plone/volto/icons/clear.svg';
import { compose } from 'redux';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import { listActions } from '@plone/volto/actions';
import qs from 'query-string';
import saveSVG from '@plone/volto/icons/save.svg';
import { withRouter } from 'react-router-dom';

const messages = defineMessages({
  save: {
    id: 'Save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'Cancel',
    defaultMessage: 'Cancel',
  },
  messageCreate: {
    id: 'Reservation are been added',
    defaultMessage: 'Reservation are been added',
  },
  errorCreate: {
    id: 'Error on adding reservation',
    defaultMessage: 'Error on adding reservation',
  },
  messageUpdate: {
    id: 'Reservation are been updated',
    defaultMessage: 'Reservation are been updated',
  },
  errorUpdate: {
    id: 'Error on updating reservation',
    defaultMessage: 'Error on updating reservation',
  },
  deleteConfirm: {
    id: 'Do you really want to delete the following reservation?',
    defaultMessage: 'Do you really want to delete the following reservation?',
  },
  messageDelete: {
    id: 'Reservation are been deleted',
    defaultMessage: 'Reservation are been deleted',
  },
  errorDelete: {
    id: 'Error on deleting reservation',
    defaultMessage: 'Error on deleting reservation',
  },
  test: {
    id: 'Test',
    defaultMessage: 'Test',
  },
});

const twekSchema = (schema, data, shifts, onBlurField, onClickInput) => {
  if (schema?.properties) {
    keys(schema.properties || {}).forEach(field => {
      schema.properties[field].onBlur = onBlurField;
      schema.properties[field].onClick = onClickInput;
    });
    if (data.shift && data.date) {
      schema.properties.places_number.maximum = shifts.find(
        item => `${item.start}-${item.end}` === data.shift && data.date === item.date
      )?.places_available;
    }
  }
  return schema;
};

const submit = (intl, schema, data) => {
  const errors = FormValidation.validateFieldsPerFieldset({
    schema: schema,
    formData: data,
    formatMessage: intl.formatMessage,
  });
  if (keys(errors).length > 0) {
    return convertErrors(schema.properties, errors);
  } else {
    return true;
  }
};

const convertErrors = (properties, errors) => {
  const errors_copy = {
    ...errors,
  };
  const errs = {};
  keys(properties).forEach(k => {
    if (errors_copy[properties[k].title]) {
      errs[k] = errors_copy[properties[k].title];
      delete errors_copy[properties[k].title];
    }
  });
  return {
    ...errs,
    ...errors_copy,
  };
};

const EventSubscription = ({
  content,
  id,
  intl,
  history,
  objectActions,
  open,
  pathname,
  returnUrl,
  setOpen,
  toastify,
  token,
}) => {
  const loading = useSelector(
    state =>
      state.eventBooking.create?.loading ||
      state.eventBooking.delete?.loading ||
      state.eventBooking.get?.loading ||
      state.eventBooking.update?.loading
  );
  const error = useSelector(state => state.eventBooking.get?.error);
  const createState = useSelector(state => state.eventBooking.create || {});
  const updateState = useSelector(state => state.eventBooking.update || {});
  const deleteState = useSelector(state => state.eventBooking.delete || {});
  const schema = useSelector(state => state.eventBooking.data?.schema);
  const shifts = useSelector(state => state.eventBooking.data?.shifts);

  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [isFormPristine, setIsFormPristine] = useState(true);
  const [validToken, setValidToken] = useState(null);
  const [showDelete, setShowDelete] = useState(false);

  let initAction = pathname?.length > 1 ? pathname.replace(/\//g, '') : pathname;
  if (initAction?.length > 0) {
    initAction = initAction?.replace(/-/g, '_');
  } else {
    initAction = 'homepage';
  }
  const [action, setAction] = useState(initAction);

  const dispatch = useDispatch();

  const captcha = process.env.RAZZLE_RECAPTCHA_KEY ? 'GoogleReCaptcha' : null;
  const editPermission = find(objectActions, { id: 'edit' });
  const notShift = !loading && !shifts?.length;

  const onClose = () => {
    if (setOpen) setOpen(false);
    else history.push(returnUrl || getBaseUrl(pathname));
  };

  const onSubmit = event => {
    event.preventDefault();
    const valid = submit(intl, schema, data);
    if (valid === true) {
      if (id) {
        dispatch(
          updateEventBooking(getBaseUrl(pathname), id, {
            ...data,
            'g-recaptcha-response': validToken,
          })
        );
      } else {
        dispatch(
          createEventBooking(getBaseUrl(pathname), { ...data, 'g-recaptcha-response': validToken })
        );
      }
    } else {
      setErrors(valid);
    }
  };

  const onTest = () => {
    const valid = submit(intl, schema, data);
    if (valid === true) {
      dispatch(
        createEventBooking(getBaseUrl(pathname), {
          ...data,
          'g-recaptcha-response': validToken,
          test: true,
        })
      );
    } else {
      setErrors(valid);
    }
  };

  /**
   * If user clicks on input, the form will be not considered pristine
   * this will avoid onBlur effects without interraction with the form
   */
  const onClickInput = () => setIsFormPristine(false);

  /**
   * Validate fields on blur
   * @method onBlurField
   * @param {string} id Id of the field
   * @param {*} value Value of the field
   * @returns {undefined}
   */
  const onBlurField = (id, value) => {
    if (!isFormPristine) {
      const new_errors = FormValidation.validateFieldsPerFieldset({
        schema: schema,
        formData: data,
        formatMessage: intl.formatMessage,
        touchedField: { [id]: value },
      });

      setErrors(convertErrors(schema.properties, new_errors));
    }
  };

  const onVerifyCaptcha = useCallback(
    token => {
      if ((open || id) && !validToken) {
        setValidToken(token);
      }
    },
    [setValidToken, validToken, open, id]
  );

  const onDelete = () => {
    if (id) {
      setShowDelete(true);
    }
  };

  const onDeleteCancel = () => {
    setShowDelete(false);
  };

  const onDeleteOk = () => {
    dispatch(deleteEventBooking(getBaseUrl(pathname), id));
  };

  useEffect(() => {
    setValidToken(null);
    if ((open || id) && pathname) {
      dispatch(getEventBooking(getBaseUrl(pathname), id));
    }
  }, [dispatch, pathname, open, id]);

  useEffect(() => {
    if (id && shifts?.length) {
      const shift = shifts[0];
      setData({
        date: shift.date,
        shift: `${shift.start}-${shift.end}`,
        ...shift.participants[0],
      });
    }
  }, [id, shifts, pathname]);

  useEffect(() => {
    if (open || id) {
      if (!updateState.loading && updateState.loaded && !updateState.error) {
        history.push(returnUrl || getBaseUrl(pathname));
        dispatch(resetEventBooking());
        toastify.toast.success(
          <Toast
            success
            title={intl.formatMessage({ id: 'Success' })}
            content={intl.formatMessage(messages.messageUpdate)}
          />
        );
      } else if (!updateState.loading && updateState.error) {
        toastify.toast.error(
          <Toast
            error
            title={intl.formatMessage({
              id: 'Error',
            })}
            content={intl.formatMessage(messages.errorUpdate)}
          />
        );
      }
    }
  }, [updateState.loading]);

  useEffect(() => {
    if (!createState.loading && createState.loaded && !createState.error) {
      onClose();
      setData({});
      setErrors({});
      toastify.toast.success(
        <Toast
          success
          title={intl.formatMessage({ id: 'Success' })}
          content={intl.formatMessage(messages.messageCreate)}
        />
      );
      dispatch(resetEventBooking());
    } else if (!createState.loaded && createState.error) {
      toastify.toast.error(
        <Toast
          error
          title={intl.formatMessage({
            id: 'Error',
          })}
          content={intl.formatMessage(messages.errorCreate)}
        />
      );
    }
  }, [createState.loading]);

  useEffect(() => {
    if (!deleteState.loading && deleteState.loaded && !deleteState.error) {
      history.push(returnUrl || getBaseUrl(pathname));
      dispatch(resetEventBooking());
      toastify.toast.success(
        <Toast
          success
          title={intl.formatMessage({ id: 'Success' })}
          content={intl.formatMessage(messages.messageDelete)}
        />
      );
    } else if (!deleteState.loading && deleteState.error) {
      toastify.toast.error(
        <Toast
          error
          title={intl.formatMessage({
            id: 'Error',
          })}
          content={intl.formatMessage(messages.errorDelete)}
        />
      );
    }
  }, [deleteState.loading]);

  useEffect(() => {
    return () => dispatch(resetEventBooking());
  }, []);

  useEffect(() => {
    if (pathname) {
      let newAction = pathname?.length > 1 ? pathname.replace(/\//g, '') : pathname;
      if (newAction?.length > 0) {
        newAction = newAction?.replace(/-/g, '_');
      } else {
        newAction = 'homepage';
      }
      setAction(newAction);
    }
  }, [pathname]);

  const twekedSchema = twekSchema({ ...(schema || {}) }, data, shifts, onBlurField, onClickInput);

  const form = (
    <EventSubscriptionForm
      loading={loading}
      data={data}
      error={error}
      errors={errors}
      schema={twekedSchema}
      onSubmit={onSubmit}
      onChangeField={(id, value) => {
        setData({
          ...data,
          [id]: value,
        });

        if (id === 'date') {
          if (shifts) {
            const shiftFieldIndex = schema.fieldsets[0].fields.indexOf('shift');
            setData({
              ...data,
              [id]: value,
              shift: null,
            });
            if (value) {
              const shiftField = schema.properties.shift;
              if (shiftFieldIndex === -1) {
                schema.fieldsets[0].fields.splice(1, 0, 'shift');
              }
              shiftField.choices = (shifts || [])
                .filter(item => item.places_available > 0 && item.date == value)
                .map(item => [`${item.start}-${item.end}`, `${item.start} ${item.end}`]);
            } else {
              if (shiftFieldIndex !== -1) {
                schema.fieldsets[0].fields.splice(shiftFieldIndex, 1);
              }
            }
          }
        } else if (id === 'shift' && data.date) {
          if (value) {
            schema.properties.places_number.maximum = (shifts || []).find(
              item => `${item.start}-${item.end}` === value && item.date === data.date
            )?.places_available;
          }
        }
      }}
    />
  );

  return content?.['@type'] === 'Event' && content?.booking_required && !error ? (
    <>
      {id && (loading || shifts?.length) ? (
        <div className="public-ui">
          <Container className="my-4 newsitem-view">
            <div className="px-4 px-lg-0">
              <SkipToMainContent />
              <PageHeader
                content={content}
                readingtime={null}
                showreadingtime={true}
                showdates={true}
                showtassonomiaargomenti={true}
              />
              <Confirm
                open={showDelete}
                header={intl.formatMessage(messages.deleteConfirm)}
                onCancel={onDeleteCancel}
                onConfirm={onDeleteOk}
                size="mini"
              />
              <Row className="border-top row-column-border row-column-menu-left">
                <Col
                  tag="section"
                  lg={12}
                  id="main-content-section"
                  className="it-page-sections-container pr-lg-0"
                >
                  <div className="mt-3">
                    <UniversalLink
                      className="ml-2"
                      href={returnUrl || getBaseUrl(pathname)}
                      title={content.title || content.id}
                    >
                      <Icon name={backSVG} size="30px" />
                    </UniversalLink>
                    <div className="cms-ui">
                      <Dimmer active={loading}>
                        <Loader>
                          <FormattedMessage id="Loading" defaultMessage="Loading." />
                        </Loader>
                      </Dimmer>
                    </div>
                    <div className="mt-5">
                      {form}
                      <Button
                        icon
                        color="primary"
                        className="float-right mr-3 mr-lg-4"
                        aria-label={intl.formatMessage(messages.save)}
                        title={intl.formatMessage(messages.save)}
                        onClick={onSubmit}
                        disabled={captcha && !validToken}
                      >
                        <Icon name={saveSVG} className="contents" size="24px" />
                      </Button>
                      {onDelete && (
                        <Button
                          icon
                          color="danger"
                          aria-label={intl.formatMessage(messages.cancel)}
                          title={intl.formatMessage(messages.cancel)}
                          className="float-right mr-3"
                          onClick={onDelete}
                        >
                          <Icon name={clearSVG} size="24px" />
                        </Button>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      ) : !id ? (
        <Modal
          isOpen={open}
          wrapClassName="public-ui"
          size="xl"
          toggle={onClose}
          backdrop="static"
          centered={true}
          scrollable={true}
        >
          <ModalHeader toggle={onClose}>{twekedSchema?.title}</ModalHeader>
          <div className="cms-ui">
            <Dimmer active={loading}>
              <Loader>
                <FormattedMessage id="Loading" defaultMessage="Loading." />
              </Loader>
            </Dimmer>
          </div>
          <ModalBody>
            {!loading && shifts?.length ? (
              form
            ) : notShift ? (
              <div>
                <FormattedMessage id="No shift available" defaultMessage="No shift available" />
              </div>
            ) : null}
          </ModalBody>
          <ModalFooter>
            {token && editPermission && (
              <Button
                color="secondary"
                className="mr-2"
                aria-label={intl.formatMessage(messages.test)}
                title={intl.formatMessage(messages.test)}
                onClick={onTest}
              >
                {intl.formatMessage(messages.test)}
              </Button>
            )}
            {onClose && (
              <Button
                icon
                color="danger"
                className="mr-2"
                aria-label={intl.formatMessage(messages.cancel)}
                title={intl.formatMessage(messages.cancel)}
                onClick={onClose}
              >
                <Icon name={clearSVG} size="24px" />
              </Button>
            )}
            <Button
              icon
              color="primary"
              aria-label={intl.formatMessage(messages.save)}
              title={intl.formatMessage(messages.save)}
              onClick={onSubmit}
              // disabled={(captcha && !validToken) || notShift}
              disabled={notShift}
            >
              <Icon name={aheadSVG} className="contents" size="24px" />
            </Button>
          </ModalFooter>
        </Modal>
      ) : id && !loading && !shifts?.length ? (
        <NotFound />
      ) : null}
      {/* <GoogleReCaptchaWidget key={action} onVerify={onVerifyCaptcha} action={action} /> */}
    </>
  ) : (
    <NotFound />
  );
};

export default compose(
  injectIntl,
  asyncConnect([
    {
      key: 'actions',
      promise: async ({ location, store: { dispatch } }) => {
        await dispatch(listActions(getBaseUrl(location.pathname)));
      },
    },
  ]),
  injectLazyLibs(['toastify']),
  withRouter,
  connect((state, props) => ({
    content: state.content.data,
    id: props.match.params.id,
    objectActions: state.actions.actions.object,
    pathname: props.location.pathname,
    returnUrl: qs.parse(props.location.search).return_url,
    token: state.userSession.token,
  }))
)(EventSubscription);
