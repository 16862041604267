/**
 * TinymceWidget container.
 * @module components/manage/Widgets/TinymceWidget
 */

import { Component, useEffect, useState } from 'react';
import { Form, Label, TextArea } from 'semantic-ui-react';

import { Editor } from '@tinymce/tinymce-react';
import { FormFieldWrapper } from '@plone/volto/components';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import contentCss from 'tinymce/skins/content/default/content.css';
import contentUiSkinCss from 'tinymce/skins/ui/oxide/content.min.css';
import { injectIntl } from 'react-intl';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import loadable from '@loadable/component';
import { map } from 'lodash';
import oxideSkin from 'tinymce/skins/ui/oxide/skin.min.css';

const tinymceLoadable = loadable(() => import('tinymce/tinymce'));

/**
 * TinymceWidget container class.
 * @class TinymceWidget
 * @extends Component
 */
class TinymceWidgetComponent extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    /**
     * Id of the field
     */
    id: PropTypes.string.isRequired,
    /**
     * Title of the field
     */
    title: PropTypes.string.isRequired,
    /**
     * Description of the field
     */
    description: PropTypes.string,
    /**
     * True if field is required
     */
    required: PropTypes.bool,
    /**
     * Value of the field
     */
    value: PropTypes.shape({
      /**
       * Content type of the value
       */
      'content-type': PropTypes.string,
      /**
       * Data of the value
       */
      data: PropTypes.string,
      /**
       * Encoding of the value
       */
      encoding: PropTypes.string,
    }),
    /**
     * List of error messages
     */
    error: PropTypes.arrayOf(PropTypes.string),
    /**
     * On change handler
     */
    onChange: PropTypes.func,
  };

  /**
   * Default properties
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    description: null,
    required: false,
    value: null,
    error: [],
    onChange: null,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs TinymceWidget
   */
  constructor(props) {
    super(props);

    this.state = { editorState: props.value?.data || '' };

    this.schema = {
      fieldsets: [
        {
          id: 'default',
          title: props.intl.formatMessage({ id: 'Default' }),
          fields: ['title', 'id', 'description', 'required'],
        },
      ],
      properties: {
        id: {
          type: 'string',
          title: props.intl.formatMessage({
            id: 'Short Name',
          }),
          description: props.intl.formatMessage({
            id: 'Used for programmatic access to the fieldset.',
          }),
        },
        title: {
          type: 'string',
          title: props.intl.formatMessage({ id: 'Title' }),
        },
        description: {
          type: 'string',
          widget: 'textarea',
          title: props.intl.formatMessage({ id: 'Ditle' }),
        },
        required: {
          type: 'boolean',
          title: props.intl.formatMessage({ id: 'Required' }),
        },
      },
      required: ['id', 'title'],
    };

    this.onChange = this.onChange.bind(this);
  }

  /**
   * Change handler
   * @method onChange
   * @param {object} editorState Editor state.
   * @returns {undefined}
   */
  onChange(editorState) {
    this.setState({ editorState });
    this.props.onChange(this.props.id, {
      ...this.props.value,
      data: editorState,
    });
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { id, title, description, required, value, error, fieldSet, language } = this.props;

    if (__SERVER__) {
      return (
        <Form.Field
          inline
          required={required}
          error={error.length > 0}
          className={description ? 'help' : ''}
          id={`${fieldSet || 'field'}-${id}`}
        >
          <div className="wrapper">
            <label htmlFor={`field-${id}`}>{title}</label>
            <TextArea id={id} name={id} value={value ? value.data : ''} />
            {description && <p className="help">{description}</p>}
            {map(error, message => (
              <Label key={message} basic color="red" pointing>
                {message}
              </Label>
            ))}
          </div>
        </Form.Field>
      );
    }

    return (
      <FormFieldWrapper {...this.props} className="wysiwyg">
        <div style={{ boxSizing: 'initial' }}>
          {this.props.onChange ? (
            <>
              <Editor
                initialValue={this.state.editorState}
                init={{
                  language_url: `/tinymce_langs/${language}.js`,
                  height: 500,
                  menubar: 'view',
                  external_plugins: {
                    'tt-image': '/tt-image/plugin.min.js',
                  },
                  skin: false,
                  content_css: false,
                  relative_urls: false,
                  remove_script_host: true,
                  formats: {
                    callout: {
                      block: 'p',
                      classes: 'callout',
                    },
                  },
                  init_instance_callback: () => {
                    var freeTiny = document.querySelector('.tox .tox-notification--in');
                    if (freeTiny) {
                      freeTiny.style.display = 'none';
                    }
                  },
                  block_formats: 'callout=callout',
                  forced_root_block: 'p',
                  table_header_type: 'cells',
                  body_class: 'public-ui',
                  table_default_attributes: {
                    border: '1',
                    class: 'ui celled fixed table',
                  },
                  content_style:
                    oxideSkin.toString() +
                    '\n' +
                    contentUiSkinCss.toString() +
                    '\n' +
                    contentCss.toString() +
                    '\n' +
                    '.table th { background-color: #ebeced; padding: 18px!important; } .table td { padding: 18px!important } \
                                  .callout { padding: 1.25rem; margin-top: 1.25rem; margin-bottom: 1.25rem; border: 1px solid #d9dadb; border-left-width: 0.4rem; border-radius: 0.25rem; }',
                  language: this.props.intl.locale,
                  image_class_list: [
                    { title: 'Inline', value: '' },
                    { title: 'Left', value: 'image-left' },
                    { title: 'Right', value: 'image-right' },
                  ],
                  plugins: [
                    'advlist autolink lists link tt-image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount',
                  ],
                  toolbar: [
                    'underline bold italic link tt-image anchor media h2 h3 bullist numlist blockquote formatselect removeformat table',
                    'callout',
                  ],
                }}
                onEditorChange={this.onChange}
              />
            </>
          ) : (
            <div className="DraftEditor-root" />
          )}
        </div>
      </FormFieldWrapper>
    );
  }
}

export const TinymceWidget = compose(
  injectIntl,
  // loade tinymce requires libraries before init
  // defined in config/Loadables/loadables
  injectLazyLibs([
    'tinyMceAdvlist',
    'tinyMceAutolink',
    'tinyMceLists',
    'tinyMceLink',
    'tinyMceImage',
    'tinyMceCharmap',
    'tinyMcePrint',
    'tinyMcePreview',
    'tinyMceAnchor',
    'tinyMceSearchreplace',
    'tinyMceVisualblocks',
    'tinyMceCode',
    'tinyMceFullsceen',
    'tinyMceInsertdatetime',
    'tinyMceMedia',
    'tinyMceTable',
    'tinyMcePaste',
    'tinyMceHelp',
    'tinyMceWordcount',
    'tinyMceDefaultIcons',
    'tinyMceThemeSilver',
  ]),
  connect(
    (state, props) => ({
      language: props?.location?.state?.language || props?.properties?.language?.token || 'it',
      token: state.userSession.token,
    }),
    {}
  )
)(TinymceWidgetComponent);

const Preloader = props => {
  // load tinymce library before render widget
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    tinymceLoadable.load().then(() => {
      setLoaded(true);
    });
  }, []);
  return loaded ? <TinymceWidget {...props} /> : null;
};

export default Preloader;
