import { defineMessages, useIntl } from 'react-intl';

import PropTypes from 'prop-types';
import { UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  view_all: {
    id: 'Vedi tutto',
    defaultMessage: 'Vedi tutto',
  },
});

export const ListingLinkMore = ({ title, href, className = '' }) => {
  const intl = useIntl();
  const url = href?.[0]?.['@id'];

  return url ? (
    <div className={`link-button text-center ${className}`}>
      <UniversalLink href={flattenToAppURL(url)} className="btn">
        <em>{title || intl.formatMessage(messages.view_all)}</em>
      </UniversalLink>
    </div>
  ) : null;
};

ListingLinkMore.propTypes = {
  linkMore: PropTypes.object,
};

export default ListingLinkMore;
