import HomeBannerBody from '@arpav/components/ArpavTheme/Blocks/HomeBanner/HomeBannerBody';

const View = ({ id, path, data }) => {
  return (
    <div className="block homeBanner">
      <div className="public-ui">
        <div className="simple-card-default full-width">
          <HomeBannerBody id={id} path={path} data={data} />
        </div>
      </div>
    </div>
  );
};

export default View;
