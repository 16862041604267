import { TinymceWidget } from '@arpav/components/ArpavTheme';
import { injectIntl } from 'react-intl';

// Note: we use `file_path` instead of `url` because we're dealing with an
// Array. This is a limitation of the current state of the ObjectBrowserWidget

const RichTextEdit = props => {
  const { intl, onChangeBlock, block, data } = props;
  return (
    <div className={`${data['@type']}-edit`}>
      <TinymceWidget
        {...props}
        id="richtext"
        title={intl.formatMessage({ id: 'Text', defaultMessage: 'Text' })}
        onChange={(id, value) => {
          onChangeBlock(block, {
            ...data,
            [id]: value,
          });
        }}
        value={data?.richtext}
      />
    </div>
  );
};

export default injectIntl(RichTextEdit);
