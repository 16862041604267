import ChartDataLabels from 'chartjs-plugin-datalabels';
/**
 * Add ARPAV API to window
 */
import ChartJS from 'chart.js/auto'; // import chartjs with all registered
import { openlayers } from '@eeacms/volto-openlayers-map';

const ArpavApi = () => {
  // import bootstrap and Chart js
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const bootstrap = __CLIENT__ ? require('bootstrap') : {};

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const Chart = ChartJS;

  if (__CLIENT__) {
    window.ARPAVAPI = {
      bootstrap: bootstrap,
      Chart: Chart,
      ChartDataLabels: ChartDataLabels,
      openlayers: openlayers,
    };

    document.dispatchEvent(new Event('arpavapiinit'));
  }

  return <></>;
};

export default ArpavApi;
