/**
 * ThemeListBody view component.
 * @module components/theme/ArpavView/Indicators
 */

import { Col, Container, Row } from 'design-react-kit/dist/design-react-kit';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import IndicatorListBody from '@arpav/components/ArpavTheme/View/Indicators/IndicatorListBody';
import Skeleton from '@arpav/components/ArpavTheme/View/Indicators/Skeleton';
import { UniversalLink } from '@plone/volto/components';
import cx from 'classnames';
import { getBaseUrl } from '@plone/volto/helpers';
import { getContent } from '@plone/volto/actions';
import { injectIntl } from 'react-intl';

/**
 * ThemeListBody view component class.
 * @function ThemeListBody
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const ThemeListBody = ({ even, id, pathname }) => {
  const content = useSelector(state => state.content.subrequests[id]?.data);
  const loading = useSelector(state => state.content.subrequests[id]?.loading);
  const [initialPath] = useState(getBaseUrl(pathname));
  const dispatch = useDispatch();

  useEffect(() => {
    // prevent load content first time
    if (dispatch && initialPath && id) {
      dispatch(getContent(initialPath, null, id));
    }
  }, [id, initialPath]);

  return (
    <div className="public-ui">
      <div
        className={cx('full-width section py-5', {
          'bg-light': even,
        })}
      >
        {loading ? (
          <Skeleton />
        ) : content ? (
          <Container>
            <Row className="mb-4">
              <Col className="text-center template-header">
                <h2 className="mb-4">
                  <UniversalLink item={content} title={content.title || content.id}>
                    {content.title}
                  </UniversalLink>
                </h2>
                {content?.description ? <p>{content.description}</p> : null}
              </Col>
            </Row>

            <Row>
              {content.items?.map(item => (
                <Col xs={12} lg={6} key={item['@id']}>
                  <IndicatorListBody item={item} />
                </Col>
              ))}
            </Row>
          </Container>
        ) : null}
      </div>
    </div>
  );
};

export default injectIntl(ThemeListBody);
