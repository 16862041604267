import { Col, Row } from 'design-react-kit/dist/design-react-kit';
import { Container, Image } from 'semantic-ui-react';
import { Metadata, PageHeader, SkipToMainContent } from '@arpav/components/ArpavTheme/View';
import {
  NewsItemCard,
  NewsItemCardSkeleton,
} from '@arpav/components/ArpavTheme/View/Commons/NewsItemCard';
import { createRef, useEffect, useState } from 'react';

import { Pagination } from '@arpav/components/ArpavTheme';
import config from '@plone/volto/registry';
import { getBaseUrl } from '@plone/volto/helpers';
import { getNewsItemsList } from '@arpav/actions';
import qs from 'query-string';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

const NewsList = ({ content, location, path, id, history }) => {
  const loading = useSelector(state => state.newsItemsList.get?.loading);
  const itemsIni = useSelector(state => state.newsItemsList.data?.items);
  const itemsCount = useSelector(state => state.newsItemsList.data?.items_total);

  const intl = useIntl();
  const listingRef = createRef();

  // pagination variables
  const b_size = config.settings.defaultPageSize;
  const totalPages = Math.ceil(itemsCount / b_size);
  const [b_start, setBStart] = useState(qs.parse(history.location.search).b_start || 0);
  let currentPage = b_start ? Math.floor(b_start / b_size) + 1 : 0;

  const dispatch = useDispatch();

  // news requests
  useEffect(() => {
    dispatch(
      getNewsItemsList(getBaseUrl(location.pathname), { b_size: b_size, b_start: b_start }, id)
    );
  }, [dispatch, path, b_start]);

  /**
   * on page change event
   * @param {Event} e Event
   * @param {Number} children
   */
  const onPageChage = (e, { activePage: { children } }) => {
    listingRef.current.scrollIntoView({ behavior: 'smooth' });
    setBStart((children - 1) * b_size);
    currentPage = children;
    history.push({
      search: qs.stringify({
        b_start: b_size * (children - 1),
      }),
    });
  };

  return (
    <Container id="page-document">
      <SkipToMainContent />
      <PageHeader content={content} showdates={false} />
      {content.image && (
        <Image
          className="document-image"
          src={content.image.scales.thumb.download}
          floated="right"
        />
      )}
      <div ref={listingRef}>
        <Row id="news-list-container">
          {!loading
            ? itemsIni?.map((item, index) => (
                <Col xs={12} lg={4} key={item['@id']}>
                  <NewsItemCard intl={intl} item={item} isEditMode={false} />
                </Col>
              ))
            : Array.from(Array(6).keys()).map((item, index) => (
                <Col xs={12} lg={4} key={item['@id']}>
                  <div className="skeleton-template">
                    <NewsItemCardSkeleton />
                  </div>
                </Col>
              ))}
        </Row>
      </div>
      {totalPages > 1 && (
        <div className="pagination-wrapper">
          <Pagination
            activePage={currentPage || 1}
            totalPages={totalPages}
            onPageChange={onPageChage}
          />
        </div>
      )}
      <div className="mb-5">
        <Metadata content={content} />
      </div>
    </Container>
  );
};

export default NewsList;
