import { Col, Row } from 'design-react-kit/dist/design-react-kit';

import { FormattedDate } from '@plone/volto/components';
import { FormattedMessage } from 'react-intl';
import { Icon } from '@arpav/components/ArpavTheme';
import PropTypes from 'prop-types';
import { options } from '../../../../../../omelette/src/config/RichTextEditor/ToHTML';

const RealTimeItem = ({ item }) => {
  return (
    <div className="pl-5 pr-5">
      <h2 className="mt-2">{item?.provincia}</h2>
      <Row className="mt-4">
        <Col>
          <Icon icon="cloud-rain" size="xl" />
          <p className="mt-2">
            <strong className="mb-1 mt-1">
              <FormattedMessage id="Rainfall" defaultMessage="Rainfall" />
            </strong>
            <br />
            <span className="text-monospace">
              {item?.precipitazioni}
              {item?.precipitazioni_um}
            </span>
          </p>
        </Col>
        <Col>
          <Icon icon="thermometer-half" size="xl" />
          <p className="mt-2">
            <strong className="mb-1 mt-1">
              <FormattedMessage id="Temperature" defaultMessage="Temperature" />
            </strong>
            <br />
            <span className="text-monospace">
              {item?.temperatura}
              {item?.temperatura_um}
            </span>
          </p>
        </Col>
        <Col>
          <Icon icon="flag" size="xl" />
          <p className="mt-2">
            <strong className="mb-1 mt-1">
              <FormattedMessage id="Wind" defaultMessage="Wind" />
            </strong>
            <br />
            <span className="text-monospace">
              {item?.velocitaventokm}
              {item?.velocitaventokm_um}
            </span>
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mt-2">
            <small>
              <FormattedMessage id="Station" defaultMessage="Station" />:{' '}
              <strong>{item?.nome_stazione}</strong>
              <br />
              <span>{item?.dataora}</span>
            </small>
          </div>
        </Col>
      </Row>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
RealTimeItem.propTypes = {
  item: PropTypes.object,
};

export default RealTimeItem;
