import { Alert, Col, Container, Row } from 'design-react-kit/dist/design-react-kit';
import { ContentImage, EnvironmentalThemeRelItems } from '@arpav/components/ArpavTheme/View';
import { PageHeader, SideMenu, SkipToMainContent } from '@arpav/components/ArpavTheme/View';
import { defineMessages, useIntl } from 'react-intl';
import { flattenToAppURL, getParentUrl } from '@plone/volto/helpers';

import ContextNavigation from '@plone/volto/components/theme/Navigation/ContextNavigation';

const messages = defineMessages({
  warningPageVisibleForAuth: {
    id: 'warning-page-visible-for-auth',
    defaultMessage:
      'This page is visible because you are authenticated. Anonymous users are redirect to the parent Environmental theme.',
  },
});

const EnvironmentalTheme = ({ content, token, history, location }) => {
  const intl = useIntl();
  const issimpletheme = !content.subthemes.length;

  let showAuthMessage = false;
  if (content.parent['@type'] === 'Environmental Theme') {
    if (token) {
      showAuthMessage = true;
    } else {
      history.replace(content.parent['@id']);
    }
  }

  return (
    <Container id="page-organization">
      <SkipToMainContent />
      {showAuthMessage && (
        <Alert color="warning">{intl.formatMessage(messages.warningPageVisibleForAuth)}</Alert>
      )}
      <PageHeader content={content} showdates={false} />
      <ContentImage content={content} position="afterHeader" />
      <Row className="border-top row-column-border row-column-menu-left">
        <Col
          tag="section"
          lg={8}
          id="main-content-section"
          className="it-page-sections-container pr-lg-0"
        >
          <div className="mt-3 mt-lg-5 mr-lg-5 ml-lg-3">
            {content.text && (
              <div
                dangerouslySetInnerHTML={{
                  __html: content.text.data,
                }}
              />
            )}
          </div>
        </Col>
        <Col tag="aside" lg={4}>
          {
            <SideMenu
              content={content}
              sections={[
                {
                  component: ContextNavigation,
                  props: {
                    content,
                    params: {
                      includeTop: true,
                      root_path: getParentUrl(flattenToAppURL(content['@id'])),
                    },
                  },
                },
              ]}
            />
          }
        </Col>
      </Row>
      {issimpletheme ? (
        <EnvironmentalThemeRelItems
          showTitle={false}
          itemurl={content['@id']}
          key={content['@id']}
          itemindex={0}
        />
      ) : (
        content.subthemes.map((item, index) => (
          <EnvironmentalThemeRelItems
            key={item['@id']}
            showTitle={true}
            title={item.title}
            summary={item.description}
            itemurl={item['@id']}
            itemindex={index}
          />
        ))
      )}
    </Container>
  );
};

export default EnvironmentalTheme;
