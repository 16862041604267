/**
 * JsonWidget component.
 * @module components/manage/Widgets/JsonWidget
 */

import { Label, TextArea } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';

import { FormFieldWrapper } from '@plone/volto/components';
import PropTypes from 'prop-types';
import { useState } from 'react';

const messages = defineMessages({
  invalidSchema: {
    id: 'Invalid schema. Must comply with the following: {schema}',
    defaultMessage: 'Invalid schema. Must comply with the following: {schema}',
  },
});

const JsonWidgetComponent = props => {
  const { id, intl, isDisabled, onChange, placeholder, schema, Validator, value } = props;
  const [lengthError, setlengthError] = useState('');

  const v = new Validator();

  /**
   * Update date storage
   * @method onTimeChange
   * @param {Object} time updated momentjs Object for time
   * @returns {undefined}
   */
  function onhandleChange(id, value) {
    const json_value = JSON.parse(value || '{}');
    if (schema && !v.validate(json_value, schema).valid) {
      setlengthError(
        intl.formatMessage(messages.invalidSchema, {
          schema: JSON.stringify(schema, null, '  '),
        })
      );
    } else {
      setlengthError('');
    }
    onChange(id, json_value);
  }

  return (
    <FormFieldWrapper {...props}>
      <div className="json-widget-wrapper">
        <div className="ui input json-input">
          <TextArea
            id={`field-${id}`}
            name={id}
            value={JSON.stringify(value || {}, null, '  ')}
            disabled={isDisabled}
            placeholder={placeholder}
            onChange={({ target }) =>
              onhandleChange(id, target.value === '' ? undefined : target.value)
            }
          />
          {lengthError.length > 0 && (
            <Label key={lengthError} basic color="red" pointing>
              {lengthError}
            </Label>
          )}
        </div>
      </div>
    </FormFieldWrapper>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
JsonWidgetComponent.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  isDisabled: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
JsonWidgetComponent.defaultProps = {
  description: null,
  required: false,
  error: [],
  value: null,
};

export const JsonWidget = injectIntl(JsonWidgetComponent);

const Preloader = props => {
  if (__CLIENT__) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const Validator = require('jsonschema').Validator;
    return __CLIENT__ ? <JsonWidget {...props} Validator={Validator} /> : null;
  }
  return null;
};

export default Preloader;
