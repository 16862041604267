export const schemaSummary = props => {
  const { intl } = props;

  return {
    title: intl.formatMessage({
      id: 'Summary',
    }),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['type'],
      },
    ],

    properties: {
      type: {
        title: `${intl.formatMessage({
          id: 'Type',
        })}`,
        widget: 'token',
        widgetOptions: {
          vocabulary: {
            '@id': 'arpav.announcements.vocabularies.announcementfiletypes',
          },
        },
      },
    },
    required: [],
  };
};

export default schemaSummary;
