/**
 * IconPicker component.
 * @module components/manage/Widgets/ColorWidget/IconPicker
 */

import { Accordion, Button } from 'semantic-ui-react';

import { Icon } from '@plone/volto/components';
import IconTab from '@arpav/components/ArpavTheme/manage/Widgets/IconWidget/IconTab';
import PropTypes from 'prop-types';
import clearSVG from '@plone/volto/icons/clear.svg';
import downSVG from '@plone/volto/icons/down-key.svg';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import upSVG from '@plone/volto/icons/up-key.svg';
import { useState } from 'react';

const IconPicker = ({ onClose, onIconChange }) => {
  const iconTabs = [
    {
      title: 'Solid',
      icons: fas,
    },
    {
      title: 'Regular',
      icons: far,
    },
    {
      title: 'Brand',
      icons: fab,
    },
  ];

  const [currentActiveTab, setCurrentActiveTab] = useState(0);

  const handleCurrentActiveTab = (e, blockProps) => {
    const { index } = blockProps;
    const newIndex = currentActiveTab === index ? -1 : index;

    setCurrentActiveTab(newIndex);
  };

  return (
    <div className="iconpopover">
      <div style={{ height: '10px' }}>
        <Button onClick={onClose} className="item noborder" floated="right">
          <Icon name={clearSVG} size="24px" />
        </Button>
      </div>
      <Accordion fluid styled className="form">
        {iconTabs.map((icon, index) => (
          <div key={index}>
            <Accordion.Title
              active={currentActiveTab === index}
              index={index}
              onClick={handleCurrentActiveTab}
            >
              {<>{icon.title}</>}
              {currentActiveTab === index ? (
                <Icon name={upSVG} size="20px" />
              ) : (
                <Icon name={downSVG} size="20px" />
              )}
            </Accordion.Title>
            <Accordion.Content active={currentActiveTab === index}>
              <IconTab
                icons={icon.icons}
                onIconChange={(icon, e) => {
                  onIconChange(icon, e);
                  onClose();
                }}
              />
            </Accordion.Content>
          </div>
        ))}
      </Accordion>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
IconPicker.propTypes = {
  onClose: PropTypes.func.isRequired,
  onIconChange: PropTypes.func.isRequired,
};

export default IconPicker;
