/**
 * HeaderCenter component.
 * @module components/Header/HeaderCenter
 */

import { HeaderSearch, Logo, LogoSecondary, SocialHeader } from '@arpav/components/ArpavTheme';
import { Header, HeaderContent, HeaderRightZone } from 'design-react-kit/dist/design-react-kit';

import { UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { useSelector } from 'react-redux';

const HeaderCenter = () => {
  const subsite = useSelector(state => state.subsite?.data);

  let logoSubsite = subsite?.subsite_logo && (
    <figure className="icon">
      <img src={flattenToAppURL(subsite.subsite_logo.scales?.mini?.download)} alt="Logo" />
    </figure>
  );

  return (
    <Header className="py-3" small={false} theme="" type="center">
      <HeaderContent>
        <div className="it-brand-wrapper d-flex">
          <UniversalLink href={subsite?.['@id'] ? flattenToAppURL(subsite['@id']) : '/'}>
            {subsite?.subsite_logo ? logoSubsite : <Logo />}
            {/*<BrandText subsite={subsite} />*/}
          </UniversalLink>
          <UniversalLink className="ml-4" href="https://www.snpambiente.it/">
            <LogoSecondary />
          </UniversalLink>
        </div>
        <HeaderRightZone>
          <SocialHeader />
          <HeaderSearch />
        </HeaderRightZone>
      </HeaderContent>
    </Header>
  );
};

export default HeaderCenter;
