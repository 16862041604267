import {
  Card,
  CardBody,
  CardReadMore,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from 'design-react-kit/dist/design-react-kit';
import { FormattedDate, Icon as PloneIcon } from '@plone/volto/components';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import {
  Icon,
  ListingText,
  LastPublicationsSkeleton as Skeleton,
} from '@arpav/components/ArpavTheme';
import { getBaseUrl, getContentIcon } from '@plone/volto/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { getMostVisited } from '@arpav/actions';
import { getQueryStringResults } from '@plone/volto/actions';

const messages = defineMessages({
  readMore: {
    id: 'Read More…',
    defaultMessage: 'Read More…',
  },
});

const Body = ({ id, path }) => {
  const intl = useIntl();

  // save the path so it won't trigger dispatch on eager router location change
  const [initialPath] = useState(getBaseUrl(path));

  const last_publication = useSelector(state => state.querystringsearch.subrequests[id]);
  const last_publication_loading = useSelector(
    state => state.querystringsearch.subrequests[id]?.loading
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getQueryStringResults(
        initialPath,
        {
          limit: 15,
          metadata_fields: '_all',
          query: [
            {
              i: 'review_state',
              o: 'plone.app.querystring.operation.selection.any',
              v: ['published'],
            },
            {
              i: 'last_pubblications',
              o: 'plone.app.querystring.operation.boolean.isTrue',
            },
          ],
          sort_on: 'effective',
          sort_order: 'descending',
        },
        id
      )
    );
  }, [dispatch, initialPath]);

  return (
    <Container>
      {last_publication_loading ? (
        <Skeleton />
      ) : (
        <Row>
          {last_publication?.items?.length > 0 &&
            last_publication.items.map((item, index) => (
              <Col key={`last-publication-${index}`} md={6}>
                <Card className="rounded shadow mb-3" teaser noWrapper>
                  <CardBody className="pb-5">
                    <div className="category-top categoryicon-top">
                      <PloneIcon
                        name={getContentIcon(item['@type'], item.is_folderish)}
                        size="18px"
                        className="icon"
                        title={item['@type']}
                      />
                      <span className="category">
                        {item.effective && <FormattedDate date={item.effective} />} &ndash;{' '}
                        {item.Type}
                      </span>
                    </div>
                    <CardTitle tag="h3">{item.title}</CardTitle>
                    <CardText>
                      <ListingText item={item} />
                    </CardText>
                    <CardReadMore
                      href={item['@id']}
                      text={intl.formatMessage(messages.readMore)}
                      iconName="it-arrow-right"
                    ></CardReadMore>
                  </CardBody>
                </Card>
              </Col>
            ))}
        </Row>
      )}
    </Container>
  );
};

export default Body;
