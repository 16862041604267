import {
  Card,
  CardBody,
  CardReadMore,
  CardText,
  CardTitle,
  Col,
  Row,
} from 'design-react-kit/dist/design-react-kit';

import { Link } from 'react-router-dom';
import { NewsItemCardSkeleton } from '@arpav/components/ArpavTheme/View/Commons/NewsItemCard';

const Skeleton = () => {
  return (
    <div className="skeleton-template">
      <Row>
        {[0, 1, 2].map(item => (
          <Col xs={12} lg={4} key={item}>
            {/*start card*/}
            <NewsItemCardSkeleton />
            {/*end card*/}
          </Col>
        ))}
      </Row>
      <Row>
        {[0, 1, 2, 3].map(item => (
          <Col xs={12} lg={3} key={item}>
            {/*start card*/}
            <Card spacing className="card-bg card-img rounded shadow">
              <CardBody>
                <div className="category-top categoryicon-top"> </div>
                <CardTitle tag="h5"> </CardTitle>
                <CardText> </CardText>
                <CardReadMore tag={Link} iconName="it-arrow-right" text=" " to="#" />
              </CardBody>
            </Card>
            {/*end card*/}
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Skeleton;
