/**
 * Header component.
 * @module components/theme/Header/Header
 */

import PropTypes from 'prop-types';

import { Navigation } from '@plone/volto/components';

import { HeaderSlim, HeaderCenter, SubsiteHeader } from '@arpav/components/ArpavTheme';
import { Headers } from 'design-react-kit/dist/design-react-kit';

const Header = ({ pathname }) => {
  return (
    <>
      <div className="public-ui">
        <Headers>
          {/* <HeaderSlim /> */}

          <div className="it-nav-wrapper">
            <HeaderCenter />
            <Navigation pathname={pathname} />
          </div>
        </Headers>
        <SubsiteHeader />
      </div>
      <div id="portal-header-image"></div>
    </>
  );
};

Header.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default Header;
