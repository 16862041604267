import { Col, Container, Row } from 'design-react-kit/dist/design-react-kit';
import { Icon, ListingLinkMore } from '@arpav/components/ArpavTheme';

import PropTypes from 'prop-types';
import { UniversalLink } from '@plone/volto/components';
import cx from 'classnames';
import { getItemClass } from '@arpav/helpers';

const ProvinceRelItems = ({ label, relitems, itemindex, skeleton = false }) => {
  // calculate section style
  let sessionClassName = 'full-width py-5 ';
  let btnClassName = ' btn-primary ';
  switch (itemindex % 2) {
    case 0:
      sessionClassName += 'bg-light';
      break;
    case 1:
      sessionClassName += 'bg-primary-a11';
      btnClassName += ' btn-white text-primary ';
      break;
  }

  return (
    <div className="public-ui block listing">
      <div className={sessionClassName + ' skeleton-template'}>
        <Container>
          <div className="simple-card-compact-template">
            <Row className="mb-4">
              <Col>
                <div className="template-header">
                  <h2 className={cx({ 'skeleton-item-title': skeleton })}>{label}&nbsp;</h2>
                </div>
              </Col>
            </Row>

            <Row>
              {!skeleton
                ? relitems?.map((item, index) => (
                    <Col md={4} className="mb-4 px-2" key={index}>
                      <UniversalLink
                        className={`button-card rounded text-left btn-icon btn btn-block ${btnClassName} ${getItemClass(
                          item
                        )}`}
                        item={item}
                        title={item.title || item.id}
                      >
                        <span className="flex-grow-1">{item.title || item.id}</span>
                        <Icon icon="it-arrow-right" padding={false} />
                      </UniversalLink>
                    </Col>
                  ))
                : Array.from(Array(9).keys()).map((item, index) => (
                    <Col md={4} className="mb-4 px-2" key={index}>
                      <div>
                        <UniversalLink
                          className="button-card rounded text-left btn-icon btn btn-block btn-skeleton"
                          href="#"
                        >
                          <span className="flex-grow-1">&nbsp;</span>
                          <Icon icon="it-arrow-right" padding={false} />
                        </UniversalLink>
                      </div>
                    </Col>
                  ))}
            </Row>
            {/* {relitemsCount >= limit ? (
          <ListingLinkMore title={`view more`} href={readMoreLink} className="my-4" />
        ) : null} */}
          </div>
        </Container>
      </div>
    </div>
  );
};

ProvinceRelItems.propTypes = {
  label: PropTypes.string,
  relitems: PropTypes.arrayOf(
    PropTypes.shape({
      '@id': PropTypes.string,
      '@type': PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      review_state: PropTypes.string,
    })
  ),
  itemindex: PropTypes.number,
};

export default ProvinceRelItems;
