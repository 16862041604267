import { Chip, ChipLabel } from 'design-react-kit/dist/design-react-kit';
import { defineMessages, injectIntl } from 'react-intl';

import PropTypes from 'prop-types';
import moment from 'moment/min/moment-with-locales';

/**
 * PageHeaderNewsItem view component class.
 * @function PageHeaderNewsItem
 * @params {object} content: Content object.
 * @returns {string} Markup of the component.
 */

const messages = defineMessages({
  numero_progressivo_cs: {
    id: 'numero_progressivo_cs',
    defaultMessage: 'Numero del comunicato stampa',
  },
  pressRelease: {
    id: 'Press release',
    defaultMessage: 'Press release',
  },
});

const PageHeaderNewsItem = ({ intl, content }) => {
  moment.locale(intl.locale);

  return content['@type'] === 'News Item' ? (
    <Chip
      disabled={false}
      large={false}
      simple
      tag="span"
      className={`${content.is_pressrelease ? 'pressrelease' : 'news'}`}
    >
      <ChipLabel tag="span">
        {content.is_pressrelease
          ? intl.formatMessage(messages.pressRelease)
          : intl.formatMessage({ id: 'News Item' })}
      </ChipLabel>
    </Chip>
  ) : null;
};

PageHeaderNewsItem.propTypes = {
  params: PropTypes.shape({
    content: PropTypes.object,
  }),
};

export default injectIntl(PageHeaderNewsItem);
