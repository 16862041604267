/**
 * Highlight news actions.
 * @module actions/highlightNews/highlightNews
 */

import {
  GET_HIGHLIGHT_NEWS,
  RESET_HIGHLIGHT_NEWS,
  UPDATE_HIGHLIGHT_NEWS,
} from '@arpav/constants/ActionTypes';

/**
 * Get highlight news function.
 * @function getHighlightNews
 * @param {string} url Content url.
 * @param {object} data Request data.
 * @param {srting|number} data.b_size Items list size.
 * @param {srting|number} data.b_start Items list start.
 * @param {string} subrequest Optional. Key of the subrequest.
 * @returns {Object} Get schema action.
 */
export function getHighlightNews(url, data, subrequest) {
  url = typeof url !== 'undefined' ? url : '';
  const query = Object.keys(data || {})
    .map(function (key) {
      return key + '=' + data[key];
    })
    .join('&');
  return {
    type: GET_HIGHLIGHT_NEWS,
    subrequest,
    request: {
      op: 'get',
      path: `${url}/@highlightnews${query ? `?${query}` : ''}`,
    },
  };
}

/**
 * Update highlight news function.
 * @function updateHighlightNews
 * @param {string} url Content url.
 * @param {Object} data Data to update.
 * @returns {Object} Get schema action.
 */
export function updateHighlightNews(url, data) {
  url = typeof url !== 'undefined' ? url : '';
  return {
    type: UPDATE_HIGHLIGHT_NEWS,
    request: {
      op: 'patch',
      path: `${url}/@highlightnews`,
      data: data,
    },
  };
}

/**
 * Reset highlight news function.
 * @function resetHighlightNews
 * @returns {Object} Get schema action.
 */
export function resetHighlightNews() {
  return {
    type: RESET_HIGHLIGHT_NEWS,
  };
}
