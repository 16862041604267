import { assign, getEvents, getOptions } from '@eeacms/volto-openlayers-map/helpers';
import React from 'react';
import { isEqual } from 'lodash';
import { openlayers } from '@eeacms/volto-openlayers-map';
import { withMapContext } from '@eeacms/volto-openlayers-map/hocs';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { icon as fIcon } from '@fortawesome/fontawesome-svg-core';

const { layer } = openlayers;

const dataPoints = [];

/**
 * Get icon svg code
 *
 * @param {Object} icon Fontawesome icon definition
 * @param {String} icon.iconName Icon name
 * @param {String} icon.prefix Fontawesome icon prefix (fas, far, fab)
 * @param {String} color String for style color
 * @returns {String} Icon svg code for inline image src
 */
const getIconHtml = (icon, color) => {
  const iconDefinition = fIcon(
    { iconName: icon.iconName, prefix: icon.prefix },
    color && {
      styles: {
        color: color,
      },
    }
  );
  if (iconDefinition) {
    const iconNode = iconDefinition.node[0];
    iconNode.setAttribute('width', 36);
    iconNode.setAttribute('height', 36);
    return iconNode.outerHTML;
  }
  return null;
};

class Balneazione extends React.Component {
  layer = undefined;

  options = {
    className: undefined,
    declutter: undefined,
    extent: undefined,
    map: undefined,
    maxResolution: undefined,
    maxZoom: undefined,
    minResolution: undefined,
    minZoom: undefined,
    opacity: undefined,
    renderBuffer: undefined,
    renderOrder: undefined,
    source: undefined,
    style: undefined,
    updateWhileAnimating: undefined,
    updateWhileInteracting: undefined,
    visible: undefined,
    zIndex: 1,
  };

  events = {
    'change:extent': undefined,
    'change:maxResolution': undefined,
    'change:maxZoom': undefined,
    'change:minResolution': undefined,
    'change:minZoom': undefined,
    'change:opacity': undefined,
    'change:source': undefined,
    'change:visible': undefined,
    'change:zIndex': undefined,
    change: undefined,
    error: undefined,
    postrender: undefined,
    prerender: undefined,
    propertychange: undefined,
  };

  constructor(props) {
    super(props);
    this.options = getOptions(assign(this.options, this.props));
    this.addLayer = this.addLayer.bind(this);
  }

  updateOptions() {
    const { style } = openlayers;
    const { Style, Icon } = style;

    const icon_scale = 0.5;

    this.options.style = feature => {
      let flagColor;
      /* array  filter */
      const filter = dataPoints.find(cod => cod.CODSEQST === feature.values_.data.codseqst);
      switch (filter['STATOATT']) {
        case 'BLU':
          flagColor = 'rgb(9, 112, 230)';
          break;
        case 'ROSSO':
          flagColor = 'rgb(255,0,0)';
          break;
        default:
          flagColor = 'rgb(221, 175, 35)';
      }

      /* create the icon with the relative color */
      let style = new Style({
        image: new Icon({
          anchor: [0.5, 1],
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          scale: icon_scale,
          src: `data:image/svg+xml,${getIconHtml(
            {
              iconName: 'flag',
              prefix: 'fas',
            },
            flagColor
          )}`,
        }),
      });
      return style;
    };
  }

  addLayer() {
    const { mapRendered } = this.props;
    let events = getEvents(this.events, this.props);
    this.layer = new layer.Vector(this.options);
    for (let event in events) {
      this.layer.on(event, events[event]);
    }
    if (!mapRendered) {
      this.props.addLayer(this.layer);
    }
  }

  componentDidMount() {
    this.options.source = new openlayers.source.Vector({
      projection: 'EPSG:27700',
      format: new openlayers.format.GeoJSON(),
    });
    this.options.style = this.styleFunction;

    this.updateOptions();
    this.addLayer();
    this.addFeatures();
  }

  componentDidUpdate(prevProps) {
    const prevOptions = getOptions(assign(this.options, prevProps));
    const options = getOptions(assign(this.options, this.props));
    if (!isEqual(prevOptions, options)) {
      Object.keys(options).forEach(o => {
        if (o !== 'source' && o !== 'style' && prevOptions[o] !== o) {
          this.layer.set(o, options[o]);
        }
      });
      this.options = getOptions(assign(this.options, this.props));
      this.layer.changed();
    }
  }

  componentWillUnmount() {
    if (__SERVER__ || !this.layer) return;
    this.layer.dispose();
  }

  /**
   * Add features
   */
  addFeatures() {
    const me = this;
    const geojsonObject = {
      type: 'FeatureCollection',
      crs: {
        type: 'name',
        properties: {
          name: 'EPSG:3857',
        },
      },
      features: [],
    };
    try {
      fetch(
        `https://api.arpa.veneto.it/REST/v1/acqua_balneazione_punti_prelievo/acqua_balneazione_punti_prelievo_point`
      )
        .then(res => res.json())
        .then(result => {
          fetch('https://api.arpa.veneto.it/REST/v1/acqua_balneazione_punti_prelievo/')
            .then(a => a.json())
            .then(b => {
              b?.data.forEach(i => {
                dataPoints.push(i);
              });
            });
          // get features
          result?.data.map(i => {
            geojsonObject.features.push({
              type: 'Feature',
              geometry: JSON.parse(i.point),
              properties: {
                id: i.codseqst,
                data: { ...i },
              },
            });
          });
          // read features
          const features = new openlayers.format.GeoJSON({
            featureProjection: 'EPSG:3857',
          }).readFeatures(geojsonObject);
          // add features
          me.layer?.getSource()?.addFeatures(features);
        });
    } catch (e) {
      // Do nothing
    }
  }

  render() {
    return null;
  }
}

export default withMapContext(Balneazione);
