/**
 * Get string and record, and return an evaluation of string
 *
 * @param {Object} record
 * @param {String} placeholder
 * @returns {String} Evaluated string template
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const executePlaceholder = (record, string) => {
  // `record` used in `title_placeholder` evaluation
  const placeholder = string ? '`' + string.replace(/`/g, '\\`') + '`' : '';
  let result = '';
  try {
    result = eval(placeholder);
  } catch (err) {
    console.error(err);
  }
  return result;
};
