import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

/**
 *
 * @param {String} blockId
 * @returns {String}
 */
const getCleanedBlockId = blockId => blockId.replace(/-/g, '');

/**
 * View container class.
 * @class View
 * @extends Component
 */
class CustomJavascriptView extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    id: PropTypes.string,
    path: PropTypes.string,
    data: PropTypes.shape({
      '@type': PropTypes.string,
      jscode: PropTypes.string,
    }),
    properties: PropTypes.shape({
      '@id': PropTypes.string,
      '@type': PropTypes.string,
      UID: PropTypes.string,
    }),
  };

  render() {
    // generates a div with id generated using the block id
    return (
      <div>
        <div id={`jsblock-${getCleanedBlockId(this.props.id)}`}></div>
      </div>
    );
  }

  componentDidMount() {
    // get block id and function name usign the block id
    const blockId = `jsblock-${getCleanedBlockId(this.props.id)}`;
    const fnname = `fn_${getCleanedBlockId(this.props.id)}`;

    // try to evaluate the script
    try {
      eval(
        `function ${fnname}() {const block = document.getElementById('${blockId}'); ${this.props.data?.jscode}} ${fnname}();`
      );
    } catch (e) {
      console.error(e);
    }
  }
}

export default injectIntl(CustomJavascriptView);
