import { assign, getEvents, getOptions } from '@eeacms/volto-openlayers-map/helpers';

import React from 'react';
import { isEqual } from 'lodash';
import { openlayers } from '@eeacms/volto-openlayers-map';
import { withMapContext } from '@eeacms/volto-openlayers-map/hocs';

const { layer, source } = openlayers;

class VectorImage extends React.Component {
  layer = undefined;

  options = {
    className: undefined,
    opacity: undefined,
    visible: undefined,
    extent: undefined,
    zIndex: undefined,
    minResolution: undefined,
    maxResolution: undefined,
    minZoom: undefined,
    maxZoom: undefined,
    map: undefined,
    source: undefined,
    properties: undefined,
  };

  events = {
    'change:extent': undefined,
    'change:maxResolution': undefined,
    'change:maxZoom': undefined,
    'change:minResolution': undefined,
    'change:minZoom': undefined,
    'change:opacity': undefined,
    'change:source': undefined,
    'change:visible': undefined,
    'change:zIndex': undefined,
    change: undefined,
    error: undefined,
    postrender: undefined,
    prerender: undefined,
    propertychange: undefined,
  };

  constructor(props) {
    super(props);
    this.options = getOptions(assign(this.options, this.props));
    this.addLayer = this.addLayer.bind(this);
  }

  addLayer() {
    const { mapRendered } = this.props;
    let events = getEvents(this.events, this.props);

    this.layer = new layer.Image(this.options);
    for (let event in events) {
      this.layer.on(event, events[event]);
    }

    if (!mapRendered) {
      this.props.addLayer(this.layer);
    }
  }

  /**
   * load image
   */
  componentDidMount() {
    const me = this;
    try {
      fetch('https://api.arpa.veneto.it/REST/v1/radar_imgs_geo')
        .then(res => res.json())
        .then(result => {
          const image = result.data[0].image;
          me.options.source = new source.ImageStatic({
            url: 'data:image/jpeg;base64,' + image,
            projection: 'EPSG:3857',
            // Used https://www.georeferencer.com/ to calculate image extent
            imageExtent: [1069124.515, 5464986.767, 1669457.005, 5975011.496],
          });
          me.addLayer();
        });
    } catch (e) {
      // Do nothing
    }
  }

  componentDidUpdate(prevProps) {
    const prevOptions = getOptions(assign(this.options, prevProps));
    const options = getOptions(assign(this.options, this.props));
    const me = this;
    if (!isEqual(prevOptions, options)) {
      Object.keys(options).forEach(o => {
        if (o !== 'source' && o !== 'style' && prevOptions[o] !== o) {
          this.layer?.set(o, options[o]);
        }
      });
      this.options = getOptions(assign(this.options, this.props));
      this.layer?.changed();
    }
  }

  componentWillUnmount() {
    if (__SERVER__ || !this.layer) return;
    this.layer.dispose();
  }

  render() {
    return null;
  }
}

export default withMapContext(VectorImage);
