/**
 * NewsItemView view component.
 * @module components/theme/View/NewsItemView
 */

import { Col, Container, Row } from 'design-react-kit/dist/design-react-kit';
import {
  ContentImage,
  Metadata,
  NextPreviousItems,
  PageHeader,
  SideMenu,
  SkipToMainContent,
} from '@arpav/components/ArpavTheme/View';
import { defineMessages, injectIntl } from 'react-intl';
import {
  flattenHTMLToAppURL,
  getBaseUrl,
  getBlocksFieldname,
  getBlocksLayoutFieldname,
  hasBlocksData,
} from '@plone/volto/helpers';

import { Icon } from '@arpav/components/ArpavTheme';
import PropTypes from 'prop-types';
import config from '@plone/volto/registry';
import { map } from 'lodash';

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
});

/**
 * NewsItemView view component class.
 * @function NewsItemView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const NewsItemView = ({ content, location, intl }) => {
  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);

  return (
    <Container className="my-4 newsitem-view">
      <div className="px-4 px-lg-0">
        <SkipToMainContent />
        <PageHeader
          content={content}
          readingtime={null}
          showreadingtime={true}
          showdates={true}
          showtassonomiaargomenti={true}
        />

        <Row className="border-top row-column-border row-column-menu-left">
          <Col
            tag="section"
            lg={8}
            id="main-content-section"
            className="it-page-sections-container pr-lg-0"
          >
            <div className="mt-3 mt-lg-5 mr-lg-5 ml-lg-3">
              {/* HEADER IMAGE */}
              <ContentImage content={content} position="afterHeader" />
              {/* BLOCKs */}
              {hasBlocksData(content)
                ? map(content[blocksLayoutFieldname].items, block => {
                    // exlude block title
                    if (content[blocksFieldname]?.[block]?.['@type'] !== 'title') {
                      const Block =
                        config.blocks.blocksConfig[content[blocksFieldname]?.[block]?.['@type']]?.[
                          'view'
                        ] || null;
                      return Block !== null ? (
                        <Block
                          key={block}
                          id={block}
                          properties={content}
                          data={content[blocksFieldname][block]}
                          path={getBaseUrl(location?.pathname || '')}
                        />
                      ) : (
                        <div key={block}>
                          {intl.formatMessage(messages.unknownBlock, {
                            block: content[blocksFieldname]?.[block]?.['@type'],
                          })}
                        </div>
                      );
                    }
                  })
                : content.text && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: flattenHTMLToAppURL(content.text.data),
                      }}
                    />
                  )}
            </div>
          </Col>
          <Col tag="aside" lg={4}>
            <SideMenu
              content={content}
              sections={[
                {
                  component: NextPreviousItems,
                  props: {
                    content,
                    showParent: true,
                  },
                },
              ]}
            />
          </Col>
        </Row>
        {content.is_pressrelease && (
          <div className="mb-5 ml-lg-3">
            <small>
              <Icon icon="it-mail" size="sm" padding={true} aria-hidden />
              <a href={'mailto:ufficiostampa@arpa.veneto.it?subject=' + content.title}>
                Agenzia Ambiente Veneto - ufficiostampa@arpa.veneto.it
              </a>
            </small>
          </div>
        )}
        <div className="mb-5 ml-lg-3">
          <Metadata content={content} />
        </div>
      </div>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
NewsItemView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default injectIntl(NewsItemView);
