import { defineMessages } from 'react-intl';

const messages = defineMessages({
  dataTable: {
    id: 'Data table',
    defaultMessage: 'Data table',
  },
  fixed: {
    id: 'Fixed width table cells',
    defaultMessage: 'Fixed width table cells',
  },
  compact: {
    id: 'Make the table compact',
    defaultMessage: 'Make the table compact',
  },
  basic: {
    id: 'Reduce complexity',
    defaultMessage: 'Reduce complexity',
  },
  sortable: {
    id: 'Enable table sort',
    defaultMessage: 'Enable table sort',
  },
  celled: {
    id: 'Divide each row into separate cells',
    defaultMessage: 'Divide each row into separate cells',
  },
  inverted: {
    id: 'Table color inverted',
    defaultMessage: 'Table color inverted',
  },
  sourceType: {
    id: 'Source type',
    defaultMessage: 'Source type',
  },
  striped: {
    id: 'Stripe alternate rows with color',
    defaultMessage: 'Stripe alternate rows with color',
  },
  styling: {
    id: 'Styling',
    defaultMessage: 'Styling',
  },
  defaultFieldset: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  dataFile: {
    id: 'Data file',
    defaultMessage: 'Data file',
  },
  csv: {
    id: 'CSV',
    defaultMessage: 'CSV',
  },
  api: {
    id: 'ARPAV API',
    defaultMessage: 'ARPAV API',
  },
  apiUrl: {
    id: 'API url',
    defaultMessage: 'API url',
  },
  defaultSortColumn: {
    id: 'Default sort column',
    defaultMessage: 'Default sort column',
  },
  defaultSortDirection: {
    id: 'Default sort direction',
    defaultMessage: 'Default sort direction',
  },
  ascending: {
    id: 'Ascending',
    defaultMessage: 'Ascending',
  },
  descending: {
    id: 'Descending',
    defaultMessage: 'Descending',
  },
  pagination: {
    id: 'Enable pagination',
    defaultMessage: 'Enable pagination',
  },
  pageSize: {
    id: 'Page size',
    defaultMessage: 'Page size',
  },
  header: {
    id: 'Titolo',
    defaultMessage: 'Titolo',
  },
  align: {
    id: 'Align',
    defaultMessage: 'Align',
  },
  dataColumn: {
    id: 'Data column',
    defaultMessage: 'Data column',
  },
  format: {
    id: 'Format',
    defaultMessage: 'Format',
  },
  HTMLformat: {
    id: 'HTMLformat',
    defaultMessage: 'HTMLformat',
  },
  filtered: {
    id: 'Enable filter',
    defaultMessage: 'Enable filter',
  },
  filterRequired: {
    id: 'Filter required',
    defaultMessage: 'Filter required',
  },
  column: {
    id: 'Column',
    defaultMessage: 'Column',
  },
  columns: {
    id: 'Columns',
    defaultMessage: 'Columns',
  },
  columnsDescription: {
    id: 'Leave empty to show all columns',
    defaultMessage: 'Leave empty to show all columns',
  },
});

const SOURCE_TYPES = {
  csv: {
    id: 'csv',
    label: messages.csv,
  },
  api: {
    id: 'api',
    label: messages.api,
  },
};

const TableSchema = ({ intl }) => ({
  title: intl.formatMessage(messages.dataTable),

  fieldsets: [
    {
      id: 'default',
      title: intl.formatMessage(messages.defaultFieldset),
      fields: ['source_type', 'file_path', 'api_url', 'columns'],
    },
    {
      id: 'style',
      title: intl.formatMessage(messages.styling),
      fields: [
        'pagination',
        'page_size',
        'sortable',
        'default_sort_direction',
        'default_sort_column',
        'fixed',
        'celled',
        'striped',
        'compact',
        'basic',
        'inverted',
      ],
    },
  ],

  properties: {
    source_type: {
      factory: 'Choice',
      type: 'string',
      title: intl.formatMessage(messages.sourceType),
      choices: Object.keys(SOURCE_TYPES).map(k => [
        SOURCE_TYPES[k].id,
        intl.formatMessage(SOURCE_TYPES[k].label),
      ]),
    },
    file_path: {
      type: 'string',
      title: intl.formatMessage(messages.dataFile),
      widget: 'url',
    },
    api_url: {
      type: 'string',
      title: intl.formatMessage(messages.apiUrl),
      widget: 'url',
    },
    pagination: {
      type: 'boolean',
      title: intl.formatMessage(messages.pagination),
    },
    page_size: {
      type: 'integer',
      title: intl.formatMessage(messages.pageSize),
      defaultValue: 30,
    },
    sortable: {
      type: 'boolean',
      title: intl.formatMessage(messages.sortable),
    },
    default_sort_direction: {
      factory: 'Choice',
      type: 'string',
      title: intl.formatMessage(messages.defaultSortDirection),
      default: 'ascending',
      choices: [
        ['ascending', intl.formatMessage(messages.ascending)],
        ['descending', intl.formatMessage(messages.descending)],
      ],
    },
    default_sort_column: {
      factory: 'Choice',
      type: 'string',
      title: intl.formatMessage(messages.defaultSortColumn),
    },
    fixed: {
      type: 'boolean',
      title: intl.formatMessage(messages.fixed),
    },
    compact: {
      type: 'boolean',
      title: intl.formatMessage(messages.compact),
    },
    basic: {
      type: 'boolean',
      title: intl.formatMessage(messages.basic),
    },
    celled: {
      type: 'boolean',
      title: intl.formatMessage(messages.celled),
    },
    inverted: {
      type: 'boolean',
      title: intl.formatMessage(messages.inverted),
    },
    striped: {
      type: 'boolean',
      title: intl.formatMessage(messages.striped),
    },
    columns: {
      title: intl.formatMessage(messages.columns),
      description: intl.formatMessage(messages.columnsDescription),
      widget: 'object_list_inline',
      schema: ColumnSchema({ intl }),
    },
  },

  required: ['source_type'],
});

const ColumnSchema = ({ intl }) => ({
  title: intl.formatMessage(messages.column),
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: [
        'column',
        'title',
        'textAlign',
        'renderer',
        'HTMLformat',
        'filtered',
        'filterRequired',
      ],
    },
  ],
  properties: {
    title: {
      title: intl.formatMessage(messages.header),
    },
    textAlign: {
      title: intl.formatMessage(messages.align),
      widget: 'text_align',
    },
    column: {
      title: intl.formatMessage(messages.dataColumn),
      choices: [],
    },
    renderer: {
      title: intl.formatMessage(messages.format),
      choices: [],
    },
    HTMLformat: {
      type: 'boolean',
      title: intl.formatMessage(messages.HTMLformat),
    },
    filtered: {
      type: 'boolean',
      title: intl.formatMessage(messages.filtered),
    },
    filterRequired: {
      type: 'boolean',
      title: intl.formatMessage(messages.filterRequired),
      isDisabled: true,
    },
  },
  required: ['column'],
});

export { SOURCE_TYPES, TableSchema };
