/**
 * Document view component.
 * @module components/theme/View/DefaultView
 */

import { Container, Image } from 'semantic-ui-react';
import { PreviewImage, UniversalLink } from '@plone/volto/components';
import React, { useEffect } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import {
  getBaseUrl,
  getBlocksFieldname,
  getBlocksLayoutFieldname,
  hasBlocksData,
} from '@plone/volto/helpers';
import { useDispatch, useSelector } from 'react-redux';

import { Segment } from 'semantic-ui-react';
import config from '@plone/volto/registry';
import { getDefaultView } from '@arpav/actions';
import { map } from 'lodash';

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
});

const ViewItems = ({ fields, items }) => {
  return (
    <div>
      {fields
        ? fields
            .filter(
              field =>
                ['title', 'description', 'text', 'image', 'blocks', 'blocks_layout'].indexOf(
                  field.id
                ) === -1
            )
            .map(field => (
              <div
                key={field.id}
                dangerouslySetInnerHTML={{
                  __html: field.text.data,
                }}
              />
            ))
        : null}
      {items ? (
        <>
          <div>
            <strong>Contenuti</strong>
          </div>
          {items.map(item => (
            <Segment key={item.url} className="listing-item">
              <Container>
                <h2>
                  <UniversalLink item={item} title={item['@type']}>
                    {item.title}
                  </UniversalLink>
                </h2>
                {item.description && <p>{item.description}</p>}
              </Container>
              {item.image_field && (
                <PreviewImage
                  item={item}
                  size="thumb"
                  alt={item.image_caption ? item.image_caption : item.title}
                  className="ui image"
                />
              )}
            </Segment>
          ))}
        </>
      ) : null}
    </div>
  );
};

/**
 * Component to display the default view.
 * @function DefaultView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const DefaultView = ({ content, intl, location }) => {
  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);
  const defaultView = useSelector(state => state.defaultView.defaultView);
  const dispatch = useDispatch();

  useEffect(() => {
    if (location?.pathname) {
      dispatch(getDefaultView(getBaseUrl(location.pathname)));
    }
  }, [dispatch, location?.pathname]);

  return hasBlocksData(content) ? (
    <div id="page-document" className="ui container">
      {map(content[blocksLayoutFieldname].items, block => {
        const Block =
          config.blocks.blocksConfig[content[blocksFieldname]?.[block]?.['@type']]?.['view'] ||
          null;
        return (
          <div>
            {Block !== null ? (
              <Block
                key={block}
                id={block}
                properties={content}
                data={content[blocksFieldname][block]}
                path={getBaseUrl(location?.pathname || '')}
              />
            ) : (
              <div key={block}>
                {intl.formatMessage(messages.unknownBlock, {
                  block: content[blocksFieldname]?.[block]?.['@type'],
                })}
              </div>
            )}
          </div>
        );
      })}
      {defaultView && content && (
        <ViewItems content={content} fields={defaultView?.fields} items={defaultView?.items} />
      )}
    </div>
  ) : (
    <Container id="page-document">
      <h1 className="documentFirstHeading">{content.title}</h1>
      {content.description && <p className="documentDescription">{content.description}</p>}
      {content.image && (
        <Image
          className="document-image"
          src={content.image.scales.thumb.download}
          floated="right"
        />
      )}
      {content.remoteUrl && (
        <span>
          The link address is:
          <a href={content.remoteUrl}>{content.remoteUrl}</a>
        </span>
      )}
      {content.text && (
        <div
          dangerouslySetInnerHTML={{
            __html: content.text.data,
          }}
        />
      )}
      {defaultView && content && (
        <ViewItems content={content} fields={defaultView?.fields} items={defaultView?.items} />
      )}
    </Container>
  );
};

export default injectIntl(DefaultView);
