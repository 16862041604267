import { Icon as PloneIcon, UniversalLink } from '@plone/volto/components';

import { Icon } from '@arpav/components/ArpavTheme';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CardCategory = props => {
  const { icon, iconName, date, href, ...attributes } = props;
  const { children, ...rest } = attributes;
  const classes = classNames({
    'category-top': date || ' ',
    'categoryicon-top': icon || iconName,
  });
  // Simple category link
  const categoryLink = href && (
    <UniversalLink href={href} className="category">
      {children}
    </UniversalLink>
  );
  const categoryDate = date && <span className="data">{date}</span>;
  // Category with icon
  const categoryText = !href && children && <span className="text">{children}</span>;
  const categoryIcon = icon ? (
    <PloneIcon name={icon} size="18px" className="icon" />
  ) : iconName ? (
    <Icon icon={iconName} />
  ) : null;

  return (
    <div className={classes} {...rest}>
      {categoryLink}
      {categoryIcon}

      {categoryText}
      {categoryDate}
    </div>
  );
};

CardCategory.propTypes = {
  icon: PropTypes.shape({
    xmlns: PropTypes.string,
    viewBox: PropTypes.string,
    content: PropTypes.string,
  }),
  iconName: PropTypes.string,
  date: PropTypes.any,
  href: PropTypes.string,
};

export default CardCategory;
