/**
 * Routes.
 * @module routes
 */

import {
  EventSubscription,
  EventSubscriptionManage,
  HighlightNews,
} from '@arpav/components/ArpavTheme';
import { defaultRoutes, multilingualRoutes } from '@plone/volto/routes';

import { App } from '@plone/volto/components';
import config from '@plone/volto/registry';

/**
 * Routes array.
 * @array
 * @returns {array} Routes.
 */
const routes = [
  {
    path: '/',
    component: App, // Change this if you want a different component
    routes: [
      {
        path: '/**/eventsubscription/:id',
        component: EventSubscription,
      },
      {
        path: '/**/manageeventsubscription',
        component: EventSubscriptionManage,
      },
      {
        path: '/**/highlightnews',
        component: HighlightNews,
        exact: true,
      },
      ...(config.addonRoutes || []),
      ...((config.settings?.isMultilingual && multilingualRoutes) || []),
      ...defaultRoutes,
    ],
  },
];

export default routes;
