import {
  AnnouncementsSummaryEdit,
  AnnouncementsSummaryView,
  CustomJavascriptEdit,
  CustomJavascriptView,
  DataChartEdit,
  DataChartView,
  DataMapEdit,
  DataMapView,
  DataTableEdit,
  DataTableView,
  DoubleListingEdit,
  DoubleListingView,
  HomeBannerEdit,
  HomeBannerView,
  ImageCellRenderer,
  LastPublicationsEdit,
  LastPublicationsView,
  MostVisitedPagesEdit,
  MostVisitedPagesView,
  NewsInEvidenceEdit,
  NewsInEvidenceView,
  ProgressCellRenderer,
  RichTextEdit,
  RichTextView,
  TextTemplateRenderer,
} from '@arpav/components/ArpavTheme';

import BlockSettingsSchema from '@plone/volto/components/manage/Blocks/Block/Schema';
import DefaultListingBlockTemplate from '@plone/volto/components/manage/Blocks/Listing/DefaultTemplate';
import ImageGalleryListingBlockTemplate from '@plone/volto/components/manage/Blocks/Listing/ImageGallery';
// block sidebar schemas (not the Dexterity Layout block settings schemas)
import ListingBlockSchema from '@plone/volto/components/manage/Blocks/Listing/schema';
import SummaryListingBlockTemplate from '@plone/volto/components/manage/Blocks/Listing/SummaryTemplate';
import chartSVG from '@arpav/icons/chart.svg';
import codeSVG from '@plone/volto/icons/code.svg';
import { defineMessages } from 'react-intl';
import { getArpavListingVariations } from '@arpav/config/Blocks/listingVariations';
import getListingBlockAsyncData from '@plone/volto/components/manage/Blocks/Listing/getAsyncData';
import imageFitSVG from '@plone/volto/icons/image-fit.svg';
import listBulletSVG from '@plone/volto/icons/list-bullet.svg';
import mapSVG from '@plone/volto/icons/map.svg';
import tableSVG from '@plone/volto/icons/table.svg';
import textSVG from '@plone/volto/icons/text.svg';

const messages = defineMessages({
  announcementsSummary: {
    id: 'Announcements Summary',
    defaultMessage: 'Vista riepilogativa bandi',
  },
  apigroup: {
    id: 'api',
    defaultMessage: 'Api',
  },
  customJavascript: {
    id: 'Custom Javascript',
    defaultMessage: 'Custom Javascript',
  },
  dataChart: {
    id: 'Data chart',
    defaultMessage: 'Data chart',
  },
  dataMap: {
    id: 'Data map',
    defaultMessage: 'Data map',
  },
  dataTable: {
    id: 'Data table',
    defaultMessage: 'Data table',
  },
  doubleListing: {
    id: 'Double listing',
    defaultMessage: 'Double listing',
  },
  homeBanner: {
    id: 'Home banner',
    defaultMessage: 'Home banner',
  },
  richtext: {
    id: 'Rich Text',
    defaultMessage: 'Rich Text',
  },
  image: {
    id: 'Image',
    defaultMessage: 'Image',
  },
  textTemplate: {
    id: 'Text template',
    defaultMessage: 'Text template',
  },
  progress: {
    id: 'Progress',
    defaultMessage: 'Progress',
  },
  newsInEvidence: {
    id: 'News in evidence',
    defaultMessage: 'News in evidence',
  },
});

const getArpavBlocks = config => {
  let groupBlocksOrder = [...config.blocks.groupBlocksOrder];
  groupBlocksOrder.splice(2, 0, {
    id: 'api',
    title: messages.apigroup.title,
  });
  return {
    groupBlocksOrder: groupBlocksOrder,
    blocksConfig: {
      ...config.blocks.blocksConfig,
      announcementsSummary: {
        id: 'announcementsSummary',
        title: messages.announcementsSummary.defaultMessage,
        icon: tableSVG,
        group: 'common',
        view: AnnouncementsSummaryView,
        edit: AnnouncementsSummaryEdit,
        schema: BlockSettingsSchema,
        blockSchema: ListingBlockSchema,
        restricted: false,
        mostUsed: true,
        sidebarTab: 1,
        showLinkMore: false,
        security: {
          addPermission: [],
          view: [],
        },
        getAsyncData: getListingBlockAsyncData,
      },
      lastPublications: {
        id: 'lastPublications',
        title: 'Last publications',
        group: 'common',
        icon: tableSVG,
        view: LastPublicationsView,
        edit: LastPublicationsEdit,
        /**
         *
         * @param {Object} data
         * @param {Object} data.properties current object data
         * @param {Object} data.block block being evaluated in `BlockChooser`
         * @returns {Boolean} if return true the block it won't show in the chooser
         */
        restricted: () => {
          return false;
        },
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
      },
      mostVisitedPages: {
        id: 'mostVisitedPages',
        title: 'Most Visited Pages',
        group: 'common',
        icon: tableSVG,
        view: MostVisitedPagesView,
        edit: MostVisitedPagesEdit,
        /**
         *
         * @param {Object} data
         * @param {Object} data.properties current object data
         * @param {Object} data.block block being evaluated in `BlockChooser`
         * @returns {Boolean} if return true the block it won't show in the chooser
         */
        restricted: () => {
          return false;
        },
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
      },
      dataChart: {
        id: 'dataChart',
        title: messages.dataChart.defaultMessage,
        icon: chartSVG,
        group: 'api',
        view: DataChartView,
        edit: DataChartEdit,
        restricted: false,
        mostUsed: false,
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
        blockHasValue: () => true,
      },
      dataMap: {
        id: 'dataMap',
        title: messages.dataMap.defaultMessage,
        icon: mapSVG,
        group: 'api',
        view: DataMapView,
        edit: DataMapEdit,
        restricted: false,
        mostUsed: false,
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
        blockHasValue: () => true,
      },
      dataTable: {
        id: 'dataTable',
        title: messages.dataTable.defaultMessage,
        icon: tableSVG,
        group: 'api',
        view: DataTableView,
        edit: DataTableEdit,
        restricted: false,
        mostUsed: false,
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
        cellRenderers: {
          image: {
            id: 'image',
            title: 'Image',
            view: ImageCellRenderer,
            schemaExtender: ImageCellRenderer.schemaExtender,
          },
          textTemplate: {
            id: 'textTemplate',
            title: 'Text template',
            view: TextTemplateRenderer,
            schemaExtender: TextTemplateRenderer.schemaExtender,
          },
          progress: {
            id: 'progress',
            title: 'Progress',
            view: ProgressCellRenderer,
          },
        },
      },
      customJavascript: {
        id: 'customJavascript',
        title: messages.customJavascript.defaultMessage,
        icon: codeSVG,
        group: 'api',
        view: CustomJavascriptView,
        edit: CustomJavascriptEdit,
        restricted: false,
        mostUsed: false,
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
        blockHasValue: () => true,
      },
      doubleListing: {
        id: 'doubleListing',
        title: messages.doubleListing.defaultMessage,
        icon: listBulletSVG,
        group: 'common',
        view: DoubleListingView,
        edit: DoubleListingEdit,
        schema: BlockSettingsSchema,
        blockSchema: ListingBlockSchema,
        restricted: false,
        mostUsed: true,
        sidebarTab: 1,
        showLinkMore: false,
        security: {
          addPermission: [],
          view: [],
        },
        variations: [
          {
            id: 'default',
            isDefault: true,
            title: 'Default',
            template: DefaultListingBlockTemplate,
          },
          {
            id: 'imageGallery',
            title: 'Image gallery',
            template: ImageGalleryListingBlockTemplate,
          },
          {
            id: 'summary',
            title: 'Summary',
            template: SummaryListingBlockTemplate,
          },
        ],
        getAsyncData: getListingBlockAsyncData,
      },
      homeBanner: {
        id: 'homeBanner',
        title: messages.homeBanner.defaultMessage,
        icon: imageFitSVG,
        group: 'common',
        view: HomeBannerView,
        edit: HomeBannerEdit,
        /**
         *
         * @param {Object} properties current object data
         * @returns {Boolean} if return true the block it won't show in the chooser
         */
        restricted: ({ properties }) => {
          return properties?.['@type'] != 'Plone Site';
        },
        mostUsed: false,
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
        blockHasValue: () => true,
      },
      listing: {
        ...config.blocks.blocksConfig.listing,
        showLinkMore: true,
        variations: [
          ...config.blocks.blocksConfig.listing.variations.filter(
            item => item.id !== 'imageGallery'
          ),
          ...getArpavListingVariations(config),
        ],
        listing_bg_colors: [
          { name: 'primary-a11', label: 'Primario' },
          { name: 'secondary', label: 'Secondario' },
          { name: 'tertiary', label: 'Terziario' },
          { name: 'light', label: 'Chiaro' },
        ],
        listing_items_colors: [], //{name:'blue', label:'Blu'},{name:'light-blue', label:'Light blue'},{name:'sidebar-background', label:'Gre
      },
      newsInEvidence: {
        id: 'newsInEvidence',
        title: messages.newsInEvidence.defaultMessage,
        icon: listBulletSVG,
        group: 'common',
        view: NewsInEvidenceView,
        edit: NewsInEvidenceEdit,
        restricted: false,
        mostUsed: false,
        sidebarTab: 0,
        security: {
          addPermission: [],
          view: [],
        },
        blockHasValue: () => true,
      },
      richtext: {
        id: 'richtext',
        title: messages.richtext.defaultMessage,
        icon: textSVG,
        group: 'text',
        view: RichTextView,
        edit: RichTextEdit,
        restricted: false,
        mostUsed: false,
        sidebarTab: 0,
        security: {
          addPermission: [],
          view: [],
        },
        blockHasValue: ({ richtext }) => {
          const isEmpty = !richtext || !richtext?.data?.length;
          return !isEmpty;
        },
      },
    },
  };
};

export default getArpavBlocks;
