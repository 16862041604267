/**
 * PageView view component.
 * @module components/theme/View/PageView
 */
import { Col, Container, Row } from 'design-react-kit/dist/design-react-kit';
import {
  Metadata,
  PageHeader,
  SideMenu,
  SkipToMainContent,
  TextOrBlocks,
} from '@arpav/components/ArpavTheme/View';
import {
  getBaseUrl,
  getBlocksFieldname,
  getBlocksLayoutFieldname,
  getLayoutFieldname,
} from '@plone/volto/helpers';

import config from '@plone/volto/registry';
import cx from 'classnames';
import { find } from 'lodash';

/**
 * PageView view component class.
 * @function PageView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */

const Page = ({ content, token, location, history }) => {
  const layout = content[getLayoutFieldname(content)];

  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);

  let leftContents = [];

  const tocBlock =
    content[blocksLayoutFieldname]?.items?.length > 0
      ? find(
          content[blocksLayoutFieldname].items,
          block => content[blocksFieldname]?.[block]?.['@type'] === 'toc'
        )
      : null;

  if (tocBlock) {
    const TocBlock = config.blocks.blocksConfig['toc']?.['view'] || null;

    if (TocBlock != null) {
      leftContents.push({
        component: TocBlock,
        props: {
          properties: content,
          id: tocBlock,
          data: content[blocksFieldname][tocBlock],
          path: getBaseUrl(location?.pathname || ''),
        },
      });
    }
  }

  if (layout === 'document_view') {
    return (
      <Container id="page-document">
        <SkipToMainContent />
        <PageHeader content={content} showdates={false} />
        <Row
          className={cx({
            'border-top row-column-border row-column-menu-left': leftContents.length > 0,
          })}
        >
          <Col
            tag="section"
            lg={leftContents.length > 0 ? 8 : 12}
            id="main-content-section"
            className="it-page-sections-container pr-lg-0"
          >
            <div className="mt-3 mt-lg-5 mr-lg-5 ml-lg-3">
              <TextOrBlocks content={content} exclude={['title', 'description', 'toc']} />
            </div>
          </Col>
          {leftContents.length > 0 ? (
            <Col tag="aside" lg={4}>
              <SideMenu content={content} sections={leftContents} />
            </Col>
          ) : null}
        </Row>
        <div className="mb-5  ml-lg-3">
          <Metadata content={content} />
        </div>
      </Container>
    );
  } else {
    const getViewByLayout = () => config.views.layoutViews[layout] || null;
    const Layout = getViewByLayout();

    return Layout ? (
      <Layout content={content} location={location} token={token} history={history} />
    ) : null;
  }
};

export default Page;
