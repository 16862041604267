import { Col, Container, Row } from 'design-react-kit/dist/design-react-kit';

import HeroSkeleton from '@arpav/components/ArpavTheme/Blocks/HomeBanner/HeroSkeleton';

const HomeBannerSkeleton = () => (
  <Container fluid>
    <Row className="mr-lg-2">
      <Col lg={8} className="px-0">
        <div className="skeleton-template homeMap">
          <div className="it-hero-wrapper it-overlay it-dark">
            <div className="it-hero-text-wrapper bg-dark"></div>
          </div>
        </div>
      </Col>
      <Col lg={4} className="px-0">
        <Row>
          <Col xs={12}>
            <HeroSkeleton />
          </Col>
        </Row>
      </Col>
    </Row>
  </Container>
);

export default HomeBannerSkeleton;
