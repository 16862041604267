/**
 * Highlight news item component.
 * @module components/ArpavTheme/HighlightNews/HighlightNewsItem
 */

import { Button, Dropdown, Table } from 'semantic-ui-react';
import { FormattedDate, Icon } from '@plone/volto/components';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

import DatetimeWidget from '@plone/volto/components/manage/Widgets/DatetimeWidget';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import cx from 'classnames';
import deleteSVG from '@plone/volto/icons/delete.svg';
import dragSVG from '@plone/volto/icons/drag.svg';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import moreSVG from '@plone/volto/icons/more.svg';
import moveDownSVG from '@plone/volto/icons/move-down.svg';
import moveUpSVG from '@plone/volto/icons/move-up.svg';
import showSVG from '@plone/volto/icons/show.svg';
import { useMemo } from 'react';

const messages = defineMessages({
  startDate: {
    id: 'Start date',
    defaultMessage: 'Start date',
  },
  endDate: {
    id: 'End date',
    defaultMessage: 'End date',
  },
});

/**
 * Contents item component class.
 * @function HighlightNewsItemComponent
 * @returns {string} Markup of the component.
 */
export const HighlightNewsItemComponent = ({
  intl,
  item,
  onChange,
  onDelete,
  onMoveToTop,
  onMoveToBottom,
  connectDragPreview,
  connectDragSource,
  connectDropTarget,
  isDragging,
  order,
  isEditMode,
}) => {
  return connectDropTarget(
    connectDragPreview(
      <tr
        key={item.uid}
        className={cx('', {
          'dragging-row': isDragging,
          'highlight-current-news': isEditMode,
          'highlight-primary-news': order === 0,
          'highlight-secondary-news': order === 3,
          'highlight-tertiary-news': order === 7,
          'highlight-news-others': order > 7,
        })}
      >
        <Table.Cell className={cx('', { 'dragging-cell': isDragging })}>
          {connectDragSource(
            <div style={{ display: 'inline-block' }}>
              <Button icon basic>
                <Icon name={dragSVG} size="20px" color="#878f93" className="content drag handle" />
              </Button>
            </div>
          )}
        </Table.Cell>
        <Table.Cell className={cx('', { 'dragging-cell': isDragging })}>{item.title}</Table.Cell>
        <Table.Cell className={cx('', { 'dragging-cell': isDragging })}>
          {item.date ? <FormattedDate date={item.date} includeTime={true} /> : null}
        </Table.Cell>
        <Table.Cell className={cx('', { 'dragging-cell': isDragging, 'ui form': isEditMode })}>
          {isEditMode ? (
            <DatetimeWidget
              id="startDate"
              title={intl.formatMessage(messages.startDate)}
              value={item.startDate}
              onChange={(id, value) => {
                onChange(order, id, value === '' ? undefined : value);
              }}
            />
          ) : item.startDate ? (
            <FormattedDate date={item.startDate} includeTime={true} />
          ) : null}
        </Table.Cell>
        <Table.Cell className={cx('', { 'dragging-cell': isDragging, 'ui form': isEditMode })}>
          {isEditMode ? (
            <DatetimeWidget
              id="endDate"
              title={intl.formatMessage(messages.endDate)}
              value={item.endDate}
              onChange={(id, value) => {
                onChange(order, id, value === '' ? undefined : value);
              }}
            />
          ) : item.endDate ? (
            <FormattedDate date={item.endDate} includeTime={true} />
          ) : null}
        </Table.Cell>
        <Table.Cell className={cx('', { 'dragging-cell': isDragging })} textAlign="right">
          <div className="actions">
            <div>
              <Button icon basic aria-label="Delete" onClick={e => onDelete(e, item.uid)}>
                <Icon name={deleteSVG} color="#e40166" size="24px" />
              </Button>
            </div>
            <Dropdown
              className="row-actions"
              icon={<Icon name={moreSVG} size="24px" color="#007eb1" />}
            >
              <Dropdown.Menu className="left">
                <Link className="item right-dropdown icon-align" to={`${item.url}/highlightnews`}>
                  <Icon name={showSVG} color="#007eb1" size="24px" /> <FormattedMessage id="View" />
                </Link>
                <Dropdown.Item
                  onClick={onMoveToTop}
                  value={order}
                  className="right-dropdown icon-align"
                >
                  <Icon name={moveUpSVG} color="#007eb1" size="24px" />{' '}
                  <FormattedMessage id="Move to top of folder" />
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={onMoveToBottom}
                  value={order}
                  className="right-dropdown icon-align"
                >
                  <Icon name={moveDownSVG} color="#007eb1" size="24px" />{' '}
                  <FormattedMessage id="Move to bottom of folder" />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Table.Cell>
      </tr>
    )
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
HighlightNewsItemComponent.propTypes = {
  item: PropTypes.shape({
    uid: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    position: PropTypes.number,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onMoveToTop: PropTypes.func.isRequired,
  onMoveToBottom: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  order: PropTypes.number.isRequired,
  isEditMode: PropTypes.bool,
};

const DragDropConnector = props => {
  const { DropTarget, DragSource } = props.reactDnd;

  const DndConnectedHighlightNewsItem = useMemo(
    () =>
      DropTarget(
        'item',
        {
          hover(props, monitor) {
            const id = monitor.getItem().id;
            const dragOrder = monitor.getItem().order;
            const hoverOrder = props.order;

            if (dragOrder === hoverOrder) {
              return;
            }

            props.onOrderItem(id, dragOrder, hoverOrder - dragOrder);

            monitor.getItem().order = hoverOrder;
          },
          drop(props, monitor) {
            const dragOrder = monitor.getItem().startOrder;
            const dropOrder = props.order;

            if (dragOrder === dropOrder) {
              return;
            }
            monitor.getItem().order = dropOrder;
          },
        },
        connect => ({
          connectDropTarget: connect.dropTarget(),
        })
      )(
        DragSource(
          'item',
          {
            beginDrag(props) {
              return {
                id: props.item.uid,
                order: props.order,
                startOrder: props.order,
              };
            },
          },
          (connect, monitor) => ({
            connectDragSource: connect.dragSource(),
            connectDragPreview: connect.dragPreview(),
            isDragging: monitor.isDragging(),
          })
        )(HighlightNewsItemComponent)
      ),
    [DragSource, DropTarget]
  );

  return <DndConnectedHighlightNewsItem {...props} />;
};

export default compose(injectIntl, injectLazyLibs('reactDnd'))(DragDropConnector);
