import {
  AnnouncementAttachments,
  AnnouncementInfo,
  Metadata,
  PageHeader,
  SkipToMainContent,
} from '@arpav/components/ArpavTheme/View';
import { Col, Container, Row } from 'design-react-kit/dist/design-react-kit';

const Announcement = ({ content, token, history, location }) => {
  let attachments = {};
  let att_categories = [];

  content.items.forEach(att => {
    if (!attachments[att.type.token]) {
      attachments[att.type.token] = [];
      att_categories.push(att.type);
    }
    attachments[att.type.token].push(att);
  });

  return (
    <Container id="page-announcement">
      <SkipToMainContent />
      <PageHeader content={content} showdates={false} />
      <Row className="border-top row-column-border row-column-menu-left">
        <Col
          tag="section"
          lg={8}
          id="main-content-section"
          className="it-page-sections-container pr-lg-0"
        >
          <div className="mt-3 mt-lg-5 mr-lg-5 ml-lg-3">
            {content.text && (
              <div
                dangerouslySetInnerHTML={{
                  __html: content.text.data,
                }}
              />
            )}
            {att_categories.map(({ title, token }) => (
              <AnnouncementAttachments title={title} files={attachments[token]} key={token} />
            ))}
          </div>
        </Col>
        <Col tag="aside" lg={4}>
          {<AnnouncementInfo content={content} />}
        </Col>
      </Row>

      <div className="mb-5  ml-lg-3">
        <Metadata content={content} />
      </div>
    </Container>
  );
};

export default Announcement;
