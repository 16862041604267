import { defineMessages, injectIntl } from 'react-intl';

const messages = defineMessages({
  content: {
    id: 'Go to content',
    defaultMessage: 'Vai al contenuto',
  },
});

const SkipToMainContent = ({ intl, to = '#main-content-section' }) => (
  <a className="sr-only" href={to}>
    {intl.formatMessage(messages.content)}
  </a>
);

export default injectIntl(SkipToMainContent);
