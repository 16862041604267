import { Icon } from '@arpav/components/ArpavTheme';
import PropTypes from 'prop-types';

/**
 * Dates view component class.
 * @function ArgumentIcon
 * @params {object} Dates: object.
 * @returns {string} Markup of the component.
 */
const ArgumentIcon = ({ icon }) =>
  icon ? (
    <div className="icon-argument-container mb-2">
      <Icon icon={icon} />
    </div>
  ) : null;

export default ArgumentIcon;

ArgumentIcon.propTypes = {
  icon: PropTypes.string,
};
