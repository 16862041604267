/**
 * EIEvaluation view component.
 * @module components/theme/ArpavView/Indicators
 */

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  LinkList,
  LinkListItem,
  Row,
  Table,
} from 'design-react-kit/dist/design-react-kit';
import { FormattedDate, UniversalLink } from '@plone/volto/components';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import Legend, { StatusIcon, TrendIcon } from '@arpav/components/ArpavTheme/View/Indicators/Legend';
import { Metadata, RichText, TextOrBlocks } from '@arpav/components/ArpavTheme/View';

import { Icon } from '@arpav/components/ArpavTheme';
import { useState } from 'react';

const messages = defineMessages({
  nextYear: {
    id: 'Next year',
    defaultMessage: 'Next year',
  },
  previousYear: {
    id: 'Previous year',
    defaultMessage: 'Previous year',
  },
});

const MetadataTable = ({ content, indicator }) => (
  <Table>
    <colgroup style={{ minWidth: '180px' }}></colgroup>
    <colgroup></colgroup>
    <tbody>
      <tr>
        <th scope="row">
          <FormattedMessage id="Last update date" defaultMessage="Last update date" />
        </th>
        <td>
          <FormattedDate date={content.indicator_last_update} />
        </td>
      </tr>
      <tr>
        <th scope="row">
          <FormattedMessage id="Data source" defaultMessage="Data source" />
        </th>
        <td>{content.data_source}</td>
      </tr>
      <tr>
        <th scope="row">
          <FormattedMessage id="Source of the indicator" defaultMessage="Source of the indicator" />
        </th>
        <td>{content.indicator_source}</td>
      </tr>
      <tr>
        <th scope="row">
          <FormattedMessage id="Digital source of data" defaultMessage="Digital source of data" />
        </th>
        <td>{content.digital_data_source}</td>
      </tr>
      <tr>
        <th scope="row">
          <FormattedMessage id="Purpose" defaultMessage="Purpose" />
        </th>
        <td>{indicator.purpose}</td>
      </tr>
      <tr>
        <th scope="row">
          <FormattedMessage id="Unit of measure" defaultMessage="Unit of measure" />
        </th>
        <td>{content.unit_measure || indicator.unit_measure}</td>
      </tr>
      <tr>
        <th scope="row">
          <FormattedMessage id="Processing method" defaultMessage="Processing method" />
        </th>
        <td>{indicator.processing_method}</td>
      </tr>
      <tr>
        <th scope="row">
          <FormattedMessage id="Normative reference" defaultMessage="Normative reference" />
        </th>
        <td>{content.normative_reference}</td>
      </tr>
      <tr>
        <th scope="row">
          <FormattedMessage id="Reference value" defaultMessage="Reference value" />
        </th>
        <td>{content.reference_value}</td>
      </tr>
      <tr>
        <th scope="row">
          <FormattedMessage
            id="Periodicity of data collection"
            defaultMessage="Periodicity of data collection"
          />
        </th>
        <td>{content.period_data_collection}</td>
      </tr>
      <tr>
        <th scope="row">
          <FormattedMessage
            id="Periodicity of indicator collection"
            defaultMessage="Periodicity of indicator collection"
          />
        </th>
        <td>{content.period_indicator_collection}</td>
      </tr>
      <tr>
        <th scope="row">
          <FormattedMessage id="Reference periodicity" defaultMessage="Reference periodicity" />
        </th>
        <td>
          {content.data_time_coverage_from && (
            <>
              <FormattedMessage id="from" defaultMessage="from" />{' '}
              <FormattedDate date={content.data_time_coverage_from} />{' '}
            </>
          )}
          {content.data_time_coverage_to && (
            <>
              <FormattedMessage id="to" defaultMessage="to" />{' '}
              <FormattedDate date={content.data_time_coverage_to} />
            </>
          )}
        </td>
      </tr>
      <tr>
        <th scope="row">
          <FormattedMessage
            id="Elementary detection unit"
            defaultMessage="Elementary detection unit"
          />
        </th>
        <td>{content.elementary_detection_unit}</td>
      </tr>
      <tr>
        <th scope="row">
          <FormattedMessage
            id="Geographic minimum level"
            defaultMessage="Geographic minimum level"
          />
        </th>
        <td>{content.geo_min_level?.title}</td>
      </tr>
      <tr>
        <th scope="row">
          <FormattedMessage id="Geographical coverage" defaultMessage="Geographical coverage" />
        </th>
        <td>{content.geo_coverage}</td>
      </tr>
      <tr>
        <th scope="row">
          <FormattedMessage id="Aspetcs to improve" defaultMessage="Aspetcs to improve" />
        </th>
        <td>{content.improve_aspects}</td>
      </tr>
      <tr>
        <th scope="row">
          <FormattedMessage id="Useful links" defaultMessage="Useful links" />
        </th>
        <td>
          <LinkList className="left-align">
            {content.links?.map((link, index, links) => (
              <li key={link['@id']}>
                <UniversalLink href={link.link} title={link.label} className="left-icon list-item">
                  <Icon className="left" icon="it-chevron-right" aria-hidden />
                  {link.label || link.link}
                </UniversalLink>
                {index + 1 < links.length ? <span className="list-item divider" /> : null}
              </li>
            ))}
            {content.links?.length > 0 && <LinkListItem divider />}
            {indicator.links?.map((link, index, links) => (
              <li key={link['@id']}>
                <UniversalLink href={link.link} title={link.label} className="left-icon list-item">
                  <Icon className="left" icon="it-chevron-right" aria-hidden />
                  {link.label || link.link}
                </UniversalLink>
                {index + 1 < links.length ? <span className="list-item divider" /> : null}
              </li>
            ))}
          </LinkList>
        </td>
      </tr>
    </tbody>
  </Table>
);

/**
 * EIEvaluation view component class.
 * @function EIEvaluation
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const EIEvaluation = ({ content, intl }) => {
  const [collapseElementOpen, setCollapseElement] = useState('');
  const indicator = content?.parent;

  return (
    <div id="page-ei-evaluation">
      <Container>
        <Row>
          <Col xs={12} className="mb-5">
            <Card spacing className="card-bg card-img rounded shadow no-after">
              <CardBody>
                <CardTitle tag="h1">{indicator.title}</CardTitle>
                <CardText>{indicator.description}</CardText>
                <div className="category-top">
                  <span className="category">
                    <FormattedMessage id="year " defaultMessage="Year " />:
                  </span>
                  {content.id}
                </div>
                <div className="category-top">
                  <span className="category">
                    <FormattedMessage id="Last update date" defaultMessage="Last update date" />:{' '}
                  </span>
                  <FormattedDate date={content.indicator_last_update} />
                </div>
                <div className="category-top">
                  <span className="category">
                    <FormattedMessage id="DPSIR" defaultMessage="DPSIR" />:{' '}
                  </span>
                  {indicator.dpsir?.map(item => item.token).join(', ')}
                </div>
                <div className="category-top">
                  <span className="category">
                    <FormattedMessage id="Current state" defaultMessage="Current state" />:{' '}
                  </span>
                  <StatusIcon value={content.status?.token} />
                </div>
                <div className="category-top">
                  <span className="category">
                    <FormattedMessage id="Trend" defaultMessage="Trend" />:{' '}
                  </span>
                  <TrendIcon value={content.trend?.token} />
                </div>
                <Row>
                  {content.previous_item?.['@id'] && (
                    <Col>
                      <Button
                        icon
                        color="primary"
                        tag={UniversalLink}
                        item={content.previous_item}
                        title={intl.formatMessage(messages.previousYear)}
                      >
                        <Icon icon="it-arrow-left" className="mr-2" />
                        <FormattedMessage
                          id="Year {year}"
                          defaultMessage="Year {year}"
                          values={{
                            year: content.previous_item.title,
                          }}
                        />
                      </Button>
                    </Col>
                  )}
                  {content.next_item?.['@id'] && (
                    <Col className="text-right">
                      <Button
                        icon
                        color="primary"
                        tag={UniversalLink}
                        item={content.next_item}
                        title={intl.formatMessage(messages.nextYear)}
                      >
                        <FormattedMessage
                          id="Year {year}"
                          defaultMessage="Year {year}"
                          values={{
                            year: content.next_item.title,
                          }}
                        />
                        <Icon icon="it-arrow-right" className="ml-2" />
                      </Button>
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {(indicator.text || indicator.blocks_layout?.items?.length > 0) && (
              <>
                <h2>
                  <FormattedMessage id="Description" defaultMessage="Description" />
                </h2>
                <RichText serif={false} content={indicator.text} />
                <div className="mt-3 mt-lg-5">
                  <TextOrBlocks content={indicator} exclude={['title', 'description', 'toc']} />
                </div>
              </>
            )}
            {/*
             ** Inserimento dei blocchi
             */}
            {content.target?.data && (
              <>
                <h2>
                  <FormattedMessage id="Purpose" defaultMessage="Purpose" />
                </h2>
                <RichText serif={false} content={content.target} />
              </>
            )}
            {content.evaluations && (
              <>
                <h2>
                  <FormattedMessage id="Evaluation" defaultMessage="Evaluation" />
                </h2>
                <RichText serif={false} content={content.evaluations} />
              </>
            )}
            {/*
             ** Inserimento dei blocchi
             */}
            {content.blocks_layout?.items?.length > 0 ? (
              <div className="mt-3 mt-lg-5">
                <TextOrBlocks content={content} exclude={['title', 'description', 'toc']} />
              </div>
            ) : null}
            {content.next_update && (
              <>
                <p>
                  <strong>
                    <FormattedMessage id="next_update" defaultMessage="Next update" />:{' '}
                  </strong>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: content.next_update,
                    }}
                  />
                </p>
              </>
            )}
            {content.license && (
              <>
                <p>
                  <strong>
                    <FormattedMessage id="license" defaultMessage="License" />:{' '}
                  </strong>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: content.license.title,
                    }}
                  />
                </p>
              </>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Accordion>
              <AccordionHeader
                active={collapseElementOpen === '1'}
                onToggle={() => setCollapseElement(collapseElementOpen ? '' : '1')}
              >
                <FormattedMessage id="Metadata" defaultMessage="Metadata" />
              </AccordionHeader>
              <AccordionBody active={collapseElementOpen === '1'}>
                <MetadataTable content={content} indicator={indicator} />
              </AccordionBody>
            </Accordion>
          </Col>
        </Row>
        <Legend showDpsir={true} />
        <div className="mb-5">
          <Metadata content={content} />
        </div>
      </Container>
    </div>
  );
};

export default injectIntl(EIEvaluation);
