/**
 * Login container.
 * @module components/ArpavTheme/HighlightNews/HighlightNews
 */

import { Button, Container, Dimmer, Loader, Segment, Table } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { Helmet, asyncConnect, getBaseUrl } from '@plone/volto/helpers';
import { Icon, NotFound, Toast, Toolbar } from '@plone/volto/components';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  getHighlightNews,
  resetHighlightNews,
  updateHighlightNews,
} from '@arpav/actions/highlightNews/highlightNews';
import { useEffect, useMemo, useState } from 'react';

import HighlightNewsItem from '@arpav/components/ArpavTheme/manage/HighlightNews/HighlightNewsItem';
import { Portal } from 'react-portal';
import { Unauthorized } from '@arpav/components/ArpavTheme';
import clearSVG from '@plone/volto/icons/clear.svg';
import { compose } from 'redux';
import { find } from 'lodash';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import { listActions } from '@plone/volto/actions';
import move from 'lodash-move';
import qs from 'query-string';
import saveSVG from '@plone/volto/icons/save.svg';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

const messages = defineMessages({
  highlightNews: {
    id: 'Highlight news',
    defaultMessage: 'Highlight news',
  },
  invalidDate: {
    id: 'Invalid date',
    defaultMessage: 'Invalid date',
  },
  invalidDateMessage: {
    id: 'End date must be after start date.',
    defaultMessage: 'End date must be after start date.',
  },
  messageUpdate: {
    id: 'Highlights news are been updated',
    defaultMessage: 'Highlights news are been updated',
  },
  errorUpdate: {
    id: 'Error on updating highlights news',
    defaultMessage: 'Error on updating highlights news',
  },
});

const HighlightNews = ({ content, history, intl, objectActions, pathname, returnUrl, token }) => {
  const loading = useSelector(state => state.highlightNews.get?.loading);
  const updateState = useSelector(state => state.highlightNews.update || {});
  const highlightnews_items = useSelector(state => state.highlightNews.data?.items);

  const [items, setItems] = useState(highlightnews_items ? [...highlightnews_items] : []);

  const dispatch = useDispatch();

  const editPermission = find(objectActions, { id: 'edit' });

  useEffect(() => {
    dispatch(getHighlightNews(getBaseUrl(pathname)));
  }, [dispatch, pathname]);

  useEffect(() => {
    if (!updateState.loading && updateState.loaded) {
      if (!updateState?.error) {
        toast.success(
          <Toast
            success
            title={intl.formatMessage({ id: 'Success' })}
            content={intl.formatMessage(messages.messageUpdate)}
          />
        );
      } else {
        toast.error(
          <Toast
            error
            title={intl.formatMessage({
              id: 'Error',
            })}
            content={intl.formatMessage(messages.errorUpdate)}
          />
        );
      }
      history.push(returnUrl || getBaseUrl(pathname));
      // reset highlights store data on exit
      return () => dispatch(resetHighlightNews());
    }
  }, [updateState.loading, updateState.loaded]);

  useEffect(() => {
    if (highlightnews_items) {
      setItems([...highlightnews_items]);
    }
  }, [highlightnews_items]);

  const onChange = (order, id, value) => {
    if (
      (id === 'startDate' && value && items[order].endDate && value >= items[order].endDate) ||
      (id === 'endDate' && value && items[order].startDate && value < items[order].startDate)
    ) {
      toast.error(
        <Toast
          error
          title={intl.formatMessage(messages.invalidDate)}
          content={intl.formatMessage(messages.invalidDateMessage)}
        />
      );
    } else {
      items[order][id] = value;
      setItems([...items]);
    }
  };

  const onDelete = (e, uid) => {
    setItems(items.filter(item => item.uid !== uid));
  };
  /**
   * On order item
   * @method onOrderItem
   * @param {string} id Item id
   * @param {number} itemIndex Item index
   * @param {number} delta Delta
   * @returns {undefined}
   */
  const onOrderItem = (id, itemIndex, delta) => {
    setItems([...move(items, itemIndex, itemIndex + delta)]);
  };
  const onMoveToTop = (event, { value }) => {
    setItems([...move(items, value, 0)]);
  };
  const onMoveToBottom = (event, { value }) => {
    setItems([...move(items, value, items.length - 1)]);
  };

  const onSave = () => {
    dispatch(
      updateHighlightNews(getBaseUrl(pathname), {
        items: items.map((item, index) => ({
          ...item,
          position: index,
        })),
      })
    );
  };

  /**
   * Cancel handler
   * @method onCancel
   * @returns {undefined}
   */
  const onCancel = () => {
    history.push(returnUrl || getBaseUrl(pathname));
  };

  return token && editPermission ? (
    content?.['@type'] === 'News Item' ? (
      <Container id="page-highlight-news" className="highlight-news">
        <Dimmer.Dimmable as="div" blurring dimmed={loading}>
          <Dimmer active={loading} inverted>
            <Loader indeterminate size="massive">
              {intl.formatMessage({
                id: 'loading',
              })}
            </Loader>
          </Dimmer>

          <Helmet title={intl.formatMessage(messages.highlightNews)} />
          <Container>
            <article id="content">
              <section id="content-core">
                <Segment.Group raised>
                  <div className="contents-table-wrapper">
                    <Table selectable compact singleLine attached>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell></Table.HeaderCell>
                          <Table.HeaderCell>
                            <FormattedMessage id="Title" />
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            <FormattedMessage id="Data" />
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            <FormattedMessage id="Visible from" defaultMessage="Visible from" />
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            <FormattedMessage id="Visible to" defaultMessage="Visible to" />
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            <FormattedMessage id="Actions" />
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {items?.map((item, order) => (
                          <HighlightNewsItem
                            key={item.uid}
                            item={item}
                            order={order}
                            onChange={onChange}
                            onDelete={onDelete}
                            onOrderItem={onOrderItem}
                            onMoveToTop={onMoveToTop}
                            onMoveToBottom={onMoveToBottom}
                            isEditMode={item.uid === content.UID}
                          />
                        ))}
                      </Table.Body>
                    </Table>
                  </div>
                </Segment.Group>
              </section>
            </article>
          </Container>
          {__CLIENT__ && (
            <Portal node={document.getElementById('toolbar')}>
              <Toolbar
                pathname={pathname}
                hideDefaultViewButtons
                inner={
                  <>
                    <Button
                      id="toolbar-save"
                      className="save"
                      aria-label={intl.formatMessage({
                        id: 'Save',
                      })}
                      onClick={onSave}
                      disabled={loading}
                      loading={loading}
                    >
                      <Icon
                        name={saveSVG}
                        className="circled"
                        size="30px"
                        title={intl.formatMessage({
                          id: 'Save',
                        })}
                      />
                    </Button>
                    <Button
                      className="cancel"
                      aria-label={intl.formatMessage({
                        id: 'Cancel',
                      })}
                      onClick={onCancel}
                    >
                      <Icon
                        name={clearSVG}
                        className="circled"
                        size="30px"
                        title={intl.formatMessage({
                          id: 'Cancel',
                        })}
                      />
                    </Button>
                  </>
                }
              />
            </Portal>
          )}
        </Dimmer.Dimmable>
      </Container>
    ) : (
      <NotFound />
    )
  ) : (
    <Unauthorized />
  );
};

const DragDropConnector = props => {
  const { DragDropContext } = props.reactDnd;
  const HTML5Backend = props.reactDndHtml5Backend.default;

  const DndConnectedContents = useMemo(() => DragDropContext(HTML5Backend)(HighlightNews), [
    DragDropContext,
    HTML5Backend,
  ]);

  return <DndConnectedContents {...props} />;
};

export default compose(
  injectIntl,
  asyncConnect([
    {
      key: 'actions',
      promise: async ({ location, store: { dispatch } }) => {
        await dispatch(listActions(getBaseUrl(location.pathname)));
      },
    },
  ]),
  withRouter,
  connect((state, props) => ({
    content: state.content.data,
    objectActions: state.actions.actions.object,
    pathname: props.location.pathname,
    returnUrl: qs.parse(props.location.search).return_url,
    token: state.userSession.token,
  })),
  injectLazyLibs(['reactDnd', 'reactDndHtml5Backend'])
)(DragDropConnector);
