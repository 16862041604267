import { Col, Container, Row } from 'design-react-kit/dist/design-react-kit';
import { ListingBlockBody as ListingBody, SidebarPortal } from '@plone/volto/components';
import { memo, useEffect } from 'react';

import SummaryData from '@arpav/components/ArpavTheme/Blocks/AnnouncementsSummary/SummaryData';
import PropTypes from 'prop-types';
import { getBaseUrl } from '@plone/volto/helpers';
import { isEqual } from 'lodash';
import { useIntl } from 'react-intl';
import { AnnouncementsSummaryBody } from 'components/ArpavTheme';

import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import withResourcesData from '@arpav/components/ArpavTheme/Blocks/DataMap/withResourcesData';

const AnnouncementsSummaryEdit = memo(
  props => {
    const { data, onChangeBlock, block, selected, pathname } = props;

    const intl = useIntl();

    // componentDidMount
    useEffect(() => {
      if (!data.query) {
        onChangeBlock(block, {
          ...data,
          query: [],
          block,
        });
      }
    }, []);

    const placeholder =
      data.placeholder ||
      (data?.query?.length
        ? intl.formatMessage({ id: 'Results preview' })
        : intl.formatMessage({ id: 'Contained items' }));

    return (
      <>
        <Container>
          <Row>
            <Col>
              <p className="items-preview">{placeholder}</p>
            </Col>
          </Row>
          <div className="public-ui card-wrapper card-teaser-wrapper card-teaser-wrapper-equal card-teaser-block-2 mb-3">
            <AnnouncementsSummaryBody id={block} path={getBaseUrl(pathname)} data={data} />
          </div>
        </Container>
        <SidebarPortal selected={selected}>
          <SummaryData
            key={block}
            {...props}
            data={data}
            block={block}
            onChangeBlock={onChangeBlock}
          />
        </SidebarPortal>
      </>
    );
  },
  function areEquals(prevProps, nextProps) {
    return !(nextProps.selected !== prevProps.selected || !isEqual(prevProps.data, nextProps.data));
  }
);

AnnouncementsSummaryEdit.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  block: PropTypes.string.isRequired,
  onSelectBlock: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.any),
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
  pathname: PropTypes.string.isRequired,
};

export default compose(
  injectIntl,
  withResourcesData(({ data: { resources }, block, path, pathname }) => ({
    block,
    resources,
    path: path ?? pathname,
    isEditMode: true,
  }))
)(AnnouncementsSummaryEdit);