/**
 * File view component.
 * @module components/theme/View/FileView
 */

import { Col, Container, Row } from 'design-react-kit/dist/design-react-kit';
import { Metadata, PageHeader, SkipToMainContent } from '@arpav/components/ArpavTheme/View';
import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { flattenToAppURL } from '@plone/volto/helpers';

/**
 * View container class.
 * @class View
 * @extends Component
 */
class FileView extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    content: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      file: PropTypes.shape({
        download: PropTypes.string,
        filename: PropTypes.string,
      }),
    }).isRequired,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    content: null,
    token: null,
  };

  /**
   * Component mount
   */
  componentDidMount() {
    if (!this.props.token) {
      window.location = flattenToAppURL(`${this.props.content['@id']}/@@display-file/file`);
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <Container id="page-document">
        <SkipToMainContent />
        <PageHeader content={this.props.content} showdates={false} />
        <Row className={'border-top row-column-border row-column-menu-left'}>
          <Col
            tag="section"
            lg={8}
            id="main-content-section"
            className="it-page-sections-container pr-lg-0"
          >
            <div className="mt-3 mt-lg-5 mr-lg-5 ml-lg-3">
              {this.props.content.file?.download && (
                <a href={flattenToAppURL(this.props.content.file.download)}>
                  {this.props.content.file.filename}{' '}
                  <small>({this.props.content.file['content-type']})</small>
                </a>
              )}
            </div>
          </Col>

          <Col tag="aside" lg={4}></Col>
        </Row>
        <div className="mb-5  ml-lg-3">
          <Metadata content={this.props.content} />
        </div>
      </Container>
    );
  }
}

export default FileView;
