import { defineMessages } from 'react-intl';

const messages = defineMessages({
  newsInEvidence: {
    id: 'News in evidence',
    defaultMessage: 'News in evidence',
  },
});

const NewsInEvidenceSchema = ({ intl }) => ({
  title: intl.formatMessage(messages.newsInEvidence),
  fieldsets: [
    {
      id: 'default',
      title: intl.formatMessage({ id: 'Default' }),
      fields: ['title', 'description', 'linkTitle', 'linkHref'],
    },
  ],
  properties: {
    title: {
      title: intl.formatMessage({ id: 'Title' }),
    },
    description: {
      title: intl.formatMessage({ id: 'Description' }),
      widget: 'textarea',
    },
    linkTitle: {
      title: intl.formatMessage({
        id: 'Link title',
      }),
    },
    linkHref: {
      title: intl.formatMessage({
        id: 'Link to',
      }),
      widget: 'object_browser',
      mode: 'link',
      selectedItemAttrs: ['Title', 'Description'],
      allowExternals: true,
    },
  },
  required: ['title'],
});

export default NewsInEvidenceSchema;
