/**
 * Most visited actions.
 * @module actions/mostVisited/mostVisited
 */

import { GET_MOST_VISITED } from '@arpav/constants/ActionTypes';

/**
 * Get most visited function.
 *
 * @function getMostVisited
 * @param {Object} query Data.
 * @returns {Object} Get schema action.
 */
export function getMostVisited(data) {
  const query = Object.keys(data)
    .map(function (key) {
      return key + '=' + data[key];
    })
    .join('&');
  return {
    type: GET_MOST_VISITED,
    request: {
      op: 'get',
      path: `/@most_visited_pages${query ? `?${query}` : ''}`,
      data: data,
    },
  };
}
