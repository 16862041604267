import { LastPublicationsBody } from '@arpav/components/ArpavTheme';
import { getBaseUrl } from '@plone/volto/helpers';

const Edit = ({ block, pathname }) => {
  return (
    <div className="public-ui">
      <LastPublicationsBody id={block} path={getBaseUrl(pathname)} />
    </div>
  );
};

export default Edit;
