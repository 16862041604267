import {
  Card,
  CardBody,
  CardReadMore,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from 'design-react-kit/dist/design-react-kit';
import { FormattedDate, Icon as PloneIcon } from '@plone/volto/components';
import { defineMessages, injectIntl } from 'react-intl';
import { getBaseUrl, getContentIcon } from '@plone/volto/helpers';
import { useDispatch, useSelector } from 'react-redux';

import { ListingLinkMore } from '@arpav/components/ArpavTheme';
import { NewsItemCard } from '@arpav/components/ArpavTheme/View/Commons/NewsItemCard';
import Skeleton from '@arpav/components/ArpavTheme/Blocks/NewsInEvidence/Skeleton';
import cx from 'classnames';
import { getHighlightNews } from '@arpav/actions';
import { useEffect } from 'react';

const messages = defineMessages({
  pressRelease: {
    id: 'Press release',
    defaultMessage: 'Press release',
  },
  readMore: {
    id: 'Read More…',
    defaultMessage: 'Read More…',
  },
});

const Body = ({ intl, path, data, isEditMode, id }) => {
  const loading = useSelector(state => state.highlightNews.subrequests[id]?.get?.loading);
  const items = useSelector(state => state.highlightNews.subrequests[id]?.data?.items);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHighlightNews(getBaseUrl(path), { b_size: 7, b_start: 1 }, id));
  }, [dispatch, path]);

  return (
    <Container>
      <Row className="mb-4">
        <Col className="text-center template-header">
          <h2 className="mb-4">{data.title}</h2>
          {data.description ? <p>{data.description}</p> : null}
        </Col>
      </Row>
      {!loading ? (
        <>
          <Row>
            {items?.slice(0, 3).map(item => (
              <Col xs={12} lg={4} key={item['@id']}>
                {/*start card*/}
                <NewsItemCard intl={intl} item={item} isEditMode={isEditMode} />
                {/*end card*/}
              </Col>
            ))}
          </Row>
          <Row>
            {items?.slice(3).map(item => (
              <Col xs={12} lg={3} key={item['@id']}>
                {/*start card*/}
                <Card spacing className="card-bg card-img rounded shadow no-after">
                  <CardBody className="pb-5">
                    <div className="category-top categoryicon-top">
                      <span
                        className={cx(
                          'category',
                          `${item.is_pressrelease ? 'pressrelease' : 'newsitem'}`
                        )}
                      >
                        <PloneIcon
                          name={getContentIcon(item['@type'])}
                          size="18px"
                          className="icon"
                          title={item['@type']}
                        />
                        {item.date && <FormattedDate date={item.date} />}
                        <br />
                        {item.is_pressrelease
                          ? intl.formatMessage(messages.pressRelease)
                          : intl.formatMessage({ id: 'News Item' })}
                      </span>
                    </div>
                    <CardTitle tag="h3">{item.title}</CardTitle>
                    <CardText>{item.text}</CardText>
                    <CardReadMore
                      href={item.url}
                      title={item.title}
                      text={intl.formatMessage({
                        id: 'Read More…',
                      })}
                      iconName="it-arrow-right"
                    ></CardReadMore>
                  </CardBody>
                </Card>
                {/*end card*/}
              </Col>
            ))}
          </Row>
          <ListingLinkMore title={data.linkTitle} href={data.linkHref} className="my-4" />
        </>
      ) : (
        <Skeleton />
      )}
    </Container>
  );
};

export default injectIntl(Body);
