import {
  ColorListWidget,
  ColorWidget,
  IconWidget,
  JsonWidget,
  TextAlign,
  TimeWidget,
  TinymceWidget,
} from '@arpav/components/ArpavTheme';

import { ObjectBrowserWidgetMode } from '@plone/volto/components/manage/Widgets/ObjectBrowserWidget';
import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';
import UrlWidget from 'customizations/volto/components/manage/Widgets/UrlWidget';

const getArpavWidgets = config => {
  const widgets = {
    widget: {
      ...config.widgets.widget,
      color_list: ColorListWidget,
      color: ColorWidget,
      icon: IconWidget,
      json: JsonWidget,
      richtext: TinymceWidget,
      time: TimeWidget,
    },
    factory: {
      ...config.widgets.factory,
      Datagrid: ObjectListWidget,
    },
    type: {
      ...config.widgets.type,
      time: TimeWidget,
    },
    id: {
      ...config.widgets.id,
      curriculum_url: UrlWidget,
    },
  };
  if (!config.widgets.widget.pick_object) {
    widgets.widget.pick_object = ObjectBrowserWidgetMode('link');
  }

  if (!config.widgets.widget.text_align) {
    widgets.widget.text_align = TextAlign;
  }
  return widgets;
};

export default getArpavWidgets;
