import { Col, Row } from 'design-react-kit/dist/design-react-kit';
import { defineMessages, injectIntl } from 'react-intl';

import { Pagination } from '@arpav/components/ArpavTheme';
import { SelectInput } from '@arpav/components';
import Skeleton from '@arpav/components/ArpavTheme/Blocks/DataTable/Skeleton';
import { Table } from 'semantic-ui-react';
import { compose } from 'redux';
import config from '@plone/volto/registry';
import withFileData from '@arpav/components/ArpavTheme/Blocks/DataTable/withFileData';

const messages = defineMessages({
  noData: {
    id: 'No data',
    defaultMessage: 'No data',
  },
});

const calculatePagesCount = (pageSize, totalCount) => {
  // we suppose that if we have 0 items we want 1 empty page
  return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize);
};

const format = data => {
  return {
    fixed: data.fixed,
    compact: data.compact,
    basic: data.basic ? 'very' : undefined,
    celled: data.celled,
    inverted: data.inverted,
    striped: data.striped,
    sortable: data.sortable,
  };
};

const Cell = ({ column, value }) => {
  const { renderer } = column;

  const Render = renderer
    ? config.blocks.blocksConfig.dataTable.cellRenderers[renderer].view
    : null;
  return Render ? (
    <Render column={column} value={value} />
  ) : column.HTMLformat ? (
    <div dangerouslySetInnerHTML={{ __html: value }} />
  ) : (
    value
  );
};

const DataTableView = ({
  intl,
  data_result,
  loading,
  data,
  isEditMode,
  onPaginationChange,
  currentPage,
  onSort,
  sortColumn,
  sortDirection,
  filter,
  onFilter,
  columns,
  filteredColumns,
  showTable,
  filtersOptions,
}) => {
  const column = sortColumn || columns?.[0]?.column;
  showTable = isEditMode || showTable;

  return (
    <div className="block dataTable">
      {data_result?.data?.length ? (
        <>
          {filteredColumns?.length ? (
            <Row className="pb-3 border-bottom">
              {filteredColumns.map((col, i) => (
                <Col
                  xs={6}
                  md={filteredColumns.length > 4 ? 3 : Math.floor(12 / filteredColumns.length)}
                  key={i}
                >
                  <span>{col.filterRequired}</span>
                  <SelectInput
                    id={`table-filter-${col.column}`}
                    isClearable={true}
                    isSearchable={true}
                    placeholder={
                      !loading && showTable
                        ? col.title || col.column
                        : 'Seleziona la provincia per visualizzare la tabella'
                    }
                    value={
                      filtersOptions[col.column]
                        ?.sort((a, b) => {
                          a;
                          const nameA = a?.value?.toUpperCase ? a?.value?.toUpperCase() : a?.value;
                          const nameB = b?.value?.toUpperCase ? b?.value?.toUpperCase() : b?.value;
                          if (nameA < nameB) {
                            return -1;
                          }
                          if (nameA > nameB) {
                            return 1;
                          }
                          return 0;
                        })
                        .filter(({ value }) => value === filter[col.column])[0]
                    }
                    onChange={opt => {
                      if (opt?.value) {
                        filter[col.column] = opt.value;
                      } else {
                        delete filter[col.column];
                      }
                      onFilter({
                        ...filter,
                      });
                    }}
                    options={filtersOptions[col.column]}
                  />
                </Col>
              ))}
            </Row>
          ) : null}
          <div className="table-container">
            <Table {...format(data)}>
              <Table.Header>
                <Table.Row>
                  {columns?.map((col, i) => (
                    <Table.HeaderCell
                      key={i}
                      textAlign={col.textAlign}
                      sorted={column === col.column ? sortDirection : null}
                      onClick={() => {
                        data.sortable && onSort(col.column);
                      }}
                    >
                      {col.title || col.column}
                    </Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>
              {!loading && showTable ? (
                <Table.Body>
                  {data_result.data?.map((o, i) => (
                    <Table.Row key={i}>
                      {columns?.map((col, y) => (
                        <Table.Cell textAlign={col.textAlign} key={`${y}-${i}`}>
                          <Cell value={o[col.column] ?? ''} column={col} />
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  ))}
                  {isEditMode && (
                    <Table.Row>
                      {columns?.map((col, y) => (
                        <Table.Cell textAlign={col.textAlign} key={`${y}-last`}>
                          <Cell value="..." column={col} />
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  )}
                </Table.Body>
              ) : loading && showTable ? (
                <Skeleton columns={columns} pageSize={data?.page_size} />
              ) : null}
              {!isEditMode && data?.pagination && (
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan={columns?.length}>
                      <Pagination
                        activePage={currentPage}
                        totalPages={calculatePagesCount(
                          data_result?.meta?.page_size,
                          data_result?.meta?.total
                        )}
                        onPageChange={(e, { activePage: { children } }) =>
                          onPaginationChange(e, { activePage: children })
                        }
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              )}
            </Table>
          </div>
        </>
      ) : loading && showTable ? (
        <Skeleton columns={columns} pageSize={data?.page_size} />
      ) : (
        !loading && <div>{intl.formatMessage(messages.noData)}</div>
      )}
    </div>
  );
};

export default compose(
  injectIntl,
  withFileData(
    ({
      data: {
        source_type,
        file_path,
        api_url,
        default_sort_direction,
        default_sort_column,
        pagination,
        page_size,
        block,
      },
    }) => ({
      source_type,
      file_path,
      api_url,
      default_sort_direction,
      default_sort_column,
      pagination,
      page_size,
      block,
      isEditMode: false,
    })
  )
)(DataTableView);
