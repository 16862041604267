/**
 * Add your action types here.
 * @module constants/ActionTypes
 * @example
 * export const UPDATE_CONTENT = 'UPDATE_CONTENT';
 */

export const GET_ARPAV_API_CONTENT = 'GET_ARPAV_API_CONTENT';
export const GET_DEFAULT_VIEW = 'GET_DEFAULT_VIEW';
export const GET_HIGHLIGHT_NEWS = 'GET_HIGHLIGHT_NEWS';
export const RESET_HIGHLIGHT_NEWS = 'RESET_HIGHLIGHT_NEWS';
export const UPDATE_HIGHLIGHT_NEWS = 'UPDATE_HIGHLIGHT_NEWS';
export const GET_MOST_VISITED = 'GET_MOST_VISITED';
export const GET_RAW_CONTENT = 'GET_RAW_CONTENT';
export const CREATE_EVENT_BOOKING = 'CREATE_EVENT_BOOKING';
export const DELETE_EVENT_BOOKING = 'DELETE_EVENT_BOOKING';
export const GET_EVENT_BOOKING = 'GET_EVENT_BOOKING';
export const RESET_EVENT_BOOKING = 'RESET_EVENT_BOOKING;';
export const UPDATE_EVENT_BOOKING = 'UPDATE_EVENT_BOOKING';
export const GET_NEWSITEMS_LIST = 'GET_NEWSITEMS_LIST';
export const GET_ENVIRONMENTAL_THEME_RELITEMS = 'GET_ENVIRONMENTAL_THEME_RELITEMS';
export const GET_ANNOUNCEMENTS = 'GET_ANNOUNCEMENTS';
export const GET_PROVINCE_RELITEMS = 'GET_PROVINCE_RELITEMS';
export const GET_HOME_METEO_DATA = 'GET_HOME_METEO_DATA';
