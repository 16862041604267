import { Chip, ChipLabel } from 'design-react-kit/dist/design-react-kit';
import { defineMessages, injectIntl } from 'react-intl';

import PropTypes from 'prop-types';
import { UniversalLink } from '@plone/volto/components';

const messages = defineMessages({
  related_items: {
    id: 'related_items',
    defaultMessage: 'Related items',
  },
});

/**
 * RelatedItems view component class.
 * @function RelatedItems
 * @params {object} location: object.
 * @returns {string} Markup of the component.
 */
const RelatedItems = ({ content, intl, list = [], children }) => {
  const related_items_content = content?.relatedItems?.length > 0 ? [...content.relatedItems] : [];
  const related = [...related_items_content, ...list];

  return related?.length > 0 || children ? (
    <>
      <p className="text-serif mt-4">{intl.formatMessage(messages.related_items)}</p>
      {related.map(item => (
        <Chip
          color=""
          disabled={false}
          large={false}
          simple
          key={item['@id']}
          className="mr-2"
          tag={UniversalLink}
          title={item.title || item.id}
          item={item}
        >
          <ChipLabel tag="span">{item.title || item.id}</ChipLabel>
        </Chip>
      ))}
      {children}
    </>
  ) : null;
};

RelatedItems.propTypes = {
  content: PropTypes.shape({
    '@id': PropTypes.string,
    '@type': PropTypes.string,
    title: PropTypes.string,
    relatedItems: PropTypes.array,
  }),
  children: PropTypes.any,
};

export default injectIntl(RelatedItems);
