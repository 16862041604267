/**
 * IconWidget component.
 * @module components/manage/Widgets/IconWidget
 */

import '@arpav/components/ArpavTheme/manage/Widgets/IconWidget/iconwidget.less';

import { FormFieldWrapper, Icon } from '@plone/volto/components';
import { defineMessages, injectIntl } from 'react-intl';
import { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconPicker from '@arpav/components/ArpavTheme/manage/Widgets/IconWidget/IconPicker';
import PropTypes from 'prop-types';
import clearSVG from '@plone/volto/icons/clear.svg';

const messages = defineMessages({
  selectIcon: {
    id: 'Select an icon',
    defaultMessage: 'Select an icon',
  },
});

const IconWidget = props => {
  const { intl, id, value, required, isDisabled, onChange } = props;
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [icon, setIcon] = useState();

  const onResetIcon = () => {
    onChange(id, null);
  };
  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };
  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  useEffect(() => {
    if (icon) {
      onChange(id, { iconName: icon.iconName, prefix: icon.prefix });
    }
  }, [icon]);

  return (
    <FormFieldWrapper {...props}>
      <div className="icon-widget-wrapper">
        <div
          className="iconpickeropener"
          style={{ cursor: !isDisabled ? 'pointer' : 'default' }}
          onClick={handleClick}
        >
          <div className="iconshow">
            {value ? <FontAwesomeIcon icon={value} /> : intl.formatMessage(messages.selectIcon)}
          </div>
        </div>
        {!isDisabled && (
          <>
            {!required && value ? (
              <button onClick={onResetIcon} className="item ui noborder button">
                <Icon name={clearSVG} size="24px" className="close" />
              </button>
            ) : null}
            {displayColorPicker ? (
              <IconPicker onClose={handleClose} onIconChange={icon => setIcon(icon)} />
            ) : null}
          </>
        )}
      </div>
    </FormFieldWrapper>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
IconWidget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  required: PropTypes.bool,
  isDisabled: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
IconWidget.defaultProps = {
  description: null,
  required: false,
  isDisabled: false,
  error: [],
  value: null,
};

export default injectIntl(IconWidget);
