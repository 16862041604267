import { Col, Container, Row } from 'design-react-kit/dist/design-react-kit';
import { Icon, ListingLinkMore } from '@arpav/components/ArpavTheme';

import { UniversalLink } from '@plone/volto/components';
import { expandToBackendURL } from '@plone/volto/helpers';
import { getEnvironmentalThemeRelatedItems } from '@arpav/actions';
import { getItemClass } from '@arpav/helpers';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const EnvironmentalThemeRelItems = ({ showTitle, title, summary, itemurl, itemindex }) => {
  const limit = 9;
  const loading = useSelector(state => state.environmentalThemeRelItems.loading);
  const relitems = useSelector(
    state => state.environmentalThemeRelItems.data?.[`${itemindex}`]?.items
  );
  const relitemsCount = useSelector(
    state => state.environmentalThemeRelItems.data?.[`${itemindex}`]?.items_count
  );

  // TODO: implements read more link
  // const readMoreLink = [
  //   {
  //     '@id': 'search?related_environmental_themes=' + itemurl,
  //   },
  // ];

  const dispatch = useDispatch();

  // news requestss
  useEffect(() => {
    dispatch(getEnvironmentalThemeRelatedItems(expandToBackendURL(itemurl), itemindex));
  }, [dispatch]);

  let sessionClassName = 'full-width py-5 ';
  let btnClassName = ' btn-primary ';
  switch (itemindex % 2) {
    case 0:
      sessionClassName += 'bg-light';
      break;
    case 1:
      sessionClassName += 'bg-primary-a11';
      btnClassName += ' btn-white text-primary ';
      break;
  }

  return (
    <div className="public-ui block listing">
      <div className={sessionClassName}>
        <Container>
          <div className="simple-card-compact-template">
            {showTitle && (
              <Row className="mb-4">
                <Col>
                  <div className="template-header">
                    <h2>{title}</h2>
                    {summary && <p>{summary}</p>}
                  </div>
                </Col>
              </Row>
            )}
            <Row className="skeleton-template">
              {!loading
                ? relitems?.map((item, index) => (
                    <Col md={4} className="mb-4 px-2" key={index}>
                      <UniversalLink
                        className={`button-card rounded text-left btn-icon btn btn-block ${btnClassName} ${getItemClass(
                          item
                        )}`}
                        item={item}
                        title={item.title || item.id}
                      >
                        <span className="flex-grow-1">{item.title || item.id}</span>
                        <Icon icon="it-arrow-right" padding={false} />
                      </UniversalLink>
                    </Col>
                  ))
                : Array.from(Array(9).keys()).map((item, index) => (
                    <Col md={4} className="mb-4 px-2" key={index}>
                      <div>
                        <UniversalLink
                          className="button-card rounded text-left btn-icon btn btn-block btn-skeleton"
                          href="#"
                        >
                          <span className="flex-grow-1">&nbsp;</span>
                          <Icon icon="it-arrow-right" padding={false} />
                        </UniversalLink>
                      </div>
                    </Col>
                  ))}
            </Row>
            {/* {relitemsCount >= limit ? (
              <ListingLinkMore title={`view more`} href={readMoreLink} className="my-4" />
            ) : null} */}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default EnvironmentalThemeRelItems;
