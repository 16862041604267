import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';

import { getArpavApiContent } from '@arpav/actions';

const withFileData = getData => WrappedComponent => {
  return props => {
    const api_url = 'https://api.arpa.veneto.it/REST/v1/meteo_capoluoghi';
    const { block } = getData(props);

    const apiRequest = useSelector(state => state.arpavApiContent?.[`${block}-${api_url || ''}`]);

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(getArpavApiContent(api_url, {}, `${block}-${api_url}`));
    }, [dispatch, api_url]);

    const api_content = apiRequest?.data;
    const data_result = useMemo(() => {
      if (api_content) {
        const new_res = [];
        api_content.forEach(i => {
          let vvalue;
          let item = new_res.find(r => r.codice_stazione === i.codice_stazione);
          if (!item) {
            item = {
              codice_stazione: i.codice_stazione,
              nome_stazione: i.nome_stazione,
              provincia: i.provincia,
              dataora: new Date(i.dataora).toLocaleString('it-IT'),
            };
            new_res.push(item);
          }
          switch (i.tipo) {
            case 'PREC':
              item.precipitazioni = i.valore;
              item.precipitazioni_um = i.misura;
              break;
            case 'TARIA2M':
              item.temperatura = i.valore;
              item.temperatura_um = i.misura;
              break;
            case 'VVENTO5M':
            case 'VVENTO10M':
              vvalue = i.valore ? JSON.parse(i.valore) : {};
              item.velocitavento = vvalue.VELOCITA;
              item.direzionevento = vvalue.DIREZIONE;
              item.velocitavento_um = i.misura;
              item.velocitaventokm = (vvalue.VELOCITA * 3.6).toFixed(1);
              item.velocitaventokm_um = 'km/h';
              break;
          }
        });

        return {
          data: new_res,
          errors: [],
          meta: {
            aborted: false,
            truncated: false,
            ...apiRequest?.meta,
          },
        };
      }
    }, [api_content]);

    return (
      <WrappedComponent
        data_result={{ ...data_result }}
        error={apiRequest?.error}
        loaded={apiRequest?.loaded}
        loading={apiRequest?.loading}
        {...props}
      />
    );
  };
};

export default withFileData;
