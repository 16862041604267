/**
 * Default view reducer.
 * @module reducers/mostVisited/mostVisited
 */

import { GET_MOST_VISITED } from '@arpav/constants/ActionTypes';
import { flattenToAppURL } from '@plone/volto/helpers';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  schema: null,
  post: {
    loaded: false,
    loading: false,
    error: null,
  },
  update: {
    loaded: false,
    loading: false,
    error: null,
  },
  put: {
    loaded: false,
    loading: false,
    error: null,
  },
};

/**
 * Default view reducer.
 * @function schema
 * @param {Object} mostVisited Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function mostVisited(state = initialState, action = {}) {
  switch (action.type) {
    /** PENDING */
    case `${GET_MOST_VISITED}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };

    /** SUCCESS */
    case `${GET_MOST_VISITED}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        mostvisited: {
          ...action.result,
          items: action?.result?.items?.map(item => ({
            ...item,
            url: flattenToAppURL(item['@id']),
            breadcrumbs: item.breadcrumbs?.map(breadcrumb => ({
              ...breadcrumb,
              url: flattenToAppURL(breadcrumb['@id']),
            })),
          })),
        },
      };

    /** FAIL */
    case `${GET_MOST_VISITED}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        mostvisited: null,
      };
    default:
      return state;
  }
}
