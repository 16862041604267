/**
 * Api Csv helper.
 * @module helpers/Uri
 */

import { flattenHTMLToAppURL, isInternalURL } from '@plone/volto/helpers';

import config from '@plone/volto/registry';

/**
 * Consider static resources as external URL.
 *
 * @param {String} url
 * @returns {Boolean}
 */
export const isCMSInternalURL = url => {
  const { settings } = config;

  return (
    isInternalURL(url) &&
    url.indexOf(settings.publicURL + '/risorse') === -1 &&
    url.indexOf(settings.publicURL + '/api/risorse') === -1
  );
};

/**
 * Flatten html imported from old editor.
 *
 * @method flattenEditorHTMLToAppURL
 *
 * @param {String} html
 * @returns {String}
 */
export const flattenEditorHTMLToAppURL = html => {
  html = html
    .replace(new RegExp('href="/api', 'gm'), 'href="')
    .replace(new RegExp('src="/api', 'gm'), 'src="');
  return flattenHTMLToAppURL(html);
};
