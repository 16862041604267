/**
 * TimeWidget component.
 * @module components/manage/Widgets/TimeWidget
 */

import 'rc-time-picker/assets/index.css';

import { FormFieldWrapper, Icon } from '@plone/volto/components';
import { defineMessages, injectIntl } from 'react-intl';

import PropTypes from 'prop-types';
import clearSVG from '@plone/volto/icons/clear.svg';
import { compose } from 'redux';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import loadable from '@loadable/component';

const TimePicker = loadable(() => import('rc-time-picker'));

// Plone Time format
const format = 'HH:mm:ss';

const messages = defineMessages({
  time: {
    id: 'Time',
    defaultMessage: 'Time',
  },
});

const TimeWidget = props => {
  const { id, value, required, intl, isDisabled, onChange } = props;
  const moment = props.moment.default;
  const time = getInternalValue();

  function getInternalValue() {
    return value ? moment(value, format) : null;
  }

  /**
   * Update date storage
   * @method onTimeChange
   * @param {Object} time updated momentjs Object for time
   * @returns {undefined}
   */
  function onTimeChange(time) {
    if (time) {
      const base = (getInternalValue() || moment()).set({
        hours: time?.hours() ?? 0,
        minutes: time?.minutes() ?? 0,
        seconds: 0,
      });
      const dateValue = base.format(format);
      onChange(id, dateValue);
    }
  }

  function onResetDates() {
    onChange(id, null);
  }

  return (
    <FormFieldWrapper {...props}>
      <div className="date-time-widget-wrapper">
        <div className="ui input time-input">
          <TimePicker
            disabled={isDisabled}
            defaultValue={time}
            value={time}
            onChange={onTimeChange}
            allowEmpty={false}
            showSecond={false}
            use12Hours={intl.locale === 'en'}
            id={`${id}-time`}
            format={moment.localeData(intl.locale).longDateFormat('LT')}
            placeholder={intl.formatMessage(messages.time)}
            focusOnOpen
            placement="bottomRight"
          />
        </div>
        {!required && (
          <button
            disabled={isDisabled || !time}
            onClick={() => onResetDates()}
            className="item ui noborder button"
          >
            <Icon name={clearSVG} size="24px" className="close" />
          </button>
        )}
      </div>
    </FormFieldWrapper>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
TimeWidget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
TimeWidget.defaultProps = {
  description: null,
  required: false,
  error: [],
  value: null,
};

export default compose(injectLazyLibs(['moment']), injectIntl)(TimeWidget);
