import {
  Card,
  CardBody,
  CardReadMore,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from 'design-react-kit/dist/design-react-kit';
import { ListingLinkMore, ListingText } from '@arpav/components/ArpavTheme';
import { PreviewImage, UniversalLink } from '@plone/volto/components';

import PropTypes from 'prop-types';
import Slider from 'react-slick';
import cx from 'classnames';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  readMore: {
    id: 'Read More…',
    defaultMessage: 'Read More…',
  },
});

const CardCarouselTemplate = ({
  items,
  isEditMode,
  title,
  linkTitle,
  linkHref,
  show_block_bg,
  description,
}) => {
  const intl = useIntl();

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
    ],
  };

  return (
    <Container>
      <div className="sliderTemplate">
        {title && (
          <Row className="mb-4">
            <Col className="text-center">
              <div className="template-header">
                <h2 className={cx('mb-4', { 'mt-5': !show_block_bg })}>{title}</h2>
                {description ? <p>{description}</p> : null}
              </div>
            </Col>
          </Row>
        )}
        <div className="slider-container">
          <div className="it-carousel-all it-card-bg">
            <Slider {...settings}>
              {/* First only padding right */}
              {items?.map((item, index) => (
                <div className="it-single-slide-wrapper pr-lg-2" key={index}>
                  <Card className="card-bg card-img no-after rounded bg-light">
                    <div className="img-responsive-wrapper">
                      <div className="img-responsive">
                        <figure className="img-wrapper">
                          <PreviewImage
                            item={item}
                            size="large"
                            alt={item.image_caption ? item.image_caption : item.title}
                            className="ui image"
                          />
                        </figure>
                      </div>
                    </div>
                    <CardBody className="pb-5">
                      <CardTitle tag="h3">{item.title}</CardTitle>
                      <CardText>
                        <ListingText item={item} />
                      </CardText>
                      <CardReadMore
                        iconName="it-arrow-right"
                        tag={UniversalLink}
                        item={!isEditMode ? item : null}
                        href={isEditMode ? '#' : null}
                        text={intl.formatMessage(messages.readMore)}
                      ></CardReadMore>
                    </CardBody>
                  </Card>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <ListingLinkMore title={linkTitle} href={linkHref} className="my-4" />
      </div>
    </Container>
  );
};

CardCarouselTemplate.propTypes = {
  description: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
  linkTitle: PropTypes.any,
  linkHref: PropTypes.any,
  show_block_bg: PropTypes.bool,
  title: PropTypes.string,
};

export default CardCarouselTemplate;
