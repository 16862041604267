import {
  Announcement,
  AnnouncementsList,
  CompetitionsList,
  DefaultView,
  EIArea,
  EIEvaluation,
  EIIndicator,
  EITheme,
  EnvironmentalTheme,
  EventoView,
  NewsList,
  Organization,
  Page,
  Province,
  Publication,
  TransparentAdministration,
} from '@arpav/components/ArpavTheme/View';

import { FileView } from '@plone/volto/components';
import layouts from '@plone/volto/constants/Layouts';

/*  CONTENT TYPES VIEWS */
const arpavContentTypesViews = {
  Announcement: Announcement,
  'Announcement File': FileView,
  Document: Page,
  'EI Area': EIArea,
  'EI Evaluation': EIEvaluation,
  'EI Indicator': EIIndicator,
  'EI Theme': EITheme,
  'Environmental Theme': EnvironmentalTheme,
  Event: EventoView,
  Organization: Organization,
  Province: Province,
  Publication: Publication,
};
/**
 * FOLDERS VIEWS
 **/
const getArpavViews = config => {
  return {
    contentTypesViews: {
      ...config.views.contentTypesViews,
      ...arpavContentTypesViews,
    },
    layoutViews: {
      ...config.views.layoutViews,
      announcementslist_view: AnnouncementsList,
      competitionslist_view: CompetitionsList,
      newslist_view: NewsList,
      transparentadministration_view: TransparentAdministration,
    },
    // defaultView: DefaultView,
  };
};

layouts['newslist_view'] = 'Vista notizie';
layouts['announcementslist_view'] = 'Vista bandi';
layouts['competitionslist_view'] = 'Vista concorsi';
layouts['transparentadministration_view'] = 'Vista Amministrazione Trasparente';

export default getArpavViews;
