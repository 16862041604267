import {
  Actions,
  ArgumentIcon,
  PageHeaderAnnouncement,
  PageHeaderBando,
  PageHeaderDates,
  PageHeaderNewsItem,
  PageHeaderOrganization,
  Sharing,
} from '@arpav/components/ArpavTheme/View';
import { Col, Row } from 'design-react-kit/dist/design-react-kit';
import { defineMessages, useIntl } from 'react-intl';

import { Icon } from '@plone/volto/components';
import { Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import moment from 'moment/min/moment-with-locales';
import starSVG from '@plone/volto/icons/star.svg';

/**
 * PageHeader view component class.
 * @function PageHeader
 * @params {object} content: Content object.
 * @returns {string} Markup of the component.
 */

const messages = defineMessages({
  reading_time: {
    id: 'reading_time',
    defaultMessage: 'Tempo di lettura',
  },
  minutes: {
    id: 'minutes',
    defaultMessage: 'min',
  },
});

const PageHeader = props => {
  const intl = useIntl();
  moment.locale(intl.locale);

  return (
    <Row className="mb-2 mb-lg-0">
      <Col lg={props.imageinheader ? 6 : 8} className="px-lg-4 py-lg-2">
        {(props.content.icon || props.content.icona) && (
          <ArgumentIcon icon={props.content.icon || props.content.icona} />
        )}
        <h1>
          {props.content.title}
          {props.content.subtitle && ` - ${props.content.subtitle}`}
          {props.content.sottotitolo && ` - ${props.content.sottotitolo}`}
          {props.content.is_highlighted ? (
            <Icon className="ml-4" name={starSVG} size="50px" color="#007eb1" />
          ) : null}
        </h1>

        {props.content.description && (
          <p className="documentDescription">{props.content.description}</p>
        )}

        <PageHeaderBando content={props.content} />

        <PageHeaderNewsItem content={props.content} />

        <PageHeaderOrganization content={props.content} />

        <PageHeaderAnnouncement content={props.content} />

        {(props.showreadingtime || props.showdates) && (
          <Row className="mt-3 mb-0 readingtime-dates">
            {props.showdates ? <PageHeaderDates content={props.content} /> : <Col xs={6} />}

            {props.showreadingtime &&
              props.readingtime > 0 &&
              ((
                <Col xs={6}>
                  <small>{intl.formatMessage(messages.reading_time)}</small>
                  <p className="font-weight-semibold">
                    {props.readingtime} {intl.formatMessage(messages.minutes)}
                  </p>
                </Col>
              ) || <Col xs={6} />)}
          </Row>
        )}
      </Col>

      {props.imageinheader && props.content[props.imageinheader_field] ? (
        <Col lg={2}>
          <figure>
            <Image
              image={props.content[props.imageinheader_field]}
              alt={props.content.title}
              className="img-fluid"
              maxSize={300}
            />
          </figure>
        </Col>
      ) : null}

      <div className="col-lg-3 page-header-right offset-lg-1">
        <Sharing url={props.content['@id']} title={props.content.title} />
        <Actions url={props.content['@id']} title={props.content.title} />
      </div>
    </Row>
  );
};
export default PageHeader;

PageHeader.propTypes = {
  params: PropTypes.shape({
    content: PropTypes.object,
    readingtime: PropTypes.string,
    showreadingtime: PropTypes.bool,
    imageinheader: PropTypes.bool,
    imageinheader_field: PropTypes.string,
    showdates: PropTypes.bool,
    showtassonomiaargomenti: PropTypes.bool,
  }),
};
