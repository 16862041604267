import {
  Card,
  CardBody,
  CardReadMore,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from 'design-react-kit/dist/design-react-kit';
import { FormattedDate, UniversalLink } from '@plone/volto/components';
import { defineMessages, injectIntl } from 'react-intl';

import DefaultImageSVG from '@arpav/components/ArpavTheme/Blocks/Listing/default-image.svg';
import { ListingLinkMore } from '@arpav/components/ArpavTheme';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { getItemClass } from '@arpav/helpers';

const messages = defineMessages({
  pressRelease: {
    id: 'Press release',
    defaultMessage: 'Press release',
  },
  readMore: {
    id: 'Read More…',
    defaultMessage: 'Read More…',
  },
});

const NewsTemplate = ({
  intl,
  items,
  isEditMode,
  linkTitle,
  linkHref,
  show_block_bg,
  title,
  description,
}) => {
  const currentYear = `${new Date().getFullYear()}`;
  return (
    <Container>
      <div className="simple-card-compact-template">
        {title && (
          <Row className="mb-4">
            <Col className="text-center">
              <div className="template-header">
                <h2 className={cx('mb-4', { 'mt-5': !show_block_bg })}>{title}</h2>
                {description ? <p>{description}</p> : null}
              </div>
            </Col>
          </Row>
        )}
        <Row className="mx-n2">
          {items?.map((item, index) => {
            const is_image = item.image;
            const url = is_image
              ? item.image.scales?.['large']?.download ?? item.image.download
              : null;
            const src = is_image && url ? url : DefaultImageSVG;
            return (
              <Col xs={12} md={4} key={index}>
                <Card
                  spacing
                  className={`card-bg card-img rounded shadow no-after ${getItemClass(item)}`}
                >
                  <div className="img-responsive-wrapper">
                    <div className="img-responsive">
                      <figure className="img-wrapper">
                        <img
                          src={src}
                          alt={item.image_caption ?? item.title}
                          className={cx('ui image', {
                            'img-placeholder': !is_image,
                          })}
                        />
                      </figure>
                      {item.effective && (
                        <div className="card-calendar d-flex flex-column justify-content-center">
                          <FormattedDate
                            date={item.effective}
                            format={{
                              day: 'numeric',
                              month: 'long',
                              year: 'numeric',
                            }}
                          >
                            {parts => {
                              return parts
                                .filter(
                                  p =>
                                    p.type !== 'literal' &&
                                    (p.type !== 'year' || p.value !== currentYear)
                                )
                                .map((p, i) => (
                                  <span
                                    className={`card-${p.type == 'day' ? 'date' : 'day'}`}
                                    key={i}
                                  >
                                    {p.value}
                                  </span>
                                ));
                            }}
                          </FormattedDate>
                        </div>
                      )}
                      <div
                        className={`card-${
                          item.is_pressrelease ? 'pressrelease' : 'news'
                        }-category`}
                      >
                        {item.is_pressrelease
                          ? intl.formatMessage(messages.pressRelease)
                          : intl.formatMessage({ id: 'News Item' })}
                      </div>
                    </div>
                  </div>
                  <CardBody className="pb-5">
                    <CardTitle tag="h3">{item.title}</CardTitle>
                    <CardText>{item.text}</CardText>
                    <CardReadMore
                      iconName="it-arrow-right"
                      tag={UniversalLink}
                      item={!isEditMode ? item : null}
                      href={isEditMode ? '#' : null}
                      text={intl.formatMessage(messages.readMore)}
                    ></CardReadMore>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </Row>

        <ListingLinkMore title={linkTitle} href={linkHref} className="my-4" />
      </div>
    </Container>
  );
};

NewsTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
  linkTitle: PropTypes.any,
  c: PropTypes.any,
};

export default injectIntl(NewsTemplate);
