import { defineMessages, injectIntl } from 'react-intl';

import { Icon } from '@plone/volto/components';
import { Link } from 'react-router-dom';
import { Plug } from '@plone/volto/components/manage/Pluggable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getBaseUrl } from '@plone/volto/helpers';
import starSVG from '@plone/volto/icons/star.svg';

const messages = defineMessages({
  manageHighlightNews: {
    id: 'Manage highlight news',
    defaultMessage: 'Manage highlight news',
  },
});

export const HighlightNewsToolbar = ({ intl, token, pathname, content }) => {
  const path = getBaseUrl(pathname);
  return token && content?.['@type'] === 'News Item' ? (
    <Plug pluggable="toolbar-more-manage-content" id="highlightnews">
      <li>
        <Link
          id="toolbar-highlightnews-form"
          aria-label={intl.formatMessage(messages.manageHighlightNews)}
          className="highlightnews"
          to={`${path}/highlightnews`}
        >
          <div>
            <span className="pastanaga-menu-label">
              {intl.formatMessage(messages.manageHighlightNews)}
            </span>
            <span className="pastanaga-menu-value" />
          </div>
          <Icon
            name={starSVG}
            size="30px"
            title={intl.formatMessage(messages.manageHighlightNews)}
          />
        </Link>
      </li>
    </Plug>
  ) : null;
};

export default compose(
  injectIntl,
  connect((state, props) => ({
    token: state.userSession.token,
    pathname: props.location.pathname,
  }))
)(HighlightNewsToolbar);
