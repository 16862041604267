import NewsInEvidenceBody from '@arpav/components/ArpavTheme/Blocks/NewsInEvidence/NewsInEvidenceBody';

const View = props => {
  return (
    <div className="block newsinEvidence listing">
      <div className="full-width section section-background-header py-5">
        <NewsInEvidenceBody {...props} />
      </div>
    </div>
  );
};

export default View;
