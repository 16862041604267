import { Col, Container, Row } from 'design-react-kit/dist/design-react-kit';
import { FormattedDate, PreviewImage, UniversalLink } from '@plone/volto/components';
import { useDispatch, useSelector } from 'react-redux';

import HeroSkeleton from '@arpav/components/ArpavTheme/Blocks/HomeBanner/HeroSkeleton';
import HomeBannerSkeleton from '@arpav/components/ArpavTheme/Blocks/HomeBanner/HomeBannerSkeleton';
import HomeMap from './HomeMap';
import HomeRealTimeData from './realtime/HomeRealTimeData';
import { getBaseUrl } from '@plone/volto/helpers';
import { getHighlightNews } from '@arpav/actions';
import { useEffect } from 'react';

const Body = ({ id, path, isEditMode, data }) => {
  const loading = useSelector(state => state.highlightNews.subrequests[id]?.get?.loading);
  const items = useSelector(state => state.highlightNews.subrequests[id]?.data?.items);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHighlightNews(getBaseUrl(path), { b_size: 1, b_start: 0 }, id));
  }, [dispatch, path]);

  return (
    <Container fluid>
      <Row className="mr-lg-2">
        <Col lg={8} className="px-0">
          <div className="homeMap">
            <HomeMap {...data} />
          </div>
        </Col>
        <Col lg={4} className="px-0">
          <Row>
            <Col xs={12}>
              {!loading ? (
                items?.length ? (
                  items.slice(0, 1).map((item, index) => (
                    <div className="it-hero-wrapper it-overlay it-dark" key={index}>
                      <UniversalLink
                        item={!isEditMode ? item : null}
                        href={isEditMode ? '#' : null}
                        title={item.title || item.id}
                      >
                        <div className="img-responsive-wrapper">
                          <div className="img-responsive">
                            <div className="img-wrapper">
                              <PreviewImage
                                item={item}
                                size="large"
                                alt={item.image_caption ? item.image_caption : item.title}
                              />
                            </div>
                          </div>
                        </div>
                      </UniversalLink>
                      <div className="it-hero-text-wrapper bg-dark">
                        <span className="it-category">
                          <FormattedDate
                            date={item.date}
                            format={{
                              day: 'numeric',
                              month: 'long',
                              year: 'numeric',
                            }}
                          />
                        </span>
                        <h1 className="it-hero-title">
                          <UniversalLink
                            item={!isEditMode ? item : null}
                            href={isEditMode ? '#' : null}
                            title={item.title || item.id}
                          >
                            {item.title}
                          </UniversalLink>
                        </h1>
                      </div>
                    </div>
                  ))
                ) : null
              ) : (
                <HeroSkeleton />
              )}
            </Col>
            <Col xs={12}>
              <HomeRealTimeData isEditMode={isEditMode} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const Preloader = props => (__CLIENT__ ? <Body {...props} /> : <HomeBannerSkeleton />);

export default Preloader;
