import { LastPublicationsBody } from '@arpav/components/ArpavTheme';

const View = ({ id, path }) => {
  return (
    <div className="block lastPublications">
      <LastPublicationsBody id={id} path={path} />
    </div>
  );
};

export default View;
