import { RRule, rrulestr } from 'rrule';

import { Button } from 'design-react-kit/dist/design-react-kit';
import { EventSubscription } from '@arpav/components/ArpavTheme';
import { FormattedMessage } from 'react-intl';
import { Icon as PloneIcon } from '@plone/volto/components';
import PropTypes from 'prop-types';
import { RichTextArticle } from '@arpav/components/ArpavTheme/View';
import plusCalendarSVG from '@arpav/icons/it-plus-calendar.svg';
import { useState } from 'react';

const EventoIscrizione = ({ content }) => {
  const [open, setOpen] = useState(false);
  let recurrence = content?.recurrence;
  let endCurrentocc = content?.end;

  if (recurrence) {
    recurrence = recurrence.replace(/DTSTART:[^\n]+\n/, '');
    const dtstart = RRule.optionsToString({
      dtstart: new Date(content.start),
    });
    recurrence = dtstart + '\n' + recurrence;
    const startOfDay = new Date();
    startOfDay.setUTCHours(0, 0, 0, 0);
    const rrule = rrulestr(recurrence, { unfold: true, forceset: true });
    const currentocc = rrule.after(startOfDay);
    if (currentocc) {
      endCurrentocc = new Date(
        currentocc.getTime() + (new Date(content.end).getTime() - new Date(content.start).getTime())
      );
    }
  }

  return content &&
    content.booking_required &&
    new Date(endCurrentocc).getTime() > new Date().getTime() ? (
    <>
      <RichTextArticle tag_id="subscribe" className="page-side-menu mt-5">
        <Button color="primary" onClick={() => setOpen(true)}>
          <PloneIcon name={plusCalendarSVG} className="mr-3" />
          <FormattedMessage id="Subscribe" defaultMessage="Subscribe" />
        </Button>
      </RichTextArticle>
      <EventSubscription open={open} setOpen={setOpen} />
    </>
  ) : null;
};

EventoIscrizione.propTypes = {
  content: PropTypes.object.isRequired,
};

export default EventoIscrizione;
