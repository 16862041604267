import config from '@plone/volto/registry';

const MeteoTempControl = ({ icons, visible }) => {
  const OrderIcons = [...icons].sort((a, b) => b.lat - a.lat);
  const { settings } = config;
  const imagesBaseUrl = settings.publicURL + '/risorse/data-bollettini/meteo/icone/meteo/';
  return (
    <>
      {visible &&
        OrderIcons.map(i => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexFlow: 'column wrap',
              height: '33%',
              maxWidth: '120px',
              zIndex: '-1',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexFlow: 'column wrap',
                height: '100%',
                width: '100%',
                backgroundColor: 'rgba(210, 210, 210, 0.5)',
                paddingRight: '20px',
                paddingLeft: '20px',
                fontSize: '14px',
                zIndex: '-1',
              }}
            >
              <span> {i.zona.replace('TEMP_', ' ')}</span>
              <img
                src={imagesBaseUrl + i.simbolo}
                style={{
                  width: '60px',
                  padding: '10px',
                }}
              />
              <span
                style={{
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  fontSize: '12px',
                }}
              >
                {i.testo}
              </span>
            </div>
          </div>
        ))}
    </>
  );
};

export default MeteoTempControl;
