import { Card, CardBody, CardText, CardTitle } from 'design-react-kit/dist/design-react-kit';
import {
  ContactLink,
  OfficeCard,
  RichTextArticle,
  richTextHasContent,
} from '@arpav/components/ArpavTheme/View';
import { defineMessages, useIntl } from 'react-intl';

import { Icon } from '@arpav/components/ArpavTheme';
import PropTypes from 'prop-types';

const messages = defineMessages({
  supported_by: {
    id: 'supported_by',
    defaultMessage: 'Con il supporto di',
  },
  contatti: {
    id: 'Contatti',
    defaultMessage: 'Contatti',
  },
  event_web_site: {
    id: 'event_web_site',
    defaultMessage: "Sito web dell'evento",
  },
  contatti_interni: {
    id: 'contatti_interni',
    defaultMessage: 'Contatti interni',
  },
});

const EventoDocumenti = ({ content }) => {
  const intl = useIntl();
  const getSupportatoDa = () => {
    return (
      content?.supportato_da?.length > 0 && (
        <>
          <h5 className="mt-4 supported-by">{intl.formatMessage(messages.supported_by)}</h5>
          {content?.supportato_da?.map(item => (
            <OfficeCard key={item['@id']} office={item} extended={true} icon="it-pa" />
          ))}
        </>
      )
    );
  };

  return richTextHasContent(content?.organizzato_da_esterno) ||
    content?.organizzato_da_interno?.length > 0 ||
    content?.supportato_da?.length > 0 ||
    content?.event_url ||
    content?.contact_phone ||
    content?.contact_email ||
    content?.fax ? (
    <RichTextArticle
      tag_id="contatti"
      className="page-side-menu mt-5"
      title={intl.formatMessage(messages.contatti)}
    >
      {/* ---organizzato da esterno */}
      {content?.contact_phone || content?.contact_name || content?.contact_email || content?.fax ? (
        <Card className="card card-teaser rounded shadow mt-3" noWrapper={true} tag="div">
          <CardTitle tag="h5">
            <Icon icon="it-telephone" padding={true} />
          </CardTitle>
          <CardBody tag="div" className="pr-3">
            {content?.contact_name && <CardText className="mt-3">{content.contact_name}</CardText>}
            {content?.contact_phone && (
              <CardText className="mt-3">
                <ContactLink tel={content.contact_phone} label={true} />
              </CardText>
            )}
            {content?.fax && (
              <CardText className="mt-3">
                <ContactLink fax={content.fax} label={true} />
              </CardText>
            )}
            {content?.contact_email && (
              <CardText className="mt-3">
                <ContactLink email={content.contact_email} label={true} />
              </CardText>
            )}
          </CardBody>
        </Card>
      ) : null}

      {content?.attendees?.length > 0 ? (
        <div className="mt-3">
          <Card className="card card-teaser rounded shadow mt-3" noWrapper={true} tag="div">
            <CardTitle tag="h5">
              <Icon icon="it-user" padding={true} />
            </CardTitle>
            <CardBody tag="div" className="pr-3">
              {content.attendees.map((attendee, index) => (
                <CardText key={index} className="mt-3">
                  {attendee}
                </CardText>
              ))}
            </CardBody>
          </Card>
        </div>
      ) : null}

      {/* ---contatti interno */}
      {content?.organizzato_da_interno?.length > 0 && (
        <div className="mt-3">
          <h5>{intl.formatMessage(messages.contatti_interni)}</h5>
          {content?.organizzato_da_interno?.map((item, index) => (
            <OfficeCard
              margin_bottom={index < content?.organizzato_da_interno?.length - 1}
              key={item['@id']}
              office={item}
              extended={true}
              icon="it-telephone"
            >
              {richTextHasContent(content?.contatto_reperibilita) && (
                <p className="card-text mt-3">
                  {content?.contatto_reperibilita?.replace(/(<([^>]+)>)/g, '')}
                </p>
              )}
            </OfficeCard>
          ))}
        </div>
      )}

      {/* ---supportato da */}
      {getSupportatoDa()}
    </RichTextArticle>
  ) : (
    <></>
  );
};

EventoDocumenti.propTypes = {
  content: PropTypes.object.isRequired,
};

export default EventoDocumenti;
