import { defineMessages, useIntl } from 'react-intl';

import { cloneDeep } from 'lodash';

const messages = defineMessages({
  textTemplate: {
    id: 'Text template',
    defaultMessage: 'Text template',
  },
  textTemplateDescription: {
    id: "Add suffix/prefix to text. Use '{} for value placeholder",
    defaultMessage: "Add suffix/prefix to text. Use '{} for value placeholder",
  },
});

const TextTemplateRenderer = ({ column, value }) => {
  const output = column.textTemplate ? column.textTemplate.replace('{}', value) : value;
  return column.HTMLformat ? <div dangerouslySetInnerHTML={{ __html: value }} /> : output;
};

TextTemplateRenderer.schemaExtender = (schema, data) => {
  if (!data.renderer === 'textTemplate') return schema;

  const intl = useIntl();

  schema = cloneDeep(schema);

  schema.properties.textTemplate = {
    title: intl.formatMessage(messages.textTemplate),
    description: intl.formatMessage(messages.textTemplateDescription),
  };

  schema.fieldsets[0].fields.push('textTemplate');

  return schema;
};

export default TextTemplateRenderer;
