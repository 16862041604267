import { getArpavApiContent, getRawContent } from '@arpav/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';

import { SOURCE_TYPES } from '@arpav/components/ArpavTheme/Blocks/DataTable/schema';
import csv from 'papaparse';

const withFileData = getData => WrappedComponent => {
  return props => {
    const { api_url, block, file_path, source_type } = getData(props);
    const isCsv = source_type === SOURCE_TYPES.csv.id;
    const isApi = source_type === SOURCE_TYPES.api.id;

    const id = file_path?.[0]?.['@id'];
    const path = id ? `${id}/@@download` : null;

    const rawRequest = useSelector(state => state.rawContent?.subrequests?.[path]);
    const apiRequest = useSelector(state => state.arpavApiContent?.[`${block}-${api_url || ''}`]);

    const dispatch = useDispatch();

    useEffect(() => {
      if (isApi && dispatch && api_url) {
        dispatch(getArpavApiContent(api_url, {}, `${block}-${api_url}`));
      }
    }, [api_url]);

    useEffect(() => {
      if (isCsv && path && dispatch && !rawRequest?.loading) dispatch(getRawContent(path));
    }, [file_path]);

    const file_content = rawRequest?.data;
    const file_data = useMemo(() => {
      if (file_content) {
        const res = csv.parse(file_content, { header: true });
        return res;
      }
    }, [file_content]);

    const api_content = apiRequest?.data;
    const data_result = useMemo(() => {
      if (api_content)
        return {
          data: api_content,
          errors: [],
          meta: {
            aborted: false,
            fields: Object.keys(api_content[0]),
            truncated: false,
            ...apiRequest?.meta,
          },
        };
    }, [api_content]);

    return (
      <WrappedComponent
        data_result={{ ...(isCsv ? file_data || {} : isApi ? data_result || {} : {}) }}
        error={rawRequest?.error || apiRequest?.error}
        loaded={rawRequest?.loaded || apiRequest?.loaded}
        loading={rawRequest?.loading || apiRequest?.loading}
        {...props}
      />
    );
  };
};

export default withFileData;
