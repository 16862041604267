import { assign, getOptions } from '@eeacms/volto-openlayers-map/helpers';

import { Portal } from 'react-portal';
import PropTypes from 'prop-types';
import React from 'react';
import _uniqueId from 'lodash/uniqueId';
import cs from 'classnames';
import cx from 'classnames';
import { isEqual } from 'lodash';
import { openlayers } from '@eeacms/volto-openlayers-map';
import { withMapContext } from '@eeacms/volto-openlayers-map/hocs';

class LayerControls extends React.Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    controlsFn: PropTypes.func.isRequired,
    defaultValue: PropTypes.string.isRequired,
    activeLayerId: PropTypes.string.isRequired,
    updateRequestParameter: PropTypes.func.isRequired,
    layerData: PropTypes.array.isRequired,
    top: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,
    bottom: PropTypes.string,
  };

  control = undefined;

  options = {
    element: undefined,
    render: undefined,
    target: undefined,
  };

  constructor(props) {
    super(props);
    this.options = getOptions(assign(this.options, this.props));
    this.addControl = this.addControl.bind(this);
    this.element = React.createRef();
    this.state = {
      id: null,
      current: null,
    };
    this.controls = [];
  }

  addControl() {
    const { map, mapRendered } = this.props;

    const id = this.props.id || _uniqueId('ol-overlay-');

    const element = document.createElement('div');
    element.setAttribute('id', id);
    element.setAttribute('class', cs('ol-control', this.props.className));
    element.style.top = this.props.top;
    element.style.left = this.props.left;
    element.style.right = this.props.right;
    element.style.bottom = this.props.bottom;

    let options = {
      ...getOptions(assign(this.options, this.props)),
      element,
    };

    this.control = new openlayers.control.Control(options);

    if (!mapRendered) {
      this.props.addControl(this.control);
    } else {
      map.addControl(this.control);
    }

    this.activeValue = null;
    this.setState({ id });
  }

  componentDidMount() {
    this.addControl();

    this.activeValue = this.props.defaultValue;

    this.updateControls();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.activeLayerId, this.props.activeLayerId)) {
      this.activeValue = this.props.defaultValue;
    }
    if (
      !isEqual(prevProps.controlsFn, this.props.controlsFn) ||
      !isEqual(prevProps.defaultValue, this.props.defaultValue) ||
      !isEqual(prevProps.layerData, this.props.layerData)
    ) {
      this.updateControls();
    }
  }

  componentWillUnmount() {
    const { map, mapRendered } = this.props;
    if (__SERVER__ || !mapRendered) return;
    map.removeControl(this.control);
  }

  updateControls() {
    const { controlsFn, layerData, isEditMode } = this.props;
    if (!isEditMode && controlsFn && layerData?.data?.length) {
      let controlsFnExec;
      const jsfn = `controlsFnExec  = function(current, data) {${controlsFn}}`;

      try {
        eval(jsfn);
        this.controls = controlsFnExec(this.activeValue, layerData.data);
      } catch (e) {
        this.controls = [];
        console.error('Error evaluating controlsFn js');
      }
    } else {
      this.controls = [];
    }
    this.forceUpdate();
  }

  render() {
    return this.state.id && this.controls?.length ? (
      <Portal node={document.querySelector(`#${this.state.id}`)}>
        {this.controls?.map((c, i) => (
          <button
            key={i}
            className={cx('chip mr-2 mb-2', {
              activelayer: this.activeValue == c.value,
            })}
            disabled={c.disabled}
            type="button"
            onClick={() => {
              this.activeValue = c.value;
              this.props.updateRequestParameter({
                currentlayer: this.props.activeLayerId,
                parameter: c.value,
              });
            }}
          >
            <span
              className={cx('chip-label', {
                'visually-hidden': c.disabled,
              })}
            >
              {c.label}
            </span>
          </button>
        ))}
      </Portal>
    ) : (
      ''
    );
  }
}

export default withMapContext(LayerControls);
