import { Chip, ChipLabel } from 'design-react-kit/dist/design-react-kit';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  completed: {
    id: 'Completed',
    defaultMessage: 'Completed',
  },
  expired: {
    id: 'Expired',
    defaultMessage: 'Expired',
  },
});

const PageHeaderAnnouncement = ({ content }) => {
  const intl = useIntl();

  const is_expired = new Date(content.competition_end) < new Date();
  const is_completed = content.completed;
  return content['@type'] === 'Announcement' ? (
    <>
      <Chip disabled={false} large={false} simple tag="span" className="announcement">
        <ChipLabel tag="span">{content.type?.title}</ChipLabel>
      </Chip>
      {is_expired && (
        <Chip disabled={false} large={false} simple tag="span" className="expired ml-2">
          <ChipLabel tag="span">
            {intl.formatMessage(is_completed ? messages.completed : messages.expired)}
          </ChipLabel>
        </Chip>
      )}
    </>
  ) : null;
};

export default PageHeaderAnnouncement;
