import {
  Callout,
  CalloutText,
  CalloutTitle,
  Card,
  CardBody,
  CardText,
  CardTitle,
} from 'design-react-kit/dist/design-react-kit';
import { defineMessages, useIntl } from 'react-intl';

import { ContactLink } from '@arpav/components/ArpavTheme/View';
import { Icon } from '@arpav/components/ArpavTheme';
import { renderDateTime } from '@arpav/helpers';

const messages = defineMessages({
  info: {
    id: 'Info',
    defaultMessage: 'Info',
  },
  infos: {
    id: 'Information',
    defaultMessage: 'Information',
  },
  start_date: {
    id: 'Start',
    defaultMessage: 'Start',
  },
  exp_date: {
    id: 'Expiration',
    defaultMessages: 'Expiration',
  },
  cig: {
    id: 'CIG',
    defaultMessage: 'CIG',
  },
  cup: {
    id: 'CUP',
    defaultMessage: 'CUP',
  },
  reference: {
    id: 'Reference',
    defaultMessage: 'Reference',
  },
  contact: {
    id: 'Contact',
    defaultMessage: 'Contact',
  },
});

const AnnouncementInfo = ({ content }) => {
  const intl = useIntl();
  const showInfo =
    content.competition_start ||
    content.competition_end ||
    content.cig ||
    content.cup ||
    content.reference;

  const showContactInfo = content.contact_name || content.contact_phone || content.contact_email;

  return (
    <>
      {showInfo && (
        <Callout>
          <CalloutTitle>
            <Icon icon="it-info-circle" padding={false} aria-hidden />
            <span className="sr-only">{intl.formatMessage(messages.info)}</span>
            {intl.formatMessage(messages.infos)}
          </CalloutTitle>
          <CalloutText>
            {content.competition_start ? (
              <p className="mb-0">
                <Icon icon="it-calendar" size="sm" padding={true} aria-hidden />
                <strong>{intl.formatMessage(messages.start_date)}: </strong>
                {renderDateTime(content.competition_start)}
              </p>
            ) : null}
            {content.competition_end ? (
              <p className="mb-0">
                <Icon icon="it-calendar" size="sm" padding={true} aria-hidden />
                <strong>{intl.formatMessage(messages.exp_date)}: </strong>
                {renderDateTime(content.competition_end)}
              </p>
            ) : null}
            {content.cig ? (
              <p className="mb-0">
                <Icon icon="it-check-circle" size="sm" padding={true} aria-hidden />
                <strong>{intl.formatMessage(messages.cig)}: </strong>
                {content.cig}
              </p>
            ) : null}
            {content.cup ? (
              <p className="mb-0">
                <Icon icon="it-check-circle" size="sm" padding={true} aria-hidden />
                <strong>{intl.formatMessage(messages.cup)}: </strong>
                {content.cup}
              </p>
            ) : null}
            {content.cup ? (
              <p className="mb-0">
                <Icon icon="it-check-circle" size="sm" padding={true} aria-hidden />
                <strong>{intl.formatMessage(messages.reference)}: </strong>
                {content.reference}
              </p>
            ) : null}
          </CalloutText>
        </Callout>
      )}
      {showContactInfo && (
        <Card teaser noWrapper className="rounded shadow">
          <CardBody>
            <CardTitle>
              {intl.formatMessage(messages.contact)}
              {content.contact_name && ': '}
              {content.contact_name && <strong>{content.contact_name}</strong>}
            </CardTitle>
            <CardText>
              {content.contact_email ? (
                <p className="mb-0">
                  <Icon icon="it-mail" size="sm" padding={true} aria-hidden />
                  <ContactLink email={content.contact_email} label={true} />
                </p>
              ) : null}
              {content.contact_phone ? (
                <p className="mb-0">
                  <Icon icon="it-telephone" size="sm" padding={true} aria-hidden />
                  <ContactLink tel={content.contact_phone} label={true} />
                </p>
              ) : null}
            </CardText>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default AnnouncementInfo;
