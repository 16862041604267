const HeroSkeleton = () => (
  <div className="skeleton-template">
    <div className="it-hero-wrapper it-overlay it-dark">
      <div className="it-hero-text-wrapper bg-dark">
        <span className="it-category"> </span>
        <h1 className="it-hero-title"> </h1>
      </div>
    </div>
  </div>
);

export default HeroSkeleton;
