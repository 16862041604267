import { Layer, Layers } from '@eeacms/volto-openlayers-map/Layers';

import Borders from './layers/Borders';
import Control from '@arpav/components/ArpavTheme/Blocks/DataMap/Control';
import { Controls } from '@eeacms/volto-openlayers-map/Controls';
import { Interactions } from '@eeacms/volto-openlayers-map/Interactions';
import { MAP_DEFAULT_EXTENT } from '@arpav/constants/Theme';
import { Map } from '@eeacms/volto-openlayers-map/Map';
import Meteo from './layers/Meteo';
import MeteoExtraControl from './layers/MeteoExtraControl';
import MeteoTempControl from './layers/MeteoTempControl';
import NeveValanghe from './layers/NeveValanghe';
import { Overlays } from '@eeacms/volto-openlayers-map/Overlays';
import PM10 from './layers/PM10';
import Radar from './layers/Radar';
import Ozono from './layers/Ozono';
import Pollini from './layers/Pollini';
import Balneazione from './layers/Balneazione';
import LivelliIdro from './layers/LivelliIdro';
import { UniversalLink } from '@plone/volto/components';
import cx from 'classnames';
import { flattenToAppURL } from '@plone/volto/helpers';
import { openlayers } from '@eeacms/volto-openlayers-map';
import { useRef } from 'react';
import { useState } from 'react';

const HomeMap = ({
  meteoShow,
  meteoBtnLabel,
  meteoUrl,
  pm10Show,
  pm10BtnLabel,
  pm10Url,
  ozonoShow,
  ozonoBtnLabel,
  ozonoUrl,
  polliniShow,
  polliniBtnLabel,
  polliniUrl,
  nevevalangheShow,
  nevevalangheBtnLabel,
  nevevalangheUrl,
  nevevalangheKml,
  balneazioneShow,
  balneazioneBtnLabel,
  balneazioneUrl,
  livelliIdroShow,
  livelliIdroBtnLabel,
  livelliIdroUrl,
  radarShow,
  radarBtnLabel,
  radarUrl,
  kmlUrl,
}) => {
  const { proj, source } = openlayers;
  const mapRef = useRef();
  const onRenderComplete = () => {
    mapRef.current.map.updateSize();
  };

  const defaultLayersVisibility = {
    meteo: false,
    pm10: false,
    nevevalanghe: false,
    radar: false,
    ozono: false,
    pollini: false,
    balneazione: false,
    livelliIdro: false,
    kml: kmlUrl?.length,
  };
  const [layersVisibility, setLayersVisibility] = useState({
    ...defaultLayersVisibility,
    meteo: true,
  });
  const [meteoTempIcons, setMeteoTempIcons] = useState([]);
  const [meteoExtraIcons, setMeteoExtraIcons] = useState([]);

  const updateLayersVisibility = visibleLayer => {
    setLayersVisibility({
      ...defaultLayersVisibility,
      [visibleLayer]: true,
    });
  };

  let maxZoom = 10;

  layersVisibility.balneazione == true ? (maxZoom = 12) : (maxZoom = 10);

  return (
    <Map
      view={{
        center: proj.transform([11.8, 45.8], 'EPSG:4326', 'EPSG:3857'),
        zoom: 8.5,
        maxZoom: maxZoom,
        extent: MAP_DEFAULT_EXTENT,
        target: 'map',
      }}
      ref={mapRef}
      onRendercomplete={onRenderComplete}
    >
      <Layers>
        <Layer.Tile
          source={
            new source.OSM({
              // url:
              //   'https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}',
              url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
            })
          }
        />
        <Borders
          visible={layersVisibility.nevevalanghe}
          sourceUrl={nevevalangheKml?.[0]?.['@id']}
        />
        <Borders visible={layersVisibility.kml} sourceUrl={kmlUrl?.[0]?.['@id']} />
        {meteoShow && (
          <Meteo
            visible={layersVisibility.meteo}
            meteoTempIconsSetter={setMeteoTempIcons}
            meteoExtraIconsSetter={setMeteoExtraIcons}
          />
        )}
        {nevevalangheShow && <NeveValanghe visible={layersVisibility.nevevalanghe} />}
        {pm10Show && <PM10 visible={layersVisibility.pm10} />}
        {radarShow && <Radar visible={layersVisibility.radar} />}
        {ozonoShow && <Ozono visible={layersVisibility.ozono} />}
        {polliniShow && <Pollini visible={layersVisibility.pollini} />}
        {balneazioneShow && <Balneazione visible={layersVisibility.balneazione} />}
        {livelliIdroShow && <LivelliIdro visible={layersVisibility.livelliIdro} />}
      </Layers>
      <Controls attribution={false}>
        {meteoShow && (
          <>
            <Control
              className="layer-button-control text-center d-none d-sm-block"
              bottom="0"
              top="0"
              right="0"
            >
              <MeteoTempControl icons={meteoTempIcons} visible={layersVisibility.meteo} />
            </Control>
            <Control
              className="layer-button-control text-center d-none d-sm-block"
              bottom="0"
              top="0"
              left="0"
            >
              <MeteoExtraControl icons={meteoExtraIcons} visible={layersVisibility.meteo} />
            </Control>
          </>
        )}
        <Control
          className="layer-button-control bg-transparent text-center homeMap-buttons"
          top="0.5rem"
          left="0"
          right="0"
        >
          {meteoShow && (
            <button
              className={cx('chip chip-simple chip-lg mr-2 mb-2', {
                selected: layersVisibility.meteo,
              })}
              onClick={() => updateLayersVisibility('meteo')}
              type="button"
            >
              <span className="chip-label">Meteo</span>
            </button>
          )}
          {pm10Show && (
            <button
              className={cx('chip chip-simple chip-lg mr-2 mb-2', {
                selected: layersVisibility.pm10,
              })}
              onClick={() => updateLayersVisibility('pm10')}
              type="button"
            >
              <span className="chip-label">PM10</span>
            </button>
          )}
          {ozonoShow && (
            <button
              className={cx('chip chip-simple chip-lg mr-2 mb-2', {
                selected: layersVisibility.ozono,
              })}
              onClick={() => updateLayersVisibility('ozono')}
              type="button"
            >
              <span className="chip-label">Ozono</span>
            </button>
          )}
          {polliniShow && (
            <button
              className={cx('chip chip-simple chip-lg mr-2 mb-2', {
                selected: layersVisibility.pollini,
              })}
              onClick={() => updateLayersVisibility('pollini')}
              type="button"
            >
              <span className="chip-label">Pollini</span>
            </button>
          )}
          {nevevalangheShow && (
            <button
              className={cx('chip chip-simple chip-lg mr-2 mb-2 disabled', {
                selected: layersVisibility.nevevalanghe,
              })}
              onClick={() => updateLayersVisibility('nevevalanghe')}
              type="button"
            >
              <span className="chip-label">Neve/Valanghe</span>
            </button>
          )}
          {balneazioneShow && (
            <button
              className={cx('chip chip-simple chip-lg mr-2 mb-2', {
                selected: layersVisibility.balneazione,
              })}
              onClick={() => updateLayersVisibility('balneazione')}
              type="button"
            >
              <span className="chip-label">Balneazione</span>
            </button>
          )}
          {livelliIdroShow && (
            <button
              className={cx('chip chip-simple chip-lg mr-2 mb-2', {
                selected: layersVisibility.livelliIdro,
              })}
              onClick={() => updateLayersVisibility('livelliIdro')}
              type="button"
            >
              <span className="chip-label">Livelli idro</span>
            </button>
          )}
          {radarShow && (
            <button
              className={cx('chip chip-simple chip-lg mr-2 mb-2', {
                selected: layersVisibility.radar,
              })}
              onClick={() => updateLayersVisibility('radar')}
              type="button"
            >
              <span className="chip-label">Radar</span>
            </button>
          )}
        </Control>
        <Control
          className="layer-button-control bg-transparent text-center"
          bottom="0.5rem"
          left="0"
          right="0"
        >
          <div className="link-button text-center my-0">
            {meteoShow && meteoUrl?.[0] && meteoBtnLabel && layersVisibility.meteo && (
              <UniversalLink href={flattenToAppURL(meteoUrl?.[0]?.['@id'])} className="btn">
                {meteoBtnLabel}
              </UniversalLink>
            )}
            {pm10Show && pm10Url?.[0] && pm10BtnLabel && layersVisibility.pm10 && (
              <UniversalLink href={flattenToAppURL(pm10Url?.[0]?.['@id'])} className="btn">
                {pm10BtnLabel}
              </UniversalLink>
            )}
            {nevevalangheShow &&
              nevevalangheUrl?.[0] &&
              nevevalangheBtnLabel &&
              layersVisibility.nevevalanghe && (
                <UniversalLink
                  href={flattenToAppURL(nevevalangheUrl?.[0]?.['@id'])}
                  className="btn"
                >
                  {nevevalangheBtnLabel}
                </UniversalLink>
              )}
            {radarShow && radarUrl?.[0] && radarBtnLabel && layersVisibility.radar && (
              <UniversalLink href={flattenToAppURL(radarUrl?.[0]?.['@id'])} className="btn">
                {radarBtnLabel}
              </UniversalLink>
            )}
            {ozonoShow && ozonoUrl?.[0] && ozonoBtnLabel && layersVisibility.ozono && (
              <UniversalLink href={flattenToAppURL(ozonoUrl?.[0]?.['@id'])} className="btn">
                {ozonoBtnLabel}
              </UniversalLink>
            )}
            {polliniShow && polliniUrl?.[0] && polliniBtnLabel && layersVisibility.pollini && (
              <UniversalLink href={flattenToAppURL(polliniUrl?.[0]?.['@id'])} className="btn">
                {polliniBtnLabel}
              </UniversalLink>
            )}
            {balneazioneShow &&
              balneazioneUrl?.[0] &&
              balneazioneBtnLabel &&
              layersVisibility.balneazione && (
                <UniversalLink href={flattenToAppURL(balneazioneUrl?.[0]?.['@id'])} className="btn">
                  {balneazioneBtnLabel}
                </UniversalLink>
              )}
            {livelliIdroShow &&
              livelliIdroUrl?.[0] &&
              livelliIdroBtnLabel &&
              layersVisibility.livelliIdro && (
                <UniversalLink href={flattenToAppURL(livelliIdroUrl?.[0]?.['@id'])} className="btn">
                  {livelliIdroBtnLabel}
                </UniversalLink>
              )}
          </div>
        </Control>
      </Controls>
      <Overlays />
      <Interactions />
    </Map>
  );
};
export default HomeMap;
