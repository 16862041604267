/**
 * Input that supports the floating labels, prepend and append buttons or icons
 */
import React, { useState } from 'react';
import cx from 'classnames';

export default function TextInput(props) {
  const { id, label, placeholder, onChange, prepend, append, size } = props;
  const [isFocused, setIsFocused] = useState(false);

  const toggleFocusLabel = () => {
    setIsFocused(true);
  };

  const toggleBlurLabel = e => {
    if (e.target.value === '') {
      setIsFocused(!isFocused);
    }
  };

  return (
    <div className="form-group">
      <div className={cx('input-group', size ? 'input-group-' + size : '')}>
        {prepend && <div className="input-group-prepend">{prepend}</div>}
        <label htmlFor={id} className={isFocused ? 'active' : ''}>
          {label}
        </label>

        <input
          {...props}
          type="text"
          className={cx('form-control', size ? 'form-control-' + size : '', {
            'focus--mouse': isFocused,
          })}
          onFocus={toggleFocusLabel}
          onBlur={e => toggleBlurLabel(e)}
          name={id}
          onChange={e => {
            onChange(e.target.id, e.target.value);
          }}
          placeholder={placeholder ? placeholder : label}
        />
        {append && <div className="input-group-append">{append}</div>}
      </div>
    </div>
  );
}
