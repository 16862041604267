/**
 * Highlight news actions.
 * @module actions/newsItemsList/newsItemsList
 */

import { GET_NEWSITEMS_LIST } from '@arpav/constants/ActionTypes';

/**
 * Get highlight news function.
 * @function getNewsItemsList
 * @param {string} url Content url.
 * @param {object} data Request data.
 * @param {srting|number} data.b_size Items list size.
 * @param {srting|number} data.b_start Items list start.
 * @param {string} subrequest Optional. Key of the subrequest.
 * @returns {Object} Get schema action.
 */
export function getNewsItemsList(url, data, subrequest) {
  url = typeof url !== 'undefined' ? url : '';
  const query = Object.keys(data || {})
    .map(function (key) {
      return key + '=' + data[key];
    })
    .join('&');

  return {
    type: GET_NEWSITEMS_LIST,
    subrequest,
    request: {
      op: 'get',
      path: `${url}/@newsitemslist${query ? `?${query}` : ''}`,
    },
  };
}
