import { GET_ANNOUNCEMENTS } from '@arpav/constants/ActionTypes';

const initialState = {
  data: null,
  loaded: false,
  loading: false,
  error: null,
};

/**
 * Environmentale theme related items reducer.
 * @function schema
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function announcementsList(state = initialState, action = {}) {
  switch (action.type) {
    /** PENDING */
    case `${GET_ANNOUNCEMENTS}_PENDING`:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    /** SUCCESS */
    case `${GET_ANNOUNCEMENTS}_SUCCESS`:
      return {
        ...state,
        data: action.result,
        loading: false,
        loaded: true,
        error: null,
      };
    /** FAIL */
    case `${GET_ANNOUNCEMENTS}_FAIL`:
      return {
        ...state,
        data: null,
        loading: false,
        loaded: true,
        error: action.error,
      };
    case GET_ANNOUNCEMENTS:
      return initialState;
    default:
      return state;
  }
}
