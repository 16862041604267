/**
 * FooterMain component.
 * @module components/Footer/FooterMain
 */

import { FooterInfos, FooterNavigation, LogoFooter } from '@arpav/components/ArpavTheme/';
import { Col, Container, Row } from 'design-react-kit/dist/design-react-kit';

import { UniversalLink } from '@plone/volto/components';

/**
 * FooterMain component class.
 * @class FooterMain
 * @extends Component
 */
const FooterMain = () => {
  return (
    <div className="it-footer-main">
      <Container tag="div">
        <section>
          <Row className="clearfix" tag="div">
            <Col sm={12} tag="div" widths={['xs', 'sm', 'md', 'lg', 'xl']}>
              <div className="it-brand-wrapper">
                <UniversalLink href="/">
                  <LogoFooter />
                  {/* <BrandTextFooter /> */}
                </UniversalLink>
              </div>
            </Col>
          </Row>
        </section>
        <section className="py-4">
          <FooterInfos />
        </section>
        <section>
          <FooterNavigation />
        </section>
      </Container>
    </div>
  );
};

export default FooterMain;
