import { InlineForm } from '@plone/volto/components';
import NewsInEvidenceBody from '@arpav/components/ArpavTheme/Blocks/NewsInEvidence/NewsInEvidenceBody';
import NewsInEvidenceSchema from '@arpav/components/ArpavTheme/Blocks/NewsInEvidence/schema';
import { SidebarPortal } from '@plone/volto/components';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  newsInEvidence: {
    id: 'News in evidence',
    defaultMessage: 'News in evidence',
  },
});

export const schema = ({ intl }) => ({
  title: intl.formatMessage(messages.newsInEvidence),
  fieldsets: [
    {
      id: 'default',
      title: intl.formatMessage({ id: 'Default' }),
      fields: ['title', 'description'],
    },
  ],
  properties: {
    title: {
      title: intl.formatMessage({ id: 'Title' }),
    },
    description: {
      title: intl.formatMessage({ id: 'Description' }),
      widget: 'textarea',
    },
  },
});

const Edit = props => {
  const { block, data, selected, onChangeBlock, intl } = props;
  const schema = NewsInEvidenceSchema({ intl });
  return (
    <div className="public-ui">
      <SidebarPortal selected={selected}>
        <InlineForm
          schema={schema}
          title={schema.title}
          onChangeField={(id, value) => {
            onChangeBlock(block, {
              ...data,
              [id]: value,
            });
          }}
          formData={data}
        />
      </SidebarPortal>
      <NewsInEvidenceBody {...props} isEditMode={true} />
    </div>
  );
};

export default Edit;
