import { Button, Dropdown, Table } from 'semantic-ui-react';
import { FormattedDate, Icon, ModalForm, Toast } from '@plone/volto/components';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { concat, find, indexOf, map, orderBy, pull } from 'lodash';
import { deleteEventBooking, getEventBooking, resetEventBooking } from '@arpav/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import checkboxCheckedSVG from '@plone/volto/icons/checkbox-checked.svg';
import checkboxIndeterminateSVG from '@plone/volto/icons/checkbox-indeterminate.svg';
import checkboxUncheckedSVG from '@plone/volto/icons/checkbox-unchecked.svg';
import { compose } from 'redux';
import deleteSVG from '@plone/volto/icons/delete.svg';
import editingSVG from '@plone/volto/icons/editing.svg';
import { getBaseUrl } from '@plone/volto/helpers';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import moment from 'moment';

const messages = defineMessages({
  select: {
    id: 'Select…',
  },
  selected: {
    id: '{count} selected',
  },
  deleteConfirm: {
    id: 'Do you really want to delete the following reservation?',
    default: 'Do you really want to delete the following reservation?',
  },
  eventBooking: {
    id: 'Event booking',
    defaultMessage: 'Event booking',
  },
  message: {
    id: 'Message',
    defaultMessage: 'Message',
  },
  messageDescription: {
    id: 'Message that will be sent to the user',
    defaultMessage: 'Message that will be sent to the user',
  },
  messageDelete: {
    id: 'Reservation are been deleted',
    defaultMessage: 'Reservation are been deleted',
  },
  errorDelete: {
    id: 'Error on deleting reservation',
    defaultMessage: 'Error on deleting reservation',
  },
});

const EventSubscriptionTable = ({ intl, items, pathname, history, toastify }) => {
  const loading = useSelector(state => state.eventBooking.delete?.loading);
  const loaded = useSelector(state => state.eventBooking.delete?.loaded);
  const error = useSelector(state => state.eventBooking.delete?.error);

  const [column, setColumn] = useState('surname');
  const [direction, setDirection] = useState('ascending');

  const [rows, setRows] = useState([...(items || [])]);
  const [selected, setSelected] = useState([]);
  const [itemsToDelete, setItemsToDelete] = useState([]);
  const [showDelete, setShowDelete] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setRows([...items]);
    return () => setRows([]);
  }, [items]);

  useEffect(() => {
    if (rows?.length) {
      setRows(orderBy(rows, [column], [direction === 'descending' ? 'desc' : 'asc']));
    }
  }, [direction, column]);

  useEffect(() => {
    if (showDelete) {
      if (!loading && loaded && !error) {
        toastify.toast.success(
          <Toast
            success
            title={intl.formatMessage({ id: 'Success' })}
            content={intl.formatMessage(messages.messageDelete)}
          />
        );
        dispatch(resetEventBooking());
        dispatch(getEventBooking(getBaseUrl(pathname)));
        setShowDelete(false);
      } else if (!loading && error) {
        toastify.toast.error(
          <Toast
            error
            title={intl.formatMessage({
              id: 'Error',
            })}
            content={intl.formatMessage(messages.errorDelete)}
          />
        );
        setShowDelete(false);
      }
    }
  }, [loading, loaded]);

  const onSort = clickedColumn => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
    } else {
      setDirection(direction === 'ascending' ? 'descending' : 'ascending');
    }
  };

  /**
   * On deselect handler
   * @method onDeselect
   * @param {object} event Event object
   * @param {string} value Value
   * @returns {undefined}
   */
  const onDeselect = (event, { value }) => {
    setSelected([...pull(selected, value)]);
  };

  /**
   * On select handler
   * @method onSelect
   * @param {object} event Event object
   * @returns {undefined}
   */
  const onSelect = (event, id) => {
    if (indexOf(selected, id) === -1) {
      setSelected([...concat(selected, id)]);
    } else {
      setSelected([...pull(selected, id)]);
    }
  };

  /**
   * On select all handler
   * @method onSelectAll
   * @returns {undefined}
   */
  const onSelectAll = () => {
    setSelected(map(rows, item => item.id));
  };

  /**
   * On select none handler
   * @method onSelectNone
   * @returns {undefined}
   */
  const onSelectNone = () => {
    setSelected([]);
  };

  const handleActionClick = (id, item) => {
    if (id === 'edit') {
      history.push(`${getBaseUrl(pathname)}/eventsubscription/${item}`);
    } else if (id === 'delete') {
      setItemsToDelete(item ? [...[item]] : [...selected]);
      setShowDelete(true);
    }
  };

  const onDeleteCancel = () => {
    setShowDelete(false);
  };

  const onDeleteOk = data => {
    dispatch(
      deleteEventBooking(getBaseUrl(pathname), null, {
        ...data,
        items: itemsToDelete,
      })
    );
  };

  const getItemById = id => {
    return find(rows, { id: id });
  };

  const getTitleById = id => {
    const item = getItemById(id);
    return item
      ? `${item.surname} ${item.name} ${moment(new Date(item.date)).format('D/MM/YYYY')} ${
          item.start
        }-${item.end}`
      : '';
  };

  return (
    <div className="contents-table-wrapper">
      <ModalForm
        open={showDelete}
        loading={loading}
        loadingMessage={intl.formatMessage({ id: 'Loading' })}
        onSubmit={onDeleteOk}
        onCancel={onDeleteCancel}
        title={intl.formatMessage(messages.deleteConfirm)}
        schema={{
          fieldsets: [
            {
              id: 'default',
              title: intl.formatMessage({ id: 'Default' }),
              fields: ['message'],
            },
          ],
          properties: {
            message: {
              title: intl.formatMessage(messages.message),
              description: intl.formatMessage(messages.messageDescription),
              type: 'string',
              widget: 'textarea',
            },
          },
          required: ['message'],
        }}
      />
      <Table selectable compact singleLine attached sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <Dropdown
                upward={false}
                trigger={
                  <Icon
                    name={
                      selected.length === 0
                        ? checkboxUncheckedSVG
                        : selected.length === items.length
                        ? checkboxCheckedSVG
                        : checkboxIndeterminateSVG
                    }
                    color={selected.length > 0 ? '#007eb1' : '#826a6a'}
                    size="24px"
                  />
                }
                icon={null}
              >
                <Dropdown.Menu>
                  <Dropdown.Header content={intl.formatMessage(messages.select)} />
                  <Dropdown.Item onClick={onSelectAll}>
                    <Icon name={checkboxCheckedSVG} color="#007eb1" size="24px" />{' '}
                    <FormattedMessage id="All" />
                  </Dropdown.Item>
                  <Dropdown.Item onClick={onSelectNone}>
                    <Icon name={checkboxUncheckedSVG} size="24px" /> <FormattedMessage id="None" />
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Header
                    content={intl.formatMessage(messages.selected, {
                      count: selected.length,
                    })}
                  />
                  <Dropdown.Menu scrolling>
                    {map(selected, item => (
                      <Dropdown.Item key={item} value={item} onClick={onDeselect}>
                        <Icon name={deleteSVG} color="#e40166" size="24px" /> {getTitleById(item)}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown.Menu>
              </Dropdown>
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'surname' ? direction : null}
              onClick={() => onSort('surname')}
            >
              <FormattedMessage id="Surname" defaultMessage="Surname" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'name' ? direction : null}
              onClick={() => onSort('name')}
            >
              <FormattedMessage id="Name" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'places_number' ? direction : null}
              onClick={() => onSort('places_number')}
            >
              <FormattedMessage id="Places number" defaultMessage="Places number" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'email' ? direction : null}
              onClick={() => onSort('email')}
            >
              <FormattedMessage id="Email" defaultMessage="Email" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'telephone' ? direction : null}
              onClick={() => onSort('telephone')}
            >
              <FormattedMessage id="Telephone" defaultMessage="Telephone" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'booking_date' ? direction : null}
              onClick={() => onSort('booking_date')}
            >
              <FormattedMessage id="Booking date" defaultMessage="Booking date" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              {selected.length ? (
                <Button icon basic onClick={e => handleActionClick('delete', null, e)}>
                  <Icon name={deleteSVG} color="#e40166" size="24px" className="delete" />
                </Button>
              ) : null}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rows?.map(item => (
            <Table.Row key={item.id}>
              <Table.Cell>
                {indexOf(selected, item.id) !== -1 ? (
                  <Button icon basic aria-label="Unchecked" onClick={e => onSelect(e, item.id)}>
                    <Icon
                      name={checkboxCheckedSVG}
                      color="#007eb1"
                      size="24px"
                      className="checked"
                    />
                  </Button>
                ) : (
                  <Button icon basic aria-label="Checked" onClick={e => onSelect(e, item.id)}>
                    <Icon
                      name={checkboxUncheckedSVG}
                      color="#826a6a"
                      size="24px"
                      className="unchecked"
                    />
                  </Button>
                )}
              </Table.Cell>
              <Table.Cell>{item.surname}</Table.Cell>
              <Table.Cell>{item.name}</Table.Cell>
              <Table.Cell>{item.places_number}</Table.Cell>
              <Table.Cell>
                <a href={`mailto:${item.email}`}>{item.email}</a>
              </Table.Cell>
              <Table.Cell>
                <a href={`tel:${item.telephone}`}>{item.telephone}</a>
              </Table.Cell>
              <Table.Cell>
                <FormattedDate
                  date={item.booking_date}
                  format={{
                    minute: 'numeric',
                    hour: 'numeric',
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  }}
                />
              </Table.Cell>
              <Table.Cell>
                <Button icon basic onClick={e => handleActionClick('edit', item.id, e)}>
                  <Icon name={editingSVG} color="#007eb1" size="24px" className="edit" />
                </Button>
                <Button icon basic onClick={e => handleActionClick('delete', item.id, e)}>
                  <Icon name={deleteSVG} color="#e40166" size="24px" className="delete" />
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default compose(injectIntl, injectLazyLibs(['toastify']))(EventSubscriptionTable);
