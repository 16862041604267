/**
 * ARPAV API content reducer.
 * @module reducers/arpavApiContent/arpavApiContent
 */

import { GET_ARPAV_API_CONTENT } from '@arpav/constants/ActionTypes';

const initialState = {
  subrequests: {},
};

/**
 * ARPAV API content reducer.
 * @function schema
 * @param {Object} arpavApiContent Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function arpavApiContent(state = initialState, action = {}) {
  let { result, url, id } = action;

  switch (action.type) {
    case `${GET_ARPAV_API_CONTENT}_PENDING`:
      return {
        ...state,
        [id || url]: {
          ...state[url],
          loading: true,
          loaded: false,
          error: undefined,
        },
      };
    case `${GET_ARPAV_API_CONTENT}_SUCCESS`:
      return {
        ...state,
        [id || url]: {
          ...state[url],
          loading: false,
          loaded: true,
          error: undefined,
          data: result?.data,
          meta: result?.meta,
        },
      };
    case `${GET_ARPAV_API_CONTENT}_FAIL`:
      return {
        ...state,
        [id || url]: {
          ...state[url],
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    default:
      break;
  }
  return state;
}
